export const chapter8 = {
    id: 8,
    boss: {
        name: "Nexus Prime",
        idleAnimationKey: "boss_08_walk",
        walkAnimationKey: "boss_08_walk",
        jumpAnimationKey: "boss_08_walk",
        attackAnimationKey: "boss_08_attack",
        gethitAnimationKey: "boss_08_gethit",
        destroyedAnimationKey: "boss_08_dead",
        size: {
            scale: 0.4,
        },
        position: {
            y: -30,
        },
        body: {
            size: {
                w: 250,
                h: 200,
            },
            offset: {
                x: -50,
                h: -70,
            },
        },
        texture: ["boss_08_walk", "robot11-walk0.png"],
    },
    creep: {
        walkAnimationKey: "creep_08_walk",
        attackAnimationKey: "creep_08_attack",
        destroyedAnimationKey: "creep_08_die",
        size: {
            scale: 0.8,
        },
        position: {
            y: 0,
        },
        body: {
            offset: {
                x: -70,
                h: -70,
            },
        },
        texture: ["creep_08_walk", "robot_9-walk0.png"],
    },
    background: {
        mining: "chapter_08_bg",
        boss: "chapter_08_bg",
        intro: "chapter_08_intro",
    },
};
