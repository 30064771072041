import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "../events/EventBusComponent";
import { WeaponId } from "@/shared/types/loot-box";
import { getOptimizedSoundConfig } from "@/utils/sound-utils";

const sounds = [
    "boss_fight_start",
    "boss_fight_over",
    "boss_fight_win",
    "coin_recieved",
    "player_rifle_shoot",
    "player_mangun_shoot",
    "player_bazooka_shoot",
    "player_sniper_shoot",
    "reel_spinning",
    "cash_register",
    "spin_tapped",
    "payline",
    "bonus_symbol",
    "bonus_game",
    "spaceship_tapped",
    "bonus_winning_popup",
    "mini_jackpot",
    "minor_jackpot",
    "major_jackpot",
    "grand_jackpot",
    "double_grand_jackpot",
    "boss_fight_over",
    "boss_fight_win",
    "level_up_wheel",
    "drum",
    "special_wheel",
    "spin_wheel",
    "win_usd",
];

const musics = ["mining_bg", "slots_bg", "bonus_bg", "free_bg", "boss_bg", "wheel_bg"];

type MusicId = "mining_bg" | "slots_bg" | "bonus_bg" | "free_bg" | "boss_bg" | "wheel_bg";

export class AudioComponent {
    game: Phaser.Game;
    isBlur: boolean = false;
    eventBusComponent: typeof EventBus;
    backgroundMusic?: MusicId;

    constructor(game: Phaser.Game) {
        this.game = game;
        this.eventBusComponent = EventBus;

        this.setupEventListeners();

        this.setupIOSAudioSession();

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAY_MINING_BG, this.playMiningBgSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAY_SLOTS_BG, this.playSlotsBgSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAY_BONUS_BG, this.playBonusBgSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAY_FREE_BG, this.playFreeBgSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAY_BOSS_BG, this.playBossBgSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAY_WHEEL_BG, this.playWheelBg, this);

        // Mining

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_SHOOT, this.playShootSound, this);
        this.eventBusComponent.on(CUSTOM_EVENTS.CLAIM_REWARD, this.claimRewardSound, this);

        // Boss fight

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_FIGHT_START, this.playBossFightStartSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_FIGHT_OVER, this.playBossFightOverSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_FIGHT_WIN, this.playBossFightWinSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_VOICE, this.playPlayerVoiceSound, this);

        // normal spin
        this.eventBusComponent.on(CUSTOM_EVENTS.REEL_SPINNING, this.playReelSpinningSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.CASH_REGISTER, this.playCashRegisterSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.SPIN_TAPPED, this.playSpinTappedSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PAYLINE_HIGHLIGHT, this.playPaylineHighlightSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.SPIN_WIN, this.playSpinWinSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.WIN_POPUP, this.playWinPopupSound, this);

        // pick bonus

        this.eventBusComponent.on(CUSTOM_EVENTS.BONUS_SYMBOL, this.playBonusSymbolSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BONUS_GAME, this.playBonusGameSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.SPACESHIP_TAPPED, this.playSpaceShipTappedSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BONUS_WINNING_POPUP, this.playBonusWinningPopupSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.JACKPOT_WIN, this.playJackpotWinSound, this);

        // free spin
        this.eventBusComponent.on(CUSTOM_EVENTS.SLOT_ACTIVE, this.playSlotActiveSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.COIN_ANIMATION, this.playCoinAnimationSound, this);

        // lucky wheel

        this.eventBusComponent.on(CUSTOM_EVENTS.LEVEL_UP_WHEEL, this.playLevelUpWheelSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.DRUM, this.playDrumSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.SPECIAL_WHEEL, this.playSpecialWheelSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.SPIN_WHEEL, this.playSpinWheelSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.WINNING_USD, this.playWinningUsdSound, this);

        // common
        this.eventBusComponent.on(CUSTOM_EVENTS.TAP, this.playTapSound, this);
    }

    private setupEventListeners() {
        this.game.events.on(Phaser.Core.Events.BLUR, () => {
            this.disableSound();
            this.isBlur = true;
        });

        this.game.events.on(Phaser.Core.Events.FOCUS, () => {
            this.isBlur = false;
            this.resumeAudio();
        });

        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                this.disableSound();
                this.isBlur = true;
            } else {
                this.isBlur = false;
                this.resumeAudio();
            }
        });
    }

    private setupIOSAudioSession() {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

        if (isIOS) {
            document.addEventListener(
                "touchend",
                () => {
                    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
                    const silentBuffer = audioContext.createBuffer(1, 1, 22050);
                    const source = audioContext.createBufferSource();
                    source.buffer = silentBuffer;
                    source.connect(audioContext.destination);
                    source.start();

                    if (audioContext.state === "suspended") {
                        audioContext.resume();
                    }
                },
                false,
            );
        }
    }

    private resumeAudio() {
        if (this.backgroundMusic) {
            this.playBgSound(this.backgroundMusic);
        }
    }

    get soundEnabled() {
        return this.game.scene.systemScene.settingsManager.getData()?.sound;
    }

    get musicEnabled() {
        return this.game.scene.systemScene.settingsManager.getData()?.music;
    }

    disableSound() {
        sounds.forEach((sound) => {
            this.game.sound.stopByKey(sound);
        });
    }

    disableMusic() {
        musics.forEach((music) => {
            this.game.sound.stopByKey(music);
        });

        this.backgroundMusic = undefined;
    }

    enableMusic() {
        this.playMiningBgSound();
    }

    checkSoundOnBlur(sound: Phaser.Sound.BaseSound) {
        if (this.isBlur) {
            if (sound && sound.isPlaying) {
                sound.stop();
            }
            return true;
        }

        return false;
    }

    checkBackgroundMusicPlaying(id: MusicId) {
        return this.game.sound.get(id)?.isPlaying || this.backgroundMusic === id;
    }

    playBgSound(soundId: MusicId) {
        if (!this.musicEnabled || this.checkBackgroundMusicPlaying(soundId)) {
            return;
        }

        const config = getOptimizedSoundConfig({
            baseVolume: 0.4,
            loop: true,
        });
        if (config) {
            this.stopAllAudio();
            this.game.sound.play(soundId, config);
            this.backgroundMusic = soundId;
        }
    }

    playMiningBgSound() {
        this.playBgSound("mining_bg");
    }

    playSlotsBgSound() {
        this.playBgSound("slots_bg");
    }

    playBonusBgSound() {
        this.playBgSound("bonus_bg");
    }

    playFreeBgSound() {
        this.playBgSound("free_bg");
    }

    playBossBgSound() {
        this.playBgSound("boss_bg");
    }

    playWheelBg() {
        this.playBgSound("wheel_bg");
    }

    playBaseSound(soundId: string, baseVolume: number) {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume,
        });
        if (config) {
            this.game.sound.play(soundId, config);
        }
    }

    playBossFightStartSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.5,
        });
        if (config) {
            this.game.sound.play("boss_fight_start", config);
        }
    }

    playBossFightOverSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.5,
        });
        if (config) {
            this.game.sound.play("boss_fight_over", config);
        }
    }

    playBossFightWinSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.5,
        });
        if (config) {
            this.game.sound.play("boss_fight_win", config);
        }
    }

    playPlayerVoiceSound(weaponId: WeaponId, type: "win" | "lose") {
        let playerName;

        switch (weaponId) {
            case "mangun":
            case "sniper":
                playerName = "musk";
                break;
            case "rifle":
            case "bazooka":
                playerName = "trump";
                break;
            default:
                break;
        }
        if (playerName) {
            this.playBaseSound(`${playerName}_${type}`, 1);
        }
    }

    playShootSound(weaponId: WeaponId) {
        if (!this.soundEnabled) return;
        if (this.checkSoundOnBlur(this.game.sound.get(`player_${weaponId}_shoot`))) {
            return;
        }

        const config = getOptimizedSoundConfig({
            baseVolume: 0.05,
        });
        if (config) {
            this.game.sound.play(`player_${weaponId}_shoot`, config);
        }
    }

    claimRewardSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("coin_recieved", config);
        }
    }

    playReelSpinningSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("reel_spinning", config);
        }
    }

    playCashRegisterSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("cash_register", config);
        }
    }

    playSpinTappedSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("spin_tapped", config);
        }
    }

    playPaylineHighlightSound() {
        if (!this.soundEnabled) return;
        if (this.checkSoundOnBlur(this.game.sound.get("payline"))) {
            return;
        }

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("payline", config);
        }
    }

    playSpinWinSound(soundId: "big_win" | "huge_win" | "massive_win" | "super_win") {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.8,
        });
        if (config) {
            this.game.sound.play(soundId, config);
        }
    }

    playBonusSymbolSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("bonus_symbol", config);
        }
    }

    playBonusGameSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("bonus_game", config);
        }
    }

    playSpaceShipTappedSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("spaceship_tapped", config);
        }
    }

    playBonusWinningPopupSound() {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.6,
        });
        if (config) {
            this.game.sound.play("bonus_winning_popup", config);
        }
    }

    playJackpotWinSound(type: "mini" | "major" | "minor" | "grand" | "double_grand") {
        if (!this.soundEnabled) return;

        const config = getOptimizedSoundConfig({
            baseVolume: 0.8,
        });
        if (config) {
            this.game.sound.play(`${type}_jackpot`, config);
        }
    }

    playTapSound() {
        this.playBaseSound("tap", 0.6);
    }

    playWinPopupSound() {
        this.playBaseSound("win_popup", 0.6);
    }

    playSlotActiveSound() {
        this.playBaseSound("slot_active", 0.6);
    }

    playCoinAnimationSound() {
        this.playBaseSound("coin_animation", 0.6);
    }

    stopAllAudio() {
        this.disableSound();
        this.disableMusic();
    }

    playLevelUpWheelSound() {
        this.playBaseSound("level_up_wheel", 0.6);
    }

    playDrumSound() {
        this.playBaseSound("drum", 0.6);
    }

    playSpecialWheelSound() {
        this.playBaseSound("special_wheel", 0.6);
    }

    playSpinWheelSound() {
        this.playBaseSound("spin_wheel", 0.6);
    }

    playWinningUsdSound() {
        this.playBaseSound("win_usd", 0.6);
    }
}
