export enum LogLevel {
    DEBUG = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3,
    NONE = 4,
}

interface LoggerConfig {
    minLevel: LogLevel;
    enableAlerts: boolean;
}

class Logger {
    private config: LoggerConfig = {
        minLevel: import.meta.env.VITE_LOG_LEVEL
            ? LogLevel[import.meta.env.VITE_LOG_LEVEL as keyof typeof LogLevel]
            : LogLevel.DEBUG,
        enableAlerts: false,
    };

    private namespace?: string;

    constructor(namespace?: string) {
        this.namespace = namespace;
    }

    public configure(config: Partial<LoggerConfig>) {
        this.config = { ...this.config, ...config };
    }

    private shouldLog(level: LogLevel): boolean {
        return level >= this.config.minLevel;
    }

    private formatMessage(level: string, message: any, ...args: any[]): string {
        const timestamp = new Date().toISOString();
        const namespacePrefix = this.namespace ? `${this.namespace} ` : "";
        const formattedMessage = typeof message === "string" ? message : JSON.stringify(message);
        return `[${timestamp}][${level}]${namespacePrefix}${formattedMessage}`;
    }

    // Create a new logger instance with a namespace
    public withNamespace(namespace: string): Logger {
        const namespacedLogger = new Logger(namespace);
        namespacedLogger.configure(this.config);
        return namespacedLogger;
    }

    public debug(message: any, ...args: any[]) {
        if (this.shouldLog(LogLevel.DEBUG)) {
            console.log(this.formatMessage("DEBUG", message), ...args);
        }
    }

    public info(message: any, ...args: any[]) {
        if (this.shouldLog(LogLevel.INFO)) {
            console.info(this.formatMessage("INFO", message), ...args);
        }
    }

    public warn(message: any, ...args: any[]) {
        if (this.shouldLog(LogLevel.WARN)) {
            console.warn(this.formatMessage("WARN", message), ...args);
        }
    }

    public error(message: any, ...args: any[]) {
        if (this.shouldLog(LogLevel.ERROR)) {
            console.error(this.formatMessage("ERROR", message), ...args);
        }
    }

    public alert(message: string) {
        if (this.config.enableAlerts) {
            alert(message);
        }
    }

    public handleError(error: Error, showAlert: boolean = false) {
        this.error(error.message, error);
        if (showAlert && this.config.enableAlerts) {
            this.alert(error.message);
        }
    }
}

// Create a singleton instance
export const appLogger = new Logger();

// Configure logger based on environment
if (import.meta.env.VITE_APP_ENV === "production") {
    appLogger.configure({
        minLevel: import.meta.env.VITE_LOG_LEVEL
            ? LogLevel[import.meta.env.VITE_LOG_LEVEL as keyof typeof LogLevel]
            : LogLevel.ERROR,
        enableAlerts: false,
    });
}
