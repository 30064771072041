// You can write more code here

import { type Mission, type MissionRewardType, claimMission } from "@/lib/api/telegram-backend/methods/mission";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[shared/prefabs/missions/MissionItem]");

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class MissionItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 196, y ?? 54);

        this.blendMode = Phaser.BlendModes.SKIP_CHECK;

        // background
        const background = scene.add.image(0, 0, "missions", "mission-item.png");
        background.scaleX = 0.25127460389438366;
        background.scaleY = 0.25127460389438366;
        this.add(background);

        // missionName
        const missionName = scene.add.text(-111, -18, "", {});
        missionName.setStyle({ "fixedWidth": 160, "fontFamily": "Oxanium", "fontStyle": "bold", "maxLines": 1 });
        missionName.setLineSpacing(1);
        missionName.setWordWrapWidth(160);
        this.add(missionName);

        // missionReward
        const missionReward = scene.add.text(-111, 4, "", {});
        missionReward.setStyle({
            "color": "#D8E63C",
            "fixedWidth": 160,
            "fontFamily": "Oxanium",
            "fontSize": "14px",
            "fontStyle": "bold",
            "maxLines": 1,
        });
        missionReward.setWordWrapWidth(160);
        this.add(missionReward);

        // start
        const start = scene.add.image(110, 0, "missions", "start.png");
        start.scaleX = 0.25159491440221693;
        start.scaleY = 0.25159491440221693;
        this.add(start);

        // claim
        const claim = scene.add.image(129, 0, "missions", "claim.png");
        claim.scaleX = 0.22490318611392535;
        claim.scaleY = 0.22490318611392535;
        claim.visible = false;
        this.add(claim);

        // claimed
        const claimed = scene.add.image(132, 0, "missions", "claimed.png");
        claimed.scaleX = 0.22639520372243868;
        claimed.scaleY = 0.22639520372243868;
        claimed.visible = false;
        this.add(claimed);

        this.missionName = missionName;
        this.missionReward = missionReward;
        this.start = start;
        this.claim = claim;
        this.claimed = claimed;

        /* START-USER-CTR-CODE */
        this.missionName.setLetterSpacing(0.4);
        this.missionReward.setLetterSpacing(0.4);
        /* END-USER-CTR-CODE */
    }

    private missionName: Phaser.GameObjects.Text;
    private missionReward: Phaser.GameObjects.Text;
    private start: Phaser.GameObjects.Image;
    private claim: Phaser.GameObjects.Image;
    private claimed: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private mission: Mission;

    public setDisplayData(mission: Mission) {
        this.mission = mission;

        this.missionName.setText(mission.title);

        // Remove fixed width temporarily to measure actual text width
        this.missionName.setStyle({ fixedWidth: 0 });

        // If text is too wide, reduce font size until it fits
        const maxWidth = 160;
        let fontSize = 16;
        const minFontSize = 12;

        while (this.missionName.width > maxWidth && fontSize > minFontSize) {
            fontSize--;
            this.missionName.setStyle({ fontSize: `${fontSize}px` });
        }

        // Restore fixed width
        this.missionName.setStyle({
            fixedWidth: 160,
            fontSize: `${fontSize}px`,
        });

        if (mission.rewards.length > 0) {
            // For now, default to first reward
            const reward = mission.rewards[0];
            // Use mapping to get the reward type display text
            const rewardTypeMapping: Record<MissionRewardType, string> = {
                "ota_gold": "GOLD",
            };
            this.missionReward.setText(
                `${numberWithCommas(Math.round(reward.rewardValue))} ${rewardTypeMapping[reward.rewardType]}`,
            );
        }

        if (mission.isClaimed) {
            this.start.setVisible(false);
            this.claim.setVisible(false);
            this.claimed.setVisible(true);
        } else {
            this.start.setVisible(true);
            this.claim.setVisible(false);
            this.claimed.setVisible(false);
            this.start.setInteractive({ useHandCursor: true });
            new PushComponent(this.start, () => this.handleStart()).activate();
            new PushComponent(this.claim, () => this.handleClaim()).activate();
        }
    }

    private handleStart() {
        this.emit("mission-start", this.mission);
    }

    public showClaimButton() {
        this.start.setVisible(false);
        this.claim.setInteractive({ useHandCursor: true }).setVisible(true);
    }

    private async handleClaim() {
        if (!this.mission) return;

        this.claim.disableInteractive();

        try {
            const response = await claimMission({ missionId: this.mission.id });

            if (response) {
                // Update UI to show claimed state
                this.claim.setVisible(false);
                this.claimed.setVisible(true);
                this.emit("mission-claimed");
            } else {
                // Re-enable claim button if request failed
                this.claim.setInteractive({ useHandCursor: true });
                logger.error("[handleClaim] Failed to claim mission");
            }
        } catch (error) {
            // Re-enable claim button on error
            this.claim.setInteractive({ useHandCursor: true });
            logger.error("[handleClaim] Error claiming mission:", error);
        }
    }

    destroy() {
        this.start.off("pointerdown");
        this.claim.off("pointerdown");
        super.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
