// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS, EventBusComponent } from "../../components/events/EventBusComponent";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
/* END-USER-IMPORTS */

export default class TopConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 79);

        // topConsole
        const topConsole = scene.add.image(1, -48, "top_console", "top-console.png");
        this.add(topConsole);

        // balancePanelOverview
        const balancePanelOverview = scene.add.image(1, -54, "top_console", "top-console-balance-overview.png");
        this.add(balancePanelOverview);

        // rmxBalanceDisplay
        const rmxBalanceDisplay = scene.add.container(-71, -52);
        rmxBalanceDisplay.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(rmxBalanceDisplay);

        // rmxAmount
        const rmxAmount = new Text(scene, 24, -17);
        rmxAmount.setOrigin(0, 0);
        rmxAmount.text = "0";
        rmxAmount.setStyle({ "color": "#D8E63C", "fontSize": "24px", "fontStyle": "bold" });
        rmxBalanceDisplay.add(rmxAmount);

        // remisCoin
        const remisCoin = scene.add.image(5, -5, "remis_coin");
        remisCoin.scaleX = 0.09879509072226451;
        remisCoin.scaleY = 0.09879509072226451;
        rmxBalanceDisplay.add(remisCoin);

        // goldBalanceDisplay
        const goldBalanceDisplay = scene.add.container(79, -66);
        goldBalanceDisplay.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(goldBalanceDisplay);

        // image_6
        const image_6 = scene.add.image(20, 2, "mining", "Coin2 1.png");
        image_6.scaleX = 0.5516154312274804;
        image_6.scaleY = 0.5516154312274804;
        goldBalanceDisplay.add(image_6);

        // goldAmount
        const goldAmount = new Text(scene, 19, 19);
        goldAmount.setOrigin(0.5, 0.5);
        goldAmount.text = "0";
        goldAmount.setStyle({ "fontSize": "12px", "fontStyle": "bold" });
        goldBalanceDisplay.add(goldAmount);

        this.rmxAmount = rmxAmount;
        this.goldAmount = goldAmount;

        /* START-USER-CTR-CODE */
        // Write your code here
        /* END-USER-CTR-CODE */
    }

    private rmxAmount: Text;
    private goldAmount: Text;

    /* START-USER-CODE */
    public eventBusComponent: EventBusComponent;

    setRmxAmount(amount: number) {
        this.rmxAmount.setText(formatCompactNumber(amount));
    }

    setGoldAmount(amount: number) {
        this.goldAmount.setText(formatCompactNumber(amount));
    }

    handleSyncUser = async () => {
        const cachedUser = this.scene.userManager.getData();
        const updatedUser = await this.scene.userManager.syncWithBackend();
        if (!cachedUser || !updatedUser) return;

        this.scene.tweens.addCounter({
            from: cachedUser.otaGold,
            to: updatedUser.otaGold,
            duration: 500,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.setGoldAmount(value);
            },
        });

        this.scene.tweens.addCounter({
            from: cachedUser.rmx,
            to: updatedUser.rmx,
            duration: 600,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                this.setRmxAmount(Number(tween.getValue().toFixed(3)));
            },
        });
    };

    public syncUserBalances = async () => {
        await this.handleSyncUser();
    };

    updateUser() {
        const user = this.scene.userManager.getData();
        if (user) {
            this.scene.tweens.addCounter({
                from: 0,
                to: user.otaGold,
                duration: 500,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.setGoldAmount(value);
                },
            });

            this.scene.tweens.addCounter({
                from: 0,
                to: user.rmx,
                duration: 600,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    this.setRmxAmount(Number(tween.getValue().toFixed(3)));
                },
            });
        }
    }

    init(eventBusComponent: EventBusComponent, disableLootBox?: boolean) {
        const updateUser = this.updateUser.bind(this);
        this.eventBusComponent = eventBusComponent;
        eventBusComponent.on(CUSTOM_EVENTS.CLAIM_REWARD_SUCCESS, updateUser);

        this.updateUser();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
