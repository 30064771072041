// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class FreeTotalWin extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 131, y ?? 76);

        // totalWin
        const totalWin = new Text(scene, 64, 0);
        totalWin.text = "Total Win";
        totalWin.setStyle({ "color": "#F0EEE9", "fontSize": "22px", "fontStyle": "bold" });
        this.add(totalWin);

        // totalWinAmountText
        const totalWinAmountText = new Text(scene, 64, 37);
        totalWinAmountText.text = "0";
        totalWinAmountText.setStyle({
            "color": "#FEFEFE",
            "fontSize": "40px",
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#D8E63C",
            "shadow.blur": 10,
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(totalWinAmountText);

        this.totalWin = totalWin;
        this.totalWinAmountText = totalWinAmountText;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private totalWin: Text;
    private totalWinAmountText: Text;

    /* START-USER-CODE */

    private amountTween: Phaser.Tweens.Tween | null = null;
    public currentTotalWin: number = 0;

    public setAmount(delta: number) {
        this.currentTotalWin = delta;
        this.setVisible(true);

        if (this.amountTween) {
            this.amountTween.remove();
            this.amountTween = null;
        }

        this.amountTween = this.scene.tweens.addCounter({
            from: 0,
            to: delta,
            duration: 400,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.totalWinAmountText.setText(`${numberWithCommas(value)}`);
            },
            onComplete: () => {
                this.amountTween = null;
            },
        });
    }

    hide() {
        this.totalWinAmountText.setText("+0");
        this.currentTotalWin = 0;
        this.setVisible(false);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
