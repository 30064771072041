// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { getItemTextureName, getRarityColor, getTotalAverageAttackPower } from "@/shared/utils/loot-box";
import { InventoryItem } from "@/shared/types/loot-box";
import Gem from "@/shared/prefabs/loot-box/Gem";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
/* END-USER-IMPORTS */

export default class BossConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // image_1
        const image_1 = scene.add.image(0, 354, "mining", "nav bottom.png");
        this.add(image_1);

        // image_2
        const image_2 = scene.add.image(0, 269, "mining", "wrapper.png");
        this.add(image_2);

        // bulletPanel
        const bulletPanel = scene.add.image(0, 172, "boss_base", "SelectPanel01.png");
        this.add(bulletPanel);

        // weapon
        const weapon = scene.add.image(81, 250, "magnum");
        weapon.scaleX = 0.25;
        weapon.scaleY = 0.25;
        this.add(weapon);

        // weaponRank
        const weaponRank = new Text(scene, 3, 292);
        weaponRank.setOrigin(0, 0);
        weaponRank.text = "N";
        weaponRank.setStyle({ "color": "#DEA614", "fontSize": "10px", "stroke": "#DEA614" });
        this.add(weaponRank);

        // weaponLevel
        const weaponLevel = new Text(scene, 27, 292);
        weaponLevel.setOrigin(0, 0);
        weaponLevel.text = "level 0";
        weaponLevel.setStyle({ "fontSize": "10px" });
        this.add(weaponLevel);

        // weaponAttack
        const weaponAttack = new Text(scene, 86, 292);
        weaponAttack.setOrigin(0, 0);
        weaponAttack.text = "Attack: 0";
        weaponAttack.setStyle({ "color": "#DEA614", "fontSize": "10px", "stroke": "#DEA614" });
        this.add(weaponAttack);

        // gemContainer
        const gemContainer = scene.add.container(-145, 237);
        this.add(gemContainer);

        this.bulletPanel = bulletPanel;
        this.weapon = weapon;
        this.weaponRank = weaponRank;
        this.weaponLevel = weaponLevel;
        this.weaponAttack = weaponAttack;
        this.gemContainer = gemContainer;

        /* START-USER-CTR-CODE */
        this.setupBulletPanel();
        this.setupWeapon();
        this.setupGems();
        /* END-USER-CTR-CODE */
    }

    private bulletPanel: Phaser.GameObjects.Image;
    private weapon: Phaser.GameObjects.Image;
    private weaponRank: Text;
    private weaponLevel: Text;
    private weaponAttack: Text;
    private gemContainer: Phaser.GameObjects.Container;

    /* START-USER-CODE */
    private bulletContainer: Phaser.GameObjects.Container;

    setupBulletPanel() {
        const bulletPanel = this.bulletPanel;
        const bulletContainer = this.scene.add.container(bulletPanel.x, bulletPanel.y);
        for (let i = 0; i < 5; i++) {
            const bullet = this.scene.add.sprite((i - 2) * -18, 80, "bullet_drop", "Property 1=bullet_drop_f_01.png");
            bulletContainer.add(bullet);
        }
        this.bulletContainer = bulletContainer;
        this.add(bulletContainer);
    }

    setupWeapon() {
        const userWeapon = this.scene.userManager.getWeapon();
        if (userWeapon) {
            const itemTextureName = getItemTextureName(userWeapon);
            if (itemTextureName) this.weapon.setTexture("loot_box", itemTextureName + ".png");
            this.weaponRank.text = userWeapon.rarity.toUpperCase();
            this.weaponRank.setStyle({ color: getRarityColor(userWeapon) });
            this.weaponLevel.text = `Level ${userWeapon.level}`;
            const items = this.scene.userManager.getInventoryItems();
            if (items) {
                const totalAttack = getTotalAverageAttackPower(items);
                this.weaponAttack.text = `Total Attack: ${formatCompactNumber(totalAttack)}`;
            }
        } else {
            this.weapon.visible = false;
            this.weaponRank.visible = false;
            this.weaponLevel.visible = false;
            this.weaponAttack.visible = false;
        }
    }

    setupGems() {
        const fightingEquipments = this.scene.userManager.getInventoryItems();
        if (fightingEquipments) {
            fightingEquipments
                .filter((equipment: InventoryItem) => equipment.name === "gem")
                .sort((a: InventoryItem, b: InventoryItem) => a.slot - b.slot)
                .forEach((equipment: InventoryItem, index: number) => {
                    const row = Math.floor(index / 3);
                    const col = index % 3;
                    const gem = new Gem(this.scene, col * 55, row * 55);
                    gem.init(equipment, {
                        hideLevel: true,
                        hideRank: false,
                    });
                    gem.scale = 0.6;
                    this.gemContainer.add(gem);
                });
        }
    }

    playBulletAnimation(index: number) {
        const bullet = (this.bulletContainer.list as Phaser.GameObjects.Sprite[])[Math.abs(index - 4)];
        if (!bullet) return;
        bullet.play("bullet_drop");
    }

    reset() {
        this.bulletContainer.list.forEach((bullet: Phaser.GameObjects.GameObject) => {
            (bullet as Phaser.GameObjects.Sprite).stop();
            (bullet as Phaser.GameObjects.Sprite).setTexture("bullet_drop", "Property 1=bullet_drop_f_01.png");
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
