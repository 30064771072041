// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Jackpot from "../prefabs/normal-spin/Jackpot";
/* START-USER-IMPORTS */
import SpaceShip from "../prefabs/pick-bonus/SpaceShip";
import { spinPiggyJackpot, SpinPiggyJackpotResponse } from "@/lib/api/telegram-backend/client";
import { PickBonusType, randomPickBonus } from "../utils/random-pick-bonus";
import { DEPTH_PRIORITY, SCENE_KEYS } from "@/ota-slots/constants";
import { setupBackground } from "../helpers/setup-background";
import JackpotWinningPopup from "../prefabs/popup/JackpotWinningPopup";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import { SpinData } from "../types";
import { setupScreenTracking } from "@/services/analytics";
/* END-USER-IMPORTS */

export default class PickBonus extends Phaser.Scene {
    constructor() {
        super("SlotsPickBonus");

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // pick_bonus_bg
        this.add.image(195, 422, "pick_bonus_bg");

        // jackpot
        const jackpot = new Jackpot(this, 195, 41);
        this.add.existing(jackpot);

        // pick_bonus
        this.add.image(204, 153, "pick_bonus");

        // backgroundShadow
        const backgroundShadow = this.add.rectangle(195, 422, 390, 844);
        backgroundShadow.alpha = 0;
        backgroundShadow.isFilled = true;
        backgroundShadow.fillColor = 0;
        backgroundShadow.fillAlpha = 0.5;

        this.jackpot = jackpot;
        this.backgroundShadow = backgroundShadow;

        this.events.emit("scene-awake");
    }

    private jackpot!: Jackpot;
    private backgroundShadow!: Phaser.GameObjects.Rectangle;

    /* START-USER-CODE */
    public pickedSpaceShips: SpaceShip[] = [];
    public spaceShips: SpaceShip[] = [];
    public picking: boolean = false;

    public sceneKey: string;
    public spinData: SpinData;

    async init(data: { sceneKey: string; spinData: SpinData }) {
        setupScreenTracking(this);
        if (!data.spinData) return;
        this.sceneKey = data.sceneKey;
        this.spinData = data.spinData;

        // try refresh piggy jackpot response
        if (!this.spinData?.piggyJackpotResponse) {
            const res = await spinPiggyJackpot(this.spinData.spinId);
            this.spinData.piggyJackpotResponse = res as SpinPiggyJackpotResponse;
        }
    }

    create() {
        this.editorCreate();
        this.setupBackground();
        this.setupSpaceShip();
        this.setupJackpot();

        EventBus.emit(CUSTOM_EVENTS.PLAY_BONUS_BG);

        this.events.once(Phaser.Scenes.Events.SHUTDOWN, this.shutdown);
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/pick_bonus_bg.png");
    }

    setupJackpot() {
        this.jackpot.totalBet = this.spinData!.totalBet;
        this.jackpot.start();
    }

    setupSpaceShip() {
        const TOTAL = 15;
        const ITEM_PER_ROW = 3;
        const ROW_GAP = 115;
        const COL_GAP = 130;
        const START_X_POSITION = 10;
        const START_Y_POSITION = 260;

        Array.from({ length: TOTAL }, (v, i) => i).map((index) => {
            const currentRow = Math.floor(index / ITEM_PER_ROW); // 1..5
            const positionInColumn = index % ITEM_PER_ROW; // 0,1,2
            const spaceShip = new SpaceShip(
                this,
                START_X_POSITION + positionInColumn * COL_GAP,
                START_Y_POSITION + currentRow * ROW_GAP,
            );
            spaceShip.pickType = "";
            spaceShip.index = index;
            spaceShip.setInteractive();
            spaceShip.once("pointerdown", this.handlePointerDown, this);
            this.add.existing(spaceShip);
            this.spaceShips.push(spaceShip);
        });

        this.pickedSpaceShips = [];
    }

    setupBackgroundShadow = () => {
        this.backgroundShadow.setDepth(1);
        this.add.tween({
            targets: this.backgroundShadow,
            duration: 150,
            delay: 500,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear,
            onComplete: () => {
                this.time.delayedCall(1000, () => {
                    this.picking = false;
                    this.handleWinPopup();
                });
            },
        });
    };

    handleWinPopup = () => {
        const piggyJackpotResponse = this.spinData!.piggyJackpotResponse;
        if (!piggyJackpotResponse) return;

        const popupContainer = new JackpotWinningPopup(this, 0, 0);
        popupContainer.winAmount = piggyJackpotResponse.finalReward;
        popupContainer.winType = piggyJackpotResponse.finalType;
        popupContainer.isAuto = this.spinData!.isAuto;

        popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        this.add.existing(popupContainer);
        popupContainer.init();
        popupContainer.playWinAnimation();
        EventBus.emit(CUSTOM_EVENTS.BONUS_WINNING_POPUP);

        popupContainer.once("destroy", () => {
            this.scene.stop(SCENE_KEYS.PICK_BONUS).wake(this.sceneKey, {
                finalReward: piggyJackpotResponse.finalReward || 0,
                fromScene: SCENE_KEYS.PICK_BONUS,
                isAuto: this.spinData!.isAuto,
            });
        });
    };

    handlePointerDown = (spaceShip: SpaceShip) => {
        const piggyJackpotResponse = this.spinData!.piggyJackpotResponse;
        if (!piggyJackpotResponse) return;

        const setupBackgroundShadow = this.setupBackgroundShadow;

        if (this.picking || !!this.pickedSpaceShips.find((s) => s.index === spaceShip.index)) {
            return;
        }

        this.picking = true;
        const pickIndex = this.pickedSpaceShips.length;
        const symbol = piggyJackpotResponse.result[pickIndex] as PickBonusType;

        this.pickedSpaceShips.push(spaceShip);

        spaceShip.pickType = symbol;
        spaceShip.playAnimation();
        EventBus.emit(CUSTOM_EVENTS.SPACESHIP_TAPPED);

        if (this.pickedSpaceShips.length === piggyJackpotResponse.result.length) {
            this.scene.scene.time.delayedCall(500, () => {
                this.pickedSpaceShips
                    .filter((ship) => ship.pickType === piggyJackpotResponse.finalType)
                    .forEach((p) => {
                        p.setDepth(2);
                    });

                let unpickSpaceShips = randomPickBonus(15 - piggyJackpotResponse.result.length, false);
                if (piggyJackpotResponse.hasUpgraded) {
                    unpickSpaceShips = unpickSpaceShips.map(spaceShip.handleUpgradePickBonusType);
                }

                const spaceShips = this.spaceShips
                    .filter((ship) => !ship.pickType)
                    .map((p, idx) => {
                        p.pickType = unpickSpaceShips[idx] as PickBonusType;
                        return p;
                    });

                spaceShips.forEach((p) => p.playAnimation());

                setupBackgroundShadow();
            });
        } else {
            const totalPigUpgrade = this.pickedSpaceShips.filter((p) => p.pickType === "upgrade_chance").length;

            if (symbol === "upgrade_chance" && totalPigUpgrade === 3) {
                this.scene.scene.time.delayedCall(1000, () => {
                    this.pickedSpaceShips.forEach((p) => p.upgradeAnimation());
                    this.jackpot.doUpgrade();

                    if (this.spinData!.piggyJackpotResponse) {
                        this.spinData!.piggyJackpotResponse.result = piggyJackpotResponse.result.map(
                            spaceShip.handleUpgradePickBonusType,
                        );

                        this.spinData!.piggyJackpotResponse.finalType = spaceShip.handleUpgradePickBonusType(
                            piggyJackpotResponse.finalType,
                        );
                    }

                    this.picking = false;
                });
            } else {
                this.picking = false;
            }
        }
    };

    shutdown() {
        EventBus.emit(CUSTOM_EVENTS.PLAY_SLOTS_BG);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
