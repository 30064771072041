export const chapter7 = {
    id: 7,
    boss: {
        name: "Volterra",
        idleAnimationKey: "boss_07_attack",
        walkAnimationKey: "boss_07_walk",
        jumpAnimationKey: "boss_07_walk",
        attackAnimationKey: "boss_07_attack",
        gethitAnimationKey: "boss_07_gethit",
        destroyedAnimationKey: "boss_07_dead",
        size: {
            scale: 0.55,
        },
        position: {
            y: 0,
        },
        texture: ["boss_07_attack", "robot10-attack0.png"],
    },
    creep: {
        walkAnimationKey: "creep_07_walk",
        attackAnimationKey: "creep_07_attack",
        destroyedAnimationKey: "creep_07_die",
        size: {
            scale: 1,
        },
        position: {
            y: -10,
        },
        texture: ["creep_07_walk", "tile000.png"],
    },
    background: {
        mining: "chapter_07_bg",
        boss: "chapter_07_bg",
        intro: "chapter_07_bg",
    },
};
