// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Reels from "../prefabs/normal-spin/Reels";
import Jackpot from "../prefabs/normal-spin/Jackpot";
import TotalBet from "../prefabs/normal-spin/TotalBet";
import SlotsBalance from "../prefabs/normal-spin/SlotsBalance";
import TotalWin from "../prefabs/normal-spin/TotalWin";
import LootBox from "../prefabs/normal-spin/LootBox";
import Store from "../../shared/prefabs/telegram-star/Store";
import SpinButton from "../prefabs/normal-spin/SpinButton";
import AutoSpinWinDisplay from "../prefabs/auto-spin/AutoSpinWinDisplay";
/* START-USER-IMPORTS */
import { spinSlotMachine } from "@/lib/api/telegram-backend/client";
import { DEPTH_PRIORITY, SCENE_KEYS } from "@/ota-slots/constants";
import { getWinType } from "../utils/get-win-type";
import { setupBackground } from "../helpers/setup-background";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { EventBus } from "@/lib/event-bus";
import ActionDialog from "@/shared/prefabs/action-dialog/ActionDialog";
import { LoginData } from "@/shared/registry/user";
import { miningBossData, openBoxData, spinData, userDataWithRifle } from "@/shared/plugins/tutorial/mock-data";
import LootBoxPopup from "@/shared/prefabs/loot-box/LootBoxPopup";
import { InventoryItem } from "@/shared/types/loot-box";
import JackpotPopup from "../prefabs/popup/JackpotPopup";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import type { SpinData } from "../types";
import SpinWinningPopup from "../prefabs/popup/SpinWinningPopup";
import Missions from "@/shared/prefabs/missions/Missions";
import { PopupManager } from "@/shared/managers/PopupManager";
import { BUTTON_NAMES, SCREEN_NAMES, setupScreenTracking, trackButtonTap } from "@/services/analytics";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[ota-slots/scenes/Game]");
/* END-USER-IMPORTS */

export default class Game extends Phaser.Scene {
    constructor() {
        super("SlotsGame");

        /* START-USER-CTR-CODE */
        this.popupManager = new PopupManager(this);
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        this.add.image(195, 422, "slots_bg");

        // bottom_console_png
        this.add.image(195, 675, "normal_spin", "bottom console.png");

        // top_console_png
        this.add.image(195, 73, "normal_spin", "top console.png");

        // reel_png
        this.add.image(195, 335, "normal_spin", "reel.png");

        // reels
        const reels = new Reels(this, 10, 227);
        this.add.existing(reels);

        // jackpot
        const jackpot = new Jackpot(this, 195, 120);
        this.add.existing(jackpot);

        // totalBet
        const totalBet = new TotalBet(this, 108, 703);
        this.add.existing(totalBet);

        // slotsBalance
        const slotsBalance = new SlotsBalance(this, 139, 12);
        this.add.existing(slotsBalance);

        // totalWin
        const totalWin = new TotalWin(this, 195, 510);
        this.add.existing(totalWin);
        totalWin.visible = false;

        // lootBox
        const lootBox = new LootBox(this, 187, 621);
        this.add.existing(lootBox);

        // storePopup
        const storePopup = new Store(this, 195, 95);
        this.add.existing(storePopup);
        storePopup.visible = false;

        // maxBetButton
        const maxBetButton = this.add.image(349, 782, "buttons_interface", "max_bet_01.png");
        maxBetButton.scaleX = 0.3;
        maxBetButton.scaleY = 0.3;

        // spinButton
        const spinButton = new SpinButton(this, 0, 710);
        this.add.existing(spinButton);

        // autoSpinWinDisplay
        const autoSpinWinDisplay = new AutoSpinWinDisplay(this, 194, 379);
        this.add.existing(autoSpinWinDisplay);

        // settings
        const settings = this.add.image(350, 40, "buttons_interface", "settings_button_01.png");
        settings.scaleX = 0.5;
        settings.scaleY = 0.5;

        // menuLabel_1
        const menuLabel_1 = this.add.text(349, 74, "", {});
        menuLabel_1.setOrigin(0.5, 0.5);
        menuLabel_1.text = "Menu";
        menuLabel_1.setStyle({
            fontFamily: "Oxanium",
            fontSize: "14px",
            fontStyle: "bold",
            stroke: "#000",
            strokeThickness: 1,
        });

        // shopIcon
        const shopIcon = this.add.image(46, 777, "shop-icon");
        shopIcon.scaleX = 0.9;
        shopIcon.scaleY = 0.9;

        // backButton
        const backButton = this.add.image(47, 40, "back_btn");

        // backLabel
        const backLabel = this.add.text(47, 72, "", {});
        backLabel.setOrigin(0.5, 0.5);
        backLabel.text = "Back";
        backLabel.setStyle({
            fontFamily: "Oxanium",
            fontSize: "14px",
            fontStyle: "bold",
            stroke: "#000",
            strokeThickness: 1,
        });

        this.reels = reels;
        this.jackpot = jackpot;
        this.totalBet = totalBet;
        this.slotsBalance = slotsBalance;
        this.totalWin = totalWin;
        this.lootBox = lootBox;
        this.storePopup = storePopup;
        this.maxBetButton = maxBetButton;
        this.spinButton = spinButton;
        this.autoSpinWinDisplay = autoSpinWinDisplay;
        this.settings = settings;
        this.shopIcon = shopIcon;
        this.backButton = backButton;

        this.events.emit("scene-awake");
    }

    private reels!: Reels;
    private jackpot!: Jackpot;
    private totalBet!: TotalBet;
    private slotsBalance!: SlotsBalance;
    private totalWin!: TotalWin;
    private lootBox!: LootBox;
    private storePopup!: Store;
    private maxBetButton!: Phaser.GameObjects.Image;
    private spinButton!: SpinButton;
    private autoSpinWinDisplay!: AutoSpinWinDisplay;
    private settings!: Phaser.GameObjects.Image;
    private shopIcon!: Phaser.GameObjects.Image;
    private backButton!: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private isSlotsGameReady: boolean = false;
    private isAuto: boolean = false;
    private spinData: SpinData;
    private resolveFreeSpin?: (value: unknown) => void;
    private resolvePickBonus?: (value: unknown) => void;
    private actionDialog?: ActionDialog;
    private missionsPopup?: Missions;
    private popupManager: PopupManager;
    private maxBetButtonComponent?: PushComponent;
    private backButtonComponent?: PushComponent;
    private settingsButtonComponent?: PushComponent;
    private shopIconComponent?: PushComponent;
    private static hasVisitedMining: boolean = false;

    init() {
        setupScreenTracking(this);
        const step = this.userManager.getIntroStep();
        const isTutorialCompleted = this.userManager.isTutorialCompleted();
        if (!isTutorialCompleted) {
            this.time.delayedCall(1000, () => {
                this.tutorial.init(step as string);
                if (step === "8") {
                    // mock lootbox
                    this.lootBox.updateProgress(100);
                }
            });
        }
    }

    create() {
        this.editorCreate();

        this.setupBackground();
        this.setupSpinButton();
        this.setupMaxBetButton();
        this.setupBackButton();
        this.setupSettingsButton();
        this.setupActionDialog();
        this.setupLootBox();
        this.setupTotalBet();
        this.setupJackpot();

        EventBus.emit(CUSTOM_EVENTS.PLAY_SLOTS_BG);

        const user = this.userManager.getData();
        const config = this.userManager.getConfigData();
        const loginData = this.userManager.getLoginData();

        if (user) {
            this.spinButton.resetSpinButton();
            this.isSlotsGameReady = true;

            if (loginData) {
                this.setupStorefront({
                    provider: loginData.provider,
                    platform: loginData.platform,
                });
            }
            this.slotsBalance.init(user.otaGold);
        }

        if (config) {
            this.totalBet.setBetsByLevel(config.betSchema);
        }

        this.events.on("wake", this.onWake, this);
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, this.shutdown, this);

        // preload pick bonus and free spin asset pack
        this.load.pack("pick-bonus-asset-pack", "ota-slots/pick-bonus-asset-pack.json");
        this.load.pack("free-spin-asset-pack", "ota-slots/free-spin-asset-pack.json");
        this.load.start();

        this.slotsBalance.on(SlotsBalance.EVENTS.INSUFFICIENT_BALANCE_CLICK, async () => {
            this.slotsBalance.disableSlotsBalanceTextInteractive();
            await this.popupManager.checkActivePopups(() => {
                this.syncAndUpdateBalance();
            });
            this.slotsBalance.enableSlotsBalanceTextInteractive();
        });
    }

    shutdown() {
        setupBackground();
        this.sys.game.audio.stopAllAudio();
        this.events.off(Phaser.Scenes.Events.WAKE, this.onWake, this);
        if (this.missionsPopup) {
            this.missionsPopup.destroy();
            this.missionsPopup = undefined;
        }
    }

    async onWake(
        _: Phaser.Scene,
        data: {
            finalReward: number;
            fromScene: typeof SCENE_KEYS.FREE_SPIN | typeof SCENE_KEYS.PICK_BONUS;
        },
    ) {
        this.setupBackground();

        if (!this.spinData || !data) return;
        const totalWinAmount = data.finalReward + this.totalWin.currentTotalWin;

        this.totalWin.setAmount(totalWinAmount);

        // if user back from PickBonus and free spin is triggered
        if (data.fromScene === SCENE_KEYS.PICK_BONUS && this.spinData.isFreeSpinTrigger) {
            // update balance & total win from PickBonus
            await this.slotsBalance.updateBalance(data.finalReward);
            // continue run free spin flow
            this.scene.sleep(SCENE_KEYS.GAME).launch(SCENE_KEYS.FREE_SPIN, this.spinData);
            return;
        }

        const winType = getWinType(totalWinAmount, this.spinData.totalBet);
        if (winType !== "none") {
            await this.handleShowWinningPopup({
                winType,
                totalWinAmount,
                isAuto: this.spinData.isAuto,
            });
        }

        this.spinData = undefined;
        await this.slotsBalance.updateBalance(data.finalReward);
        if (this.resolveFreeSpin) {
            this.resolveFreeSpin(true);
            this.resolveFreeSpin = undefined;
        }
        if (this.resolvePickBonus) {
            this.resolvePickBonus(true);
            this.resolvePickBonus = undefined;
        }
    }

    setupJackpot() {
        this.totalBet.on(TotalBet.EVENTS.BET_CHANGED, (betAmount: number) => {
            this.jackpot.totalBet = betAmount;
            this.jackpot.start();
        });
    }

    setupTotalBet() {
        this.totalBet.on(TotalBet.EVENTS.BET_CHANGED, (betAmount: number) => {
            this.slotsBalance.handleFlashBalanceWarning(betAmount);
        });
    }

    setupLootBox() {
        this.lootBox.init();
        this.lootBox.on(
            ActionDialog.EVENTS.SHOW_BATTLE_CONFIRMATION,
            ({
                chance,
                level,
                step,
            }: {
                chance: number;
                level: number;
                step: string;
            }) => {
                if (this.actionDialog == null) return;
                this.actionDialog.open({
                    type: "battle-confirmation",
                    data: { chance, level, step },
                });
            },
        );

        this.lootBox.on(CUSTOM_EVENTS.SYNC_USER, async () => {
            const updatedUser = await this.userManager.syncWithBackend();
            if (!updatedUser) return;
            this.slotsBalance.setBalance(updatedUser.otaGold);
        });
    }

    setupActionDialog() {
        this.actionDialog = new ActionDialog(this, 195, 720);
        this.add.existing(this.actionDialog);
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/slots_bg.png");
    }

    setupMaxBetButton() {
        this.maxBetButtonComponent = new PushComponent(
            this.maxBetButton,
            () => {
                if (!this.isSlotsGameReady || this.isAuto) return;

                this.totalBet.setMaxBet();
            },
            this,
        ).activate();
    }

    setupBackButton() {
        this.backButtonComponent = new PushComponent(
            this.backButton,
            () => {
                if (this.spinButton.getIsInteractive() && !this.isAuto) {
                    // Stop all audio before transitioning
                    this.sys.game.audio.stopAllAudio();

                    this.scene.start("MiningGame");
                }
            },
            this,
        ).activate();
    }

    setupSettingsButton() {
        this.settingsButtonComponent = new PushComponent(
            this.settings,
            () => {
                this.scene.sleep(this.scene.key);
                this.scene.run("Settings", {
                    forceReload: false,
                    fromScene: this.scene.key,
                    onReturn: () => {
                        this.scene.wake(this.scene.key);
                        this.scene.stop("Settings");
                    },
                });
            },
            this,
        ).activate();
    }

    setupSpinButton() {
        this.spinButton.setSpinButtonInteractive();

        // Spin related
        this.spinButton.on("normal-spin", this.handleNormalSpin);
        this.spinButton.on("auto-spin", this.handleAutoSpin);
        this.spinButton.on("auto-spin-stop", this.handleAutoSpinStop);

        // Non-spin related
        this.spinButton.on("auto-spin-option-selected", this.handleAutoSpinOptionSelected);
    }

    setupStorefront(authData: LoginData) {
        this.shopIconComponent = new PushComponent(
            this.shopIcon,
            () => {
                trackButtonTap(BUTTON_NAMES.STORE_ICON, SCREEN_NAMES.SLOTS);
                this.shopIconComponent?.deactivate();
                this.storePopup?.openStore(authData);
            },
            this,
        ).activate();

        this.storePopup.on("set-balance", (balance: number) => {
            this.slotsBalance.setBalance(balance);
        });
        this.storePopup.on("hidden", () => {
            this.shopIconComponent?.activate();
        });
    }

    async doSpin({
        isAuto,
        isAutoFast = false,
    }: {
        isAuto: boolean;
        isAutoFast?: boolean;
    }) {
        this.totalWin.hide();
        const totalBet = this.totalBet.getCurrentBet();

        this.slotsBalance.updateBalance(-totalBet);

        this.reels.cancelHighlightPaylines();

        const stopMockSpinReels = this.reels.mockSpinReels({ isAutoFast });

        const data = await spinSlotMachine(totalBet);

        stopMockSpinReels();

        if (!data) {
            logger.error("[doSpin] No slot result");
            this.spinButton.resetSpinButton();
            return;
        }

        this.lootBox.syncWithServer();

        this.spinData = {
            ...data,
            totalBet,
            isAuto,
            isAutoFast,
        };

        await this.reels.spinReels(this.spinData);

        const spinData = this.spinData;

        if (this.spinData.isAuto) {
            const isSpecialTrigger = this.spinData?.isPiggyJackpotTrigger || this.spinData?.isFreeSpinTrigger;
            if (isSpecialTrigger) {
                await this.handleWinPopup();
            } else {
                await this.handleBalanceAndTotalWin(spinData);
            }
        } else {
            await Promise.all([
                // show win popup if free spin is triggered it will be handled in onWake
                this.handleWinPopup(),
                // update balance & total win
                this.handleBalanceAndTotalWin(spinData),
            ]);
        }
    }

    async doMockSpin() {
        this.spinButton.setSpinButtonUninteractive();
        this.reels.cancelHighlightPaylines();

        this.slotsBalance.updateBalance(-1000);

        const stopMockSpinReels = this.reels.mockSpinReels();

        stopMockSpinReels();

        this.spinData = {
            ...spinData,
            totalBet: 1000,
            isAuto: false,
            isAutoFast: false,
        };

        await this.reels.spinReels(this.spinData);
        await this.handleWinPopup();
        await this.handleBalanceAndTotalWin(this.spinData);
        this.tutorial.eventBusComponent.emit("next", {
            step: "8",
        });
        this.lootBox.updateProgress(100);
        this.spinButton.setSpinButtonInteractive();
    }

    doMockOpenBox() {
        const popup = new LootBoxPopup(this, 0, 0);

        const rewardItem = openBoxData.lootBox.item as InventoryItem;
        popup.open(rewardItem, "equip");

        this.add.existing(popup);

        popup.once("equip", async () => {
            this.userManager.syncWithMockData(userDataWithRifle.user);
            const response = miningBossData;
            if (response && response.winChance >= 50) {
                this.lootBox.emit("action-dialog:show:battle-confirmation", {
                    chance: response.winChance,
                    level: rewardItem.level,
                    step: "9",
                });
            }
        });
    }

    private checkSufficientBalance = (): boolean => {
        const balance = this.slotsBalance.getCurrentBalance();
        const totalBet = this.totalBet.getCurrentBet();
        this.slotsBalance.handleFlashBalanceWarning(totalBet);
        return balance >= totalBet;
    };

    private async syncAndUpdateBalance() {
        const updatedUser = await this.userManager.syncWithBackend();
        if (!updatedUser) return;
        await this.slotsBalance.setBalance(updatedUser.otaGold);
        this.slotsBalance.handleFlashBalanceWarning(this.totalBet.getCurrentBet());
    }

    handleNormalSpin = async () => {
        EventBus.emit(CUSTOM_EVENTS.SPIN_TAPPED);

        if (!this.checkSufficientBalance()) {
            this.popupManager.checkActivePopups(async () => {
                await this.syncAndUpdateBalance();
            });
            this.spinButton.resetSpinButton();
            return;
        }

        this.disableItemsDuringSpin();

        await this.doSpin({
            isAuto: false,
            isAutoFast: this.spinButton.getIsAutoFastEnabled(),
        });

        this.enableItemsAfterSpin();

        await this.syncAndUpdateBalance();
        this.spinButton.resetSpinButton();
        this.slotsBalance.handleFlashBalanceWarning(this.totalBet.getCurrentBet());
    };

    handleAutoSpin = async () => {
        EventBus.emit(CUSTOM_EVENTS.SPIN_TAPPED);

        this.startAutoSpinState();

        while (this.isAuto) {
            if (!this.checkSufficientBalance()) {
                this.popupManager.checkActivePopups(async () => {
                    await this.syncAndUpdateBalance();
                });
                this.resetAutoSpinState();
                return;
            }
            this.spinButton.onAutoSpin();
            await this.doSpin({
                isAuto: true,
                isAutoFast: this.spinButton.getIsAutoFastEnabled(),
            });

            if (this.spinData?.isPiggyJackpotTrigger) {
                await this.waitPickBonus();
            }

            if (this.spinData?.isFreeSpinTrigger) {
                await this.waitFreeSpin();
            }
            await this.syncAndUpdateBalance();
        }

        this.time.delayedCall(500, () => {
            this.resetAutoSpinState();
        });
    };

    handleAutoSpinOptionSelected = (isOptionSelected: boolean) => {
        this.totalBet.resetYPosition();
        if (isOptionSelected) {
            this.totalBet.setY(this.totalBet.y - 26);
        }
    };

    disableItemsDuringSpin = () => {
        this.lootBox.disableLootBoxIconInteractive();
        this.maxBetButtonComponent?.deactivate();
        this.totalBet.disableButtons();
        this.backButtonComponent?.deactivate();
        this.settingsButtonComponent?.deactivate();
        this.shopIconComponent?.deactivate();
        this.slotsBalance.disableSlotsBalanceTextInteractive();
    };

    enableItemsAfterSpin = () => {
        this.lootBox.enableLootBoxIconInteractive();
        this.maxBetButtonComponent?.activate();
        this.totalBet.enableButtons();
        this.backButtonComponent?.activate();
        this.settingsButtonComponent?.activate();
        this.shopIconComponent?.activate();
        this.slotsBalance.enableSlotsBalanceTextInteractive();
    };

    startAutoSpinState = () => {
        this.isAuto = true;
        this.disableItemsDuringSpin();
    };

    resetAutoSpinState = () => {
        this.isAuto = false;
        this.spinButton.resetSpinButton();
        this.enableItemsAfterSpin();
        this.totalBet.resetYPosition();
    };

    handleAutoSpinStop = () => {
        EventBus.emit(CUSTOM_EVENTS.SPIN_TAPPED);
        this.resetAutoSpinState();
    };

    waitFreeSpin = () => {
        return new Promise((resolve) => {
            this.resolveFreeSpin = resolve;
        });
    };

    waitPickBonus = () => {
        return new Promise((resolve) => {
            this.resolvePickBonus = resolve;
        });
    };

    handleShowWinningPopup({
        winType,
        totalWinAmount,
        isAuto,
    }: {
        winType: "big_win" | "huge_win" | "super_win" | "massive_win";
        totalWinAmount: number;
        isAuto: boolean;
    }) {
        return new Promise<void>((resolve) => {
            const popupContainer = new SpinWinningPopup(this, 0, 0);
            popupContainer.winAmount = totalWinAmount;
            popupContainer.winType = winType;
            popupContainer.isAuto = isAuto;

            popupContainer.setAlpha(0);
            popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
            this.add.existing(popupContainer);
            popupContainer.init();
            this.tweens.add({
                targets: popupContainer,
                alpha: { from: 0, to: 1 },
                duration: 200,
                onComplete: () => {
                    popupContainer.playAnimation();
                },
            });

            popupContainer.once("destroy", () => {
                resolve();
            });
        });
    }

    async handleWinPopup() {
        return new Promise<void>(async (resolve) => {
            if (!this.spinData) {
                resolve();
                return;
            }

            if (this.spinData.isPiggyJackpotTrigger || this.spinData.isFreeSpinTrigger) {
                this.reels.cancelHighlightPaylines();
                await this.slotsBalance.updateBalance(this.spinData.totalPaylineReward);
            }

            if (this.spinData.isPiggyJackpotTrigger) {
                const jackpotContainer = new JackpotPopup(this, 195, 422);
                jackpotContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
                this.add.existing(jackpotContainer);
                jackpotContainer.init(SCENE_KEYS.GAME, this.spinData);
                jackpotContainer.once("destroy", () => {
                    resolve();
                });

                return;
            } else if (this.spinData.isFreeSpinTrigger) {
                this.scene.sleep(SCENE_KEYS.GAME).launch(SCENE_KEYS.FREE_SPIN, this.spinData);
                resolve();
                return;
            } else {
                const { totalPaylineReward: totalWinAmount, totalBet } = this.spinData;
                const winType = getWinType(totalWinAmount, totalBet);

                if (winType === "none") {
                    this.spinData = undefined;
                    resolve();
                    return;
                }

                await this.handleShowWinningPopup({
                    winType,
                    totalWinAmount,
                    isAuto: this.spinData.isAuto,
                });
                this.spinData = undefined;
                resolve();
            }
        });
    }

    async handleBalanceAndTotalWin(spinData: SpinData) {
        if (!spinData) return;

        const { totalPaylineReward, isAuto, isPiggyJackpotTrigger, isFreeSpinTrigger } = spinData;

        if (typeof totalPaylineReward !== "number") return;

        if (isAuto && totalPaylineReward > 0) {
            await this.showAutoSpinWinDisplay(totalPaylineReward);
        } else {
            this.totalWin.setAmount(totalPaylineReward);
        }

        if (!isPiggyJackpotTrigger && !isFreeSpinTrigger) {
            await this.slotsBalance.updateBalance(totalPaylineReward);
        }
    }

    private async showAutoSpinWinDisplay(amount: number): Promise<void> {
        if (!this.autoSpinWinDisplay) {
            return;
        }

        this.autoSpinWinDisplay.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        await this.autoSpinWinDisplay.fadeIn();

        this.autoSpinWinDisplay.setAmount(amount);

        await new Promise<void>((resolve) => {
            this.time.delayedCall(1400, async () => {
                await this.autoSpinWinDisplay.fadeOut();
                resolve();
            });
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
