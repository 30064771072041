// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
import Player from "../prefab/Player";
import ChargingCountdown from "../prefab/boss/ChargingCountdown";
import Boss from "../prefab/Boss";
/* START-USER-IMPORTS */
import PushComponent from "../components/animations/PushComponent";
import WeaponPopup from "../prefab/WeaponPopup";
import { InventoryItem } from "@/shared/types/loot-box";
import Gem from "@/shared/prefabs/loot-box/Gem";
import { getItemTextureName, getRarityColor, getTotalAverageAttackPower } from "@/shared/utils/loot-box";
import { winChanceToColor } from "@/ota-mining/utils/boss";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
import { BUTTON_NAMES, SCREEN_NAMES, setupScreenTracking, trackButtonTap } from "@/services/analytics";
import { BossConfig } from "../chapters";
/* END-USER-IMPORTS */

export default class BossCharger extends Phaser.Scene {
    constructor() {
        super("MiningBossCharger");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "boss_charger", "_bg.png");

        // playerWrapper
        const playerWrapper = this.add.image(195, 152, "boss_charger", "Frame 427318497.png");

        // winChance
        const winChance = new Text(this, 195, 458);
        this.add.existing(winChance);
        winChance.text = "Win chance 0%";
        winChance.setStyle({ color: "#d42f3a", fontSize: "20px" });

        // playerName
        const playerName = new Text(this, 196, 234);
        this.add.existing(playerName);
        playerName.text = "player name";
        playerName.setStyle({ fontSize: "12px" });

        // player
        const player = new Player(this, 195, 146);
        this.add.existing(player);
        player.scaleX = 0.7;
        player.scaleY = 0.7;

        // chargingContainer
        const chargingContainer = this.add.container(195, 767);

        // frame_427318440_png
        const frame_427318440_png = this.add.image(0, 1, "boss_charger", "Frame 427318440.png");
        chargingContainer.add(frame_427318440_png);

        // charging
        const charging = new Text(this, 14, 0);
        charging.text = "Charging...";
        charging.setStyle({ fixedWidth: 0, fixedHeight: 13, fontSize: "10px" });
        chargingContainer.add(charging);

        // container_1
        const container_1 = this.add.container(213, 768);

        // fightButton
        const fightButton = this.add.image(0, 0, "boss_charger", "fight_button.png");
        fightButton.visible = false;
        container_1.add(fightButton);

        // chargingCountdown
        const chargingCountdown = new ChargingCountdown(this, 1, 29);
        container_1.add(chargingCountdown);

        // bossLevel
        const bossLevel = new Text(this, 195, 663);
        this.add.existing(bossLevel);
        bossLevel.text = "Boss Level 1";
        bossLevel.setStyle({ fontSize: "12px" });

        // bossHealth
        const bossHealth = new Text(this, 193, 688);
        this.add.existing(bossHealth);
        bossHealth.text = "6500HP";
        bossHealth.setStyle({ fontSize: "18px" });

        // weapon
        const weapon = this.add.image(120, 297, "magnum");
        weapon.scaleX = 0.25;
        weapon.scaleY = 0.25;

        // weaponRank
        const weaponRank = new Text(this, 252, 268);
        this.add.existing(weaponRank);
        weaponRank.setOrigin(0, 0);
        weaponRank.text = "N";
        weaponRank.setStyle({
            color: "#DEA614",
            fontSize: "12px",
            stroke: "#DEA614",
        });

        // weaponLevel
        const weaponLevel = new Text(this, 252, 291);
        this.add.existing(weaponLevel);
        weaponLevel.setOrigin(0, 0);
        weaponLevel.text = "level 0";
        weaponLevel.setStyle({ fontSize: "12px" });

        // weaponAttack
        const weaponAttack = new Text(this, 252, 316);
        this.add.existing(weaponAttack);
        weaponAttack.setOrigin(0, 0);
        weaponAttack.text = "Total Attack: 0";
        weaponAttack.setStyle({
            color: "#DEA614",
            fontSize: "12px",
            stroke: "#DEA614",
        });

        // backButton
        const backButton = this.add.image(36, 40, "back_btn");

        // menuLabel
        const menuLabel = this.add.text(37, 72, "", {});
        menuLabel.setOrigin(0.5, 0.5);
        menuLabel.text = "Back";
        menuLabel.setStyle({
            fontFamily: "Oxanium",
            fontSize: "8px",
            fontStyle: "bold",
        });

        // polygon
        const polygon = this.add.image(195, 570, "polygon");
        polygon.scaleX = 0.7;
        polygon.scaleY = 0.7;

        // boss
        const boss = new Boss(this, 195, 552);
        this.add.existing(boss);
        boss.scaleX = 0.6;
        boss.scaleY = 0.6;

        this.bg = bg;
        this.playerWrapper = playerWrapper;
        this.winChance = winChance;
        this.playerName = playerName;
        this.player = player;
        this.charging = charging;
        this.fightButton = fightButton;
        this.chargingCountdown = chargingCountdown;
        this.bossLevel = bossLevel;
        this.bossHealth = bossHealth;
        this.weapon = weapon;
        this.weaponRank = weaponRank;
        this.weaponLevel = weaponLevel;
        this.weaponAttack = weaponAttack;
        this.backButton = backButton;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private playerWrapper!: Phaser.GameObjects.Image;
    private winChance!: Text;
    private playerName!: Text;
    private player!: Player;
    private charging!: Text;
    private fightButton!: Phaser.GameObjects.Image;
    private chargingCountdown!: ChargingCountdown;
    private bossLevel!: Text;
    private bossHealth!: Text;
    private weapon!: Phaser.GameObjects.Image;
    private weaponRank!: Text;
    private weaponLevel!: Text;
    private weaponAttack!: Text;
    private backButton!: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    get bossConfig(): BossConfig {
        return this.chapterManager.getData()!.boss;
    }

    get chapterData() {
        return this.userManager.getChapterData()!;
    }

    init() {
        setupScreenTracking(this);
    }

    create() {
        this.editorCreate();

        new PushComponent(this.fightButton, this.handleFight, this).activate();

        this.player.playPlayerIdleAnimation();

        this.chargingCountdown.updateBossFightStatus(this.handleCountdownDone.bind(this));

        this.bg.setInteractive().on("pointerdown", this.handleWeaponPopup, this);

        this.setupUser();
        this.setupWeapon();
        this.setupBoss();
        this.setupGems();

        new PushComponent(
            this.backButton,
            () => {
                this.scene.start("MiningGame");
            },
            this,
        ).activate();
    }

    setupUser() {
        const user = this.userManager.getData();

        this.playerName.text = user?.username ?? "Player Name";
    }

    setupWeapon() {
        const fightingEquipments = this.userManager.getInventoryItems();
        const userWeapon = this.userManager.getWeapon();
        if (userWeapon && fightingEquipments) {
            const itemTextureName = getItemTextureName(userWeapon);
            if (itemTextureName) this.weapon.setTexture("loot_box", itemTextureName + ".png");
            this.weaponRank.text = userWeapon.rarity.toUpperCase();
            this.weaponRank.setStyle({ color: getRarityColor(userWeapon) });
            this.weaponLevel.text = `Level ${userWeapon.level}`;
            const totalAttack = getTotalAverageAttackPower(fightingEquipments);
            this.weaponAttack.text = `Total Attack: ${formatCompactNumber(totalAttack)}`;
        } else {
            this.weapon.visible = false;
            this.weaponRank.visible = false;
            this.weaponLevel.visible = false;
            this.weaponAttack.visible = false;
        }
    }

    setupBoss() {
        const miningBoss = this.miningBossManager.getData();
        const winChance = Number(miningBoss?.winChance || 0).toFixed(2);

        this.winChance.text = `Win chance ${winChance.replace(".00", "")}%`;
        this.bossHealth.text = `${miningBoss?.bossHealth || 0}HP`;
        this.bossLevel.text = `${this.bossConfig.name || "Boss"} Level ${this.userManager.getChapterData().bossLevel}`;

        this.winChance.setColor(winChanceToColor(Number(winChance)));
    }

    setupGems() {
        const container = new Phaser.GameObjects.Container(this, 90, 385);

        const fightingEquipments = this.userManager.getInventoryItems();
        if (fightingEquipments) {
            fightingEquipments
                .filter((equipment: InventoryItem) => equipment.name === "gem")
                .sort((a: InventoryItem, b: InventoryItem) => a.slot - b.slot)
                .forEach((equipment: InventoryItem, index: number) => {
                    const gem = new Gem(this, index * 55, 0);
                    gem.init(equipment, {
                        hideLevel: false,
                        hideRank: false,
                    });
                    gem.scale = 0.6;
                    new PushComponent(gem.gemItem, this.handleWeaponPopup, this).activate();
                    container.add(gem);
                });
        }

        this.add.existing(container);
    }

    handleWeaponPopup() {
        const popupContainer = new WeaponPopup(this, 195, 422);
        popupContainer.setDepth(4);
        this.add.existing(popupContainer);
    }

    handleFight() {
        trackButtonTap(BUTTON_NAMES.FIGHT_BOSS, SCREEN_NAMES.BOSS);
        if (this.chapterData.isExceedChapter) return;
        this.scene.start("MiningBoss");
    }

    handleCountdownDone() {
        this.fightButton.visible = true;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
