// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import PreloadProgressBar from "@/shared/prefabs/PreloadProgressBar";
/* END-USER-IMPORTS */

export default class PreLoader extends Phaser.Scene {
    constructor() {
        super("PreLoaderWheel");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // wheel_preload_bg
        this.add.image(195, 422, "wheel_preload_bg");

        this.events.emit("scene-awake");
    }

    /* START-USER-CODE */

    init() {
        this.editorCreate();

        const progressBar = new PreloadProgressBar(this, 0, 0);

        this.load.on(Phaser.Loader.Events.PROGRESS, (value: number) => {
            progressBar.updateProgress(value);
        });
    }

    preload(): void {
        this.load.pack("base-asset-pack", "fortune-wheel/base-asset-pack.json");
    }

    create() {
        this.scene.start("LuckyWheel");
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
