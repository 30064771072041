import { isApple } from "./is-apple";

const tg = window.Telegram.WebApp;

export const openLink = (url: string) => {
    if (typeof tg.openLink === "function") {
        tg.openLink(url);
        return;
    }

    if (isApple) {
        const tmp = document.createElement("a");
        tmp.href = url;
        tmp.click();
        tmp.remove();
    } else {
        // Doesn't work on iPhone
        window.open(url);
    }
};
