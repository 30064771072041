export const chapter5 = {
    id: 5,
    boss: {
        name: "Tigorilla",
        idleAnimationKey: "boss_05_walk",
        walkAnimationKey: "boss_05_walk",
        jumpAnimationKey: "boss_05_walk",
        attackAnimationKey: "boss_05_attack",
        gethitAnimationKey: "boss_05_gethit",
        destroyedAnimationKey: "boss_05_dead",
        size: {
            scale: 0.3,
        },
        position: {
            y: -5,
            attack: {
                y: -70,
            },
            dead: {
                y: -50,
            },
        },
        body: {
            offset: {
                x: -80,
                h: -70,
            },
        },
        texture: ["boss_05_walk", "alien_9-walk0.png"],
    },
    creep: {
        walkAnimationKey: "creep_05_walk",
        attackAnimationKey: "creep_05_attack",
        destroyedAnimationKey: "creep_05_die",
        size: {
            scale: 0.2,
        },
        position: {
            y: 0,
        },
        body: {
            offset: {
                x: -90,
                h: -70,
            },
        },
        texture: ["creep_05_walk", "alien_8-walk0.png"],
    },
    background: {
        mining: "chapter_05_bg",
        boss: "chapter_05_bg",
        intro: "chapter_05_intro",
    },
};
