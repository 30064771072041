// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
import WheelGold from "../prefabs/WheelGold";
import Wheel from "../prefabs/Wheel";
import Spin from "../prefabs/Spin";
import USDTProgressBar from "../prefabs/USDTProgressBar";
import Invite from "../prefabs/Invite";
/* START-USER-IMPORTS */
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { sleep } from "@/lib/utils";
import {
    ClaimLuckyWheelResponse,
    getLuckyWheel,
    LuckyWheelResponse,
} from "@/lib/api/telegram-backend/methods/lucky-wheel";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import RewardPopup from "../prefabs/RewardPopup";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import WinUSDPopup from "../prefabs/WinUSDPopup";
import { setupScreenTracking } from "@/services/analytics";
import { mockLuckyWheel } from "../utils/mock";
/* END-USER-IMPORTS */

export default class LuckyWheel extends Phaser.Scene {
    constructor() {
        super("LuckyWheel");

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "lucky_wheel", "bg.png");

        // title_png
        this.add.image(195, 65, "lucky_wheel", "title.png");

        // backText
        const backText = new Text(this, 69, 41);
        this.add.existing(backText);
        backText.text = "Back";
        backText.setStyle({ "fontSize": "14px", "fontStyle": "bold" });

        // backButton
        const backButton = this.add.image(35, 43, "arrow");
        backButton.scaleX = 0.2;
        backButton.scaleY = 0.2;

        // wheelGold
        const wheelGold = new WheelGold(this, 195, 370);
        this.add.existing(wheelGold);
        wheelGold.visible = false;

        // wheelAnimation
        const wheelAnimation = this.add.sprite(195, 370, "wheel_animation", "Property 1=tw_normal_f_01.png");
        wheelAnimation.visible = false;

        // wheel
        const wheel = new Wheel(this, 195, 370);
        this.add.existing(wheel);

        // spin_1
        const spin_1 = new Spin(this, 50, 665);
        this.add.existing(spin_1);

        // spin_2
        const spin_2 = new Spin(this, 130, 665);
        this.add.existing(spin_2);

        // spin_3
        const spin_3 = new Spin(this, 210, 665);
        this.add.existing(spin_3);

        // specialSpin
        const specialSpin = this.add.image(332, 672, "lucky_wheel", "special_spin.png");

        // usdProgressBar
        const usdProgressBar = new USDTProgressBar(this, 196, 775);
        this.add.existing(usdProgressBar);

        // invite
        const invite = new Invite(this, 0, 0);
        this.add.existing(invite);
        invite.visible = false;

        // blurryContainer
        const blurryContainer = this.add.container(0, 0);

        // blurryBg
        const blurryBg = this.add.image(195, 422, "wheel_animation", "blurry_bg.png");
        blurryBg.alpha = 0;
        blurryBg.alphaTopLeft = 0;
        blurryBg.alphaTopRight = 0;
        blurryBg.alphaBottomLeft = 0;
        blurryBg.alphaBottomRight = 0;
        blurryContainer.add(blurryBg);

        // specialWheel
        const specialWheel = this.add.image(195, 315, "wheel_animation", "special_wheel.png");
        specialWheel.scaleX = 0;
        specialWheel.scaleY = 0;
        blurryContainer.add(specialWheel);

        this.bg = bg;
        this.backText = backText;
        this.backButton = backButton;
        this.wheelGold = wheelGold;
        this.wheelAnimation = wheelAnimation;
        this.wheel = wheel;
        this.spin_1 = spin_1;
        this.spin_2 = spin_2;
        this.spin_3 = spin_3;
        this.specialSpin = specialSpin;
        this.usdProgressBar = usdProgressBar;
        this.invite = invite;
        this.blurryBg = blurryBg;
        this.specialWheel = specialWheel;
        this.blurryContainer = blurryContainer;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private backText!: Text;
    private backButton!: Phaser.GameObjects.Image;
    private wheelGold!: WheelGold;
    private wheelAnimation!: Phaser.GameObjects.Sprite;
    private wheel!: Wheel;
    private spin_1!: Spin;
    private spin_2!: Spin;
    private spin_3!: Spin;
    private specialSpin!: Phaser.GameObjects.Image;
    private usdProgressBar!: USDTProgressBar;
    private invite!: Invite;
    private blurryBg!: Phaser.GameObjects.Image;
    private specialWheel!: Phaser.GameObjects.Image;
    private blurryContainer!: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    private spins: Spin[] = [];

    init() {
        setupScreenTracking(this);
    }

    create() {
        this.editorCreate();
        this.setupSpins();
        this.setupUSDProgressBar();
        this.setupBackButton();
        this.setupWheel();

        EventBus.emit(CUSTOM_EVENTS.PLAY_WHEEL_BG);
    }

    showInvite() {
        this.invite.visible = true;
    }

    setupSpins() {
        this.spins = [this.spin_1, this.spin_2, this.spin_3];
        this.spins.forEach((spin, index) => {
            spin.init(index + 1);
        });

        const luckyWheelCycleHistoryReward = this.userManager.getLuckyWheelCycleHistoryReward();

        if (luckyWheelCycleHistoryReward) {
            luckyWheelCycleHistoryReward.forEach((reward, index) => {
                this.spins[index]?.addReward(
                    reward as unknown as LuckyWheelResponse["luckyWheelCycleHistoryReward"][0],
                );
            });
        }
    }

    async handleSpecialWheelAnimation() {
        const luckyWheel = this.userManager.getLuckyWheelData();

        return new Promise<void>((resolve, reject) => {
            if (luckyWheel.cycleNormalSpin >= 3 && !this.wheelGold.visible) {
                EventBus.emit(CUSTOM_EVENTS.LEVEL_UP_WHEEL);
                this.bg.setTexture("lucky_wheel", "bg_gold.png");
                this.wheel.visible = false;
                this.wheelAnimation.visible = true;
                this.wheelAnimation.play("wheel_animation");
                this.wheelAnimation.once(
                    "animationcomplete",
                    async () => {
                        this.wheelGold.visible = true;
                        this.blurryContainer.visible = true;

                        await new Promise<void>((resolve) => {
                            this.tweens.add({
                                targets: this.blurryBg,
                                alpha: { from: 0, to: 1 },
                                duration: 500,
                                delay: 500,
                                onStart: () => {
                                    EventBus.emit(CUSTOM_EVENTS.DRUM);
                                },
                                onComplete: () => resolve(),
                            });
                        });

                        await new Promise<void>((resolve) => {
                            this.tweens.add({
                                targets: this.specialWheel,
                                alpha: {
                                    duration: 200,
                                    value: 1,
                                },
                                scale: {
                                    duration: 400,
                                    value: { from: 0.5, to: 1 },
                                    ease: "Bounce.easeOut",
                                    keyframes: [
                                        { scale: 0.5, duration: 80 },
                                        { scale: 1.5, duration: 0 },
                                        { scale: 1.3, duration: 80 },
                                        { scale: 1.2, duration: 80 },
                                        { scale: 0, duration: 80 },
                                        { scale: 1, duration: 80 },
                                    ],
                                },
                                delay: 500,
                                onStart: () => {
                                    EventBus.emit(CUSTOM_EVENTS.SPECIAL_WHEEL);
                                },
                                onComplete: () => resolve(),
                            });
                        });

                        await sleep(1000);
                        this.blurryContainer.visible = false;
                        this.blurryBg.alpha = 0;
                        this.specialWheel.alpha = 0;
                        this.specialWheel.scale = 0;
                        await this.wheelGold.playFlash();
                        this.wheelGold.playIdle();
                        resolve();
                    },
                    this,
                );
            }
        });
    }

    setupUSDProgressBar() {
        const user = this.userManager.getData();

        if (user) {
            this.usdProgressBar.setBalance(user.usd);
        }
    }

    setupBackButton() {
        const goBack = () => {
            this.scene.start("MiningGame");
        };

        new PushComponent(this.backButton, goBack, this).activate();

        new PushComponent(this.backText, goBack, this).activate();
    }

    async setupInvite() {
        this.wheelGold.init(mockLuckyWheel as LuckyWheelResponse);
        await this.handleSpecialWheelAnimation();
        this.showInvite();
    }

    resetWheel() {
        this.wheelGold.visible = false;
        this.wheelAnimation.visible = false;
        this.wheel.visible = true;
        this.setupWheel();
    }

    async setupWheel() {
        const userLuckyWheel = this.userManager.getLuckyWheelData();

        if (userLuckyWheel.cycleNormalSpin >= 3 && !userLuckyWheel.special) {
            this.setupInvite();
            return;
        }

        const luckyWheel = await getLuckyWheel();

        if (!luckyWheel) return;

        if (luckyWheel.spinType === "normal") {
            this.wheel.init(luckyWheel);

            this.wheel.removeAllListeners();
            this.wheel.on(Wheel.EVENTS.SPIN_DONE, this.handleSpinDone, this);
            this.wheel.on(Wheel.EVENTS.RESET_SPIN_DONE, (isLastNormalSpin: boolean) => {
                const cachedUserLuckyWheel = this.userManager.getLuckyWheelData();

                if (isLastNormalSpin) {
                    if (cachedUserLuckyWheel.special === 0) {
                        this.setupInvite();
                    } else {
                        this.setupWheel();
                    }
                }
            });
        }

        if (luckyWheel.spinType === "special") {
            this.wheelGold.init(luckyWheel);
            await this.handleSpecialWheelAnimation();

            this.wheelGold.removeAllListeners();
            this.wheelGold.on(WheelGold.EVENTS.SPIN_DONE, this.handleSpinDone, this);
            this.wheelGold.on(WheelGold.EVENTS.RESET_SPIN_DONE, (isLastSpecialSpin: boolean) => {
                if (isLastSpecialSpin) {
                    this.setupSpins();
                    this.setupUSDProgressBar();
                    this.resetWheel();
                }
            });
        }
    }

    private handleSpinDone = (
        spinType: "special" | "normal",
        claimLuckyWheel: ClaimLuckyWheelResponse,
        currentLuckyWheel: LuckyWheelResponse,
    ) => {
        this.userManager.syncWithBackend();
        const popupContainer = new RewardPopup(this, 0, 0);

        popupContainer.rewardAmount = currentLuckyWheel.rewardReel.value;
        popupContainer.pieceType = currentLuckyWheel.rewardReel.pieceType;

        popupContainer.setAlpha(0);
        popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        this.add.existing(popupContainer);

        popupContainer.init();
        this.tweens.add({
            targets: popupContainer,
            alpha: { from: 0, to: 1 },
            duration: 200,
            onComplete: () => {
                popupContainer.playAnimation();
            },
        });

        popupContainer.once("destroy", () => {
            if (spinType === "normal") {
                const spin = this.spins[currentLuckyWheel.cycleNormalSpin];

                spin.addReward({
                    value: currentLuckyWheel.rewardReel.value,
                    type: currentLuckyWheel.rewardReel.pieceType,
                });
            }

            this.usdProgressBar.setBalance(claimLuckyWheel.usd);

            if (claimLuckyWheel.rewardUsd?.rmx > 0 || claimLuckyWheel.rewardUsd?.otaGold > 0) {
                const popupContainer = new WinUSDPopup(this, 0, 0);

                popupContainer.rmxAmount = claimLuckyWheel.rewardUsd.rmx;
                popupContainer.goldAmount = claimLuckyWheel.rewardUsd.otaGold;

                popupContainer.setAlpha(0);
                popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
                this.add.existing(popupContainer);

                this.tweens.add({
                    targets: popupContainer,
                    alpha: { from: 0, to: 1 },
                    duration: 200,
                    onComplete: () => {
                        EventBus.emit(CUSTOM_EVENTS.WINNING_USD);
                        popupContainer.playAnimation();
                    },
                });
            }
        });
    };

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
