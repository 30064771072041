// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import SkipButton from "./SkipButton";
import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import Circle from "./Circle";
/* END-USER-IMPORTS */

export default class Step7 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // bg
        const bg = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        this.add(bg);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -377);
        this.add(skipButton_1);

        // playerHead
        const playerHead = scene.add.image(-155, -171, "tutorial", "Head.png");
        playerHead.alpha = 0;
        playerHead.alphaTopLeft = 0;
        playerHead.alphaTopRight = 0;
        playerHead.alphaBottomLeft = 0;
        playerHead.alphaBottomRight = 0;
        this.add(playerHead);

        // message1
        const message1 = scene.add.container(-121, -174);
        this.add(message1);

        // boxMessage
        const boxMessage = scene.add.image(147, 45, "tutorial", "box_message_2.png");
        boxMessage.scaleY = 0.9;
        boxMessage.alpha = 0;
        boxMessage.alphaTopLeft = 0;
        boxMessage.alphaTopRight = 0;
        boxMessage.alphaBottomLeft = 0;
        boxMessage.alphaBottomRight = 0;
        message1.add(boxMessage);

        // content
        const content = new Text(scene, 138, 61);
        content.alpha = 0;
        content.text = "The boss got stronger and your gear is underpowered! Time to upgrade!";
        content.setWordWrapWidth(255);
        message1.add(content);

        // content_1
        const content_1 = new Text(scene, 50, 15);
        content_1.alpha = 0;
        content_1.text = "Oops!!!";
        content_1.setStyle({ "color": "#D8E63C", "fontSize": "18px", "fontStyle": "bold" });
        content_1.setWordWrapWidth(255);
        message1.add(content_1);

        // arrow
        const arrow = scene.add.container(-8, 196);
        arrow.alpha = 0;
        this.add(arrow);

        // tap_1
        const tap_1 = new Text(scene, 100, 0);
        tap_1.text = "Tap to Upgrade";
        tap_1.setStyle({ "color": "#D8E63C", "fontSize": "26px", "fontStyle": "bold" });
        arrow.add(tap_1);

        // tap
        const tap = scene.add.image(94, 37, "tutorial", "arrow.png");
        arrow.add(tap);

        this.bg = bg;
        this.playerHead = playerHead;
        this.boxMessage = boxMessage;
        this.content = content;
        this.content_1 = content_1;
        this.message1 = message1;
        this.tap = tap;
        this.arrow = arrow;

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Image;
    private playerHead: Phaser.GameObjects.Image;
    private boxMessage: Phaser.GameObjects.Image;
    private content: Text;
    private content_1: Text;
    private message1: Phaser.GameObjects.Container;
    private tap: Phaser.GameObjects.Image;
    private arrow: Phaser.GameObjects.Container;

    /* START-USER-CODE */
    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.playerHead,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.boxMessage,
                scaleY: { from: 0, to: 1 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                ease: "Back.easeOut",
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: [this.content_1, this.content],
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.arrow,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        const circle = new Circle(this, this.scene, 290, 780, 156);
        this.add(circle);
        circle.show();
        circle.onClick(this.upgrade);

        this.scene.tweens.add({
            targets: this.tap,
            y: {
                value: "+=10",
            },
            duration: 500,
            yoyo: true,
            repeat: -1,
        });
    }

    upgrade() {
        this.scene.userManager.syncIntroStep("7");
        this.scene.scene.start("SlotsBoot");
        this.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
