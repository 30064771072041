import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";

type OptionValue = "10" | "20" | "50" | "100" | "500" | "∞";
/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class AutoSpinSelection extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 196, y ?? 472);

        this.blendMode = Phaser.BlendModes.SKIP_CHECK;

        // overlay
        const overlay = scene.add.rectangle(-1, -52, 128, 128);
        overlay.setInteractive(new Phaser.Geom.Rectangle(0, 0, 128, 128), Phaser.Geom.Rectangle.Contains);
        overlay.scaleX = 3.0414215802567477;
        overlay.scaleY = 6.634423538855507;
        overlay.alpha = 0.01;
        overlay.isFilled = true;
        overlay.fillColor = 0;
        this.add(overlay);

        // background
        const background = scene.add.image(3, 112, "auto_spin", "selection-menu.png");
        background.scaleX = 1.1117612757041908;
        background.scaleY = 1.1117612757041908;
        this.add(background);

        // option_10
        const option_10 = scene.add.container(-6, 200);
        option_10.blendMode = Phaser.BlendModes.SKIP_CHECK;
        option_10.setInteractive(
            new Phaser.Geom.Rectangle(-81.37372191578186, -12.929050893867839, 190.74744383156371, 53.85810178773568),
            Phaser.Geom.Rectangle.Contains,
        );
        this.add(option_10);

        // button
        const button = scene.add.image(7, 14, "auto_spin", "button.png");
        button.scaleX = 1.1119083318125023;
        button.scaleY = 1.106322007844284;
        option_10.add(button);

        // text_1
        const text_1 = scene.add.text(8, 15, "", {});
        text_1.setOrigin(0.5, 0.5);
        text_1.text = "10";
        text_1.setStyle({ "align": "center", "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        option_10.add(text_1);

        // option_20
        const option_20 = scene.add.container(1, 179);
        option_20.blendMode = Phaser.BlendModes.SKIP_CHECK;
        option_20.setInteractive(
            new Phaser.Geom.Rectangle(-88.95266654500018, -13.275864094131407, 177.90533309000034, 26.551728188262814),
            Phaser.Geom.Rectangle.Contains,
        );
        this.add(option_20);

        // button_1
        const button_1 = scene.add.image(0, 0, "auto_spin", "button.png");
        button_1.scaleX = 1.1119083318125023;
        button_1.scaleY = 1.106322007844284;
        option_20.add(button_1);

        // text_6
        const text_6 = scene.add.text(1, 1, "", {});
        text_6.setOrigin(0.5, 0.5);
        text_6.text = "20";
        text_6.setStyle({ "align": "center", "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        option_20.add(text_6);

        // option_50
        const option_50 = scene.add.container(1, 144);
        option_50.blendMode = Phaser.BlendModes.SKIP_CHECK;
        option_50.setInteractive(
            new Phaser.Geom.Rectangle(-88.95266654500018, -13.275864094131407, 177.90533309000034, 26.551728188262814),
            Phaser.Geom.Rectangle.Contains,
        );
        this.add(option_50);

        // button_6
        const button_6 = scene.add.image(0, 0, "auto_spin", "button.png");
        button_6.scaleX = 1.1119083318125023;
        button_6.scaleY = 1.106322007844284;
        option_50.add(button_6);

        // text
        const text = scene.add.text(1, 0, "", {});
        text.setOrigin(0.5, 0.5);
        text.text = "50";
        text.setStyle({ "align": "center", "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        option_50.add(text);

        // option_100
        const option_100 = scene.add.container(1, 108.1500244140625);
        option_100.blendMode = Phaser.BlendModes.SKIP_CHECK;
        option_100.setInteractive(
            new Phaser.Geom.Rectangle(-88.95266654500018, -13.275864094131407, 177.90533309000034, 26.551728188262814),
            Phaser.Geom.Rectangle.Contains,
        );
        this.add(option_100);

        // button_2
        const button_2 = scene.add.image(0, 0, "auto_spin", "button.png");
        button_2.scaleX = 1.1119083318125023;
        button_2.scaleY = 1.106322007844284;
        option_100.add(button_2);

        // text_2
        const text_2 = scene.add.text(1, 0, "", {});
        text_2.setOrigin(0.5, 0.5);
        text_2.text = "100";
        text_2.setStyle({ "align": "center", "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        option_100.add(text_2);

        // option_500
        const option_500 = scene.add.container(1, 72.8399658203125);
        option_500.blendMode = Phaser.BlendModes.SKIP_CHECK;
        option_500.setInteractive(
            new Phaser.Geom.Rectangle(-88.95266654500018, -13.275864094131407, 177.90533309000034, 26.551728188262814),
            Phaser.Geom.Rectangle.Contains,
        );
        this.add(option_500);

        // button_3
        const button_3 = scene.add.image(0, 0, "auto_spin", "button.png");
        button_3.scaleX = 1.1119083318125023;
        button_3.scaleY = 1.106322007844284;
        option_500.add(button_3);

        // text_3
        const text_3 = scene.add.text(1, 0, "", {});
        text_3.setOrigin(0.5, 0.5);
        text_3.text = "500";
        text_3.setStyle({ "align": "center", "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        option_500.add(text_3);

        // option_infinity
        const option_infinity = scene.add.container(1, 36);
        option_infinity.blendMode = Phaser.BlendModes.SKIP_CHECK;
        option_infinity.setInteractive(
            new Phaser.Geom.Rectangle(-88.95266654500018, -13.155868976943907, 177.90533309000034, 26.551728188262814),
            Phaser.Geom.Rectangle.Contains,
        );
        this.add(option_infinity);

        // button_4
        const button_4 = scene.add.image(0, 0.1199951171875, "auto_spin", "button.png");
        button_4.scaleX = 1.1119083318125023;
        button_4.scaleY = 1.106322007844284;
        option_infinity.add(button_4);

        // infinite
        const infinite = scene.add.image(0, 0, "auto_spin", "infinite.png");
        infinite.scaleX = 0.16370473431537946;
        infinite.scaleY = 0.16370473431537946;
        option_infinity.add(infinite);

        // auto_fast
        const auto_fast = scene.add.container(-55, -27);
        auto_fast.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(auto_fast);

        // text_5
        const text_5 = scene.add.text(0, 0, "", {});
        text_5.scaleX = 1.0686982244191812;
        text_5.scaleY = 1.0686982244191812;
        text_5.text = "AUTO FAST";
        text_5.setStyle({ "fontFamily": "Oxanium", "fontSize": "12px", "fontStyle": "bold" });
        text_5.setLineSpacing(1.5);
        text_5.setWordWrapWidth(36);
        auto_fast.add(text_5);

        // toggle
        const toggle = scene.add.image(82, 14, "auto_spin", "toggle-off.png");
        toggle.scaleX = 0.9925779739612666;
        toggle.scaleY = 0.9925779739612666;
        auto_fast.add(toggle);

        this.overlay = overlay;
        this.option_10 = option_10;
        this.option_20 = option_20;
        this.option_50 = option_50;
        this.option_100 = option_100;
        this.option_500 = option_500;
        this.option_infinity = option_infinity;
        this.toggle = toggle;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        this.setupInteractions();
        /* END-USER-CTR-CODE */
    }

    private overlay: Phaser.GameObjects.Rectangle;
    private option_10: Phaser.GameObjects.Container;
    private option_20: Phaser.GameObjects.Container;
    private option_50: Phaser.GameObjects.Container;
    private option_100: Phaser.GameObjects.Container;
    private option_500: Phaser.GameObjects.Container;
    private option_infinity: Phaser.GameObjects.Container;
    private toggle: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private isAutoFastEnabled: boolean = false;
    private selectedOption: OptionValue | undefined;
    private isAutoSpinReady: boolean = false;

    private setupInteractions(): void {
        this.overlay.on("pointerdown", () => {
            this.emit("hide-auto-spin-menu");
        });

        const options: { container: Phaser.GameObjects.Container; value: OptionValue }[] = [
            { container: this.option_10, value: "10" },
            { container: this.option_20, value: "20" },
            { container: this.option_50, value: "50" },
            { container: this.option_100, value: "100" },
            { container: this.option_500, value: "500" },
            { container: this.option_infinity, value: "∞" },
        ];

        options.forEach(({ container, value }) => {
            if (!container.input?.enabled) {
                container.setInteractive({ useHandCursor: true });
            }

            container.removeAllListeners();

            new PushComponent(container, () => {
                if (this.selectedOption === value) {
                    this.selectedOption = undefined;
                    this.emit("option-selected", undefined);
                    return;
                }

                this.selectedOption = value;
                this.emit("option-selected", value);
            }).activate();
        });

        this.toggle.setInteractive({ useHandCursor: true }).on("pointerup", () => {
            this.isAutoFastEnabled = !this.isAutoFastEnabled;
            this.toggle.setTexture("auto_spin", this.isAutoFastEnabled ? "toggle-on.png" : "toggle-off.png");
        });
    }

    public clearAutoSpinSelection() {
        this.selectedOption = undefined;
        this.setAutoSpinReady(false);
    }

    public getIsAutoFastEnabled(): boolean {
        return this.isAutoFastEnabled;
    }

    public getIsOptionSelected(): boolean {
        return !!this.selectedOption;
    }

    public setAutoSpinReady(isReady: boolean): void {
        this.isAutoSpinReady = isReady;
    }

    public getIsAutoSpinReady(): boolean {
        return this.isAutoSpinReady;
    }

    public animateIn(): void {
        this.setAlpha(0);
        const startY = this.y;
        this.y = startY + 5;
        this.setVisible(true);

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            y: startY,
            duration: 200,
            ease: "Power2",
        });
    }

    public animateOut(): void {
        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            duration: 200,
            ease: "Power2",
            onComplete: () => {
                this.setVisible(false);
            },
        });
    }

    create(): void {
        this.setupInteractions();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
