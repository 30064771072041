// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS } from "../../components/events/EventBusComponent";
import { EventBusComponent } from "../../components/events/EventBusComponent";
import PushComponent from "../../components/animations/PushComponent";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class WinningConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 424);

        // image_1
        const image_1 = scene.add.image(0, 314, "winning_console", "dialog.png");
        this.add(image_1);

        // claimButton
        const claimButton = scene.add.image(0, 382, "winning_console", "remis_claim_btn 1.png");
        this.add(claimButton);

        // text
        const text = new Text(scene, 9, 198);
        text.text = "You’re so powerful!";
        text.setStyle({ "color": "#D8E63C", "fontSize": "28px", "fontStyle": "bold" });
        this.add(text);

        // text_1
        const text_1 = new Text(scene, 8, 241);
        text_1.text = "BOSS REWARDS";
        text_1.setStyle({ "fontSize": "20px", "fontStyle": "bold" });
        this.add(text_1);

        // remis_coin
        const remis_coin = scene.add.image(33, 303, "remis_coin");
        remis_coin.scaleX = 0.15;
        remis_coin.scaleY = 0.15;
        this.add(remis_coin);

        // coin_icon
        const coin_icon = scene.add.image(-139, 305, "coin_icon");
        coin_icon.scaleX = 0.6;
        coin_icon.scaleY = 0.6;
        this.add(coin_icon);

        // goldAmount
        const goldAmount = new Text(scene, -109, 282);
        goldAmount.setOrigin(0, 0);
        goldAmount.text = "0";
        goldAmount.setStyle({ "fontSize": "18px", "fontStyle": "bold" });
        this.add(goldAmount);

        // rmxAmount
        const rmxAmount = new Text(scene, 65, 281);
        rmxAmount.setOrigin(0, 0);
        rmxAmount.text = "0";
        rmxAmount.setStyle({ "fontSize": "18px", "fontStyle": "bold" });
        this.add(rmxAmount);

        // goldAmount_1
        const goldAmount_1 = new Text(scene, -110, 307);
        goldAmount_1.setOrigin(0, 0);
        goldAmount_1.text = "GOLD";
        goldAmount_1.setStyle({ "fontSize": "18px", "fontStyle": "bold" });
        this.add(goldAmount_1);

        // goldAmount_2
        const goldAmount_2 = new Text(scene, 65, 307);
        goldAmount_2.setOrigin(0, 0);
        goldAmount_2.text = "REMIS";
        goldAmount_2.setStyle({ "fontSize": "18px", "fontStyle": "bold" });
        this.add(goldAmount_2);

        this.claimButton = claimButton;
        this.goldAmount = goldAmount;
        this.rmxAmount = rmxAmount;
        this.goldAmount_1 = goldAmount_1;
        this.goldAmount_2 = goldAmount_2;

        /* START-USER-CTR-CODE */
        new PushComponent(
            this.claimButton,
            () => {
                this.eventBusComponent.emit(CUSTOM_EVENTS.CLAIM_BOSS_REWARD);
            },
            this,
        ).activate();
        /* END-USER-CTR-CODE */
    }

    private claimButton: Phaser.GameObjects.Image;
    private goldAmount: Text;
    private rmxAmount: Text;
    private goldAmount_1: Text;
    private goldAmount_2: Text;

    /* START-USER-CODE */

    private eventBusComponent: EventBusComponent;

    init(eventBusComponent: EventBusComponent, reward?: { otaGold: number; rmx: number }) {
        this.eventBusComponent = eventBusComponent;
        if (reward) {
            this.goldAmount.text = numberWithCommas(reward.otaGold);
            this.rmxAmount.text = numberWithCommas(reward.rmx);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
