import Phaser from "phaser";

export const CUSTOM_EVENTS = Object.freeze({
    GAME_OVER: "GAME_OVER",
    PLAYER_HIT: "PLAYER_HIT",
    PLAYER_SHOOT: "PLAYER_SHOOT",
    PLAYER_DEAD: "PLAYER_DEAD",
    CREEP_DEAD: "CREEP_DEAD",
    CREEP_INIT: "CREEP_INIT",
    ENEMY_SPAWNED: "ENEMY_SPAWNED",
    BOSS_DEAD: "BOSS_DEAD",
    CLAIM_REWARD_SUCCESS: "CLAIM_REWARD_SUCCESS",
    CLAIM_REWARD: "CLAIM_REWARD",
    RETRY_BOSS_FIGHT: "RETRY_BOSS_FIGHT",
    CLAIM_BOSS_REWARD: "CLAIM_BOSS_REWARD",
    BOSS_FIGHT_START: "BOSS_FIGHT_START",
    BOSS_FIGHT_OVER: "BOSS_FIGHT_OVER",
    BOSS_FIGHT_WIN: "BOSS_FIGHT_WIN",
    CASH_REGISTER: "CASH_REGISTER",
    PLAY_MINING_BG: "PLAY_MINING_BG",
    PLAY_SLOTS_BG: "PLAY_SLOTS_BG",
    PLAY_BONUS_BG: "PLAY_BONUS_BG",
    PLAY_FREE_BG: "PLAY_FREE_BG",
    PLAY_BOSS_BG: "PLAY_BOSS_BG",
    PLAY_WHEEL_BG: "PLAY_WHEEL_BG",
    REEL_SPINNING: "REEL_SPINNING",
    SPIN_TAPPED: "SPIN_TAPPED",
    PAYLINE_HIGHLIGHT: "PAYLINE_HIGHLIGHT",
    BONUS_SYMBOL: "BONUS_SYMBOL",
    BONUS_GAME: "BONUS_GAME",
    BONUS_WINNING_POPUP: "BONUS_WINNING_POPUP",
    SPACESHIP_TAPPED: "SPACESHIP_TAPPED",
    JACKPOT_WIN: "JACKPOT_WIN",
    SPIN_WIN: "SPIN_WIN",
    WIN_POPUP: "WIN_POPUP",
    SLOT_ACTIVE: "SLOT_ACTIVE",
    COIN_ANIMATION: "COIN_ANIMATION",
    TAP: "TAP",
    SYNC_USER: "SYNC_USER",
    LEVEL_UP_WHEEL: "LEVEL_UP_WHEEL",
    DRUM: "DRUM",
    SPECIAL_WHEEL: "SPECIAL_WHEEL",
    SPIN_WHEEL: "SPIN_WHEEL",
    WINNING_USD: "WINNING_USD",
    PLAYER_VOICE: "PLAYER_VOICE",
});

/**
 * Uses the native Phaser 3 EventEmitter class to allow communication
 * between the various components in our game.
 *
 * For example, this event bus can be used for notifying the UI when
 * an enemy is destroyed so we can update the score in our game.
 */
export class EventBusComponent extends Phaser.Events.EventEmitter {
    constructor() {
        super();
    }
}
