export const chapter9 = {
    id: 9,
    boss: {
        name: "Titan Omega",
        idleAnimationKey: "boss_09_walk",
        walkAnimationKey: "boss_09_walk",
        jumpAnimationKey: "boss_09_walk",
        attackAnimationKey: "boss_09_attack",
        gethitAnimationKey: "boss_09_gethit",
        destroyedAnimationKey: "boss_09_dead",
        size: {
            scale: 0.4,
        },
        position: {
            y: -30,
            attack: {
                y: -15,
            },
        },
        body: {
            size: {
                w: 250,
                h: 200,
            },
            offset: {
                x: -50,
                h: -70,
            },
        },
        texture: ["boss_09_walk", "robot_8-walk0.png"],
    },
    creep: {
        walkAnimationKey: "creep_09_walk",
        attackAnimationKey: "creep_09_attack",
        destroyedAnimationKey: "creep_09_die",
        size: {
            scale: 1,
        },
        position: {
            y: 0,
        },
        body: {
            offset: {
                x: -70,
                h: -70,
            },
        },
        texture: ["creep_09_walk", "Property 1=f_01.png"],
    },
    background: {
        mining: "chapter_09_bg",
        boss: "chapter_09_bg",
        intro: "chapter_09_intro",
    },
};
