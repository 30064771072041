import { HealthComponent } from "../health/HealthComponent";
import { EventBusComponent } from "../events/EventBusComponent";
import Boss from "../../prefab/Boss";
import Text from "@/ota-slots/prefabs/Text";
import { createTween, playAnimation } from "@/utils/animation-utils";

export class BossColliderComponent {
    private boss: Boss;
    private healthComponent: HealthComponent;
    private eventBusComponent: EventBusComponent;
    private lastHitTime: number = 0;

    constructor(boss: Boss, healthComponent: HealthComponent, eventBusComponent: EventBusComponent) {
        this.boss = boss;
        this.healthComponent = healthComponent;
        this.eventBusComponent = eventBusComponent;
    }

    collideWithEnemyWeapon(
        { value, crit, hide }: { value: number; crit: boolean; hide: boolean },
        disableAnimation: boolean,
    ): void {
        if (this.healthComponent.isDead) {
            return;
        }
        this.healthComponent.hit(value);

        // Create floating damage text
        if (!hide) {
            const text = this.boss.scene.add.text(this.boss.x, this.boss.y - 50, crit ? "CRIT" : `-${value}hp`, {
                fontFamily: "Oxanium",
                fontSize: "28px",
                stroke: "#CFCFCF",
                color: "#EB287E",
                strokeThickness: 5,
            });

            // Animate the text floating up and scaling
            const randomX = Phaser.Math.Between(this.boss.x - 75, this.boss.x - 15);
            const randomY = Phaser.Math.Between(this.boss.y - 125, this.boss.y - 80);

            createTween(this.boss.scene, text, {
                x: randomX,
                y: randomY,
                scaleX: { from: 0.25, to: 1 },
                scaleY: { from: 0.25, to: 1 },
                alpha: { from: 1, to: 0.8 },
                duration: 400,
                ease: "Power1",
                onComplete: () => {
                    this.boss.scene.time.delayedCall(200, () => {
                        text.destroy();
                    });
                },
            });
        }

        if (disableAnimation) {
            if (this.boss.bossObject.anims.currentAnim?.key === this.boss.gethitAnimationKey) {
                this.boss.bossObject.stop();
                this.boss.bossObject.setFrame(0);
            }
            return;
        }

        const currentTime = this.boss.scene.time.now;

        this.lastHitTime = currentTime;
        this.boss.bossObject.stop();
        this.boss.bossObject.setFrame(0);
        playAnimation(this.boss.bossObject, this.boss.gethitAnimationKey);
    }
}
