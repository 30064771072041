// You can write more code here

import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { InventoryItem } from "@/shared/types/loot-box";
import { getAverageAttackPower, getItemDisplayName, getItemTextureName, getRarityColor } from "@/shared/utils/loot-box";
import LootBoxPopup from "./LootBoxPopup";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class CurrentItemDisplay extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.rectangle(195, 422, 128, 128);
        bg.scaleX = 3.0478477875318433;
        bg.scaleY = 6.5826734352555345;
        bg.alpha = 0.8;
        bg.isFilled = true;
        bg.fillColor = 0;
        this.add(bg);

        // currentItem
        const currentItem = scene.add.container(83, 85);
        currentItem.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(currentItem);

        // currentItemText
        const currentItemText = scene.add.image(115, 37, "loot_box", "current-Item.png");
        currentItem.add(currentItemText);

        // panel_sm_png
        const panel_sm_png = scene.add.image(113, 106, "loot_box", "panel-sm.png");
        currentItem.add(panel_sm_png);

        // currentItemName
        const currentItemName = scene.add.text(179, 72, "", {});
        currentItemName.setOrigin(0.5, 0.5);
        currentItemName.text = "Weapon Name";
        currentItemName.setStyle({ "align": "right", "fixedWidth": 180, "fontFamily": "Oxanium", "fontStyle": "bold" });
        currentItem.add(currentItemName);

        // currentItemLevel
        const currentItemLevel = scene.add.text(188, 101, "", {});
        currentItemLevel.text = "Level 0";
        currentItemLevel.setStyle({ "align": "right", "fixedWidth": 80, "fontFamily": "Oxanium", "fontStyle": "bold" });
        currentItem.add(currentItemLevel);

        // currentItemRarity
        const currentItemRarity = scene.add.text(238, 91, "", {});
        currentItemRarity.setOrigin(0.5, 0.5);
        currentItemRarity.text = "SSR";
        currentItemRarity.setStyle({
            "align": "right",
            "color": "#DEA614",
            "fixedWidth": 60,
            "fontFamily": "Oxanium",
            "fontStyle": "bold",
        });
        currentItem.add(currentItemRarity);

        // currentItemPower
        const currentItemPower = scene.add.text(100, 122, "", {});
        currentItemPower.text = "Attack: 11";
        currentItemPower.setStyle({
            "align": "right",
            "fixedWidth": 170,
            "fontFamily": "Oxanium",
            "fontStyle": "bold",
        });
        currentItem.add(currentItemPower);

        // currentItemImage
        const currentItemImage = scene.add.image(-23, 98, "loot_box", "magnum.png");
        currentItemImage.scaleX = 0.21978534360258425;
        currentItemImage.scaleY = 0.21978534360258425;
        currentItemImage.setOrigin(0, 0.5);
        currentItem.add(currentItemImage);

        // currentItemPrice
        const currentItemPrice = scene.add.container(-29, 122);
        currentItemPrice.blendMode = Phaser.BlendModes.SKIP_CHECK;
        currentItem.add(currentItemPrice);

        // currentPriceText
        const currentPriceText = scene.add.text(19, 0, "", {});
        currentPriceText.text = "Price";
        currentPriceText.setStyle({ "fontFamily": "Oxanium", "fontSize": "12px" });
        currentItemPrice.add(currentPriceText);

        // currentItemGoldAmount
        const currentItemGoldAmount = scene.add.text(20, 14, "", {});
        currentItemGoldAmount.text = "1,000,000,000 GOLD";
        currentItemGoldAmount.setStyle({
            "color": "#DEA614",
            "fontFamily": "Oxanium",
            "fontSize": "12px",
            "fontStyle": "bold",
        });
        currentItemPrice.add(currentItemGoldAmount);

        // coins_png
        const coins_png = scene.add.image(0, 17, "loot_box", "coins.png");
        currentItemPrice.add(coins_png);

        // arrow
        const arrow = scene.add.image(195, 321, "arrow");
        arrow.scaleX = 0.3014813312588752;
        arrow.scaleY = 0.3014813312588752;
        arrow.angle = -90;
        this.add(arrow);

        // text_1
        const text_1 = scene.add.text(76, 261, "", {});
        text_1.text = "(!) Current item will be sold automatically";
        text_1.setStyle({ "align": "center", "fontFamily": "Oxanium", "fontSize": "13px" });
        this.add(text_1);

        // button
        const button = scene.add.image(197, 741, "loot_box", "equip.png");
        this.add(button);

        // newItem
        const newItem = scene.add.container(74, 345);
        newItem.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(newItem);

        // panel_md_glow_png
        const panel_md_glow_png = scene.add.image(124, 182, "loot_box", "panel-md-glow.png");
        newItem.add(panel_md_glow_png);

        // newItemText
        const newItemText = scene.add.image(132, 45, "loot_box", "new-item.png");
        newItem.add(newItemText);

        // newItemImage
        const newItemImage = scene.add.image(-22, 168, "loot_box", "magnum.png");
        newItemImage.scaleX = 0.34157022414469074;
        newItemImage.scaleY = 0.34157022414469074;
        newItemImage.setOrigin(0, 0.5);
        newItem.add(newItemImage);

        // newItemPower
        const newItemPower = scene.add.text(-32, 231, "", {});
        newItemPower.text = "Attack: +100";
        newItemPower.setStyle({
            "align": "right",
            "color": "#A1E697",
            "fixedWidth": 310,
            "fontFamily": "Oxanium",
            "fontSize": "28px",
            "fontStyle": "bold",
        });
        newItem.add(newItemPower);

        // shadowFx_1
        newItemPower.preFX!.addShadow(0, 0, 0.1, 1, 0, 6, 1);

        // container_2
        const container_2 = scene.add.container(195, 115);
        container_2.blendMode = Phaser.BlendModes.SKIP_CHECK;
        newItem.add(container_2);

        // newItemRarity
        const newItemRarity = scene.add.text(0, 0, "", {});
        newItemRarity.text = "Rarity: SSR";
        newItemRarity.setStyle({ "align": "right", "fixedWidth": 90, "fontFamily": "Oxanium", "fontStyle": "bold" });
        container_2.add(newItemRarity);

        // newItemLevel
        const newItemLevel = scene.add.text(0, 23, "", {});
        newItemLevel.text = "Level 0";
        newItemLevel.setStyle({ "align": "right", "fixedWidth": 90, "fontFamily": "Oxanium", "fontStyle": "bold" });
        container_2.add(newItemLevel);

        // newItemName
        const newItemName = scene.add.text(-8, -14, "", {});
        newItemName.setOrigin(0.5, 0.5);
        newItemName.text = "Weapon Name";
        newItemName.setStyle({ "align": "right", "fixedWidth": 218, "fontFamily": "Oxanium", "fontStyle": "bold" });
        newItemName.setPadding({ "right": 10 });
        container_2.add(newItemName);

        this.currentItemName = currentItemName;
        this.currentItemLevel = currentItemLevel;
        this.currentItemRarity = currentItemRarity;
        this.currentItemPower = currentItemPower;
        this.currentItemImage = currentItemImage;
        this.currentItemGoldAmount = currentItemGoldAmount;
        this.button = button;
        this.newItemImage = newItemImage;
        this.newItemPower = newItemPower;
        this.newItemRarity = newItemRarity;
        this.newItemLevel = newItemLevel;
        this.newItemName = newItemName;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        bg.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private currentItemName: Phaser.GameObjects.Text;
    private currentItemLevel: Phaser.GameObjects.Text;
    private currentItemRarity: Phaser.GameObjects.Text;
    private currentItemPower: Phaser.GameObjects.Text;
    private currentItemImage: Phaser.GameObjects.Image;
    private currentItemGoldAmount: Phaser.GameObjects.Text;
    private button: Phaser.GameObjects.Image;
    private newItemImage: Phaser.GameObjects.Image;
    private newItemPower: Phaser.GameObjects.Text;
    private newItemRarity: Phaser.GameObjects.Text;
    private newItemLevel: Phaser.GameObjects.Text;
    private newItemName: Phaser.GameObjects.Text;

    /* START-USER-CODE */
    setComparisonItems(currentItem: InventoryItem, newItem: InventoryItem) {
        const currentItemDisplayName = getItemDisplayName(currentItem);
        const currentItemTextureName = getItemTextureName(currentItem) + ".png";
        if (currentItemTextureName) this.currentItemImage.setTexture("loot_box", currentItemTextureName);
        if (currentItemDisplayName) this.currentItemName.setText(currentItemDisplayName);
        this.currentItemLevel.setText(`Level: ${currentItem.level}`);
        this.currentItemRarity.setText(`${currentItem.rarity.toUpperCase()}`);
        this.currentItemPower.setText(`Attack: ${currentItem.minAtk}-${currentItem.maxAtk}`);
        this.currentItemGoldAmount.setText(`${numberWithCommas(currentItem.sellingPrice)} Gold`);

        const newItemDisplayName = getItemDisplayName(newItem);
        const newItemTextureName = getItemTextureName(newItem) + ".png";
        if (newItemTextureName) this.newItemImage.setTexture("loot_box", newItemTextureName);
        if (newItemDisplayName) this.newItemName.setText(newItemDisplayName);
        this.newItemLevel.setText(`Level: ${newItem.level}`);
        this.newItemRarity.setText(`Rarity: ${newItem.rarity.toUpperCase()}`);

        if (currentItem.name === "gem") {
            const currentItemRarityColor = getRarityColor(currentItem);
            this.currentItemRarity.setStyle({ color: currentItemRarityColor });
        }

        if (newItem.name === "gem") {
            const newItemRarityColor = getRarityColor(newItem);
            this.newItemRarity.setStyle({ color: newItemRarityColor });
            this.newItemImage.setX(this.newItemImage.x + 20);
        }

        const currentAverage = getAverageAttackPower(currentItem);
        const newAverage = getAverageAttackPower(newItem);
        this.newItemPower.setText(`Attack: +${newAverage - currentAverage}`);

        this.button.setInteractive({ useHandCursor: true });

        new PushComponent(this.button, () => {
            this.emit(LootBoxPopup.EVENTS.EQUIP);
        })
            .once()
            .activate();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
