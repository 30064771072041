import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import { sleep } from "@/lib/utils";

import Step1 from "@/shared/prefabs/tutorial/Step1";
import Step2 from "@/shared/prefabs/tutorial/Step2";
import Step3 from "@/shared/prefabs/tutorial/Step3";
import Step4 from "@/shared/prefabs/tutorial/Step4";
import Step5 from "@/shared/prefabs/tutorial/Step5";
import Step6 from "@/shared/prefabs/tutorial/Step6";
import Step7 from "@/shared/prefabs/tutorial/Step7";
import Step8 from "@/shared/prefabs/tutorial/Step8";
import Step9 from "@/shared/prefabs/tutorial/Step9";
import Step10 from "@/shared/prefabs/tutorial/Step10";
import Step11 from "@/shared/prefabs/tutorial/Step11";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";

interface TutorialStep {
    id: string;
    component:
        | typeof Step1
        | typeof Step2
        | typeof Step3
        | typeof Step4
        | typeof Step5
        | typeof Step6
        | typeof Step7
        | typeof Step8
        | typeof Step9
        | typeof Step10
        | typeof Step11;
    delay?: number;
    sceneKey?: string;
}

export class TutorialPlugin extends Phaser.Plugins.ScenePlugin {
    // private currentStepId: string = "step1";
    private currentComponent: any;
    static steps: TutorialStep[] = [
        {
            id: "0",
            component: Step1,
            sceneKey: "MiningGame",
        },
        {
            id: "1",
            component: Step2,
            sceneKey: "MiningGame",
        },
        {
            id: "2",
            component: Step3,
            sceneKey: "MiningGame",
        },
        {
            id: "3",
            component: Step4,
            delay: 3500,
            sceneKey: "MiningGame",
        },
        {
            id: "4",
            component: Step5,
            sceneKey: "MiningBoss",
        },
        {
            id: "5",
            component: Step6,
            sceneKey: "MiningGame",
        },
        {
            id: "6",
            component: Step7,
            sceneKey: "MiningBoss",
        },
        {
            id: "7",
            component: Step8,
            sceneKey: "SlotsBoot",
        },
        {
            id: "8",
            component: Step9,
            sceneKey: "SlotsBoot",
        },
        {
            id: "9",
            component: Step10,
            sceneKey: "MiningBoss",
        },
        {
            id: "10",
            component: Step11,
            sceneKey: "MiningBoss",
        },
    ];
    private tutorialContainer!: Phaser.GameObjects.Container;
    public eventBusComponent: EventBusComponent;

    constructor(scene: Phaser.Scene, pluginManager: Phaser.Plugins.PluginManager, pluginKey: string) {
        super(scene, pluginManager, pluginKey);

        this.eventBusComponent = new EventBusComponent();
    }

    init(stepId: string) {
        if (this.scene) {
            // Create tutorial container
            if (this.tutorialContainer) {
                this.tutorialContainer.destroy();
            }
            const tutorialContainer = this.scene.add.container(195, 422);
            tutorialContainer.setDepth(DEPTH_PRIORITY.TUTORIAL_POPUP);

            // Add an invisible rectangle to block interactions with lower elements
            const blocker = this.scene.add.rectangle(
                this.scene.scale.width / 2,
                this.scene.scale.height / 2,
                this.scene.scale.width,
                this.scene.scale.height,
                0x000000,
                0,
            );
            blocker.setInteractive();
            tutorialContainer.add(blocker);

            const Component = TutorialPlugin.steps.find((s) => s.id === stepId)?.component;
            if (!this.scene || !Component) return;

            const currentComponent = new Component(this.scene, 0, 0);
            currentComponent.init(this.eventBusComponent);
            tutorialContainer.add(currentComponent);

            // this.currentStepId = stepId;
            this.currentComponent = currentComponent;
            this.tutorialContainer = tutorialContainer;

            this.eventBusComponent.off("next", this.handleNext, this);
            this.eventBusComponent.on("next", this.handleNext, this);
        }
    }

    async handleNext({ step }: { step: string }) {
        const tutorialContainer = this.tutorialContainer;
        const currentStep = TutorialPlugin.steps.find((s) => s.id === step);

        const Component = currentStep?.component;
        if (!this.scene || !Component) return;
        tutorialContainer.remove(this.currentComponent);
        this.currentComponent.destroy();

        if (currentStep.delay) {
            await sleep(currentStep.delay);
        }
        const nextStepComponent = new Component(this.scene, 0, 0);
        nextStepComponent.init(this.eventBusComponent);
        tutorialContainer.add(nextStepComponent);

        this.currentComponent = nextStepComponent;

        this.scene.userManager.syncIntroStep(step);
    }

    destroy(): void {
        this.tutorialContainer?.destroy();
    }

    skipTutorial() {
        this.handleNext({ step: "10" });
    }

    startMining() {
        (this.scene as unknown as { startMining: () => void })?.startMining();
    }

    doMockSpin() {
        (this.scene as unknown as { doMockSpin: () => void })?.doMockSpin();
    }

    doMockOpenBox() {
        (this.scene as unknown as { doMockOpenBox: () => void })?.doMockOpenBox();
    }
}
