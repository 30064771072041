import { claimFreeBonus, type FreeBonusResponse } from "@/lib/api/telegram-backend/methods/free-bonus";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { numberWithCommas } from "@/ota-slots/utils/formatter";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class FreeBonus extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.rectangle(194, 422, 128, 128);
        bg.scaleX = 3.0478477875318433;
        bg.scaleY = 6.5826734352555345;
        bg.alpha = 0.8;
        bg.isFilled = true;
        bg.fillColor = 0;
        this.add(bg);

        // free_bonus
        const free_bonus = scene.add.image(446, 612, "free-bonus");
        free_bonus.scaleX = 0.2603954186708733;
        free_bonus.scaleY = 0.2580524900460001;
        free_bonus.setOrigin(1, 1);
        this.add(free_bonus);

        // claimButton
        const claimButton = scene.add.rectangle(195, 570, 128, 128);
        claimButton.setInteractive(new Phaser.Geom.Rectangle(0, 0, 128, 128), Phaser.Geom.Rectangle.Contains);
        claimButton.scaleX = 1.0032751180377946;
        claimButton.scaleY = 0.25166618746625274;
        this.add(claimButton);

        // amount
        const amount = scene.add.text(216, 387, "", {});
        amount.setOrigin(0.5, 0.5);
        amount.text = "0";
        amount.setStyle({
            "align": "center",
            "fixedWidth": 230,
            "fontFamily": "Oxanium",
            "fontSize": "36px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 4,
            "shadow.blur": 1,
        });
        amount.setLineSpacing(1);
        this.add(amount);

        this.claimButton = claimButton;
        this.amount = amount;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);

        // Make the background image interactive to block clicks
        bg.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private claimButton: Phaser.GameObjects.Rectangle;
    private amount: Phaser.GameObjects.Text;

    /* START-USER-CODE */

    public async show(freeBonus: FreeBonusResponse): Promise<boolean> {
        return new Promise((resolve) => {
            this.amount.setText(numberWithCommas(freeBonus.amountFreeBonus));

            new PushComponent(this.claimButton, async () => {
                const success = await claimFreeBonus();
                if (success) {
                    this.destroy();
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
                .once()
                .activate();
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
