import { openLink } from "./open-link";

const tg = window.Telegram.WebApp;

export const openInvoice = (invoiceUrl: string): void => {
    if (typeof tg.openInvoice === "function" && Number(tg.version) >= 6.1) {
        tg.MainButton.setText("Return to Game");
        tg.MainButton.show();

        tg.openInvoice(invoiceUrl, () => {
            tg.MainButton.hide();
        });
    } else {
        openLink(invoiceUrl);
    }
};
