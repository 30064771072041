// You can write more code here

/* START OF COMPILED CODE */

import { ScriptNode } from "@phaserjs/editor-scripts-core";
import Phaser from "phaser";
/* START-USER-IMPORTS */
import DurationConfigComp from "./DurationConfigComp";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "../events/EventBusComponent";
import { appLogger } from "@/utils/logger";

interface PushConfig {
    scale?: number;
    duration?: number;
    cooldown?: number; // Time in ms before next click is allowed
}
/* END-USER-IMPORTS */

const logger = appLogger.withNamespace("[ota-mining/game/components/animations/PushComponent]");

export default class PushComponent extends ScriptNode {
    constructor(
        parent: ScriptNode | Phaser.GameObjects.GameObject | Phaser.Scene,
        onExecute: () => void,
        context?: any,
    ) {
        super(parent);

        /* START-USER-CTR-CODE */
        this.onExecute = onExecute;
        this.context = context;
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */
    private _executing = false;
    private _lastClickTime = 0;
    private _once = false;
    private onExecute: () => void;
    private context: any;
    private config: PushConfig = {
        scale: 0.8,
        duration: 80,
        cooldown: 500,
    };

    setConfig(config: Partial<PushConfig>) {
        this.config = { ...this.config, ...config };
        return this;
    }

    once() {
        this._once = true;
        return this;
    }

    activate = (args?: any): PushComponent => {
        const obj = this.getActionTargetObject(args) as Phaser.GameObjects.Sprite;
        if (!obj) return this;

        if (this._once) {
            obj.setInteractive().once("pointerdown", this.handlePointerDown);
        } else {
            obj.setInteractive().on("pointerdown", this.handlePointerDown);
        }

        return this;
    };

    deactivate(args?: any): void {
        const obj = this.getActionTargetObject(args) as Phaser.GameObjects.Sprite;
        if (!obj) return;

        obj.off("pointerdown");
        obj.removeInteractive();
    }

    private handlePointerDown = () => {
        const obj = this.gameObject as Phaser.GameObjects.Sprite;
        if (!obj) return;

        const now = Date.now();
        if (this._executing || now - this._lastClickTime < (this.config.cooldown ?? 500)) {
            return;
        }

        this._lastClickTime = now;
        this._executing = true;
        EventBus.emit(CUSTOM_EVENTS.TAP);

        const duration = DurationConfigComp.getDuration(this, this.config.duration ?? 80);
        const { scaleX, scaleY } = obj;
        const scaleFactor = this.config.scale ?? 0.8;

        this.scene.add.tween({
            targets: obj,
            scaleX: scaleX * scaleFactor,
            scaleY: scaleY * scaleFactor,
            duration,
            yoyo: true,
            onComplete: this.handleAnimationComplete,
        });
    };

    private handleAnimationComplete = () => {
        const obj = this.gameObject as Phaser.GameObjects.Sprite;
        try {
            this.onExecute.call(this.context || obj);
        } catch (error) {
            logger.error("[handleAnimationComplete] Error in push animation callback:", error);
        } finally {
            this._executing = false;
        }
    };
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
