export const mockLuckyWheel = {
    spinId: "8a91f0ec-7b4b-4d5a-9009-53a7111d8464",
    reel: [
        {
            id: "usd_0",
            pieceType: "usd",
            title: "2 USD",
            value: 2,
        },
        {
            id: "usd_1",
            pieceType: "usd",
            title: "1.5 USD",
            value: 1.5,
        },
        {
            id: "usd_2",
            pieceType: "usd",
            title: "1.75 USD",
            value: 1.75,
        },
        {
            id: "usd_3",
            pieceType: "usd",
            title: "1.25 USD",
            value: 1.25,
        },
        {
            id: "usd_4",
            pieceType: "usd",
            title: "1 USD",
            value: 1,
        },
        {
            id: "usd_5",
            pieceType: "usd",
            title: "0.25 USD",
            value: 0.25,
        },
        {
            id: "usd_6",
            pieceType: "usd",
            title: "0.5 USD",
            value: 0.5,
        },
        {
            id: "usd_7",
            pieceType: "usd",
            title: "0.75 USD",
            value: 0.75,
        },
    ],
    rewardReel: {
        id: "usd_3",
        pieceType: "usd",
        title: "1.25 USD",
        value: 1.25,
    },
    cycleNormalSpin: 0,
    special: 0,
    init: false,
    normal: 0,
    luckyWheelCycleHistoryReward: [],
    spinType: "special",
};
