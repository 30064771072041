// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS, EventBusComponent } from "../../components/events/EventBusComponent";
import PushComponent from "../../components/animations/PushComponent";
import { winChanceToColor } from "@/ota-mining/utils/boss";
import { BUTTON_NAMES, SCREEN_NAMES, trackButtonTap } from "@/services/analytics";
import { getTotalAverageAttackPower } from "@/shared/utils/loot-box";
/* END-USER-IMPORTS */

export default class RetryConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 687);

        // image_1
        const image_1 = scene.add.image(0, 32, "retry_console", "Frame 427318414.png");
        this.add(image_1);

        // boxes_button_background_png
        const boxes_button_background_png = scene.add.image(2, 83, "bottom_console", "boxes-button-background.png");
        this.add(boxes_button_background_png);

        // moreBoxesButton
        const moreBoxesButton = scene.add.image(2, 85, "bottom_console", "get-more-boxes-button.png");
        this.add(moreBoxesButton);

        // text
        const text = new Text(scene, -20, -28);
        text.text = "Current win chance";
        text.setStyle({ "fontSize": "14px" });
        this.add(text);

        // winChance
        const winChance = new Text(scene, 49, -35);
        winChance.setOrigin(0, 0);
        winChance.text = "0%";
        winChance.setStyle({ "color": "#68DEBB", "fontSize": "14px", "stroke": "#68DEBB" });
        this.add(winChance);

        // text_2
        const text_2 = new Text(scene, -40, -2);
        text_2.text = "Total attack";
        text_2.setStyle({ "fontSize": "14px" });
        this.add(text_2);

        // attack
        const attack = new Text(scene, 9, -9);
        attack.setOrigin(0, 0);
        attack.text = "0 dmg";
        attack.setStyle({ "color": "#DEA614", "fontSize": "14px", "stroke": "#DEA614" });
        this.add(attack);

        this.moreBoxesButton = moreBoxesButton;
        this.winChance = winChance;
        this.attack = attack;

        /* START-USER-CTR-CODE */
        new PushComponent(
            this.moreBoxesButton,
            () => {
                // Although this button is now "get more boxes", we still use "upgrade weapon" in the analytics
                trackButtonTap(BUTTON_NAMES.UPGRADE_WEAPON, SCREEN_NAMES.BOSS);
                this.scene.scene.start("SlotsBoot");
            },
            this,
        ).activate();

        this.setupInfo();
        /* END-USER-CTR-CODE */
    }

    private moreBoxesButton: Phaser.GameObjects.Image;
    private winChance: Text;
    private attack: Text;

    /* START-USER-CODE */
    private eventBusComponent: EventBusComponent;

    init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;
    }

    setupInfo() {
        const miningBoss = this.scene.miningBossManager.getData();
        if (miningBoss) {
            const winChance = Number(miningBoss?.winChance || 0).toFixed(2);
            this.winChance.setText(`${winChance}%`);
            this.winChance.setColor(winChanceToColor(Number(winChance)));
        }
        const items = this.scene.userManager.getInventoryItems();
        if (items) {
            const totalAttack = getTotalAverageAttackPower(items);
            this.attack.setText(`${totalAttack} dmg`);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
