// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import SkipButton from "./SkipButton";
import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class Step5 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // step5_bg_png
        const step5_bg_png = scene.add.image(0, 0, "tutorial", "step5_bg.png");
        this.add(step5_bg_png);

        // skipButton
        const skipButton = new SkipButton(scene, 139, -377);
        this.add(skipButton);

        // message
        const message = scene.add.container(-128, -91);
        message.alpha = 0;
        this.add(message);

        // boxMessage1
        const boxMessage1 = scene.add.image(146, 35, "tutorial", "box_message_2.png");
        boxMessage1.scaleX = 0.9;
        boxMessage1.scaleY = 0.7;
        message.add(boxMessage1);

        // content1
        const content1 = new Text(scene, 146, 33);
        content1.text = "You got lucky this time, Remis Rangers! I'll be back for round two!!!";
        content1.setLineSpacing(6);
        content1.setWordWrapWidth(255);
        message.add(content1);

        // bossHead
        const bossHead = scene.add.image(131, -120, "tutorial", "boss_head.png");
        bossHead.alpha = 0;
        bossHead.alphaTopLeft = 0;
        bossHead.alphaTopRight = 0;
        bossHead.alphaBottomLeft = 0;
        bossHead.alphaBottomRight = 0;
        this.add(bossHead);

        this.message = message;
        this.bossHead = bossHead;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private message: Phaser.GameObjects.Container;
    private bossHead: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.bossHead,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        this.scene.tweens.add({
            targets: this.message,
            alpha: { from: 0, to: 1 },
            duration: 500,
            delay: 500,
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
