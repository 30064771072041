import { chapter0 } from "./0";
import { chapter1 } from "./1";
import { chapter10 } from "./10";
import { chapter2 } from "./2";
import { chapter3 } from "./3";
import { chapter4 } from "./4";
import { chapter5 } from "./5";
import { chapter6 } from "./6";
import { chapter7 } from "./7";
import { chapter8 } from "./8";
import { chapter9 } from "./9";

interface Position {
    x?: number;
    y?: number;
}

interface BodySize {
    w?: number;
    h?: number;
}

interface BodyOffset {
    x?: number;
    y?: number;
}
export interface BossConfig {
    name: string;
    idleAnimationKey: string;
    walkAnimationKey: string;
    jumpAnimationKey?: string;
    attackAnimationKey: string;
    gethitAnimationKey: string;
    destroyedAnimationKey: string;
    size: {
        scale: number;
    };
    position?: Position & {
        attack?: Position;
        dead?: Position;
    };
    body?: {
        size?: BodySize;
        offset?: BodyOffset;
    };
    texture: string[];
}

export interface CreepConfig {
    walkAnimationKey: string;
    attackAnimationKey: string;
    destroyedAnimationKey: string;
    size: {
        scale: number;
    };
    position?: Position & {
        die?: Position;
    };
    body?: {
        size?: BodySize;
        offset?: BodyOffset;
    };
    texture: string[];
}

export interface ChapterConfig {
    id: number;
    boss: BossConfig;
    creep: CreepConfig;
    background: {
        mining: string;
        boss: string;
        intro: string;
    };
}

export const chapters: Record<number, ChapterConfig> = {
    [chapter0.id]: chapter0,
    [chapter1.id]: chapter1,
    [chapter2.id]: chapter2,
    [chapter3.id]: chapter3,
    [chapter4.id]: chapter4,
    [chapter5.id]: chapter5,
    [chapter6.id]: chapter6,
    [chapter7.id]: chapter7,
    [chapter8.id]: chapter8,
    [chapter9.id]: chapter9,
    [chapter10.id]: chapter10,
};
