import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/referral]");

type ReferredByRequest = components["schemas"]["ReferredByRequestDto"];

export const processReferral = async (referredByData: ReferredByRequest): Promise<boolean> => {
    const { error } = await telegramBackendApi.POST("/referral/referred-by", {
        body: referredByData,
    });

    if (error) {
        logger.error("[processReferral]", error);
        return false;
    }

    return true;
};
