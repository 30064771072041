// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class SkipButton extends Phaser.GameObjects.Image {
    constructor(scene: Phaser.Scene, x?: number, y?: number, texture?: string, frame?: number | string) {
        super(scene, x ?? 195, y ?? 422, texture || "tutorial", frame ?? "skip_tutorial.png");

        /* START-USER-CTR-CODE */
        // new PushComponent(
        //     this,
        //     () => {
        //         this.scene.tutorial.skipTutorial();
        //     },
        //     this
        // );
        this.setInteractive();
        this.once("pointerdown", () => {
            this.scene.tutorial.skipTutorial();
        });
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */

    // Write your code here.

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
