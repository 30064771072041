// You can write more code here

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
import StoreListItem from "./StoreListItem";
import {
    createTelegramStarInvoice,
    getPaymentList,
    getTelegramStarPaymentResult,
    PaymentListResponse,
    Platform,
    verifyAppleIAPTransaction,
    verifyGooglePlayTransaction,
} from "@/lib/api/telegram-backend/methods";
import { purchaseProduct, purchaseVerificationSuccessful } from "@/lib/webview/imota";
import { AuthInitData } from "@/../types";
import { numberWithCommas } from "../../../ota-slots/utils/formatter";
import { openInvoice } from "@/utils/open-invoice";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { appLogger } from "@/utils/logger";
import { showAlert } from "@/lib/telegram/web-app";
/* END-USER-IMPORTS */

const logger = appLogger.withNamespace("[shared/prefabs/telegram-star/Store]");

export default class Store extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 99);

        // background
        const background = scene.add.image(-1, 325, "common_background");
        background.scaleX = 0.99999997085613;
        background.scaleY = 1.280143477706825;
        this.add(background);

        // header
        const header = scene.add.container(-155, -75);
        this.add(header);

        // title
        const title = scene.add.text(82, 38, "", {});
        title.text = "STORE";
        title.setStyle({
            "fontFamily": "Saiba",
            "fontSize": "44px",
            "fontStyle": "bold",
            "stroke": "#D8E63C",
            "strokeThickness": 1,
        });
        header.add(title);

        // titleShadow
        title.preFX!.addShadow(0, 0, 0.1, 1, 14214716, 6, 1);

        // backButton
        const backButton = scene.add.image(0, 23, "back_btn");
        header.add(backButton);

        // pagination
        const pagination = scene.add.container(-161, 662);
        this.add(pagination);

        // leftArrow
        const leftArrow = scene.add.image(1, 9, "arrow");
        leftArrow.scaleX = 0.3886018661195172;
        leftArrow.scaleY = 0.3886018661195172;
        pagination.add(leftArrow);

        // pages
        const pages = scene.add.text(143, -7, "", {});
        pages.text = "1/1";
        pages.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "30px",
            "fontStyle": "bold",
            "stroke": "#000",
        });
        pagination.add(pages);

        // rightArrow
        const rightArrow = scene.add.image(319, 9, "arrow");
        rightArrow.scaleX = 0.3886018661195172;
        rightArrow.scaleY = 0.3886018661195172;
        rightArrow.flipX = true;
        pagination.add(rightArrow);

        this.backButton = backButton;
        this.leftArrow = leftArrow;
        this.pages = pages;
        this.rightArrow = rightArrow;
        this.pagination = pagination;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);

        // Make the background image interactive to block clicks
        background.setInteractive();
        // Create store items container
        this.storeItemsContainer = scene.add.container(0, 0);
        this.add(this.storeItemsContainer);

        // Add event listener for Imota purchase verification
        if (!this.isImotaVerifyPurchaseEventListenerAdded) {
            window.addEventListener("imota:verify-purchase", this.handleVerifyPurchase);
            this.isImotaVerifyPurchaseEventListenerAdded = true;
        }

        backButton.setInteractive({ useHandCursor: true }).on("pointerdown", this.hideStore, this);
        /* END-USER-CTR-CODE */
    }

    private backButton: Phaser.GameObjects.Image;
    private leftArrow: Phaser.GameObjects.Image;
    private pages: Phaser.GameObjects.Text;
    private rightArrow: Phaser.GameObjects.Image;
    private pagination: Phaser.GameObjects.Container;

    /* START-USER-CODE */
    private storeItemsContainer: Phaser.GameObjects.Container;
    private isImotaVerifyPurchaseEventListenerAdded: boolean = false;
    private telegramStarInvoicePollingTimer: ReturnType<typeof setInterval> | undefined;

    private handlePollTelegramStarInvoice(transactionId: string) {
        const pollingInterval = 3000;

        if (this.telegramStarInvoicePollingTimer) {
            clearInterval(this.telegramStarInvoicePollingTimer);
        }

        this.telegramStarInvoicePollingTimer = setInterval(async () => {
            try {
                const paymentResult = await getTelegramStarPaymentResult(transactionId);

                if (paymentResult && paymentResult.status === "success") {
                    this.handleTelegramStarPaymentSuccess(paymentResult.otaGold);
                    this.clearTelegramStarInvoicePollingTimer();
                } else if (paymentResult && paymentResult.status === "failed") {
                    this.handleTelegramStarPaymentFailure();
                    this.clearTelegramStarInvoicePollingTimer();
                }
            } catch (error) {
                logger.error("[handlePollTelegramStarInvoice] Polling error:", error);
            }
        }, pollingInterval);
    }

    private handleTelegramStarPaymentSuccess(rewardAmount: number) {
        this.emit("set-balance", rewardAmount);
        showAlert(`Payment successful! You now have ${numberWithCommas(rewardAmount)} OTA GOLD.`);
        this.hideStore();
    }

    private handleTelegramStarPaymentFailure() {
        showAlert("Payment failed. Please try again.");
    }

    private clearTelegramStarInvoicePollingTimer() {
        if (this.telegramStarInvoicePollingTimer) {
            clearInterval(this.telegramStarInvoicePollingTimer);
            this.telegramStarInvoicePollingTimer = undefined;
        }
    }

    private handleVerifyPurchase = async (event: Event) => {
        const payload = (event as CustomEvent).detail;
        if (event.type === "imota:verify-purchase") {
            showAlert("Verifying...");
            if (payload.platform === "android") {
                const response = await verifyGooglePlayTransaction({
                    purchaseToken: payload.purchaseToken,
                    productId: payload.productId,
                });

                if (response?.status === "success") {
                    purchaseVerificationSuccessful();
                    this.emit("set-balance", response.otaGold);
                    showAlert(`Payment successful! You now have ${numberWithCommas(response.otaGold)} OTA GOLD.`);
                }
            } else if (payload.platform === "ios") {
                const response = await verifyAppleIAPTransaction({
                    receiptData: payload.receiptData,
                    productId: payload.productId,
                });
                if (response?.status === "success") {
                    purchaseVerificationSuccessful();
                    this.emit("set-balance", response.otaGold);
                    showAlert(`Payment successful! You now have ${numberWithCommas(response.otaGold)} OTA GOLD.`);
                }
            }

            this.hideStore();
        }
    };

    async selectProduct(product: PaymentListResponse[0], platform: Platform) {
        const confirmed = confirm("Do you want to proceed with this purchase?");
        if (!confirmed) {
            return;
        }

        showAlert("Purchasing... press OK to continue");

        if (platform === "telegram_star") {
            const result = await createTelegramStarInvoice({
                itemId: product.id,
            });
            if (!result) {
                return;
            }
            const { invoiceLink, transactionId } = result;
            openInvoice(invoiceLink);
            this.handlePollTelegramStarInvoice(transactionId);
            return;
        } else if (platform === "google" || platform === "apple") {
            if (!product.productId) return;
            purchaseProduct(product.productId);
        }
    }

    private getPlatformForPaymentList(authData: Omit<AuthInitData, "payload">): Platform {
        if (authData.provider === "imota") {
            if (authData.platform === "android") {
                return "google";
            }

            if (authData.platform === "ios") {
                return "apple";
            }
        }

        return "telegram_star";
    }

    hideStore() {
        this.setVisible(false);
        this.emit("hidden");
    }

    showStore() {
        this.setVisible(true);
    }

    private currentPlatform: Platform = "telegram_star";

    private currentPage: number = 1;
    private itemsPerPage: number = 5;
    private storeItems: PaymentListResponse = [];

    private updatePagination() {
        const totalPages = Math.ceil(this.storeItems.length / this.itemsPerPage);

        // Show/hide pagination based on total items
        this.pagination.setVisible(this.storeItems.length > this.itemsPerPage);

        // Update page text
        this.pages.setText(`${this.currentPage}/${totalPages}`);

        // Enable/disable arrows based on current page
        this.leftArrow.setAlpha(this.currentPage > 1 ? 1 : 0.5);
        this.rightArrow.setAlpha(this.currentPage < totalPages ? 1 : 0.5);
    }

    private renderCurrentPage() {
        // Clear all items from the store container
        this.storeItemsContainer.removeAll(true);

        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = Math.min(startIndex + this.itemsPerPage, this.storeItems.length);
        const currentPageItems = this.storeItems.slice(startIndex, endIndex);

        currentPageItems.forEach((product, index) => {
            const yOffset = 30;
            const xOffset = -135;
            const item = new StoreListItem(this.scene, xOffset, index * 110 + yOffset);
            item.setStoreData(product);

            item.on("click", () => {
                this.selectProduct(product, this.currentPlatform);
            });

            this.storeItemsContainer.add(item);
        });

        this.updatePagination();
    }

    async openStore(authData: Omit<AuthInitData, "payload">) {
        this.currentPlatform = this.getPlatformForPaymentList(authData);
        const data = await getPaymentList(this.currentPlatform);
        this.storeItems = data || [];
        this.currentPage = 1;

        if (this.storeItems.length > 0) {
            this.showStore();

            // Setup pagination controls
            this.leftArrow.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.renderCurrentPage();
                }
            });

            this.rightArrow.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
                const totalPages = Math.ceil(this.storeItems.length / this.itemsPerPage);
                if (this.currentPage < totalPages) {
                    this.currentPage++;
                    this.renderCurrentPage();
                }
            });

            this.renderCurrentPage();
        } else {
            logger.error("[openStore] Failed to fetch products");
        }
    }

    destroy() {
        this.storeItemsContainer.destroy();
        this.backButton.off("pointerdown", this.hideStore, this);
        this.leftArrow.off("pointerdown");
        this.rightArrow.off("pointerdown");
        window.removeEventListener("imota:verify-purchase", this.handleVerifyPurchase);
        this.clearTelegramStarInvoicePollingTimer();
        super.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
