/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { generateReferralLink, shareReferralLink } from "@/ota-mining/utils/referral-link";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { showAlert } from "@/lib/telegram/web-app";
/* END-USER-IMPORTS */

export default class InviteAFriendDisplay extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        this.visible = false;

        // blackBg
        const blackBg = scene.add.rectangle(195, 422, 390, 844);
        blackBg.visible = false;
        blackBg.isFilled = true;
        blackBg.fillColor = 0;
        this.add(blackBg);

        // background
        const background = scene.add.image(196, 428, "referral", "dialog.png");
        this.add(background);

        // closeButton
        const closeButton = scene.add.image(364, 165, "referral", "close_button.png");
        this.add(closeButton);

        // copyLinkButton
        const copyLinkButton = scene.add.image(196, 494, "referral", "copy_link_button.png");
        this.add(copyLinkButton);

        // shareNowButton
        const shareNowButton = scene.add.image(196, 589, "referral", "share_now_button.png");
        this.add(shareNowButton);

        // refCodeDisplay
        const refCodeDisplay = new Text(scene, 197, 347);
        refCodeDisplay.text = "";
        this.add(refCodeDisplay);

        this.blackBg = blackBg;
        this.refCodeDisplay = refCodeDisplay;

        /* START-USER-CTR-CODE */
        background.setInteractive();
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);

        this.closeButtonPush = new PushComponent(
            closeButton,
            () => {
                this.hide();
            },
            this,
        ).activate();

        this.copyButtonPush = new PushComponent(
            copyLinkButton,
            () => {
                this.handleCopyLink();
            },
            this,
        ).activate();

        this.shareButtonPush = new PushComponent(
            shareNowButton,
            () => {
                this.handleShareNow();
            },
            this,
        ).activate();
        /* END-USER-CTR-CODE */
    }

    private blackBg: Phaser.GameObjects.Rectangle;
    private refCodeDisplay: Text;

    /* START-USER-CODE */
    private referralLink: string;
    private closeButtonPush: PushComponent;
    private copyButtonPush: PushComponent;
    private shareButtonPush: PushComponent;

    public setUserReferralCode(code: string) {
        this.referralLink = generateReferralLink(code);
    }

    public show(showBlackBg?: boolean) {
        const displayLink = this.truncateReferralLink(this.referralLink);
        this.refCodeDisplay.setText(displayLink);
        this.setVisible(true);

        if (showBlackBg) {
            this.blackBg.setVisible(true);
        }
    }

    public hide() {
        this.setVisible(false);
    }

    private truncateReferralLink(url: string): string {
        try {
            const urlObj = new URL(url);
            const searchParams = new URLSearchParams(urlObj.search);
            const startappParam = searchParams.get("startapp");

            // Extract refCode from base64 encoded startapp parameter
            let refCode = "";
            try {
                const decodedData = JSON.parse(atob(startappParam || ""));
                refCode = decodedData.refCode;
            } catch (e) {
                refCode = startappParam || "";
            }

            // Get bot name and truncate to first 9 characters
            const botPath = urlObj.pathname.split("/")[1];
            const truncatedBotName = botPath.substring(0, 9);

            // Format as "https://t.me/BOTNAME(9chars)...refCode=CODE"
            const baseUrl = `${urlObj.protocol}//${urlObj.hostname}`;
            return `${baseUrl}/${truncatedBotName}...refCode=${refCode}`;
        } catch (e) {
            // Fallback to original URL if parsing fails
            return url;
        }
    }

    private handleCopyLink() {
        const isIos = () => {
            const ua = window.navigator.userAgent;
            return /iPhone|iPad|iPod/.test(ua);
        };

        const copyToiOSClipboard = (text: string): Promise<boolean> => {
            return new Promise((resolve) => {
                const textarea = document.createElement("textarea");
                textarea.value = text;

                // iOS requires these specific styles
                textarea.style.position = "fixed";
                textarea.style.top = "0";
                textarea.style.left = "0";
                textarea.style.width = "100px";
                textarea.style.height = "100px";
                textarea.style.opacity = "0";
                textarea.style.fontSize = "16px"; // Prevents zoom on iOS

                // Make sure it's editable
                textarea.contentEditable = "true";
                textarea.readOnly = false;

                document.body.appendChild(textarea);

                // Give iOS time to register the element
                setTimeout(() => {
                    try {
                        textarea.focus();
                        textarea.setSelectionRange(0, text.length);

                        const success = document.execCommand("copy");
                        document.body.removeChild(textarea);
                        resolve(success);
                    } catch (err) {
                        document.body.removeChild(textarea);
                        resolve(false);
                    }
                }, 100);
            });
        };

        const copyToClipboard = async (text: string) => {
            if (isIos()) {
                return copyToiOSClipboard(text);
            }

            try {
                await navigator.clipboard.writeText(text);
                return true;
            } catch (err) {
                // Fallback to older method using a temporary textarea
                try {
                    const textarea = document.createElement("textarea");
                    textarea.value = text;
                    textarea.style.position = "fixed";
                    textarea.style.opacity = "0";
                    document.body.appendChild(textarea);
                    textarea.focus();
                    textarea.select();
                    document.execCommand("copy");
                    document.body.removeChild(textarea);
                    return true;
                } catch (fallbackErr) {
                    return false;
                }
            }
        };

        copyToClipboard(this.referralLink).then((success) => {
            if (success) {
                showAlert("Referral link copied!");
            } else {
                showAlert("Failed to copy referral link. Please try again.");
            }
        });
    }

    private handleShareNow() {
        shareReferralLink(this.referralLink);
    }

    destroy(fromScene?: boolean) {
        try {
            this.closeButtonPush?.deactivate();
            this.copyButtonPush?.deactivate();
            this.shareButtonPush?.deactivate();
        } catch {}
        super.destroy(fromScene);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
