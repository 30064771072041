// You can write more code here

import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { InventoryItem } from "@/shared/types/loot-box";
import {
    getAverageAttackPower,
    getItemDisplayName,
    getItemTextureName,
    getRarityColor,
    ItemComparisonResult,
} from "@/shared/utils/loot-box";
import LootBoxPopup from "./LootBoxPopup";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class NewItemDisplay extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.rectangle(195, 422, 128, 128);
        bg.scaleX = 3.0478477875318433;
        bg.scaleY = 6.5826734352555345;
        bg.alpha = 0.8;
        bg.isFilled = true;
        bg.fillColor = 0;
        this.add(bg);

        // newItem
        const newItem = scene.add.container(72, 205);
        newItem.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(newItem);

        // panel_md_glow_png
        const panel_md_glow_png = scene.add.image(124, 182, "loot_box", "panel-md-glow.png");
        newItem.add(panel_md_glow_png);

        // newItemText
        const newItemText = scene.add.image(132, 45, "loot_box", "new-item.png");
        newItem.add(newItemText);

        // newItemImage
        const newItemImage = scene.add.image(-22, 177, "loot_box", "magnum.png");
        newItemImage.scaleX = 0.34157022414469074;
        newItemImage.scaleY = 0.34157022414469074;
        newItemImage.setOrigin(0, 0.5);
        newItem.add(newItemImage);

        // newItemPower
        const newItemPower = scene.add.text(-32, 231, "", {});
        newItemPower.text = "Attack: +100";
        newItemPower.setStyle({
            "align": "right",
            "color": "#A1E697",
            "fixedWidth": 310,
            "fontFamily": "Oxanium",
            "fontSize": "28px",
            "fontStyle": "bold",
        });
        newItem.add(newItemPower);

        // shadowFx_1
        newItemPower.preFX!.addShadow(0, 0, 0.1, 1, 0, 6, 1);

        // container_2
        const container_2 = scene.add.container(192, 115);
        container_2.blendMode = Phaser.BlendModes.SKIP_CHECK;
        newItem.add(container_2);

        // newItemRarity
        const newItemRarity = scene.add.text(0, 0, "", {});
        newItemRarity.text = "Rarity: SSR";
        newItemRarity.setStyle({ "align": "right", "fixedWidth": 90, "fontFamily": "Oxanium", "fontStyle": "bold" });
        container_2.add(newItemRarity);

        // newItemLevel
        const newItemLevel = scene.add.text(0, 23, "", {});
        newItemLevel.text = "Level 0";
        newItemLevel.setStyle({ "align": "right", "fixedWidth": 90, "fontFamily": "Oxanium", "fontStyle": "bold" });
        container_2.add(newItemLevel);

        // newItemName
        const newItemName = scene.add.text(-8, -14, "", {});
        newItemName.setOrigin(0.5, 0.5);
        newItemName.text = "Weapon Name";
        newItemName.setStyle({ "align": "right", "fixedWidth": 218, "fontFamily": "Oxanium", "fontStyle": "bold" });
        newItemName.setPadding({ "right": 10 });
        container_2.add(newItemName);

        // button
        const button = scene.add.image(197, 741, "loot_box", "equip.png");
        this.add(button);

        this.newItemText = newItemText;
        this.newItemImage = newItemImage;
        this.newItemPower = newItemPower;
        this.newItemRarity = newItemRarity;
        this.newItemLevel = newItemLevel;
        this.newItemName = newItemName;
        this.button = button;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        bg.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private newItemText: Phaser.GameObjects.Image;
    private newItemImage: Phaser.GameObjects.Image;
    private newItemPower: Phaser.GameObjects.Text;
    private newItemRarity: Phaser.GameObjects.Text;
    private newItemLevel: Phaser.GameObjects.Text;
    private newItemName: Phaser.GameObjects.Text;
    private button: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    setItem(
        rewardItem: InventoryItem,
        itemComparisonResult: Extract<ItemComparisonResult, "downgrade" | "neutral"> | "new",
    ) {
        const textureName = getItemTextureName(rewardItem);
        const displayName = getItemDisplayName(rewardItem);
        const averageAttackPower = getAverageAttackPower(rewardItem);
        if (textureName) this.newItemImage.setTexture("loot_box", textureName + ".png");
        if (displayName) this.newItemName.setText(displayName);
        this.newItemRarity.setText(`Rarity: ${rewardItem.rarity.toUpperCase()}`);
        this.newItemLevel.setText(`Level ${rewardItem.level}`);

        if (itemComparisonResult === "new") {
            this.newItemPower.setStyle({ "color": "#A1E697" }).setText(`Attack: +${averageAttackPower}`);
            this.button.setTexture("loot_box", "equip.png").setInteractive({ useHandCursor: true });

            new PushComponent(this.button, () => {
                this.emit(LootBoxPopup.EVENTS.COLLECT);
            })
                .once()
                .activate();
        } else if (itemComparisonResult === "downgrade") {
            this.newItemPower.setStyle({ "color": "#FF6666" }).setText(`Attack: -${averageAttackPower}`);
            this.button.setTexture("loot_box", "sell.png").setInteractive({ useHandCursor: true });

            new PushComponent(this.button, () => {
                this.emit(LootBoxPopup.EVENTS.SELL);
            })
                .once()
                .activate();
        } else {
            this.newItemText.setTexture("loot_box", "same-item.png");
            this.newItemPower.setStyle({ "color": "#FFFFFF" }).setText(`Attack: ${averageAttackPower}`);
            this.button.setTexture("loot_box", "sell.png").setInteractive({ useHandCursor: true });

            new PushComponent(this.button, () => {
                this.emit(LootBoxPopup.EVENTS.SELL);
            })
                .once()
                .activate();
        }

        if (rewardItem.name === "gem") {
            this.newItemImage.setX(this.newItemImage.x + 20);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
