// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../shared/prefabs/Text";
import Boss from "../Boss";
/* START-USER-IMPORTS */
import EasyProgressbar from "../EasyProgressBar";
import { HealthComponent } from "../../components/health/HealthComponent";
/* END-USER-IMPORTS */

export default class HealthFighter extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 196, y ?? 35);

        // frame_427318432_png_1
        const frame_427318432_png_1 = scene.add.image(0, 4, "boss_base", "Frame 427318432.png");
        this.add(frame_427318432_png_1);

        // button01_png
        const button01_png = scene.add.image(0, 0, "boss_base", "Button01.png");
        this.add(button01_png);

        // bossAvatar
        const bossAvatar = scene.add.image(154, -1, "boss_base", "Frame 427318435.png");
        this.add(bossAvatar);

        // frame_427318433_png_1
        const frame_427318433_png_1 = scene.add.image(-156, 0, "boss_base", "Frame 427318433.png");
        this.add(frame_427318433_png_1);

        // round
        const round = new Text(scene, 2, 0);
        round.text = "Round 1";
        round.setStyle({ fontSize: "12px" });
        this.add(round);

        // boss_frame
        const boss_frame = scene.add.image(154, -2, "boss_frame");
        boss_frame.scaleX = 0.5;
        boss_frame.scaleY = 0.5;
        this.add(boss_frame);

        // boss
        const boss = new Boss(scene, 154, -5);
        boss.scaleX = 0.18;
        boss.scaleY = 0.18;
        this.add(boss);

        this.round = round;
        this.boss = boss;

        /* START-USER-CTR-CODE */
        this.scene.events.on(Phaser.Scenes.Events.UPDATE, this.update, this);
        this.once(
            Phaser.GameObjects.Events.DESTROY,
            () => {
                this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
            },
            this,
        );
        this.boss.static();
        round.setText(`Round ${this.chapterData.bossLevel}`);
        /* END-USER-CTR-CODE */
    }

    private round: Text;
    private boss: Boss;

    /* START-USER-CODE */
    public playerHealthComponent!: HealthComponent;
    public bossHealthComponent!: HealthComponent;
    private playerHealthBar!: EasyProgressbar;
    private bossHealthBar!: EasyProgressbar;
    public isInitialized: boolean = false;

    get chapterData() {
        return this.scene.userManager.getChapterData();
    }

    init(playerHealthComponent: HealthComponent, bossHealthComponent: HealthComponent) {
        this.playerHealthComponent = playerHealthComponent;
        this.bossHealthComponent = bossHealthComponent;

        const playerHP = playerHealthComponent.startingLife;
        const bossHP = bossHealthComponent.startingLife;

        const playerHealthBar = new EasyProgressbar(this.scene, -140, -10, 100, 18, {
            container: this,
            backgroundColor: 0xbcbcbc,
            color: 0xd42f3a,
            padding: 2,
            progress: 1,
            text: {
                enabled: true,
                format: (progress) => `${(progress * playerHP).toFixed(0)}/${playerHP}`,
                origin: {
                    x: 0.5,
                    y: 0.5,
                },
                align: {
                    x: "center",
                    y: "center",
                },
                style: {
                    fontSize: "10px",
                    color: "#ffffff",
                    stroke: "#ffffff",
                },
            },
        });
        const bossHealthBar = new EasyProgressbar(this.scene, 40, -10, 100, 18, {
            container: this,
            backgroundColor: 0xbcbcbc,
            color: 0xd42f3a,
            padding: 2,
            progress: 1,
            text: {
                enabled: true,
                format: (progress) => `${(progress * bossHP).toFixed(0)}/${bossHP}`,
                origin: {
                    x: 0.5,
                    y: 0.5,
                },
                align: {
                    x: "center",
                    y: "center",
                },
                style: {
                    fontSize: "10px",
                    color: "#ffffff",
                    stroke: "#ffffff",
                },
            },
        });
        this.addAt(playerHealthBar, 1);
        this.addAt(bossHealthBar, 1);

        this.playerHealthBar = playerHealthBar;
        this.bossHealthBar = bossHealthBar;
        this.isInitialized = true;
    }

    update(): void {
        if (!this.isInitialized) {
            return;
        }

        // Show and update health bar when health is not full
        if (this.playerHealthComponent.currentLife < this.playerHealthComponent.startingLife) {
            const healthPercent = this.playerHealthComponent.currentLife / this.playerHealthComponent.startingLife;
            this.playerHealthBar.setProgress(healthPercent);
        }
        if (this.bossHealthComponent.currentLife < this.bossHealthComponent.startingLife) {
            const healthPercent = this.bossHealthComponent.currentLife / this.bossHealthComponent.startingLife;
            this.bossHealthBar.setProgress(healthPercent);
        }
    }

    reset() {
        this.isInitialized = false;
        this.playerHealthBar.destroy();
        this.bossHealthBar.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
