// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { WheelPiece } from "@/lib/api/telegram-backend/methods/lucky-wheel";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
/* END-USER-IMPORTS */

export default class PizzaBlack extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 214, y ?? 190);

        // pizza
        const pizza = scene.add.image(0, 0, "lucky_wheel", "pizza_black.png");
        this.add(pizza);

        // symbol
        const symbol = scene.add.image(-36, 28, "lucky_wheel", "rmx.png");
        symbol.scaleX = 0.4;
        symbol.scaleY = 0.4;
        this.add(symbol);

        // value
        const value = new Text(scene, -35, 5);
        value.angle = -59;
        value.setOrigin(0, 0);
        value.text = "0";
        value.setStyle({
            color: "#d9e53e",
            fontSize: "20px",
            fontStyle: "bold",
        });
        this.add(value);

        // dots
        const dots = scene.add.sprite(0, -4, "lucky_wheel", "dots_white.png");
        dots.scaleX = 0.38;
        dots.scaleY = 0.38;
        dots.visible = false;
        this.add(dots);

        this.pizza = pizza;
        this.symbol = symbol;
        this.value = value;
        this.dots = dots;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private pizza: Phaser.GameObjects.Image;
    private symbol: Phaser.GameObjects.Image;
    private value: Text;
    private dots: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    currentValue: number = 0;

    public getCurrentValue(): number {
        return this.currentValue;
    }

    setPiece(piece: WheelPiece) {
        const typeToTexture: Record<string, string> = {
            rmx: "rmx.png",
            ota_gold: "gold.png",
        };

        this.scene.tweens.addCounter({
            from: this.getCurrentValue(),
            to: piece.value,
            duration: 300,
            onUpdate: (tween) => {
                this.value.text = `${formatCompactNumber(tween.getValue())}`;
            },
            onComplete: () => {
                this.currentValue = piece.value;
            },
        });

        this.symbol.setTexture("lucky_wheel", typeToTexture[piece.pieceType]);
    }

    highlight() {
        this.dots.visible = true;
        this.dots.play("wheel_blink");
    }

    reset() {
        this.dots.stop();
        this.dots.setTexture("lucky_wheel", "dots_white.png");
        this.dots.visible = false;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
