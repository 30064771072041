// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class USDTProgressBar extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 203.16821903404116, y ?? 409.39251565957244);

        // progressBar
        const progressBar = scene.add.image(
            -8.168219034041158,
            1.6074843404275612,
            "usdt_progress_bar",
            "usdWin_f_00000.png",
        );
        this.add(progressBar);

        // balance
        const balance = new Text(scene, -5.168219034041158, -34.39251565957244);
        balance.text = "Balance: $0.0";
        balance.setStyle({ "color": "#46EA7F", "fontStyle": "bold" });
        this.add(balance);

        // text_1
        const text_1 = new Text(scene, -2.168219034041158, 41.60748434042756);
        text_1.alpha = 0.2;
        text_1.text = "Claiming $2 is equivalent to REMIS and GOLD store package";
        text_1.setStyle({ "align": "center", "color": "#DFE0E2", "fontSize": "12px" });
        text_1.setWordWrapWidth(201);
        this.add(text_1);

        // dollar_png
        const dollar_png = scene.add.image(111.83178096595884, -8.392515659572439, "lucky_wheel", "dollar.png");
        dollar_png.angle = 50;
        this.add(dollar_png);

        // ellipse_68_png
        const ellipse_68_png = scene.add.image(107.83178096595884, -5.392515659572439, "lucky_wheel", "Ellipse 68.png");
        this.add(ellipse_68_png);

        // text_3
        const text_3 = new Text(scene, 104.83178096595884, 0.6074843404275612);
        text_3.text = "$2";
        text_3.setStyle({ "fontStyle": "bold" });
        this.add(text_3);

        this.progressBar = progressBar;
        this.balance = balance;

        /* START-USER-CTR-CODE */
        this.updateBalance(0);
        /* END-USER-CTR-CODE */
    }

    private progressBar: Phaser.GameObjects.Image;
    private balance: Text;

    /* START-USER-CODE */

    private balanceAmount: number = 0;

    getBalance() {
        return this.balanceAmount;
    }

    setBalance(balance: number) {
        this.balanceAmount = balance;
        this.balance.text = `Balance: $${this.balanceAmount.toFixed(2)}`;
        const progress = Math.round((this.balanceAmount / 2) * 100);
        this.updateProgress(progress);
    }

    updateBalance(balance: number) {
        this.balanceAmount += balance;
        this.balance.text = `Balance: $${this.balanceAmount.toFixed(2)}`;
        const progress = Math.round((this.balanceAmount / 2) * 100);
        this.updateProgress(progress);
    }

    updateProgress(progress: number) {
        if (progress >= 100) {
            this.progressBar.setTexture("usdt_progress_bar", "usdWin_f_00049.png");
            return;
        }

        const imageNumber = Math.floor(progress / 2);
        const imageName = `usdWin_f_000` + (imageNumber < 10 ? `0${imageNumber}` : imageNumber) + ".png";

        this.progressBar.setTexture("usdt_progress_bar", imageName);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
