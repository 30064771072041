export const chapter0 = {
    id: 0,
    boss: {
        name: "Boss",
        idleAnimationKey: "boss_00_attack",
        walkAnimationKey: "boss_00_walk",
        jumpAnimationKey: "boss_00_jump",
        attackAnimationKey: "boss_00_attack",
        gethitAnimationKey: "boss_00_gethit",
        destroyedAnimationKey: "boss_00_die",
        size: {
            scale: 0.55,
        },
        position: {
            y: 0,
        },
        texture: ["boss_00_attack", "robot10-attack0.png"],
    },
    creep: {
        walkAnimationKey: "creep_00_walk",
        attackAnimationKey: "creep_00_attack",
        destroyedAnimationKey: "creep_00_die",
        size: {
            scale: 1,
        },
        position: {
            y: -10,
        },
        texture: ["creep_00_walk", "tile000.png"],
    },
    background: {
        mining: "chapter_00_bg",
        boss: "chapter_00_bg",
        intro: "chapter_00_bg",
    },
};
