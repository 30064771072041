type PieSliceId = "pizza_1" | "pizza_2" | "pizza_3" | "pizza_4" | "pizza_5" | "pizza_6" | "pizza_7" | "pizza_8";

const pieSliceIds: PieSliceId[] = [
    "pizza_1",
    "pizza_2",
    "pizza_3",
    "pizza_4",
    "pizza_5",
    "pizza_6",
    "pizza_7",
    "pizza_8",
];

type Theme = "Casual" | "Royal";

const THEME: Theme = "Royal";

export class WheelComponent {
    public wheelSpinning: boolean;
    private pointerBounceTween!: Phaser.Tweens.Tween;
    private lamps: Phaser.GameObjects.Sprite[] = [];
    private spinDirection: "clockwise" | "counter-clockwise" = "counter-clockwise";
    private pointerBounceTimer: Phaser.Time.TimerEvent;
    private lampBlinkTimer: Phaser.Time.TimerEvent;
    private lampRotateTimer: Phaser.Time.TimerEvent;
    private lampIndex: number;
    private scene: Phaser.Scene;
    private wheelContainer: Phaser.GameObjects.Container;
    private pointer: Phaser.GameObjects.Image;
    private pin: Phaser.GameObjects.Image;

    constructor(
        scene: Phaser.Scene,
        wheelContainer: Phaser.GameObjects.Container,
        pointer: Phaser.GameObjects.Image,
        pin: Phaser.GameObjects.Image,
    ) {
        this.scene = scene;
        this.wheelContainer = wheelContainer;
        this.pointer = pointer;
        this.pin = pin;

        this.setupPointerBounce();
    }

    setupLamps() {
        const radius = 365;
        const centerX = 480;
        const centerY = 480;
        const lampPositions = [];
        const numberOfLamps = 16;
        const rotationOffset = 11.6 * (Math.PI / 180);
        const scaleFactor = 0.94; // Expand/contract the lamp circle

        for (let i = 1; i <= numberOfLamps; i++) {
            const angle = i * (360 / numberOfLamps) * (Math.PI / 180) + rotationOffset;
            const adjustedRadius = radius * scaleFactor;
            const x = centerX + adjustedRadius * Math.cos(angle);
            const y = centerY - adjustedRadius * Math.sin(angle);
            lampPositions.push({ x, y });
        }

        lampPositions.forEach((pos) => {
            const lamp = this.scene.add.sprite(pos.x, pos.y, `${THEME}LampBig`);
            lamp.setScale(0.3);
            this.lamps.push(lamp);
        });
    }

    setupPointerBounce() {
        this.pointerBounceTween = this.scene.tweens.add({
            targets: this.pointer,
            angle: -15,
            yoyo: true,
            duration: 100,
            repeat: 0,
            paused: true,
        });
    }

    async resetWheel() {
        return new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.wheelContainer,
                angle: 360 * 2,
                duration: 1000,
                ease: "Cubic.easeOut",
                onStart: () => {
                    this.stopLampBlinking();
                    this.startLampRotation();
                    this.startPointerBounce(360 / 8);
                },
                onUpdate: (tween) => {
                    this.updatePointerBounce(tween.getValue());
                },
                onComplete: () => {
                    this.stopPointerBounce();
                    resolve();
                },
                onStop: () => {
                    this.stopPointerBounce();
                },
            });
        });
    }

    mockSpinWheel() {
        const tween = this.scene.tweens.add({
            targets: this.wheelContainer,
            angle: 360 * 100,
            duration: 100000,
            ease: "Cubic.easeOut",
            onStart: () => {
                this.stopLampBlinking();
                this.startLampRotation();
                this.startPointerBounce(360 / 8);
            },
            onUpdate: (tween) => {
                this.updatePointerBounce(tween.getValue());
            },
            onComplete: () => {
                this.stopPointerBounce();
            },
            onStop: () => {
                this.stopPointerBounce();
            },
        });

        return () => tween.stop();
    }

    async spinWheel(sliceIndex: number) {
        return await this.spinToSlice(sliceIndex);
    }

    // Generate a random offset with a bias toward the center of the slice. Bias toward the center (higher means more centered).
    private getWeightedRandomOffset(sliceAngle: number): number {
        const maxOffset = sliceAngle / 4;
        const bias = 0.5;

        const randomValue = Phaser.Math.FloatBetween(-1, 1);
        const weightedValue = randomValue * Math.pow(Math.abs(randomValue), bias);

        return weightedValue * maxOffset;
    }

    spinToSlice(sliceIndex: number) {
        return new Promise<number>((resolve) => {
            // Calculate each slice's angle (e.g., 360 / 8 for an 8-slice wheel)
            const sliceAngle = 360 / pieSliceIds.length; // Each slice is 45 degrees

            // Calculate the target angle based on the slice index
            let targetAngle = 360 - (sliceIndex * sliceAngle + sliceAngle / 2);
            // Generate a random offset with a bias toward the center of the slice. Bias toward the center (higher means more centered).
            const randomOffset = this.getWeightedRandomOffset(sliceAngle);
            targetAngle += randomOffset;

            // Apply the direction-based offset. This is to address the UI not landing on the target slice, but instead landing "2" slices away.
            if (this.spinDirection === "clockwise") {
                targetAngle += 2 * sliceAngle; // Move 2 slices forward if spinning clockwise
            } else {
                targetAngle -= 2 * sliceAngle; // Move 2 slices backward if spinning counter-clockwise
            }

            // Add extra rotations for a visual effect and end exactly on the selected slice
            const totalRotation = 5 * 360 + targetAngle;

            this.scene.tweens.add({
                targets: this.wheelContainer,
                angle: totalRotation,
                duration: 5500,
                ease: "Cubic.easeOut",
                onStart: () => {
                    this.stopLampBlinking();
                    this.startLampRotation();
                    this.startPointerBounce(sliceAngle);
                },
                onUpdate: (tween) => {
                    this.updatePointerBounce(tween.getValue());
                },
                onComplete: () => {
                    this.stopPointerBounce();
                    this.showResult(sliceIndex);
                    resolve(sliceIndex);
                },
            });
        });
    }

    showResult(sliceIndex: number) {}

    //------------------------ POINTER ANIMATIONS ------------------------

    triggerPointerBounce() {
        if (this.pointerBounceTween && this.pointerBounceTween.isPlaying()) return;

        this.pointerBounceTween = this.scene.tweens.add({
            targets: this.pointer,
            angle: -30,
            duration: 32,
            yoyo: true,
            repeat: 0,
            ease: "Quad.easeOut",
        });
    }

    startPointerBounce(sliceAngle: number) {
        let lastSliceCrossed = -1;

        this.pointerBounceTimer = this.scene.time.addEvent({
            delay: 1,
            loop: true,
            callback: () => {
                const currentAngle = Phaser.Math.Wrap(this.wheelContainer.angle, 0, 360);
                const sliceIndex = Math.floor(currentAngle / sliceAngle);

                if (sliceIndex !== lastSliceCrossed) {
                    // If we crossed into a new slice, trigger the bounce
                    this.triggerPointerBounce();
                    lastSliceCrossed = sliceIndex;
                }
            },
            callbackScope: this,
        });
    }

    stopPointerBounce() {
        if (this.pointerBounceTimer) {
            this.pointerBounceTimer.remove();
        }
        this.pointer.angle = 0;
    }

    updatePointerBounce(wheelRotationSpeed: number) {
        if (this.pointerBounceTimer) {
            const adjustedDelay = Math.max(10, 300 / Math.abs(wheelRotationSpeed));
            // @ts-expect-error FIXME
            this.pointerBounceTimer.delay = adjustedDelay;
        }
    }

    //------------------------ LAMP ANIMATIONS ------------------------
    startLampBlinking() {
        this.lampBlinkTimer = this.scene.time.addEvent({
            delay: 500,
            loop: true,
            callback: this.blinkLamps,
            callbackScope: this,
        });
    }

    blinkLamps() {
        this.lamps.forEach((lamp, index) => {
            lamp.setTexture(index % 2 === 0 ? `${THEME}LampOnBig` : `${THEME}LampBig`);
        });

        this.scene.time.delayedCall(250, () => {
            this.lamps.forEach((lamp, index) => {
                lamp.setTexture(index % 2 !== 0 ? `${THEME}LampOnBig` : `${THEME}LampBig`);
            });
        });
    }

    stopLampBlinking() {
        if (this.lampBlinkTimer) {
            this.lampBlinkTimer.remove();
        }
    }

    startLampRotation() {
        this.lampIndex = 0;
        this.lampRotateTimer = this.scene.time.addEvent({
            delay: 50, // Faster update for smoother rotation
            loop: true,
            callback: this.rotateLamps,
            callbackScope: this,
        });
    }

    rotateLamps() {
        const lampDirection: "clockwise" | "counter-clockwise" = "clockwise";
        const trailLength = 3; // Number of lamps to light up for a trailing effect
        this.lamps.forEach((lamp, index) => {
            if (
                (index >= this.lampIndex && index < this.lampIndex + trailLength) ||
                (index + this.lamps.length < this.lampIndex + trailLength &&
                    index < (this.lampIndex + trailLength) % this.lamps.length)
            ) {
                lamp.setTexture(`${THEME}LampOnBig`);
            } else {
                lamp.setTexture(`${THEME}LampBig`);
            }
        });

        // Adjust lampIndex based on direction
        if (lampDirection === "clockwise") {
            this.lampIndex = (this.lampIndex - 1 + this.lamps.length) % this.lamps.length;
        } else {
            this.lampIndex = (this.lampIndex + 1) % this.lamps.length;
        }
    }

    stopLampRotation() {
        if (this.lampRotateTimer) {
            this.lampRotateTimer.remove();
        }
        this.lamps.forEach((lamp) => lamp.setTexture(`${THEME}LampBig`));
        this.startLampBlinking();
    }
}
