// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class RewardPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.image(195, 422, "lucky_wheel", "pop_bg.png");
        this.add(bg);

        // elementPop
        const elementPop = scene.add.sprite(195, 380, "wheel_elements_pop", "Property 1=xu_pop_f_00.png");
        this.add(elementPop);

        // amountText
        const amountText = new Text(scene, 195, 475);
        amountText.alpha = 0;
        amountText.text = "0";
        amountText.setStyle({
            fontSize: "40px",
            fontStyle: "bold",
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#765C18",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(amountText);

        this.elementPop = elementPop;
        this.amountText = amountText;

        /* START-USER-CTR-CODE */
        bg.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private elementPop: Phaser.GameObjects.Sprite;
    private amountText: Text;

    /* START-USER-CODE */
    public rewardAmount: number;
    public pieceType: string;

    init() {
        const typeToTexture: Record<string, string> = {
            rmx: "Property 1=remis_pop_f_01.png",
            ota_gold: "Property 1=xu_pop_f_00.png",
            usd: "Property 1=dollarBill_pop_f_01.png",
        };
        this.elementPop.setTexture("wheel_elements_pop", typeToTexture[this.pieceType]);
    }

    playAnimation() {
        this.elementPop.play(`${this.pieceType}_pop`);

        this.elementPop.once("animationcomplete", () => {
            this.amountText.text = `${numberWithCommas(this.rewardAmount)}`;
            this.scene.add.tween({
                targets: this.amountText,
                alpha: 1,
                scale: { from: 0, to: 1 },
                duration: 300,
                ease: "Power2",
                onComplete: () => {
                    this.scene.time.delayedCall(2000, () => {
                        this.destroy();
                    });
                },
            });
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
