// You can write more code here

import { PaymentListResponse } from "@/lib/api/telegram-backend/methods";
import { numberWithCommas } from "@/ota-slots/utils/formatter";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class StoreListItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? -134, y ?? -38);

        // listing_card
        const listing_card = scene.add.image(134, 38, "telegram_star-0", "listing-card.png");
        listing_card.scaleX = 0.3191805756300511;
        listing_card.scaleY = 0.3191805756300511;
        this.add(listing_card);

        // goldAmount
        const goldAmount = scene.add.text(-32, 64, "", {});
        goldAmount.setOrigin(0, 1);
        goldAmount.text = "0";
        goldAmount.setStyle({
            "color": "#3E3214",
            "fixedWidth": 160,
            "fontFamily": "Oxanium",
            "fontSize": "30px",
            "fontStyle": "bold",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 4,
        });
        goldAmount.setLineSpacing(1);
        this.add(goldAmount);

        // button
        const button = scene.add.container(197, 45);
        button.scaleX = 0.9628800367336787;
        button.scaleY = 0.9628800367336787;
        this.add(button);

        // buttonBackground
        const buttonBackground = scene.add.image(32.5, 0, "telegram_star-0", "green-button.png");
        buttonBackground.scaleX = 0.3378846720498667;
        buttonBackground.scaleY = 0.3378846720498667;
        button.add(buttonBackground);

        // star
        const star = scene.add.image(0.5, -3, "telegram_star-0", "star.png");
        star.scaleX = 0.7343677344157566;
        star.scaleY = 0.7343677344157566;
        button.add(star);

        // starAmount
        const starAmount = scene.add.text(17, -16, "", {});
        starAmount.text = "0";
        starAmount.setStyle({
            "fixedWidth": 76,
            "fontFamily": "Oxanium",
            "fontSize": "26px",
            "fontStyle": "bold",
            "stroke": "#000",
            "shadow.offsetY": 4,
            "shadow.stroke": true,
        });
        starAmount.setWordWrapWidth(144);
        button.add(starAmount);

        // was
        const was = scene.add.container(159, 3);
        was.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(was);

        // wasText
        const wasText = scene.add.text(0, 0, "", {});
        wasText.text = "WAS 20";
        wasText.setStyle({ "align": "center", "color": "#3E3214", "fontFamily": "Oxanium", "fontSize": "12px" });
        was.add(wasText);

        // strikethrough
        const strikethrough = scene.add.rectangle(26, 7, 65, 1);
        strikethrough.isFilled = true;
        strikethrough.fillColor = 4076052;
        strikethrough.strokeColor = 4076052;
        was.add(strikethrough);

        // labelContainer
        const labelContainer = scene.add.container(300, 6);
        labelContainer.blendMode = Phaser.BlendModes.SKIP_CHECK;
        labelContainer.angle = -4;
        this.add(labelContainer);

        // label
        const label = scene.add.image(0, 0, "telegram_star-0", "label.png");
        label.scaleX = 0.03033692324818738;
        label.scaleY = 0.03033692324818738;
        labelContainer.add(label);

        // times
        const times = scene.add.text(0, -5, "", {});
        times.scaleX = 0.7543461758451406;
        times.scaleY = 0.7543461758451406;
        times.setOrigin(0.5, 0.5);
        times.text = "0X";
        times.setStyle({
            "color": "#16161D",
            "fontFamily": "Oxanium",
            "fontStyle": "bold",
            "stroke": "#D8E63C",
            "strokeThickness": 1,
            "shadow.offsetX": -2,
            "shadow.offsetY": -2,
            "shadow.color": "#775F25",
        });
        labelContainer.add(times);

        // sale
        const sale = scene.add.text(0, 5, "", {});
        sale.scaleX = 0.5148386232694206;
        sale.scaleY = 0.5148386232694206;
        sale.setOrigin(0.5, 0.5);
        sale.text = "SALE";
        sale.setStyle({
            "color": "#16161D",
            "fontFamily": "Oxanium",
            "fontStyle": "bold",
            "stroke": "#D8E63C",
            "strokeThickness": 1,
            "shadow.offsetX": -2,
            "shadow.offsetY": -2,
            "shadow.color": "#775F25",
        });
        labelContainer.add(sale);

        // firstTimePurchaseLabel
        const firstTimePurchaseLabel = scene.add.image(260, 4, "telegram_star-0", "1st-time-purchase-label.png");
        firstTimePurchaseLabel.scaleX = 0.42384289253726076;
        firstTimePurchaseLabel.scaleY = 0.42384289253726076;
        this.add(firstTimePurchaseLabel);

        this.goldAmount = goldAmount;
        this.buttonBackground = buttonBackground;
        this.starAmount = starAmount;
        this.button = button;
        this.wasText = wasText;
        this.strikethrough = strikethrough;
        this.was = was;
        this.times = times;
        this.labelContainer = labelContainer;
        this.firstTimePurchaseLabel = firstTimePurchaseLabel;

        /* START-USER-CTR-CODE */
        buttonBackground.setInteractive({ useHandCursor: true }).on("pointerdown", this.onClick, this);
        /* END-USER-CTR-CODE */
    }

    private goldAmount: Phaser.GameObjects.Text;
    private buttonBackground: Phaser.GameObjects.Image;
    private starAmount: Phaser.GameObjects.Text;
    private button: Phaser.GameObjects.Container;
    private wasText: Phaser.GameObjects.Text;
    private strikethrough: Phaser.GameObjects.Rectangle;
    private was: Phaser.GameObjects.Container;
    private times: Phaser.GameObjects.Text;
    private labelContainer: Phaser.GameObjects.Container;
    private firstTimePurchaseLabel: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    public setStoreData(product: PaymentListResponse[number]): void {
        this.goldAmount.setText(numberWithCommas(product.rewardValue));
        const defaultFontSize = 30;
        const minFontSize = 14;
        const maxWidth = 160;

        this.goldAmount.setStyle({ fixedWidth: 0 });

        if (this.goldAmount.width > maxWidth) {
            let currentSize = defaultFontSize;
            while (this.goldAmount.width > maxWidth && currentSize > minFontSize) {
                currentSize -= 1;
                this.goldAmount.setStyle({ fontSize: `${currentSize}px` });
            }
        }

        this.goldAmount.setStyle({ fixedWidth: maxWidth });

        // (1) Handle First Purchase Badge and Price Display
        this.firstTimePurchaseLabel.setVisible(product.isFirstTimePaid ?? false);

        // (2) Handle Coupon Badge and Discounted Price
        if (product.isFirstTimePaid && product.firstTimeReward) {
            const multiplier = Math.floor(product.firstTimeReward / product.rewardValue);

            if (multiplier >= 2 && multiplier <= 10) {
                this.was.setVisible(true);
                this.wasText.setText(`WAS ${numberWithCommas(product.price * multiplier)}`);

                const textWidth = this.wasText.width;
                this.strikethrough.width = textWidth + 10;

                this.labelContainer.setVisible(true);
                this.times.setText(`${multiplier}X`);

                this.starAmount.setText(numberWithCommas(product.price));
            } else {
                // Hide coupon elements if multiplier is outside valid range
                this.was.setVisible(false);
                this.labelContainer.setVisible(false);
                this.starAmount.setText(numberWithCommas(product.price));
            }
        } else {
            // Hide coupon elements if not first time purchase
            this.was.setVisible(false);
            this.labelContainer.setVisible(false);
            this.starAmount.setText(numberWithCommas(product.price));
        }
    }

    onClick(): void {
        this.emit("click");
        this.buttonBackground.disableInteractive();
        this.scene.time.delayedCall(3000, () => {
            this.buttonBackground.setInteractive({ useHandCursor: true });
        });
    }

    destroy() {
        this.buttonBackground.off("pointerdown", this.onClick, this);
        super.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
