// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
import SkipButton from "./SkipButton";
/* START-USER-IMPORTS */
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class Step2 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // tutorial_bg_png
        const tutorial_bg_png = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        this.add(tutorial_bg_png);

        // box_message_png
        const box_message_png = scene.add.image(2, -156, "tutorial", "box_message.png");
        box_message_png.scaleY = 0.3;
        this.add(box_message_png);

        // player_avatar_png
        const player_avatar_png = scene.add.image(0, -235, "tutorial", "player_avatar.png");
        player_avatar_png.scaleX = 0.4;
        player_avatar_png.scaleY = 0.4;
        this.add(player_avatar_png);

        // remis
        const remis = scene.add.image(0, 24, "tutorial", "remis.png");
        remis.alpha = 0;
        remis.alphaTopLeft = 0;
        remis.alphaTopRight = 0;
        remis.alphaBottomLeft = 0;
        remis.alphaBottomRight = 0;
        this.add(remis);

        // gold
        const gold = scene.add.image(0, 237, "tutorial", "gold.png");
        gold.alpha = 0;
        gold.alphaTopLeft = 0;
        gold.alphaTopRight = 0;
        gold.alphaBottomLeft = 0;
        gold.alphaBottomRight = 0;
        this.add(gold);

        // nextButton
        const nextButton = scene.add.image(145, 375, "tutorial", "next.png");
        nextButton.alpha = 0;
        nextButton.alphaTopLeft = 0;
        nextButton.alphaTopRight = 0;
        nextButton.alphaBottomLeft = 0;
        nextButton.alphaBottomRight = 0;
        this.add(nextButton);

        // text
        const text = new Text(scene, 3, -155);
        text.text = "You can mine two awesome tokens daily for free:";
        text.setStyle({ "fontSize": "18px" });
        text.setLineSpacing(4);
        text.setWordWrapWidth(307);
        this.add(text);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -377);
        this.add(skipButton_1);

        this.remis = remis;
        this.gold = gold;
        this.nextButton = nextButton;

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    private remis: Phaser.GameObjects.Image;
    private gold: Phaser.GameObjects.Image;
    private nextButton: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.remis,
                y: { from: 48, to: 24 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.gold,
                y: { from: 260, to: 237 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.nextButton,
                alpha: { from: 0, to: 1 },
                duration: 400,
                onComplete: () => resolve(),
            });
        });

        new PushComponent(
            this.nextButton,
            () => {
                eventBusComponent.emit("next", {
                    step: "2",
                });
            },
            this,
        ).activate();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
