// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import TopConsole from "../prefab/mining/TopConsole";
import BossConsole from "../prefab/mining/BossConsole";
import HealthFighter from "../prefab/boss/HealthFighter";
import RetryConsole from "../prefab/boss/RetryConsole";
import WinningConsole from "../prefab/boss/WinningConsole";
import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS, EventBusComponent } from "../components/events/EventBusComponent";
import Player from "../prefab/Player";
import Boss from "../prefab/Boss";
import NextChapter from "../prefab/boss/NextChapter";
import { FightBossResponse, fightMiningBoss } from "@/lib/api/telegram-backend/methods/mining-boss";
import { EventBus } from "@/lib/event-bus";
import { createTween } from "@/utils/animation-utils";
import { bossFightDataStep9, bossFightDataStep4, bossFightDataStep6 } from "@/shared/plugins/tutorial/mock-data";
import { BossConfig } from "../chapters";
import { getChapterAndBossLevel, getChapterKey, MAX_CHAPTER } from "@/shared/registry/chapter";
import WeekerItemList from "@/shared/prefabs/loot-box/WeekerItemList";
import { sellLootBox, getLootBoxInfo } from "@/lib/api/telegram-backend/methods/loot-box";
import LootBoxPopup from "@/shared/prefabs/loot-box/LootBoxPopup";
import { setupScreenTracking } from "@/services/analytics";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
/* END-USER-IMPORTS */

export default class BossFighter extends Phaser.Scene {
    constructor() {
        super("MiningBoss");

        /* START-USER-CTR-CODE */
        this.eventBusComponent = new EventBusComponent();
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "chapter_01_bg");
        bg.visible = false;

        // topConsole
        const topConsole = new TopConsole(this, 195, 81);
        this.add.existing(topConsole);

        // bossConsole
        const bossConsole = new BossConsole(this, 195, 425);
        this.add.existing(bossConsole);

        // healthFighter
        const healthFighter = new HealthFighter(this, 195, 110);
        this.add.existing(healthFighter);

        // retryConsole
        const retryConsole = new RetryConsole(this, 195, 686);
        this.add.existing(retryConsole);
        retryConsole.visible = false;

        // winningConsole
        const winningConsole = new WinningConsole(this, 195, 425);
        this.add.existing(winningConsole);
        winningConsole.visible = false;

        // fightStatus
        const fightStatus = new Text(this, 204, 200);
        this.add.existing(fightStatus);
        fightStatus.visible = false;
        fightStatus.text = "Game begins in 3...";
        fightStatus.setStyle({
            color: "#EBF29A",
            fontFamily: "Saiba",
            fontSize: "32px",
            stroke: "#EBF29A",
        });

        this.bg = bg;
        this.topConsole = topConsole;
        this.bossConsole = bossConsole;
        this.healthFighter = healthFighter;
        this.retryConsole = retryConsole;
        this.winningConsole = winningConsole;
        this.fightStatus = fightStatus;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private topConsole!: TopConsole;
    private bossConsole!: BossConsole;
    private healthFighter!: HealthFighter;
    private retryConsole!: RetryConsole;
    private winningConsole!: WinningConsole;
    private fightStatus!: Text;

    /* START-USER-CODE */

    // Write your code here
    private eventBusComponent: EventBusComponent;
    private player: Player;
    private boss: Boss;
    private bossPlayerCollider: Phaser.Physics.Arcade.Collider;
    private playerBossOverlap: Phaser.Physics.Arcade.Collider;
    private bossFightData: FightBossResponse;
    private step?: string;

    get chapterData() {
        return this.userManager.getChapterData();
    }

    get bossConfig(): BossConfig {
        return this.chapterManager.getData()!.boss;
    }

    init() {
        setupScreenTracking(this);
        this.step = this.userManager.getIntroStep();
    }

    async create() {
        this.editorCreate();
        this.setupBackground();
        this.topConsole.init(this.eventBusComponent, true);

        EventBus.emit(CUSTOM_EVENTS.PLAY_BOSS_BG);
        // check and loading next chapter in background
        this.preloadNextChapter(1);

        // Listen for scene destroy event
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.shutdown();
        });

        if (this.chapterData.chapter === 1 && this.chapterData.bossLevel === 1) {
            await this.showChapterIntro();
        }
        this.startBossFight();
    }

    preloadNextChapter(next: number = 0) {
        const nextChapter = this.userManager.getChapterData().chapter + next;
        if (nextChapter <= MAX_CHAPTER) {
            const chapterKey = getChapterKey(nextChapter);

            const assetPackKey = `chapter_${chapterKey}_asset_pack`;

            if (!this.checkNextChapterLoaded(chapterKey)) {
                if (this.cache.json.exists(assetPackKey)) {
                    this.cache.json.remove(assetPackKey);
                }
                this.load.pack(
                    `chapter_${chapterKey}_asset_pack`,
                    `ota-mining/asset-pack/chapter_${chapterKey}_asset_pack.json`,
                );

                this.load.start();
            }
        }
    }

    checkNextChapterLoaded(key: string | number) {
        const textureKeys = [
            `boss_${key}_die`,
            `boss_${key}_attack`,
            `boss_${key}_gethit`,
            `boss_${key}_walk`,
            `creep_${key}_die`,
            `creep_${key}_attack`,
            `creep_${key}_walk`,
        ];

        return textureKeys.every((textureKey) => this.textures.exists(textureKey));
    }

    shutdown() {
        // Clean up any resources
        this.eventBusComponent.removeAllListeners();
        this.eventBusComponent.destroy();

        this.player.destroy();
        this.boss.destroy();

        if (this.bossPlayerCollider) {
            this.bossPlayerCollider.destroy();
        }
        if (this.playerBossOverlap) {
            this.playerBossOverlap.destroy();
        }
    }

    showCountDownText() {
        this.fightStatus.setVisible(true);
        EventBus.emit(CUSTOM_EVENTS.BOSS_FIGHT_START);

        return new Promise<void>((resolve) => {
            const countdownSeconds = 3;
            let currentSeconds = countdownSeconds;

            const updateText = () => {
                this.fightStatus.setScale(1);
                this.fightStatus.alpha = 1;

                if (currentSeconds >= 0) {
                    if (currentSeconds === 0) {
                        this.fightStatus.text = "GO!";
                    } else {
                        this.fightStatus.text = `Game begins in ${currentSeconds}...`;
                    }
                    currentSeconds--;
                } else {
                    this.fightStatus.text = "Player's Turn";
                    resolve();
                    return;
                }
            };

            // Set initial text
            updateText();

            // Create timer event that fires every second
            this.time.addEvent({
                delay: 1000,
                repeat: countdownSeconds,
                callback: updateText,
            });
        });
    }

    async startBossFight() {
        const player = new Player(this, -150, 505);
        this.add.existing(player);
        this.player = player;

        // Add tween to fly player in from left with optimized animation
        this.tweens.add({
            targets: player,
            x: 75, // Final x position
            duration: 800, // Reduced duration for smoother animation
            onComplete: () => {
                this.player.playPlayerIdleAnimation();
            },
        });

        const boss = new Boss(this, this.scale.width + 50, this.player.y + (this.bossConfig?.position?.y || 0));

        this.add.existing(boss);
        this.boss = boss;

        boss.init(this.eventBusComponent);

        let bossFightData: FightBossResponse | undefined;
        if (this.step === "4") {
            bossFightData = bossFightDataStep4;
        } else if (this.step === "6") {
            bossFightData = bossFightDataStep6;
        } else if (this.step === "9") {
            bossFightData = bossFightDataStep9;
        } else {
            bossFightData = await fightMiningBoss();
        }
        this.bossFightData = bossFightData as FightBossResponse;
        if (!bossFightData) {
            this.scene.start("MiningGame");
            return;
        }

        await this.showCountDownText();

        player.init(this.eventBusComponent, bossFightData.bossHealth);
        player.startMining();

        boss.spawn(bossFightData.bossHealth);

        this.healthFighter.init(this.player.healthComponent, this.boss.healthComponent);

        this.retryConsole.init(this.eventBusComponent);
        this.eventBusComponent.once(CUSTOM_EVENTS.RETRY_BOSS_FIGHT, this.reset, this);

        this.winningConsole.init(this.eventBusComponent, bossFightData.reward);
        this.eventBusComponent.once(CUSTOM_EVENTS.CLAIM_BOSS_REWARD, this.claim, this);

        EventBus.emit(CUSTOM_EVENTS.PLAYER_VOICE, this.player.weapon, "win");

        const attacks = bossFightData.attacks.map((attack) => Number(attack));

        let currentAttack = 0;
        let bossAttackPlayer = false;
        let emitBossFightWin = false;

        // boss attack player
        this.bossPlayerCollider = this.physics.add.collider(this.player, this.boss, ((
            playerGameObject: Player,
            bossGameObject: Boss,
        ) => {
            if (!bossGameObject.active || !playerGameObject.active) {
                return;
            }

            bossAttackPlayer = true;
            bossGameObject.weaponComponent.attackPlayer();
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);

        // player attack boss
        this.playerBossOverlap = this.physics.add.overlap(this.player.weaponGameObjectGroup, this.boss, ((
            bossGameObject: Boss,
            projectileGameObject: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody,
        ) => {
            if (!bossGameObject.active) {
                if (bossGameObject.healthComponent.isDead) {
                    this.player.reset();
                    this.fightStatus.text = "You Win!";
                    this.fightStatus.setColor("#68DEBB");
                    if (!emitBossFightWin) {
                        EventBus.emit(CUSTOM_EVENTS.BOSS_FIGHT_WIN);
                        emitBossFightWin = true;
                    }
                }
                return;
            }

            if (currentAttack === attacks.length) return;

            this.player.weaponComponent.destroyBullet(projectileGameObject, {
                scale: 0.35,
                animationKey: "explosion",
            });

            if (attacks.length > 0 && attacks[currentAttack]) {
                this.boss.colliderComponent.collideWithEnemyWeapon(
                    {
                        value: attacks[currentAttack],
                        crit: false,
                        hide: false,
                    },
                    bossAttackPlayer,
                );
            }

            if (currentAttack < attacks.length) {
                this.bossConsole.playBulletAnimation(currentAttack);
                currentAttack++;
            }
            // if user run out of attacks and boss is not dead, run next turn and boss will attack player
            if (currentAttack === attacks.length && !bossFightData.won) {
                this.boss.colliderComponent.collideWithEnemyWeapon({ value: 0, crit: false, hide: true }, true);
                this.player.pauseMining();
                this.boss.bossObject.play({
                    key: this.boss.idleAnimationKey,
                });
                this.time.delayedCall(2000, () => {
                    this.boss.horizontalMovementComponent.resume();
                    this.fightStatus.text = "Boss Turn";
                });
                return;
            }
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);

        // player get hit by boss
        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_HIT, (bossObject: Boss) => {
            if (this.player.healthComponent.isDead) {
                return;
            }

            EventBus.emit(CUSTOM_EVENTS.PLAYER_VOICE, this.player.weapon, "lose");

            this.player.gameObject.play({
                key: this.player.hurtAnimationKey,
            });

            this.player.healthComponent.hit(this.player.healthComponent.startingLife);

            bossObject.weaponComponent.pauseAttack();
            this.time.delayedCall(3000, () => {
                EventBus.emit(CUSTOM_EVENTS.BOSS_FIGHT_OVER);
                this.fightStatus.text = "Game Over!";
                this.fightStatus.setColor("#d42f3a");
            });
        });

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_DEAD, () => {
            if (this.step === "9") {
                this.initTutorial("9");
                return;
            }
            this.winningConsole.visible = true;
        });

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_DEAD, () => {
            this.retryConsole.visible = true;
        });

        EventBus.on(CUSTOM_EVENTS.BOSS_FIGHT_OVER, () => {
            this.initTutorial("6");
        });

        // refresh mining boss data
        this.miningBossManager.syncWithBackend();
    }

    initTutorial(step: string) {
        this.time.delayedCall(2000, () => {
            if (this.step === step) {
                this.tutorial.init(this.step);
            }
        });
    }

    reset() {
        if (this.step === "6") {
            return;
        }
        this.scene.start("MiningGame");
    }

    async claim() {
        const bossFightData = this.bossFightData;
        if (!bossFightData) {
            return;
        }

        if (this.step === "4") {
            this.tutorial.init(this.step);
            this.time.delayedCall(2000, () => {
                this.boss.horizontalMovementComponent.resume();
                this.boss.jumpaway();
                this.time.delayedCall(3000, () => {
                    if (this.userManager.getIntroStep() !== "4") return;
                    this.userManager.syncIntroStep("5");
                    this.chapterManager.syncWithBackend({
                        chapter: 0,
                        bossLevel: 1,
                    });
                    this.scene.start("MiningGame");
                });
            });
            return;
        }

        this.winningConsole.visible = false;

        const nextChapterData = getChapterAndBossLevel(bossFightData.newLevel as number);
        const currentChapterData = this.userManager.getChapterData();

        if (nextChapterData.chapter > currentChapterData.chapter) {
            await this.userManager.syncWithBackend();
            this.chapterManager.syncWithBackend(nextChapterData);

            await this.showChapterIntro();

            this.openSellAllDialog(() => {
                this.scene.start("MiningGame");
            });
        } else {
            this.userManager.syncWithBackend();
            this.boss.horizontalMovementComponent.resume();
            this.boss.runaway();

            this.time.delayedCall(2500, () => {
                this.openSellAllDialog(() => {
                    this.scene.start("MiningGame");
                });
            });
        }
    }

    private async showChapterIntro() {
        return new Promise<void>((resolve) => {
            const popupContainer = new NextChapter(this, 195, 422);
            popupContainer.setAlpha(0);
            popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
            this.add.existing(popupContainer);

            createTween(this, popupContainer, {
                alpha: { from: 0, to: 1 },
                duration: 150,
            });

            popupContainer.once("destroy", () => {
                resolve();
            });
        });
    }

    private async openSellAllDialog(onComplete: () => void) {
        const lootBoxInfo = await getLootBoxInfo();

        if (!lootBoxInfo?.isNewLootBoxLevel) {
            onComplete();
            return;
        }
        let isSelling = false;
        const popup = new WeekerItemList(this, 0, 0);

        popup.on(LootBoxPopup.EVENTS.SELL_ALL, async () => {
            if (isSelling) return;
            isSelling = true;
            popup.updateCollectState(isSelling);

            const response = await sellLootBox();

            if (response) {
                await this.userManager.syncWithBackend();
            }

            isSelling = false;
            popup.updateCollectState(isSelling);
            popup.destroy();
            onComplete();
        });

        popup.init();
        this.add.existing(popup);
    }

    setupBackground() {
        const chapterConfig = this.chapterManager.getData();
        if (chapterConfig) {
            this.bg.setTexture(chapterConfig.background.boss);
            // TODO: FIXME
            if (chapterConfig.id === 0) {
                this.bg.y = 380;
            }
        }
        this.bg.visible = true;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
