import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/popup]");

export type PopupResponse = components["schemas"]["PopupResponseDto"];
type PopupsResponse = PopupResponse[];

export const getActivePopupsForUser = async (): Promise<PopupsResponse | undefined> => {
    try {
        const { data, error } = await telegramBackendApi.GET("/popup");

        if (error) {
            logger.error("[getActivePopupsForUser]", error);
            return [];
        }

        return data as PopupsResponse;
    } catch {
        return [];
    }
};
