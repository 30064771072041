// You can write more code here

import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { numberWithCommas, parseFormattedNumber } from "@/ota-slots/utils/formatter";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class AutoSpinWinDisplay extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 204, y ?? 41);

        this.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.alpha = 0;

        // win_popup_background_png
        const win_popup_background_png = scene.add.image(0, 32, "auto_spin", "win-popup-background.png");
        win_popup_background_png.scaleX = 0.25517821150839604;
        win_popup_background_png.scaleY = 0.25517821150839604;
        this.add(win_popup_background_png);

        // coin_bag_png
        const coin_bag_png = scene.add.image(3, 1, "auto_spin", "coin-bag.png");
        coin_bag_png.scaleX = 0.23847019196483182;
        coin_bag_png.scaleY = 0.23847019196483182;
        this.add(coin_bag_png);

        // autoSpinWinDisplayText
        const autoSpinWinDisplayText = scene.add.text(0, 54, "", {});
        autoSpinWinDisplayText.setOrigin(0.5, 0.5);
        autoSpinWinDisplayText.text = "0";
        autoSpinWinDisplayText.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "56px",
            "fontStyle": "bold",
        });
        this.add(autoSpinWinDisplayText);

        // shadowFx
        autoSpinWinDisplayText.preFX!.addShadow(0, 0, 0.1, 1, 608851, 6, 1);

        this.autoSpinWinDisplayText = autoSpinWinDisplayText;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        /* END-USER-CTR-CODE */
    }

    private autoSpinWinDisplayText: Phaser.GameObjects.Text;

    /* START-USER-CODE */

    private balanceTween: Phaser.Tweens.Tween | null = null;
    private coinSplashSprite: Phaser.GameObjects.Sprite | null = null;

    private adjustTextScale(value: number): void {
        this.autoSpinWinDisplayText.setScale(1);

        // If number exceeds 999,999,999 (9 digits)
        if (value > 999999999) {
            // Calculate scale based on number of digits
            const numDigits = Math.floor(Math.log10(value)) + 1;
            const extraDigits = numDigits - 9;
            const baseScale = 0.8; // Scale for 10 digits
            const scaleReduction = 0.05 * extraDigits; // Reduce scale by 0.05 for each extra digit
            const finalScale = Math.max(baseScale - scaleReduction, 0.6); // Don't go below 0.6

            this.autoSpinWinDisplayText.setScale(finalScale);
        }
    }

    public setAmount(delta: number) {
        if (this.balanceTween) {
            this.balanceTween.remove();
            this.balanceTween = null;
        }

        this.balanceTween = this.scene.tweens.addCounter({
            from: 0,
            to: delta,
            duration: 400,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.autoSpinWinDisplayText.setText(numberWithCommas(value));
                this.adjustTextScale(value);
            },
            onComplete: () => {
                this.balanceTween = null;
                this.playCoinSplashAnimation();
            },
        });
    }

    private playCoinSplashAnimation(): void {
        if (this.coinSplashSprite) {
            this.coinSplashSprite.destroy();
        }

        this.coinSplashSprite = this.scene.add.sprite(0, 32, "coin_splash");
        // this.coinSplashSprite.setScale(0.5);
        this.add(this.coinSplashSprite);

        this.coinSplashSprite.play("coin_splash");
        this.coinSplashSprite.once("animationcomplete", () => {
            if (this.coinSplashSprite) {
                this.coinSplashSprite.destroy();
                this.coinSplashSprite = null;
            }
        });
    }

    public fadeIn(): Promise<void> {
        this.setVisible(true);
        return new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this,
                alpha: 1,
                duration: 200,
                ease: Phaser.Math.Easing.Linear,
                onComplete: () => resolve(),
            });
        });
    }

    public fadeOut(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this,
                alpha: 0,
                duration: 200,
                ease: Phaser.Math.Easing.Linear,
                onComplete: () => {
                    this.setVisible(false);
                    resolve();
                    this.autoSpinWinDisplayText.setText("0");
                },
            });
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
