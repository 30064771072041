// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS, EventBusComponent } from "../components/events/EventBusComponent";
import * as CONFIG from "../config";
import { BossWeaponComponent } from "../components/weapon/BossWeaponComponent";
import { BossHorizontalMovementComponent } from "../components/movement/BossHorizontalMovementComponent";
import { HealthComponent } from "../components/health/HealthComponent";
import { BossColliderComponent } from "../components/collider/BossColliderComponent";
import { BossInputComponent } from "../components/input/BossInputComponent";
import { BossConfig } from "../chapters";
import { playAnimation } from "@/utils/animation-utils";
/* END-USER-IMPORTS */

export default class Boss extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // boss
        const boss = scene.add.sprite(0, 0, "_MISSING");
        boss.scaleX = 0.5;
        boss.scaleY = 0.5;
        this.add(boss);

        this.boss = boss;

        /* START-USER-CTR-CODE */
        this.isInitialized = false;
        this.boss.setTexture(this.config.texture[0], this.config.texture[1]);
        playAnimation(this.boss, this.idleAnimationKey);
        this.boss.setScale(this.config.size?.scale || 0.75);
        // this.y = (y ?? 422) + (this.config?.position?.y || 0);
        this.scene.add.existing(this);
        this.scene.physics.add.existing(this);
        if (this.body) {
            (this.body as Phaser.Physics.Arcade.Body).setSize(
                this.config.body?.size?.w || 250,
                this.config.body?.size?.h || 140,
            );
            (this.body as Phaser.Physics.Arcade.Body).setOffset(
                this.config.body?.offset?.x || -80,
                this.config.body?.offset?.y || -70,
            );
            (this.body as Phaser.Physics.Arcade.Body).immovable = false;
        }
        this.scene.events.on(Phaser.Scenes.Events.UPDATE, this.update, this);
        this.once(
            Phaser.GameObjects.Events.DESTROY,
            () => {
                this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
            },
            this,
        );
        /* END-USER-CTR-CODE */
    }

    private boss: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    public isInitialized: boolean;
    public inputComponent: BossInputComponent;
    public horizontalMovementComponent: BossHorizontalMovementComponent;
    public weaponComponent: BossWeaponComponent;
    public healthComponent: HealthComponent;
    public colliderComponent: BossColliderComponent;
    public eventBusComponent: EventBusComponent;
    public isRunaway: boolean = false;

    get gameObject() {
        return this;
    }

    get bossObject() {
        return this.boss;
    }

    get config(): BossConfig {
        return this.scene.chapterManager.getData()!.boss;
    }

    get bossAssetKey() {
        return "boss";
    }

    get idleAnimationKey() {
        return this.config.idleAnimationKey;
    }

    get walkAnimationKey() {
        return this.config.walkAnimationKey;
    }

    get jumpAnimationKey() {
        return this.config.jumpAnimationKey || this.walkAnimationKey;
    }

    get destroyedAnimationKey() {
        return this.config.destroyedAnimationKey;
    }

    get attackAnimationKey() {
        return this.config.attackAnimationKey;
    }

    get gethitAnimationKey() {
        return this.config.gethitAnimationKey;
    }

    get level() {
        return this.scene.userManager.getChapterData().bossLevel;
    }

    init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;
        this.inputComponent = new BossInputComponent();
        this.horizontalMovementComponent = new BossHorizontalMovementComponent(this, this.inputComponent, {
            velocity: 8,
            maxVelocity: 150,
            maxPosition: this.scene.cameras.main.width / 2,
        });
        this.weaponComponent = new BossWeaponComponent(
            this,
            {
                damage: CONFIG.CREEP_WEAPON_DAMAGE,
            },
            this.eventBusComponent,
        );

        this.eventBusComponent.emit(CUSTOM_EVENTS.CREEP_INIT, this);
        this.isInitialized = true;

        // eventBusComponent.emit(CUSTOM_EVENTS.ENEMY_SPAWNED, this);
    }

    static() {
        this.boss.stop();
        this.boss.setTexture(this.config.texture[0], this.config.texture[1]);
    }

    spawn(health?: number) {
        this.setActive(true);
        this.setVisible(true);
        this.healthComponent = new HealthComponent(health || 0);
        this.colliderComponent = new BossColliderComponent(this, this.healthComponent, this.eventBusComponent);
        this.eventBusComponent.emit(CUSTOM_EVENTS.ENEMY_SPAWNED, this);
    }

    reset() {
        this.setActive(true);
        this.setVisible(true);
        this.boss.play(this.walkAnimationKey);
        this.horizontalMovementComponent.reset();
    }

    runaway() {
        this.boss.setTexture(this.config.texture[0], this.config.texture[1]);
        this.boss.y -= this.config.position?.dead?.y || 0;

        // Flip the sprite horizontally
        this.boss.setFlipX(true);

        // Start boss running animation
        playAnimation(this.boss, this.walkAnimationKey);

        this.isRunaway = true;
        // Move boss to the left by making velocity negative
        this.inputComponent.leftIsDown = false;
        this.inputComponent.rightIsDown = true;
    }

    jumpaway() {
        this.boss.setTexture(this.config.texture[0], this.config.texture[1]);

        // // Flip the sprite horizontally
        this.boss.setFlipX(true);

        // Start boss running animation
        this.boss.play(this.jumpAnimationKey);

        this.isRunaway = true;
        // // Move boss to the left by making velocity negative
        this.inputComponent.leftIsDown = false;
        this.inputComponent.rightIsDown = true;

        this.scene.tweens.add({
            targets: this.boss,
            y: "-=50", // Move down to final position
            x: "+=100",
            duration: 300,
            delay: 200,
        });
    }

    update(ts: DOMHighResTimeStamp, dt: number) {
        if (!this.isInitialized) {
            return;
        }

        if (this.isRunaway) {
            this.horizontalMovementComponent.update();
            return;
        }

        if (!this.active) {
            return;
        }

        if (this.healthComponent && this.healthComponent.isDead) {
            this.setActive(false);

            if (this.boss.anims.currentAnim?.key !== this.destroyedAnimationKey) {
                this.boss.stop();
                this.boss.y += this.config.position?.dead?.y || 0;
                playAnimation(this.boss, this.destroyedAnimationKey);
                this.eventBusComponent.emit(CUSTOM_EVENTS.BOSS_DEAD, this);
            }
            return;
        }

        this.inputComponent.update();
        this.horizontalMovementComponent.update();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
