import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import NewItemDisplay from "./NewItemDisplay";
import CurrentItemDisplay from "./CurrentItemDisplay";
import { type InventoryItem } from "@/shared/types/loot-box";
import { compareItemAttackPower } from "@/shared/utils/loot-box";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[shared/prefabs/loot-box/LootBoxPopup]");

const mock = {
    itemComparisonResult: "neutral",
    currentSlotItem: {
        sellingPrice: 58600,
        b: 10,
        s: 8800,
        d: 1,
        level: 1,
        name: "weapon",
        maxAtk: 200,
        slot: 1,
        minAtk: 100,
        rarity: "sr",
    },
    rewardItem: {
        sellingPrice: 8000,
        b: 10,
        s: 1200,
        d: 1,
        level: 3,
        name: "gem",
        maxAtk: 300,
        slot: 1,
        minAtk: 200,
        rarity: "r",
    },
};

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */

/* END-USER-IMPORTS */

export default class LootBoxPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        /* START-USER-CTR-CODE */
        // Write your code here.
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */

    public static EVENTS = {
        COLLECT: "collect",
        SELL: "sell",
        EQUIP: "equip",
        SELL_ALL: "sell_all",
    };

    open(rewardItem: InventoryItem, action?: "equip" | "sell") {
        const currentSlotItem = this.scene.userManager.getInventoryItemBySlot(rewardItem.slot);
        logger.debug("Opening loot box", {
            rewardItemFromLootBox: rewardItem,
            currentSlotItem,
        });

        // If there's no inventory item for this reward item slot, then this is a new item for this slot
        if (!currentSlotItem) {
            const ui = new NewItemDisplay(this.scene, 0, 0);
            ui.setItem(rewardItem, "new");
            this.add(ui);
            ui.once(LootBoxPopup.EVENTS.COLLECT, () => {
                this.emit(LootBoxPopup.EVENTS.COLLECT);
            });
            return;
        }

        // Determine whether the new item is better than the current one
        const itemComparisonResult = compareItemAttackPower(currentSlotItem, rewardItem);

        if (itemComparisonResult === "upgrade") {
            const ui = new CurrentItemDisplay(this.scene, 0, 0);
            ui.setComparisonItems(currentSlotItem, rewardItem);
            this.add(ui);

            ui.once(LootBoxPopup.EVENTS.SELL, () => {
                this.emit(LootBoxPopup.EVENTS.SELL);
            });

            ui.once(LootBoxPopup.EVENTS.EQUIP, () => {
                this.emit(LootBoxPopup.EVENTS.EQUIP);
            });
        } else if (itemComparisonResult === "downgrade" || itemComparisonResult === "neutral") {
            const ui = new NewItemDisplay(this.scene, 0, 0);
            ui.setItem(rewardItem, itemComparisonResult);
            this.add(ui);

            ui.once(LootBoxPopup.EVENTS.SELL, () => {
                this.emit(LootBoxPopup.EVENTS.SELL);
            });
        }
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
