export const chapter4 = {
    id: 4,
    boss: {
        name: "Echorazor",
        idleAnimationKey: "boss_04_walk",
        walkAnimationKey: "boss_04_walk",
        jumpAnimationKey: "boss_04_walk",
        attackAnimationKey: "boss_04_attack",
        gethitAnimationKey: "boss_04_gethit",
        destroyedAnimationKey: "boss_04_dead",
        size: {
            scale: 0.4,
        },
        position: {
            y: -80,
            dead: {
                y: -100,
            },
        },
        body: {
            size: {
                w: 250,
                h: 250,
            },
            offset: {
                x: -100,
                h: -70,
            },
        },
        texture: ["boss_04_walk", "alien_10-fly0.png"],
    },
    creep: {
        walkAnimationKey: "creep_04_walk",
        attackAnimationKey: "creep_04_attack",
        destroyedAnimationKey: "creep_04_die",
        size: {
            scale: 0.7,
        },
        position: {
            y: -25,
        },
        body: {
            offset: {
                x: -80,
                h: -70,
            },
        },
        texture: ["creep_04_walk", "Property 1=f_01.png"],
    },
    background: {
        mining: "chapter_04_bg",
        boss: "chapter_04_bg",
        intro: "chapter_04_intro",
    },
};
