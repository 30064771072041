// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import SkipButton from "./SkipButton";
import Text from "../Text";
/* START-USER-IMPORTS */
import Circle from "./Circle";
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import { miningBossData, openBoxData, userDataWithRifle } from "@/shared/plugins/tutorial/mock-data";
import LootBoxPopup from "../loot-box/LootBoxPopup";
import { InventoryItem } from "@/shared/types/loot-box";
/* END-USER-IMPORTS */

export default class Step9 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 197, y ?? 422);

        // bg
        const bg = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        bg.alpha = 0.8;
        bg.alphaTopLeft = 0.8;
        bg.alphaTopRight = 0.8;
        bg.alphaBottomLeft = 0.8;
        bg.alphaBottomRight = 0.8;
        this.add(bg);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -377);
        this.add(skipButton_1);

        // playerHead
        const playerHead = scene.add.image(-155, -171, "tutorial", "Head.png");
        playerHead.alpha = 0;
        playerHead.alphaTopLeft = 0;
        playerHead.alphaTopRight = 0;
        playerHead.alphaBottomLeft = 0;
        playerHead.alphaBottomRight = 0;
        this.add(playerHead);

        // message1
        const message1 = scene.add.container(-121, -174);
        this.add(message1);

        // boxMessage1
        const boxMessage1 = scene.add.image(147, 45, "tutorial", "box_message_2.png");
        boxMessage1.scaleY = 0.9;
        boxMessage1.alpha = 0;
        boxMessage1.alphaTopLeft = 0;
        boxMessage1.alphaTopRight = 0;
        boxMessage1.alphaBottomLeft = 0;
        boxMessage1.alphaBottomRight = 0;
        message1.add(boxMessage1);

        // content1
        const content1 = new Text(scene, 134, 60);
        content1.alpha = 0;
        content1.text = "You've earned 1 Cosmic Vault.";
        content1.setStyle({ "color": "#D8E63C", "fontSize": "20px" });
        content1.setWordWrapWidth(250);
        message1.add(content1);

        // content
        const content = new Text(scene, 61, 13);
        content.alpha = 0;
        content.text = "Bravo!!!";
        content.setStyle({ "color": "#D8E63C", "fontSize": "24px" });
        content.setWordWrapWidth(255);
        message1.add(content);

        // message2
        const message2 = scene.add.container(-119, -42);
        this.add(message2);

        // boxMessage2
        const boxMessage2 = scene.add.image(146, 59, "tutorial", "box_message_2.png");
        boxMessage2.alpha = 0;
        boxMessage2.alphaTopLeft = 0;
        boxMessage2.alphaTopRight = 0;
        boxMessage2.alphaBottomLeft = 0;
        boxMessage2.alphaBottomRight = 0;
        message2.add(boxMessage2);

        // content2
        const content2 = new Text(scene, 130, 21);
        content2.alpha = 0;
        content2.text = "Keep spinning to unlock more";
        content2.setStyle({ "fontSize": "16px" });
        content2.setWordWrapWidth(255);
        message2.add(content2);

        // content3
        const content3 = new Text(scene, 73, 52);
        content3.alpha = 0;
        content3.text = "Cosmic Vaults";
        content3.setStyle({ "color": "#D8E63C", "fontSize": "16px" });
        content3.setWordWrapWidth(255);
        message2.add(content3);

        // content4
        const content4 = new Text(scene, 199, 52);
        content4.alpha = 0;
        content4.text = "and upgrade hero!";
        content4.setStyle({ "fontSize": "16px" });
        content4.setWordWrapWidth(255);
        message2.add(content4);

        // content5
        const content5 = new Text(scene, 122, 88);
        content5.alpha = 0;
        content5.text = "Let's unveil your treasures!";
        content5.setStyle({ "fontSize": "16px" });
        content5.setWordWrapWidth(255);
        message2.add(content5);

        // arrow
        const arrow = scene.add.container(-145, 189);
        arrow.alpha = 0;
        this.add(arrow);

        // tap_1
        const tap_1 = new Text(scene, 79, -2);
        tap_1.text = "Tap to Open";
        tap_1.setStyle({ "color": "#D8E63C", "fontSize": "26px", "fontStyle": "bold" });
        arrow.add(tap_1);

        // tap
        const tap = scene.add.image(176, -1, "tutorial", "arrow.png");
        tap.angle = -90;
        arrow.add(tap);

        this.bg = bg;
        this.playerHead = playerHead;
        this.boxMessage1 = boxMessage1;
        this.content1 = content1;
        this.content = content;
        this.message1 = message1;
        this.boxMessage2 = boxMessage2;
        this.content2 = content2;
        this.content3 = content3;
        this.content4 = content4;
        this.content5 = content5;
        this.message2 = message2;
        this.tap = tap;
        this.arrow = arrow;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Image;
    private playerHead: Phaser.GameObjects.Image;
    private boxMessage1: Phaser.GameObjects.Image;
    private content1: Text;
    private content: Text;
    private message1: Phaser.GameObjects.Container;
    private boxMessage2: Phaser.GameObjects.Image;
    private content2: Text;
    private content3: Text;
    private content4: Text;
    private content5: Text;
    private message2: Phaser.GameObjects.Container;
    private tap: Phaser.GameObjects.Image;
    private arrow: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.playerHead,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.boxMessage1,
                scaleY: { from: 0, to: 1 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                ease: "Back.easeOut",
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: [this.content, this.content1],
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.boxMessage2,
                alpha: { from: 0, to: 1 },
                duration: 400,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: [this.content2, this.content3, this.content4, this.content5],
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.arrow,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        const circle = new Circle(this, this.scene, 325, 610, 128);
        this.add(circle);
        circle.show();
        circle.onClick(this.handleOpenBox);

        this.scene.tweens.add({
            targets: this.tap,
            x: {
                value: "+=10",
            },
            duration: 500,
            yoyo: true,
            repeat: -1,
        });
    }

    handleOpenBox() {
        this.scene.tutorial.doMockOpenBox();
        this.scene.tutorial.destroy();
        this.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
