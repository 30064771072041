import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/loot-box]");

type LootBoxLevelInfoResponse = components["schemas"]["LootBoxLevelInfoResponseDto"];

export const getLootBoxInfo = async (): Promise<LootBoxLevelInfoResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/loot-box");

    if (error) {
        logger.error("[getLootBoxInfo]", error);
        return;
    }

    return data as LootBoxLevelInfoResponse;
};

export type LootBoxHistoryResponse = components["schemas"]["PageResponseDto"] & {
    data: components["schemas"]["LootBoxPageMetaDto"][];
    transactionId: string;
    amount: number;
};
interface LootBoxHistoryParams {
    pageSize?: number;
    lastKey?: string;
    transactionId?: string;
}

export const getLootBoxHistory = async (
    params: LootBoxHistoryParams = {},
): Promise<LootBoxHistoryResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/loot-box/history-before-selling", {
        params: {
            query: params,
        },
    });

    if (error) {
        logger.error("[getLootBoxHistory]", error);
        return;
    }

    return data as LootBoxHistoryResponse;
};

type MiningRewardResponse = components["schemas"]["MiningRewardDto"];

export const sellLootBox = async (): Promise<MiningRewardResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/loot-box/selling");

    if (error) {
        logger.error("[sellLootBox]", error);
        return;
    }

    return data as MiningRewardResponse;
};

type LootBoxOpenResponse = components["schemas"]["LootBoxOpenResponseDto"];
export type LootBoxReward = LootBoxOpenResponse["lootBox"];

export const openLootBox = async (): Promise<LootBoxOpenResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/loot-box/open-box");

    if (error) {
        logger.error("[openLootBox]", error);
        return;
    }

    return data as LootBoxOpenResponse;
};

type LootBoxActionRequest = components["schemas"]["LootBoxActionRequestDto"];
export type LootBoxActionResponse = components["schemas"]["MiningRewardDto"];

export const performLootBoxAction = async (
    actionData: LootBoxActionRequest,
): Promise<LootBoxActionResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/loot-box/action", {
        body: actionData,
    });

    if (error) {
        logger.error("[performLootBoxAction]", error);
        return;
    }

    return data as LootBoxActionResponse;
};
