import { openTelegramLink } from "@/lib/telegram/web-app";
import { BASE_URL } from "../constants";

export function generateReferralLink(userRefCode: string): string {
    const json = JSON.stringify({ refCode: userRefCode });

    const base64 = btoa(json);
    const base64url = base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

    const referralLink = `${BASE_URL}?startapp=${base64url}`;
    return referralLink;
}

// startParam is a base64 encoded JSON string
export function decodeReferralCode(startParam?: string): string | undefined {
    if (!startParam) return;
    try {
        const referralData = atob(startParam);
        return JSON.parse(referralData).refCode;
    } catch {}
}

export function shareReferralLink(referralLink: string) {
    const message = "Play, Earn, and Share $REMIS—make crypto pump again";
    const imageUrl = encodeURIComponent(`${BASE_URL}/shared/assets/share_referral.png`);
    const encodedUrl = encodeURIComponent(referralLink);
    const encodedMessage = encodeURIComponent(message);

    const shareUrl = `https://t.me/share/url?url=${encodedUrl}&text=${encodedMessage}&image_url=${imageUrl}`;

    openTelegramLink(shareUrl);
}
