import { DataManager } from "./data-manager";
import { ChapterConfig, chapters } from "@/ota-mining/game/chapters";

export const TOTAL_BOSS_LEVEL = 10;
export const MAX_CHAPTER = 10;

export function getChapterKey(chapter: string | number): string {
    return String(chapter).padStart(2, "0");
}

export function getChapterAndBossLevel(miningLevel: number = 0) {
    // Each chapter has 10 levels of bosses
    let chapter: number = Math.floor(miningLevel / TOTAL_BOSS_LEVEL) + 1; // Calculate chapter (1-indexed)
    let bossLevel: number = (miningLevel % TOTAL_BOSS_LEVEL) + 1; // Calculate boss level (1-indexed)
    let isExceedChapter = false;

    // Ensure chapter does not exceed max chapter
    if (chapter > MAX_CHAPTER) {
        chapter = MAX_CHAPTER;
        isExceedChapter = true;
    }

    return { chapter, bossLevel, isExceedChapter };
}

export class ChapterManager extends DataManager<ChapterConfig> {
    public key = "chapter";

    async syncWithBackend(chapterData: { chapter: number; bossLevel: number }) {
        const chapterConfig = chapters[chapterData.chapter];
        const data = chapterConfig;
        this.setData(data);
        return data;
    }
}
