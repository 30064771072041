// You can write more code here

/* START OF COMPILED CODE */

import Text from "../Text";
/* START-USER-IMPORTS */
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { getLootBoxHistory, LootBoxHistoryResponse } from "@/lib/api/telegram-backend/methods/loot-box";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import Gem from "./Gem";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import LootBoxPopup from "./LootBoxPopup";
import WeekerGem from "./WeekerGem";
/* END-USER-IMPORTS */

export default class WeekerItemList extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.rectangle(195, 422, 390, 844);
        bg.isFilled = true;
        bg.fillColor = 0;
        bg.fillAlpha = 0.8;
        this.add(bg);

        // collect
        const collect = scene.add.sprite(195, 750, "collect_button");
        this.add(collect);

        // text
        const text = new Text(scene, 195, 145);
        text.text = "WEAKER LOOTBOXES";
        text.setStyle({ "color": "#AC1A5B", "fontStyle": "bold" });
        this.add(text);

        // itemNumber
        const itemNumber = new Text(scene, 193, 175);
        itemNumber.text = "0 ITEMS";
        itemNumber.setStyle({ "fontStyle": "bold" });
        this.add(itemNumber);

        // prevButton
        const prevButton = scene.add.image(25, 350, "arrow");
        prevButton.scaleX = 0.35;
        prevButton.scaleY = 0.35;
        prevButton.alpha = 0;
        prevButton.alphaTopLeft = 0;
        prevButton.alphaTopRight = 0;
        prevButton.alphaBottomLeft = 0;
        prevButton.alphaBottomRight = 0;
        this.add(prevButton);

        // nextButton
        const nextButton = scene.add.image(365, 350, "arrow");
        nextButton.scaleX = 0.35;
        nextButton.scaleY = 0.35;
        nextButton.angle = -180;
        nextButton.alpha = 0;
        nextButton.alphaTopLeft = 0;
        nextButton.alphaTopRight = 0;
        nextButton.alphaBottomLeft = 0;
        nextButton.alphaBottomRight = 0;
        this.add(nextButton);

        // text_1
        const text_1 = new Text(scene, 198, 651);
        text_1.text =
            "THESE ITEMS ARE TOO WEAK TO EQUIP AND DEFEAT THE CURRENT LEVEL BOSS, SO THEY HAVE BEEN AUTOMATICALLY SOLD FOR GOLD.";
        text_1.setStyle({ "align": "center", "fontStyle": "bold" });
        text_1.setLineSpacing(5.5);
        text_1.setWordWrapWidth(340);
        this.add(text_1);

        // loading
        const loading = scene.add.sprite(197, 332, "loading", "loading_anim_00000.png");
        loading.scaleX = 0.35;
        loading.scaleY = 0.35;
        loading.alpha = 0;
        loading.alphaTopLeft = 0;
        loading.alphaTopRight = 0;
        loading.alphaBottomLeft = 0;
        loading.alphaBottomRight = 0;
        this.add(loading);

        // text_2
        const text_2 = new Text(scene, 125, 563);
        text_2.text = "TOTAL";
        text_2.setStyle({
            "color": "#8EE3FC",
            "fontSize": "32px",
            "fontStyle": "bold",
            "shadow.offsetX": 0,
            "shadow.offsetY": 4,
            "shadow.color": "#2B2B2B",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(text_2);

        // amount
        const amount = new Text(scene, 185, 546);
        amount.setOrigin(0, 0);
        amount.text = "0";
        amount.setStyle({
            "color": "#F0EEE9",
            "fontSize": "32px",
            "fontStyle": "bold",
            "shadow.offsetX": 0,
            "shadow.offsetY": 4,
            "shadow.color": "#2B2B2B",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(amount);

        this.bg = bg;
        this.collect = collect;
        this.itemNumber = itemNumber;
        this.prevButton = prevButton;
        this.nextButton = nextButton;
        this.loading = loading;
        this.amount = amount;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Rectangle;
    private collect: Phaser.GameObjects.Sprite;
    private itemNumber: Text;
    private prevButton: Phaser.GameObjects.Image;
    private nextButton: Phaser.GameObjects.Image;
    private loading: Phaser.GameObjects.Sprite;
    private amount: Text;

    /* START-USER-CODE */
    public lootBoxHistory?: LootBoxHistoryResponse;
    public lootboxContainer?: Phaser.GameObjects.Container;
    public page: number = 0;
    public paginationHistory: Record<string, { lastKey: string; transactionId: string }> = {};

    async init() {
        this.bg.setInteractive();
        await this.fetchHistory();

        new PushComponent(this.nextButton, this.nextPage, this).activate();
        new PushComponent(this.prevButton, this.prevPage, this).activate();

        new PushComponent(this.collect, this.sellAll, this).activate();
    }

    async fetchHistory() {
        this.hideLootboxes();
        this.showLoading();

        const lootBoxHistory = await getLootBoxHistory({
            pageSize: 12,
        });
        this.lootBoxHistory = lootBoxHistory;

        if (!lootBoxHistory) return;

        if (lootBoxHistory.meta.lastKey) {
            this.paginationHistory[this.page] = {
                lastKey: lootBoxHistory.meta.lastKey,
                transactionId: lootBoxHistory.transactionId,
            };
        }

        this.updateUI();
        this.hideLoading();
        this.showLootboxes();
    }

    private nextPageDisabled() {
        return !this.lootBoxHistory?.meta.lastKey || this.page >= (this.lootBoxHistory?.meta?.pageCount || 0) - 1;
    }

    async nextPage() {
        if (this.nextPageDisabled()) return;

        this.hideLootboxes();
        this.showLoading();

        const lootBoxHistory = await getLootBoxHistory({
            pageSize: 12,
            lastKey: this.lootBoxHistory!.meta.lastKey,
            transactionId: this.lootBoxHistory!.transactionId,
        });

        this.lootBoxHistory = lootBoxHistory;
        this.page++;

        if (!lootBoxHistory) return;
        if (lootBoxHistory.meta.lastKey) {
            this.paginationHistory[this.page] = {
                lastKey: lootBoxHistory.meta.lastKey,
                transactionId: lootBoxHistory.transactionId,
            };
        }

        this.updateUI();
        this.hideLoading();
        this.showLootboxes();
    }

    async prevPage() {
        if (this.page <= 0) return;

        this.hideLootboxes();
        this.showLoading();

        this.page--;

        const params =
            this.page === 0
                ? undefined
                : {
                      pageSize: 12,
                      lastKey: this.paginationHistory[this.page - 1].lastKey,
                      transactionId: this.paginationHistory[this.page - 1].transactionId,
                  };

        const lootBoxHistory = await getLootBoxHistory(params);
        this.lootBoxHistory = lootBoxHistory;

        this.updateUI();
        this.hideLoading();
        this.showLootboxes();
    }

    public updateCollectState(disable?: boolean) {
        this.collect.setAlpha(disable ? 0.5 : 1);
    }

    private sellAll() {
        this.emit(LootBoxPopup.EVENTS.SELL_ALL);
    }

    private updateUI() {
        if ((this.lootBoxHistory?.meta.pageCount || 0) <= 1) {
            this.prevButton.setAlpha(0);
            this.nextButton.setAlpha(0);
        } else {
            this.prevButton.setAlpha(this.page > 0 ? 1 : 0.2);
            this.nextButton.setAlpha(!this.nextPageDisabled() ? 1 : 0.2);
        }

        this.amount.setText(numberWithCommas(this.lootBoxHistory?.amount || 0));
        this.itemNumber.setText(`${this.lootBoxHistory?.meta.total || 0} ITEMS`);
    }

    private showLoading() {
        this.loading.play("loading");
        this.loading.alpha = 1;
    }

    private hideLoading() {
        this.loading.stop();
        this.loading.setFrame(0);
        this.loading.alpha = 0;
    }

    private showLootboxes() {
        const lootboxContainer = this.scene.add.container(80, 250);

        (this.lootBoxHistory?.data || []).forEach((historyItem, index) => {
            const row = Math.floor(index / 4);
            const col = index % 4;

            const gem = new WeekerGem(this.scene, col * 75, row * 95);
            gem.scale = 0.7;
            gem.init(historyItem.item);
            lootboxContainer.add(gem);
        });

        this.add(lootboxContainer);
        this.lootboxContainer = lootboxContainer;
    }

    private hideLootboxes() {
        this.lootboxContainer?.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
