// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import BossLevelItem from "./BossLevelItem";
import { TOTAL_BOSS_LEVEL } from "@/shared/registry/chapter";
/* END-USER-IMPORTS */

export default class BossLevel extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 70);

        // level_progress_png
        const level_progress_png = scene.add.image(0, 0, "boss_level", "level_progress.png");
        this.add(level_progress_png);

        // levelContainer
        const levelContainer = scene.add.container(-181, 22);
        this.add(levelContainer);

        // level
        const level = new Text(scene, -155, 46);
        level.text = "Level 1/10";
        level.setStyle({ color: "#219B76", fontSize: "12px" });
        this.add(level);

        this.levelContainer = levelContainer;
        this.level = level;

        /* START-USER-CTR-CODE */
        this.setupLevel();
        /* END-USER-CTR-CODE */
    }

    private levelContainer: Phaser.GameObjects.Container;
    private level: Text;

    /* START-USER-CODE */

    setupLevel() {
        const listItems = [];
        for (let i = 0; i < TOTAL_BOSS_LEVEL; i++) {
            const item = new BossLevelItem(this.scene, i * 30 + 10, 0);
            item.bossLevel = i + 1;
            item.updateStatus();
            listItems.push(item);
        }

        this.levelContainer.add(listItems);
        this.level.text = `Level ${this.scene.userManager.getChapterData().bossLevel}/${TOTAL_BOSS_LEVEL}`;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
