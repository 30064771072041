// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class WinUSDPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.rectangle(195, 422, 390, 844);
        bg.isFilled = true;
        bg.fillColor = 0;
        this.add(bg);

        // usdFly
        const usdFly = scene.add.sprite(195, 165, "usd_fly", "Property 1=money_bay_f_01.png");
        usdFly.visible = false;
        this.add(usdFly);

        // wheelGold
        const wheelGold = scene.add.sprite(195, 280, "wheel_animation", "specialWheel_zoom_f_01.png");
        this.add(wheelGold);

        // title
        const title = new Text(scene, 205, 85);
        title.alpha = 0;
        title.text = "You won a $2 Token Package";
        title.setStyle({ "align": "center", "fontSize": "40px", "fontStyle": "bold" });
        title.setWordWrapWidth(290);
        this.add(title);

        // rmxPop
        const rmxPop = scene.add.sprite(195, 285, "wheel_elements_pop", "Property 1=remis_pop_f_01.png");
        rmxPop.visible = false;
        this.add(rmxPop);

        // goldPop
        const goldPop = scene.add.sprite(195, 560, "wheel_elements_pop", "Property 1=xu_pop_f_00.png");
        goldPop.visible = false;
        this.add(goldPop);

        // rmxAmountText
        const rmxAmountText = new Text(scene, 195, 380);
        rmxAmountText.scaleX = 0;
        rmxAmountText.scaleY = 0;
        rmxAmountText.alpha = 0;
        rmxAmountText.text = "0";
        rmxAmountText.setStyle({
            "fontSize": "40px",
            "fontStyle": "bold",
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#765C18",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(rmxAmountText);

        // goldAmountText
        const goldAmountText = new Text(scene, 195, 655);
        goldAmountText.scaleX = 0;
        goldAmountText.scaleY = 0;
        goldAmountText.alpha = 0;
        goldAmountText.text = "0";
        goldAmountText.setStyle({
            "fontSize": "40px",
            "fontStyle": "bold",
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#765C18",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(goldAmountText);

        // autoCloseText
        const autoCloseText = new Text(scene, 195, 762);
        autoCloseText.text = "close (5s)";
        this.add(autoCloseText);

        this.usdFly = usdFly;
        this.wheelGold = wheelGold;
        this.title = title;
        this.rmxPop = rmxPop;
        this.goldPop = goldPop;
        this.rmxAmountText = rmxAmountText;
        this.goldAmountText = goldAmountText;
        this.autoCloseText = autoCloseText;

        /* START-USER-CTR-CODE */
        bg.setInteractive(new Phaser.Geom.Rectangle(0, 0, 390, 844), Phaser.Geom.Rectangle.Contains);
        /* END-USER-CTR-CODE */
    }

    private usdFly: Phaser.GameObjects.Sprite;
    private wheelGold: Phaser.GameObjects.Sprite;
    private title: Text;
    private rmxPop: Phaser.GameObjects.Sprite;
    private goldPop: Phaser.GameObjects.Sprite;
    private rmxAmountText: Text;
    private goldAmountText: Text;
    private autoCloseText: Text;

    /* START-USER-CODE */
    public rmxAmount: number = 0;
    public goldAmount: number = 0;
    private autoCloseTimer: Phaser.Time.TimerEvent;

    playAnimation() {
        this.wheelGold.play("wheel_gold");
        this.wheelGold.once("animationcomplete", () => {
            this.startAutoCloseTimer();
            this.scene.tweens.add({
                targets: this.title,
                duration: 400,
                alpha: { from: 0, to: 1 },
            });

            this.rmxPop.visible = true;
            this.rmxPop.play("rmx_pop");
            this.rmxPop.once("animationcomplete", () => {
                this.rmxAmountText.text = `${numberWithCommas(this.rmxAmount)}`;
                this.scene.add.tween({
                    targets: this.rmxAmountText,
                    alpha: 1,
                    scale: { from: 0, to: 1 },
                    duration: 300,
                    ease: "Power2",
                });
            });

            this.goldPop.visible = true;
            this.goldPop.play("ota_gold_pop");
            this.goldPop.once("animationcomplete", () => {
                this.goldAmountText.text = `${numberWithCommas(this.goldAmount)}`;
                this.scene.add.tween({
                    targets: this.goldAmountText,
                    alpha: 1,
                    scale: { from: 0, to: 1 },
                    duration: 300,
                    ease: "Power2",
                });
            });

            this.usdFly.visible = true;
            this.usdFly.play("usd_fly");
        });
    }

    private startAutoCloseTimer() {
        new PushComponent(this.autoCloseText, () => this.destroy(), this).activate();

        let secondsLeft = 5;
        this.autoCloseText.setText(`close (${secondsLeft}s)`);
        this.autoCloseTimer = this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
                secondsLeft--;
                if (secondsLeft > 0) {
                    this.autoCloseText.setText(`close (${secondsLeft}s)`);
                } else {
                    this.destroy();
                }
            },
            repeat: secondsLeft - 1,
        });
    }

    override destroy(fromScene?: boolean) {
        if (this.autoCloseTimer) {
            this.autoCloseTimer.destroy();
        }
        super.destroy(fromScene);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
