// You can write more code here

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
import { setupBackground } from "../helpers/setup-background";
import { SCENE_KEYS } from "../constants";
import PreloadProgressBar from "@/shared/prefabs/PreloadProgressBar";
import { EventBus, EVENTS } from "@/lib/event-bus";

import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[ota-slots/scenes/Preloader]");
/* END-USER-IMPORTS */

export default class Preloader extends Phaser.Scene {
    constructor() {
        super("SlotsPreloader");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // preload_bg
        this.add.image(195, 422, "slots_preload_bg");

        this.events.emit("scene-awake");
    }

    /* START-USER-CODE */
    init() {
        this.editorCreate();

        const progressBar = new PreloadProgressBar(this, 0, 0);

        this.load.on(Phaser.Loader.Events.PROGRESS, (value: number) => {
            progressBar.updateProgress(value);
        });

        this.events.emit("scene-awake");
    }

    private readonly sharedAssets = ["common", "loot-box", "telegram-star", "missions"];

    preload() {
        // Load shared assets if they don't exist
        this.sharedAssets.forEach((asset) => {
            const textureKey = `${asset}-asset-pack`;
            if (!this.textures.exists(textureKey)) {
                this.load.pack(textureKey, `shared/${textureKey}.json`);
            }
        });

        // Load slots-specific assets
        this.load.pack("ota-slots-asset-pack", "ota-slots/ota-slots-asset-pack.json");

        this.load.on(Phaser.Loader.Events.COMPLETE, () => {
            logger.info("Load completed");
            EventBus.emit(EVENTS.CURRENT_SCENE_READY, this);
        });
    }

    create() {
        //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
        //  For example, you can define global animations here, so we can use them in other scenes.
        //  Move to the Game. You could also swap this for a Scene Transition, such as a camera fade.
        this.scene.start(SCENE_KEYS.GAME);
    }

    shutdown() {}

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
