export const chapter10 = {
    id: 10,
    boss: {
        name: "Thunderbolt",
        idleAnimationKey: "boss_10_walk",
        walkAnimationKey: "boss_10_walk",
        jumpAnimationKey: "boss_10_walk",
        attackAnimationKey: "boss_10_attack",
        gethitAnimationKey: "boss_10_gethit",
        destroyedAnimationKey: "boss_10_dead",
        size: {
            scale: 0.35,
        },
        position: {
            y: 0,
            dead: {
                y: -125,
            },
        },
        texture: ["boss_10_attack", "robot12-fly0.png"],
    },
    creep: {
        walkAnimationKey: "creep_10_walk",
        attackAnimationKey: "creep_10_attack",
        destroyedAnimationKey: "creep_10_die",
        size: {
            scale: 1,
        },
        position: {
            y: -10,
        },
        texture: ["creep_10_walk", "tile000.png"],
    },
    background: {
        mining: "chapter_10_bg",
        boss: "chapter_10_bg",
        intro: "chapter_10_intro",
    },
};
