// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { formatCompactNumber } from "../../../shared/utils/number-formatter";
/* END-USER-IMPORTS */

export default class Slot extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 88);

        // slot
        const slot = scene.add.image(0, 12, "free_spin_atlas", "spin_1.png");
        this.add(slot);

        // dots
        const dots = scene.add.container(-28, 47);
        this.add(dots);

        // dot1
        const dot1 = scene.add.rectangle(12, 9, 10, 10);
        dot1.isFilled = true;
        dot1.fillColor = 1846590;
        dot1.isStroked = true;
        dot1.strokeColor = 0;
        dot1.strokeAlpha = 2;
        dot1.lineWidth = 2;
        dots.add(dot1);

        // dot2
        const dot2 = scene.add.rectangle(28, 9, 10, 10);
        dot2.isFilled = true;
        dot2.fillColor = 1846590;
        dot2.isStroked = true;
        dot2.strokeColor = 0;
        dot2.strokeAlpha = 2;
        dot2.lineWidth = 2;
        dots.add(dot2);

        // dot3
        const dot3 = scene.add.rectangle(43, 9, 10, 10);
        dot3.isFilled = true;
        dot3.fillColor = 1846590;
        dot3.isStroked = true;
        dot3.strokeColor = 0;
        dot3.strokeAlpha = 2;
        dot3.lineWidth = 2;
        dots.add(dot3);

        // amount
        const amount = new Text(scene, 0, 56);
        amount.visible = false;
        amount.text = "0";
        amount.setStyle({
            fontSize: "14px",
            "shadow.offsetX": 1,
            "shadow.offsetY": 2,
            "shadow.color": "#000000",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        this.add(amount);

        this.slot = slot;
        this.dot1 = dot1;
        this.dot2 = dot2;
        this.dot3 = dot3;
        this.dots = dots;
        this.amount = amount;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private slot: Phaser.GameObjects.Image;
    private dot1: Phaser.GameObjects.Rectangle;
    private dot2: Phaser.GameObjects.Rectangle;
    private dot3: Phaser.GameObjects.Rectangle;
    private dots: Phaser.GameObjects.Container;
    private amount: Text;

    /* START-USER-CODE */
    public index: number;

    init(index: number) {
        this.index = index;
        this.slot.setTexture(`free_spin_atlas`, `spin_${index}.png`);
    }

    activeSlot() {
        this.slot.setTexture(`free_spin_atlas`, `spin_${this.index}_active.png`);
        this.dot1.fillColor = 2202486;
        this.dot2.fillColor = 2202486;
        this.dot3.fillColor = 2202486;
    }

    showAmount(amount: number) {
        this.dots.visible = false;
        this.amount.visible = true;
        this.scene.tweens.addCounter({
            from: 0,
            to: amount,
            duration: 400,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.amount.text = formatCompactNumber(value);
            },
        });
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
