import {
    AnimationConfig,
    DeviceInfo,
    DevicePerformanceClass,
    PerformanceConfig,
    SoundConfig,
} from "../types/game.performance";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[game.performance]");

export class GamePerformanceConfig {
    private static instance: GamePerformanceConfig;
    private config: PerformanceConfig;
    private deviceInfo: DeviceInfo;

    constructor(userAgent?: string) {
        this.deviceInfo = this.getDeviceInfo(userAgent);
        this.config = this.optimizeForDevice();
    }

    static getInstance(): GamePerformanceConfig {
        if (!GamePerformanceConfig.instance) {
            GamePerformanceConfig.instance = new GamePerformanceConfig();
        }
        return GamePerformanceConfig.instance;
    }

    initialize(config?: PerformanceConfig) {
        if (config) {
            this.config = config;
        } else {
            this.config = this.optimizeForDevice();
        }
    }

    private optimizeForDevice(): PerformanceConfig {
        if (this.deviceInfo.performanceClass === "LOW") {
            logger.info("Performance mode enabled: Animations and sounds are turned off.");
            return {
                animations: false,
                animationMode: "reduced",
                sound: false,
                soundMode: "low",
                performanceClass: "LOW",
            };
        }

        if (this.deviceInfo.performanceClass === "AVERAGE") {
            logger.info("Optimized mode: Reduced animations and sound effects.");
            return {
                animations: true,
                animationMode: "reduced",
                sound: true,
                soundMode: "low",
                performanceClass: "AVERAGE",
            };
        }

        if (this.deviceInfo.performanceClass === "HIGH") {
            logger.info("Full experience enabled: Animations and sounds are on.");
            return {
                animations: true,
                animationMode: "normal",
                sound: true,
                soundMode: "normal",
                performanceClass: "HIGH",
            };
        }

        logger.warn("[optimizeForDevice] Unknown performance class. Defaulting to high settings.");
        return {
            animations: true,
            animationMode: "normal",
            sound: true,
            soundMode: "normal",
            performanceClass: "UNKNOWN",
        };
    }

    shouldPlayAnimation(): boolean {
        return this.config.animations;
    }

    getAnimationConfig(): AnimationConfig {
        return {
            speed: this.config.animationMode === "reduced" ? 1.5 : 1,
            skipFrames: this.config.animationMode === "reduced",
            animationMode: this.config.animationMode,
        };
    }

    shouldPlaySound(): boolean {
        return this.config.sound;
    }

    getSoundConfig(): SoundConfig {
        return {
            volume: this.config.soundMode === "low" ? 0.5 : 1,
            rate: this.config.soundMode === "low" ? 1 : 1,
        };
    }

    getDeviceInfo(userAgent: string = navigator.userAgent): DeviceInfo {
        // https://core.telegram.org/bots/webapps#additional-data-in-user-agent
        // Telegram-Android/{app_version} ({manufacturer} {model}; Android {android_version}; SDK {sdk_version}; {performance_class})
        const telegramInfoRegex =
            /Telegram-Android\/([\d.]+) \(([^;]+); Android ([^;]+); SDK (\d+); (LOW|AVERAGE|HIGH)\)/;
        const match = userAgent.match(telegramInfoRegex);
        logger.debug("[getDeviceInfo]", { userAgent, match });

        if (match) {
            return {
                appVersion: match[1],
                deviceModel: match[2],
                androidVersion: match[3],
                sdkVersion: parseInt(match[4]),
                performanceClass: match[5] as DevicePerformanceClass,
            };
        }

        return {
            appVersion: "Unknown",
            deviceModel: "Unknown",
            androidVersion: "Unknown",
            sdkVersion: 0,
            performanceClass: "UNKNOWN",
        };
    }
}

export const gamePerformanceConfig = GamePerformanceConfig.getInstance();
