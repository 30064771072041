export abstract class DataManager<T> extends Phaser.Plugins.ScenePlugin {
    public abstract key: string;
    private eventSubscriptions: Map<(data: T) => void, Function> = new Map();

    constructor(scene: Phaser.Scene, pluginManager: Phaser.Plugins.PluginManager, pluginKey: string) {
        super(scene, pluginManager, pluginKey);
    }

    abstract syncWithBackend(...args: any[]): Promise<T | undefined>;

    setData(value: T): void {
        this.game.registry.set(this.key, value);
    }

    getData(): T | undefined {
        return this.game.registry.get(this.key);
    }

    removeData(): void {
        this.game.registry.set(this.key, undefined);
    }

    // Subscribe to data changes
    on(event: string, callback: (data: T) => void): void {
        // Create the wrapped callback
        const wrappedCallback = (_: any, key: string, newData: T, oldData: T) => {
            if (event === "changedata") {
                if (key === this.key && JSON.stringify(newData) !== JSON.stringify(oldData)) {
                    callback(newData);
                }
            } else {
                if (key === this.key) {
                    callback(newData);
                }
            }
        };

        // Store the mapping between original and wrapped callback
        this.eventSubscriptions.set(callback, wrappedCallback);

        // Add the event listener
        this.game.registry.events.on(event, wrappedCallback, this);
    }

    off(event: string, callback: (data: T) => void): void {
        // Get the wrapped callback
        const wrappedCallback = this.eventSubscriptions.get(callback);
        if (wrappedCallback) {
            // Remove the event listener
            this.game.registry.events.off(event, wrappedCallback, this);
            // Clean up the mapping
            this.eventSubscriptions.delete(callback);
        }
    }

    destroy() {
        // Clean up all subscriptions when the manager is destroyed
        this.eventSubscriptions.forEach((_, callback) => {
            this.off("changedata", callback);
        });
        this.eventSubscriptions.clear();
        super.destroy();
    }
}
