import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import Phaser from "phaser";

export default class Circle extends Phaser.GameObjects.Container {
    private maskGraphics!: Phaser.GameObjects.Graphics;
    private circleStroke!: Phaser.GameObjects.Graphics;
    private clickZone!: Phaser.GameObjects.Zone;
    private circleMask!: Phaser.Display.Masks.GeometryMask;
    private parent: Phaser.GameObjects.Container;
    private size: number;
    static compositeMask: Phaser.GameObjects.Graphics | null = null;

    constructor(parent: Phaser.GameObjects.Container, scene: Phaser.Scene, x: number, y: number, size: number = 128) {
        super(scene, x, y);
        this.parent = parent;
        this.size = size;
        // this.createCircle();
    }

    onClick(cb: () => void) {
        this.clickZone?.setDepth(DEPTH_PRIORITY.TUTORIAL_CLICKZONE);
        this.clickZone?.once("pointerdown", cb, this.parent);
    }

    createCircle(size: number = this.size) {
        const radius = size / 2;

        // Create or update the composite mask
        if (!Circle.compositeMask) {
            Circle.compositeMask = this.scene.add.graphics();
            Circle.compositeMask.setDepth(1);
        }

        // Create circle graphics
        Circle.compositeMask.clear();
        Circle.compositeMask.fillStyle(0xffffff);
        Circle.compositeMask.fillCircle(this.x, this.y, radius);
        Circle.compositeMask.setAlpha(1);

        // Create a separate graphics for the circle stroke
        this.circleStroke = this.scene.add.graphics();
        this.circleStroke.clear();
        this.circleStroke.lineStyle(6, 0xd8e63c);
        this.circleStroke.strokeCircle(this.x, this.y, radius);
        this.circleStroke.setDepth(2);
        this.circleStroke.setAlpha(0);

        // Create bitmap mask
        this.circleMask = new Phaser.Display.Masks.GeometryMask(this.scene, Circle.compositeMask);
        this.circleMask.invertAlpha = true;

        // Set mask on parent
        this.parent.setMask(this.circleMask);

        // Create an interactive circular zone
        this.clickZone = this.scene.add.zone(this.x, this.y, size, size);
        this.clickZone.setInteractive({ cursor: "pointer" });
        this.clickZone.setOrigin(0.5, 0.5);
    }

    show() {
        // Ensure graphics are properly set up
        if (!Circle.compositeMask || !this.circleStroke) {
            this.createCircle();
        }

        // Circle.compositeMask?.setAlpha(1);
        Circle.compositeMask?.setAlpha(0);

        // Fade in the mask and stroke
        this.scene.tweens.add({
            targets: [this.circleStroke],
            alpha: { from: 0, to: 1 },
            duration: 500,
        });
    }

    destroy(fromScene?: boolean) {
        if (this.clickZone) {
            this.clickZone.removeAllListeners();
            this.clickZone.destroy();
        }
        if (this.circleStroke) {
            this.circleStroke.destroy();
        }
        if (this.circleMask) {
            this.circleMask.destroy();
        }

        this.parent.clearMask();
        super.destroy(fromScene);
    }

    static clearMasks() {
        if (Circle.compositeMask) {
            Circle.compositeMask.destroy();
            Circle.compositeMask = null;
        }
    }
}
