import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[services/analytics]");

/**
 * Service for tracking analytics events in the game
 */
let initialized = false;
const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;

/**
 * Initializes Google Analytics with the appropriate configuration
 */
export function initAnalytics(): void {
    if (initialized || !measurementId) return;

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
        window.dataLayer.push(arguments);
    };
    gtag("js", new Date());
    gtag("config", measurementId);

    initialized = true;
    logger.info("Analytics initialized");
}

/**
 * Tracks a screen view event in Google Analytics
 */
export function trackScreenView(screenName: string): void {
    if (!initialized) return;
    gtag("event", "screen_view", {
        screen_name: screenName,
    });
}

/**
 * Tracks a button tap event in Google Analytics
 */
export function trackButtonTap(buttonName: string, screenName: string): void {
    if (!initialized) return;
    gtag("event", "button_tap", {
        button_name: buttonName,
        screen_name: screenName,
    });
}

/**
 * Tracks a custom game event in Google Analytics
 */
export function trackGameEvent(eventName: string, params?: Record<string, any>): void {
    if (!initialized) return;
    gtag("event", eventName, params);
}

// Type declarations
declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

declare function gtag(command: "event" | "js" | "config", action: string | Date, params?: Record<string, any>): void;

export const SCREEN_NAMES = {
    MINING: "mining_screen",
    SLOTS: "slots_screen",
    BOSS: "boss_screen",
    WHEEL: "wheel_screen",
    STORE: "store_screen",
    MISSIONS: "missions_screen",
    REFERRAL: "referral_screen",
    LOOTBOX: "lootbox_screen",
} as const;

export const BUTTON_NAMES = {
    UPGRADE_WEAPON: "upgrade_weapon_button",
    SPIN: "spin_button",
    FIGHT_BOSS: "fight_boss_button",
    CLAIM: "claim_button",
    MISSION_ICON: "mission_icon",
    WHEEL_ICON: "wheel_icon",
    REFERRAL_ICON: "referral_icon",
    LOOTBOX_ICON: "lootbox_icon",
    STORE_ICON: "store_icon",
} as const;

/**
 * Utility function to automatically track screen views for a scene
 */
export function setupScreenTracking(scene: Phaser.Scene): void {
    if (!scene || !scene.events) {
        logger.warn("Scene not properly initialized for analytics tracking");
        return;
    }

    // Track when scene starts
    scene.events.once("create", () => {
        trackScreenView(scene.scene.key);
    });
}
