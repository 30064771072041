// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class Step10 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // bg
        const bg = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        bg.alpha = 0.8;
        bg.alphaTopLeft = 0.8;
        bg.alphaTopRight = 0.8;
        bg.alphaBottomLeft = 0.8;
        bg.alphaBottomRight = 0.8;
        this.add(bg);

        // playerHead
        const playerHead = scene.add.image(-155, -171, "tutorial", "Head.png");
        playerHead.alpha = 0;
        playerHead.alphaTopLeft = 0;
        playerHead.alphaTopRight = 0;
        playerHead.alphaBottomLeft = 0;
        playerHead.alphaBottomRight = 0;
        this.add(playerHead);

        // message1
        const message1 = scene.add.container(-121, -174);
        this.add(message1);

        // boxMessage1
        const boxMessage1 = scene.add.image(147, 45, "tutorial", "box_message_2.png");
        boxMessage1.alpha = 0;
        boxMessage1.alphaTopLeft = 0;
        boxMessage1.alphaTopRight = 0;
        boxMessage1.alphaBottomLeft = 0;
        boxMessage1.alphaBottomRight = 0;
        message1.add(boxMessage1);

        // content1
        const content1 = new Text(scene, 150, 60);
        content1.alpha = 0;
        content1.text = "You've defeated SteelWolf. Your reward is waiting - claim it now!";
        content1.setStyle({ color: "#D8E63C", fontSize: "18px" });
        content1.setLineSpacing(4);
        content1.setWordWrapWidth(255);
        message1.add(content1);

        // content
        const content = new Text(scene, 74, 4);
        content.alpha = 0;
        content.text = "Victory!!!";
        content.setStyle({ color: "#D8E63C", fontSize: "24px" });
        content.setWordWrapWidth(255);
        message1.add(content);

        // message2
        const message2 = scene.add.container(-119, -42);
        this.add(message2);

        // boxMessage2
        const boxMessage2 = scene.add.image(147, 53, "tutorial", "box_message_2.png");
        boxMessage2.scaleY = 0.9;
        boxMessage2.alpha = 0;
        boxMessage2.alphaTopLeft = 0;
        boxMessage2.alphaTopRight = 0;
        boxMessage2.alphaBottomLeft = 0;
        boxMessage2.alphaBottomRight = 0;
        message2.add(boxMessage2);

        // content2
        const content2 = new Text(scene, 100, 22);
        content2.alpha = 0;
        content2.text = "Tutorial complete!";
        content2.setStyle({ fontSize: "18px" });
        content2.setWordWrapWidth(255);
        message2.add(content2);

        // content3
        const content3 = new Text(scene, 172, 55);
        content3.alpha = 0;
        content3.text = "GOLD";
        content3.setStyle({ color: "#D8E63C", fontSize: "18px" });
        content3.setWordWrapWidth(255);
        message2.add(content3);

        // content4
        const content4 = new Text(scene, 84, 56);
        content4.alpha = 0;
        content4.text = "You've earned";
        content4.setStyle({ fontSize: "18px" });
        content4.setWordWrapWidth(255);
        message2.add(content4);

        // gold
        const gold = scene.add.image(272, 2, "mining", "Coin2 1.png");
        gold.alpha = 0;
        gold.alphaTopLeft = 0;
        gold.alphaTopRight = 0;
        gold.alphaBottomLeft = 0;
        gold.alphaBottomRight = 0;
        message2.add(gold);

        // content5
        const content5 = new Text(scene, 236, 56);
        content5.alpha = 0;
        content5.text = "for your";
        content5.setStyle({ fontSize: "18px" });
        content5.setWordWrapWidth(255);
        message2.add(content5);

        // content6
        const content6 = new Text(scene, 94, 83);
        content6.alpha = 0;
        content6.text = "next adventure!";
        content6.setStyle({ fontSize: "18px" });
        content6.setWordWrapWidth(255);
        message2.add(content6);

        // claimButton
        const claimButton = scene.add.image(9, 327, "tutorial", "tap_to_claim.png");
        claimButton.alpha = 0;
        claimButton.alphaTopLeft = 0;
        claimButton.alphaTopRight = 0;
        claimButton.alphaBottomLeft = 0;
        claimButton.alphaBottomRight = 0;
        this.add(claimButton);

        this.bg = bg;
        this.playerHead = playerHead;
        this.boxMessage1 = boxMessage1;
        this.content1 = content1;
        this.content = content;
        this.message1 = message1;
        this.boxMessage2 = boxMessage2;
        this.content2 = content2;
        this.content3 = content3;
        this.content4 = content4;
        this.gold = gold;
        this.content5 = content5;
        this.content6 = content6;
        this.message2 = message2;
        this.claimButton = claimButton;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Image;
    private playerHead: Phaser.GameObjects.Image;
    private boxMessage1: Phaser.GameObjects.Image;
    private content1: Text;
    private content: Text;
    private message1: Phaser.GameObjects.Container;
    private boxMessage2: Phaser.GameObjects.Image;
    private content2: Text;
    private content3: Text;
    private content4: Text;
    private gold: Phaser.GameObjects.Image;
    private content5: Text;
    private content6: Text;
    private message2: Phaser.GameObjects.Container;
    private claimButton: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.playerHead,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.boxMessage1,
                scaleY: { from: 0, to: 1 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                ease: "Back.easeOut",
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: [this.content, this.content1],
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.boxMessage2,
                alpha: { from: 0, to: 1 },
                duration: 400,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: [this.content2, this.content3, this.content4, this.content5, this.content6, this.gold],
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.claimButton,
                alpha: { from: 0, to: 1 },
                duration: 400,
                delay: 250,
                onComplete: () => resolve(),
            });
        });

        new PushComponent(
            this.claimButton,
            () => {
                eventBusComponent.emit("next", {
                    step: "10",
                });
            },
            this,
        ).activate();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
