// You can write more code here

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class PreloadProgressBar extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        const VERTICAL_OFFSET = 145 + (y ?? 0); // Increase this number to move everything up

        const PROGRESS_BAR = {
            y: scene.cameras.main.height - VERTICAL_OFFSET,
            textY: scene.cameras.main.height - (VERTICAL_OFFSET - 17), // text is 17px above progress bar
            width: 256,
            height: 36,
            borderRadius: 20,
            x: scene.cameras.main.centerX - 128,
        };

        // progressBar background
        const progressBarBgGraphics = scene.add.graphics();
        progressBarBgGraphics.fillStyle(0x020200, 1);
        progressBarBgGraphics.fillRoundedRect(
            PROGRESS_BAR.x,
            PROGRESS_BAR.y,
            PROGRESS_BAR.width,
            PROGRESS_BAR.height,
            PROGRESS_BAR.borderRadius,
        );

        // progressBar
        const progressBarGraphics = scene.add.graphics();
        progressBarGraphics.fillStyle(0xf3ba2f, 1);
        progressBarGraphics.fillRoundedRect(
            PROGRESS_BAR.x,
            PROGRESS_BAR.y,
            PROGRESS_BAR.width * 0.2,
            PROGRESS_BAR.height,
            PROGRESS_BAR.borderRadius,
        );

        // loadingText
        const loadingText = scene.add.text(scene.cameras.main.centerX, PROGRESS_BAR.textY, "Loading", {
            color: "#9d7611", // Darker than #F3BA2F
            fontFamily: "arial",
            fontSize: "16px",
        });
        loadingText.setOrigin(0.5, 0.5);

        this.loadingText = loadingText;
        this.progressBarGraphics = progressBarGraphics;
        this.progressBarBgGraphics = progressBarBgGraphics;
        this.options = PROGRESS_BAR;
    }

    loadingText: Phaser.GameObjects.Text;
    progressBarGraphics: Phaser.GameObjects.Graphics;
    progressBarBgGraphics: Phaser.GameObjects.Graphics;
    options: {
        y: number;
        x: number;
        textY: number;
        width: number;
        height: number;
        borderRadius: number;
    };

    /* START-USER-CODE */
    updateProgress(value: number) {
        this.progressBarGraphics.clear();
        this.progressBarGraphics.fillStyle(0xf3ba2f, 1);
        const progress = value <= 0.2 ? 0.2 : value;
        this.progressBarGraphics.fillRoundedRect(
            this.options.x,
            this.options.y,
            this.options.width * progress,
            this.options.height,
            this.options.borderRadius,
        );
        this.loadingText.setText(`Loading`);
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
