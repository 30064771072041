// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import SkipButton from "./SkipButton";
import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import Circle from "./Circle";
/* END-USER-IMPORTS */

export default class Step6 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // tutorial_bg_png
        const tutorial_bg_png = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        this.add(tutorial_bg_png);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -377);
        this.add(skipButton_1);

        // playerHead
        const playerHead = scene.add.image(-150, -13, "tutorial", "Head.png");
        playerHead.alpha = 0;
        playerHead.alphaTopLeft = 0;
        playerHead.alphaTopRight = 0;
        playerHead.alphaBottomLeft = 0;
        playerHead.alphaBottomRight = 0;
        this.add(playerHead);

        // message1
        const message1 = scene.add.container(-118, -17);
        this.add(message1);

        // boxMessage
        const boxMessage = scene.add.image(146, 53, "tutorial", "box_message_2.png");
        boxMessage.scaleY = 0.9;
        boxMessage.alpha = 0;
        boxMessage.alphaTopLeft = 0;
        boxMessage.alphaTopRight = 0;
        boxMessage.alphaBottomLeft = 0;
        boxMessage.alphaBottomRight = 0;
        message1.add(boxMessage);

        // content
        const content = new Text(scene, 137, 49);
        content.alpha = 0;
        content.text = "Last fight!\n\nLet's defeat this boss for good and unlock next chapter!";
        content.setWordWrapWidth(255);
        message1.add(content);

        this.playerHead = playerHead;
        this.boxMessage = boxMessage;
        this.content = content;
        this.message1 = message1;

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    private playerHead: Phaser.GameObjects.Image;
    private boxMessage: Phaser.GameObjects.Image;
    private content: Text;
    private message1: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.playerHead,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.boxMessage,
                scaleY: { from: 0, to: 1 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                ease: "Back.easeOut",
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.content,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        const circle = new Circle(this, this.scene, 325, 240, 164);
        this.add(circle);
        circle.show();
        circle.onClick(this.fightBoss);
    }

    fightBoss() {
        this.scene.userManager.syncIntroStep("6");
        this.scene.scene.start("MiningBoss");
        this.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
