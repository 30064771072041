import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/free-bonus]");

export type FreeBonusResponse = components["schemas"]["FreeBonusResponseDto"];

export const getActiveFreeBonusForUser = async (): Promise<FreeBonusResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/free-bonus");

    if (error) {
        logger.error("[getActiveFreeBonusForUser]", error);
        return;
    }

    return data as FreeBonusResponse;
};

export const claimFreeBonus = async (): Promise<boolean> => {
    const { error } = await telegramBackendApi.POST("/free-bonus");

    if (error) {
        logger.error("[claimFreeBonus]", error);
        return false;
    }

    return true;
};
