// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import InviteAFriendDisplay from "@/shared/prefabs/referral/InviteAFriendDisplay";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
/* END-USER-IMPORTS */

export default class Invite extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // rectangle_89_png
        const rectangle_89_png = scene.add.image(195, 666, "lucky_wheel", "Rectangle 89.png");
        this.add(rectangle_89_png);

        // rectangle_1
        const rectangle_1 = scene.add.rectangle(195, 422, 390, 844);
        rectangle_1.visible = false;
        rectangle_1.isFilled = true;
        this.add(rectangle_1);

        // text
        const text = new Text(scene, 205, 575);
        text.text = "Invite a Friend to earn more spins";
        text.setStyle({ "align": "center", "fontSize": "36px", "fontStyle": "bold" });
        text.setLineSpacing(4);
        text.setWordWrapWidth(317);
        this.add(text);

        // inviteButton
        const inviteButton = scene.add.image(195, 675, "lucky_wheel", "btn_invite.png");
        this.add(inviteButton);

        this.inviteButton = inviteButton;

        /* START-USER-CTR-CODE */
        this.setupReferralClick();
        /* END-USER-CTR-CODE */
    }

    private inviteButton: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    setupReferralClick() {
        const user = this.scene.userManager.getData();
        if (!user) return;

        new PushComponent(
            this.inviteButton,
            () => {
                const invitePopup = new InviteAFriendDisplay(this.scene, 0, 0);
                invitePopup.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
                invitePopup.setUserReferralCode(user.referralCode);
                invitePopup.show(true);
                this.scene.add.existing(invitePopup);
            },
            this,
        ).activate();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
