// You can write more code here

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import {
    handleTwitterFollow,
    handleChannelSubscribe,
    handleGroupJoin,
    handleCoinSaviAirdrop,
    handleAmbassadorProgram,
} from "../../../utils/missions";
import MissionItem from "./MissionItem";
import { type Mission, getMissions } from "@/lib/api/telegram-backend/methods/mission";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { addToHomeScreen } from "@/lib/telegram/web-app";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[shared/prefabs/missions/Missions]");

/* END-USER-IMPORTS */

export default class Missions extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 99);

        this.scaleX = 1.0070646966710173;
        this.scaleY = 1.0058949472492873;

        // background
        const background = scene.add.image(1, 322, "common_background");
        background.scaleX = 0.9999999909749047;
        background.scaleY = 1.2735562650776424;
        this.add(background);

        // header
        const header = scene.add.container(-155, -75);
        this.add(header);

        // backButton
        const backButton = scene.add.image(0, 18, "back_btn");
        header.add(backButton);

        // title
        const title = scene.add.text(-148, -6, "", {});
        title.text = "MISSIONS";
        title.setStyle({
            fontFamily: "Oxanium",
            fontSize: "64px",
            fontStyle: "bold",
            "shadow.offsetX": 4,
            "shadow.offsetY": 4,
            "shadow.color": "#0000008C",
            "shadow.blur": 12,
        });
        this.add(title);

        // gradientFx
        title.preFX!.addGradient(16112275, 14214716, 0.2, 0, 0, 0, 1, 0);

        // shadowFx
        title.preFX!.addShadow(0, 0, 0.1, 1, 0, 6, 1);

        // pagination
        const pagination = scene.add.container(-22, -92);
        pagination.blendMode = Phaser.BlendModes.SKIP_CHECK;
        pagination.visible = false;
        this.add(pagination);

        // paper_icon
        const paper_icon = scene.add.image(-9, 16, "missions", "paper-icon.png");
        paper_icon.scaleX = 0.21831899467090085;
        paper_icon.scaleY = 0.21831899467090085;
        pagination.add(paper_icon);

        // paginationText
        const paginationText = scene.add.text(10, 4, "", {});
        paginationText.text = "1/1";
        paginationText.setStyle({
            align: "center",
            color: "#F6F7FA",
            fontFamily: "Oxanium",
            fontSize: "20px",
            fontStyle: "bold",
        });
        pagination.add(paginationText);

        // leftArrow
        const leftArrow = scene.add.image(-73, 16, "arrow");
        leftArrow.scaleX = 0.16271285693612547;
        leftArrow.scaleY = 0.16271285693612547;
        pagination.add(leftArrow);

        // rightArrow
        const rightArrow = scene.add.image(112, 16, "arrow");
        rightArrow.scaleX = 0.16271285693612547;
        rightArrow.scaleY = 0.16271285693612547;
        rightArrow.flipX = true;
        pagination.add(rightArrow);

        // loadingText
        const loadingText = scene.add.text(-62, 228, "", {});
        loadingText.text = "LOADING...";
        loadingText.setStyle({
            fontFamily: "Oxanium",
            fontSize: "28px",
            fontStyle: "bold",
            "shadow.offsetX": 4,
            "shadow.offsetY": 4,
            "shadow.color": "#0000008C",
            "shadow.blur": 12,
        });
        this.add(loadingText);

        // gradientFx_1
        loadingText.preFX!.addGradient(16112275, 14214716, 0.2, 0, 0, 0, 1, 0);

        // shadowFx_1
        loadingText.preFX!.addShadow(0, 0, 0.1, 1, 0, 6, 1);

        this.backButton = backButton;
        this.title = title;
        this.paginationText = paginationText;
        this.leftArrow = leftArrow;
        this.rightArrow = rightArrow;
        this.pagination = pagination;
        this.loadingText = loadingText;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        background.setInteractive();
        this.paginationText.setLetterSpacing(0.4);
        this.title.setLetterSpacing(0.4);

        backButton.setInteractive({ useHandCursor: true });
        new PushComponent(backButton, () => {
            this.setVisible(false);
        }).activate();

        // Initialize container for mission items
        this.missionItemsContainer = scene.add.container(0, 0);
        this.add(this.missionItemsContainer);

        // Set up pagination controls
        this.leftArrow.setInteractive({ useHandCursor: true });
        new PushComponent(this.leftArrow, () => {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.renderCurrentPage();
            }
        }).activate();

        this.rightArrow.setInteractive({ useHandCursor: true });
        new PushComponent(this.rightArrow, () => {
            const totalPages = Math.ceil(this.missions.length / this.itemsPerPage);
            if (this.currentPage < totalPages) {
                this.currentPage++;
                this.renderCurrentPage();
            }
        }).activate();

        this.fetchMissions();
        /* END-USER-CTR-CODE */
    }

    private backButton: Phaser.GameObjects.Image;
    private title: Phaser.GameObjects.Text;
    private paginationText: Phaser.GameObjects.Text;
    private leftArrow: Phaser.GameObjects.Image;
    private rightArrow: Phaser.GameObjects.Image;
    private pagination: Phaser.GameObjects.Container;
    private loadingText: Phaser.GameObjects.Text;

    /* START-USER-CODE */
    private missionItemsContainer: Phaser.GameObjects.Container;
    private currentPage: number = 1;
    private itemsPerPage: number = 5;
    private missions: Mission[] = [];

    private missionHandlers: {
        [key in Mission["type"]]: () => void;
    } = {
        follow_x: handleTwitterFollow,
        subscribe_telegram_channel: handleChannelSubscribe,
        join_telegram_group: handleGroupJoin,
        add_to_home_screen: addToHomeScreen,
        coin_savi_user_airdrop: handleCoinSaviAirdrop,
        ambassador_program: handleAmbassadorProgram,
    };

    private missionItems: Map<string, MissionItem> = new Map();

    private async fetchMissions() {
        try {
            const missions = await getMissions();
            if (missions) {
                // Filter out missions with unsupported types
                this.missions = missions.filter((mission) => mission.type in this.missionHandlers);
                this.loadingText.setVisible(false);
                this.renderCurrentPage();
            }
        } catch (error) {
            logger.error("[fetchMissions] Failed to fetch missions:", error);
        }
    }

    private updatePagination() {
        const totalPages = Math.ceil(this.missions.length / this.itemsPerPage);

        // Show/hide pagination based on total items
        this.pagination.setVisible(this.missions.length > this.itemsPerPage);

        // Update page text
        this.paginationText.setText(`${this.currentPage}/${totalPages}`);

        // Enable/disable arrows based on current page
        this.leftArrow.setAlpha(this.currentPage > 1 ? 1 : 0.5);
        this.rightArrow.setAlpha(this.currentPage < totalPages ? 1 : 0.5);
    }

    private renderCurrentPage() {
        this.missionItemsContainer.removeAll(true);
        this.missionItems.clear();

        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = Math.min(startIndex + this.itemsPerPage, this.missions.length);
        const currentPageItems = this.missions.slice(startIndex, endIndex);

        const yOffset = 120;
        const itemSpacing = 12;
        const itemHeight = 60;

        currentPageItems.forEach((mission, index) => {
            const item = new MissionItem(this.scene, 0, yOffset + index * (itemSpacing + itemHeight));
            item.setDisplayData(mission);

            // Store reference to mission item
            this.missionItems.set(mission.id, item);

            // Listen for mission start event
            item.on("mission-start", this.handleMissionStart, this);
            item.on("mission-claimed", this.handleMissionClaimed, this);
            this.missionItemsContainer.add(item);
        });

        this.updatePagination();
    }
    private async handleMissionStart(mission: Mission) {
        const handler = this.missionHandlers[mission.type as keyof typeof this.missionHandlers];
        if (!handler) return;

        try {
            // Execute the corresponding handler with mission ID
            handler();

            // When user returns to app, show claim button
            const missionItem = this.missionItems.get(mission.id);
            if (missionItem) {
                this.scene.time.delayedCall(1000, () => {
                    missionItem.showClaimButton();
                });
            }
        } catch (error) {
            logger.error("[handleMissionStart]", error);
        }
    }

    private async handleMissionClaimed() {
        this.emit(CUSTOM_EVENTS.SYNC_USER);
        this.fetchMissions(); // Fetch missions again to sync UI with server state
    }

    destroy() {
        this.missionItems.forEach((item) => {
            item.off("mission-start", this.handleMissionStart, this);
            item.off("mission-claimed", this.handleMissionClaimed, this);
        });
        this.missionItems.clear();

        this.missionItemsContainer.destroy();
        this.leftArrow.off("pointerdown");
        this.rightArrow.off("pointerdown");
        super.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
