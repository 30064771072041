// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
import SkipButton from "./SkipButton";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import Circle from "./Circle";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { claimMiningReward } from "@/lib/api/telegram-backend/methods/mining";
import { sleep } from "@/lib/utils";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { claimData } from "@/shared/plugins/tutorial/mock-data";
/* END-USER-IMPORTS */

export default class Step3 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 194, y ?? 422);

        // tutorial_bg_png
        const tutorial_bg_png = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        this.add(tutorial_bg_png);

        // player
        const player = scene.add.image(-158, -169, "tutorial", "Head.png");
        this.add(player);

        // message1
        const message1 = scene.add.container(-122, -188);
        this.add(message1);

        // boxMessage1
        const boxMessage1 = scene.add.image(146, 53, "tutorial", "box_message_2.png");
        boxMessage1.scaleY = 0.8;
        message1.add(boxMessage1);

        // content1
        const content1 = new Text(scene, 143, 48);
        content1.text = "Every minute, both REMIS and GOLD are auto-mined based on your rate.";
        content1.setLineSpacing(6);
        content1.setWordWrapWidth(255);
        message1.add(content1);

        // message2
        const message2 = scene.add.container(-116, -160);
        message2.alpha = 0;
        this.add(message2);

        // boxMessage2
        const boxMessage2 = scene.add.image(142, 50, "tutorial", "box_message_2.png");
        boxMessage2.scaleY = 0.8;
        message2.add(boxMessage2);

        // content2
        const content2 = new Text(scene, 139, 36);
        content2.text = "Then, you can claim them at the Storage Icon";
        content2.setLineSpacing(6);
        content2.setWordWrapWidth(255);
        message2.add(content2);

        // arrow1
        const arrow1 = scene.add.container(-67, 285);
        arrow1.alpha = 0;
        this.add(arrow1);

        // tap1
        const tap1 = scene.add.image(25, 39, "tutorial", "arrow.png");
        tap1.angle = 45;
        arrow1.add(tap1);

        // tap
        const tap = new Text(scene, 143, 12);
        tap.text = "Tap to Continue";
        tap.setStyle({ color: "#D8E63C", fontSize: "26px", fontStyle: "bold" });
        arrow1.add(tap);

        // arrow2
        const arrow2 = scene.add.container(-170, 286);
        arrow2.alpha = 0;
        this.add(arrow2);

        // tap_1
        const tap_1 = new Text(scene, 94, 0);
        tap_1.text = "Tap to Continue";
        tap_1.setStyle({
            color: "#D8E63C",
            fontSize: "26px",
            fontStyle: "bold",
        });
        arrow2.add(tap_1);

        // tap2
        const tap2 = scene.add.image(216, 2, "tutorial", "arrow.png");
        tap2.angle = -90;
        arrow2.add(tap2);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -379);
        this.add(skipButton_1);

        this.player = player;
        this.content1 = content1;
        this.message1 = message1;
        this.message2 = message2;
        this.tap1 = tap1;
        this.arrow1 = arrow1;
        this.tap2 = tap2;
        this.arrow2 = arrow2;

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    private player: Phaser.GameObjects.Image;
    private content1: Text;
    private message1: Phaser.GameObjects.Container;
    private message2: Phaser.GameObjects.Container;
    private tap1: Phaser.GameObjects.Image;
    private arrow1: Phaser.GameObjects.Container;
    private tap2: Phaser.GameObjects.Image;
    private arrow2: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    private circle!: Circle;
    private eventBus!: EventBusComponent;

    async init(eventBusComponent: EventBusComponent) {
        this.eventBus = eventBusComponent;

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.arrow1,
                alpha: { from: 0, to: 1 },
                duration: 400,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        this.scene.tweens.add({
            targets: this.tap1,
            y: {
                value: "+=10",
            },
            x: {
                value: "-=10",
            },
            duration: 500,
            yoyo: true,
            repeat: -1,
        });

        this.circle = new Circle(this, this.scene, 65, 810, 128);
        this.add(this.circle);
        this.circle.show();
        this.circle.onClick(this.handleNext);
    }

    private async handleNext() {
        this.arrow1.destroy();
        this.circle.destroy();
        this.content1.destroy();

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.message1,
                scaleY: { from: 1, to: 0.7 },
                scaleX: { from: 1, to: 0.9 },
                alpha: { from: 1, to: 0.4 },
                x: { from: -122, to: -110 },
                duration: 200,
                onComplete: () => {
                    this.scene.tweens.add({
                        targets: this.message1,
                        y: { from: -188, to: -216 },
                        duration: 200,
                        onComplete: () => resolve(),
                    });
                },
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.message2,
                y: { from: -160, to: -190 },
                alpha: { from: 0, to: 1 },
                duration: 200,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.arrow2,
                alpha: { from: 0, to: 1 },
                duration: 200,
                onComplete: () => resolve(),
            });
        });

        this.circle = new Circle(this, this.scene, 345, 718, 128);
        this.add(this.circle);
        this.circle.show();
        this.circle.onClick(this.handleClaim);

        this.scene.tweens.add({
            targets: this.tap2,
            x: {
                value: "+=10",
            },
            duration: 500,
            yoyo: true,
            repeat: -1,
        });
    }

    private handleClaim() {
        this.message1.destroy();
        this.message2.destroy();
        this.arrow2.destroy();
        this.circle.destroy();
        this.player.destroy();

        this.playWinAnimation();
    }

    async playWinAnimation() {
        const win = this.scene.add.sprite(
            0,
            30, // 97
            "you_win",
            "you_win_coins_anim_00000.png",
        );

        win.scaleX = 0.4;
        win.scaleY = 0.4;
        win.setDepth(1);
        win.play("you_win");
        this.add(win);

        await sleep(1000);

        const coinAmount = claimData.reward.otaGold;
        const rmxAmount = claimData.reward.rmx;

        // winAmountText
        const coinAmountText = new Text(this.scene, 0, 47);
        coinAmountText.setOrigin(0.5, 0.5);
        coinAmountText.text = "0";
        coinAmountText.setStyle({
            color: "#f3e192",
            fontSize: "24px",
            stroke: "#f3e192",
        });
        this.add(coinAmountText);

        const rmxAmountText = new Text(this.scene, 0, 102);
        rmxAmountText.setOrigin(0.5, 0.5);
        rmxAmountText.text = "0";
        rmxAmountText.setStyle({
            color: "#f3e192",
            fontSize: "24px",
            stroke: "#f3e192",
        });
        this.add(rmxAmountText);

        this.scene.tweens.addCounter({
            from: 0,
            to: coinAmount,
            duration: 500,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = tween.getValue().toFixed(3);
                coinAmountText.setText(value);
            },
            onComplete: () => {
                const coinImage = this.scene.add.image(0 + coinAmountText.width / 2 + 30, 47, "mining", "Coin4 1.png");
                this.add(coinImage);
            },
        });

        this.scene.tweens.addCounter({
            from: 0,
            to: rmxAmount,
            duration: 600,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = tween.getValue().toFixed(3);
                rmxAmountText.setText(value);
            },
            onComplete: () => {
                const rmxImage = this.scene.add.image(
                    0 + rmxAmountText.width / 2 + 30,
                    102,
                    "mining",
                    "artifacts_05@2x.png",
                );
                this.add(rmxImage);
            },
        });

        await sleep(800);
        const collecReward = this.scene.add.image(0, 350, "tutorial", "collect_reward.png");
        this.add(collecReward);
        new PushComponent(collecReward, () => {
            this.scene.tutorial.startMining();
            this.eventBus.emit("next", {
                step: "3",
            });
        }).activate();
    }

    destroy(fromScene?: boolean) {
        Circle.clearMasks(); // Clear all masks when destroying the step
        super.destroy(fromScene);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
