// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas, parseFormattedNumber } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class SlotsBalance extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 138, y ?? 9);

        // slotsBalance
        const slotsBalance = new Text(scene, 63, -1);
        slotsBalance.text = "Balance";
        slotsBalance.setStyle({ "fontSize": "16px" });
        this.add(slotsBalance);

        // slotsBalanceText
        const slotsBalanceText = new Text(scene, 64, 8);
        slotsBalanceText.setOrigin(0.5, 0);
        slotsBalanceText.text = "0";
        slotsBalanceText.setStyle({ "align": "center", "fontSize": "18px" });
        this.add(slotsBalanceText);

        // coin_icon
        const coin_icon = scene.add.image(44, 17, "coin_icon");
        coin_icon.scaleX = 0.25;
        coin_icon.scaleY = 0.25;
        this.add(coin_icon);

        this.slotsBalanceText = slotsBalanceText;
        this.coin_icon = coin_icon;

        /* START-USER-CTR-CODE */
        this.slotsBalanceText.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            const now = Date.now();
            // Only emit if warning is active and enough time has passed since last click
            if (this.warningTween && now - this.lastClickTime >= SlotsBalance.CLICK_COOLDOWN) {
                this.lastClickTime = now;
                this.emit(SlotsBalance.EVENTS.INSUFFICIENT_BALANCE_CLICK);
            }
        });
        /* END-USER-CTR-CODE */
    }

    private slotsBalanceText: Text;
    private coin_icon: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private balanceTween: Phaser.Tweens.Tween | null = null;
    private warningTween: Phaser.Tweens.Tween | null = null;
    private lastClickTime: number = 0;
    private isInitialized: boolean = false;
    private static readonly CLICK_COOLDOWN = 1000; // 1 second cooldown
    public static readonly EVENTS = {
        INSUFFICIENT_BALANCE_CLICK: "insufficient-balance-click",
    };

    public async init(newBalance: number) {
        await this.setBalance(newBalance);
        this.isInitialized = true;
    }

    public disableSlotsBalanceTextInteractive() {
        this.slotsBalanceText.setInteractive(false);
    }

    public enableSlotsBalanceTextInteractive() {
        this.slotsBalanceText.setInteractive({ useHandCursor: true });
    }

    public getCurrentBalance(): number {
        return parseFormattedNumber(this.slotsBalanceText.text);
    }

    public handleFlashBalanceWarning(currentBet: number) {
        if (this.getCurrentBalance() < currentBet) {
            this.startInsufficientBalanceWarning();
        } else {
            this.stopInsufficientBalanceWarning();
        }
    }

    public updateUserBalance = () => {
        const user = this.scene.userManager.getData();
        if (user) {
            user.otaGold = this.getCurrentBalance();
            this.scene.registry.set("user", user);
        }
    };

    public async updateBalance(delta: number) {
        return new Promise<void>((resolve) => {
            const currentBalance = this.getCurrentBalance();

            const targetBalance = currentBalance + delta;

            if (this.balanceTween) {
                this.balanceTween.remove();
                this.balanceTween = null;
            }

            const difference = Math.abs(targetBalance - currentBalance);
            const maxDuration = 250;
            const calculatedDuration = difference * 10;
            const duration = Math.min(calculatedDuration, maxDuration);

            this.balanceTween = this.scene.tweens.addCounter({
                from: currentBalance,
                to: targetBalance,
                duration,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.slotsBalanceText.setText(numberWithCommas(value));
                    const textBounds = this.slotsBalanceText.getBounds();
                    this.coin_icon.setX(textBounds.x - (this.coin_icon.width + 24));
                },
                onComplete: () => {
                    this.balanceTween = null;
                    this.updateUserBalance();
                    resolve();
                },
            });
        });
    }

    public async setBalance(newBalance: number) {
        return new Promise<void>((resolve) => {
            const currentBalance = this.getCurrentBalance();

            if (this.balanceTween) {
                this.balanceTween.remove();
                this.balanceTween = null;
            }

            const difference = Math.abs(newBalance - currentBalance);
            const maxDuration = 400;
            const calculatedDuration = difference * 10;
            const duration = Math.min(calculatedDuration, maxDuration);

            this.balanceTween = this.scene.tweens.addCounter({
                from: currentBalance,
                to: newBalance,
                duration,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.slotsBalanceText.setText(numberWithCommas(value));
                    const textBounds = this.slotsBalanceText.getBounds();
                    this.coin_icon.setX(textBounds.x - (this.coin_icon.width + 24));
                },
                onComplete: () => {
                    this.balanceTween = null;
                    this.updateUserBalance();
                    resolve();
                },
            });
        });
    }

    public startInsufficientBalanceWarning() {
        if (!this.isInitialized) return;
        this.stopInsufficientBalanceWarning();

        this.warningTween = this.scene.tweens.add({
            targets: this.slotsBalanceText,
            alpha: 0.3,
            duration: 800,
            yoyo: true,
            repeat: -1,
            ease: "Sine.easeInOut",
            onStart: () => {
                this.slotsBalanceText.setTint(0xff0000);
            },
        });
    }

    public stopInsufficientBalanceWarning() {
        if (this.warningTween) {
            this.warningTween.stop();
            this.warningTween.remove();
            this.warningTween = null;
        }
        this.slotsBalanceText.setAlpha(1);
        this.slotsBalanceText.clearTint();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
