/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../shared/prefabs/Text";
import LootBoxIcon from "../../../shared/prefabs/loot-box/LootBoxIcon";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import { getLootBoxInfo } from "@/lib/api/telegram-backend/methods/loot-box";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class LootBox extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 176, y ?? 70);

        // experienceBar
        const experienceBar = scene.add.sprite(-1, 1, "yellow_progress_bar", "yellow_spin_prgoress_f_00000.png");
        this.add(experienceBar);

        // progress
        const progress = new Text(scene, 22, 1);
        progress.text = "0 %";
        progress.setStyle({ fontSize: "18px" });
        this.add(progress);

        // lootBoxIcon
        const lootBoxIcon = new LootBoxIcon(scene, 145, -25);
        lootBoxIcon.scaleX = 1.1250000002547207;
        lootBoxIcon.scaleY = 1.1250000002547207;
        this.add(lootBoxIcon);

        this.experienceBar = experienceBar;
        this.progress = progress;
        this.lootBoxIcon = lootBoxIcon;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private experienceBar: Phaser.GameObjects.Sprite;
    private progress: Text;
    private lootBoxIcon: LootBoxIcon;

    /* START-USER-CODE */

    async init() {
        await this.syncWithServer();
        // We sync the loot box icon after calling updateProgressFromBet so no need to sync it again
        this.lootBoxIcon.init({ skipSync: true });
        this.lootBoxIcon.on(CUSTOM_EVENTS.SYNC_USER, () => {
            this.emit(CUSTOM_EVENTS.SYNC_USER);
        });
    }

    public disableLootBoxIconInteractive() {
        this.lootBoxIcon.disableIconInteractive();
    }

    public enableLootBoxIconInteractive() {
        this.lootBoxIcon.enableIconInteractive();
    }

    getProgress() {
        return Number(this.progress.text.replace(" %", ""));
    }

    async syncWithServer() {
        const lootBoxInfo = await getLootBoxInfo();
        if (lootBoxInfo) {
            const progressIncrement = Math.min(
                (lootBoxInfo.totalAccumulatedCoins / lootBoxInfo.coinsRequiredPerLootBox) * 100,
                100,
            );
            this.updateProgress(progressIncrement);
            this.lootBoxIcon.setBoxNumber(lootBoxInfo.totalLootBox);
        }
    }

    private updateProgressBarFrame(progress: number) {
        const imageNumber = Math.floor((progress / 100) * 49);
        const paddedNumber = imageNumber.toString().padStart(2, "0");
        const imageName = `yellow_spin_prgoress_f_000${paddedNumber}.png`;

        this.experienceBar.setTexture("yellow_progress_bar", imageName);
    }

    private updateProgressTextAndBar(progress: number) {
        const roundedProgress = Math.round(progress);
        this.progress.setText(`${roundedProgress} %`);
        this.updateProgressBarFrame(roundedProgress);
    }

    updateProgress(progress: number) {
        const progressTo = Math.min(progress, 100);
        this.scene.tweens.addCounter({
            from: this.getProgress(),
            to: progressTo,
            duration: 300,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = tween.getValue();
                this.updateProgressTextAndBar(value);
            },
            onComplete: () => {
                this.updateProgressTextAndBar(progressTo);

                if (progressTo === 100) {
                    this.scene.tweens.addCounter({
                        from: 100,
                        to: 0,
                        duration: 300,
                        ease: Phaser.Math.Easing.Linear,
                        onUpdate: (tween) => {
                            const value = tween.getValue();
                            this.updateProgressTextAndBar(value);
                        },
                        onComplete: () => {
                            this.updateProgressTextAndBar(0);
                        },
                    });
                }
            },
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
