// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class JackpotWinningPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // rectangle
        const rectangle = scene.add.rectangle(195, 422, 390, 844);
        rectangle.alpha = 0;
        rectangle.isFilled = true;
        rectangle.fillColor = 10430761;
        this.add(rectangle);

        // bgTransparent
        const bgTransparent = scene.add.image(195, 422, "pick_bonus_winning", "major_bg_transparent.png");
        bgTransparent.alpha = 0;
        bgTransparent.alphaTopLeft = 0;
        bgTransparent.alphaTopRight = 0;
        bgTransparent.alphaBottomLeft = 0;
        bgTransparent.alphaBottomRight = 0;
        this.add(bgTransparent);

        // bg
        const bg = scene.add.image(195, 422, "pick_bonus_winning", "major_bg.png");
        bg.alpha = 0;
        bg.alphaTopLeft = 0;
        bg.alphaTopRight = 0;
        bg.alphaBottomLeft = 0;
        bg.alphaBottomRight = 0;
        this.add(bg);

        // bulletTop
        const bulletTop = scene.add.image(43, 77, "pick_bonus_winning", "bullet.png");
        bulletTop.angle = 65;
        bulletTop.alpha = 0;
        bulletTop.alphaTopLeft = 0;
        bulletTop.alphaTopRight = 0;
        bulletTop.alphaBottomLeft = 0;
        bulletTop.alphaBottomRight = 0;
        this.add(bulletTop);

        // bulletBottom
        const bulletBottom = scene.add.image(344, 761, "pick_bonus_winning", "bullet.png");
        bulletBottom.angle = -115;
        bulletBottom.alpha = 0;
        bulletBottom.alphaTopLeft = 0;
        bulletBottom.alphaTopRight = 0;
        bulletBottom.alphaBottomLeft = 0;
        bulletBottom.alphaBottomRight = 0;
        this.add(bulletBottom);

        // title
        const title = scene.add.image(195, 305, "pick_bonus_winning", "major.png");
        title.alpha = 0;
        title.alphaTopLeft = 0;
        title.alphaTopRight = 0;
        title.alphaBottomLeft = 0;
        title.alphaBottomRight = 0;
        this.add(title);

        // gold
        const gold = scene.add.container(157, 460);
        gold.alpha = 0;
        this.add(gold);

        // sold_item
        const sold_item = scene.add.image(44, 44, "auto_spin", "coin-bag.png");
        sold_item.scaleX = 0.25;
        sold_item.scaleY = 0.25;
        gold.add(sold_item);

        // winAmountText
        const winAmountText = new Text(scene, 44, 91);
        winAmountText.text = "0 GOLD";
        winAmountText.setStyle({
            color: "#F7F5E8",
            fontSize: "28px",
            fontStyle: "bold",
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#939C29",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        gold.add(winAmountText);

        // rms
        const rms = scene.add.container(156, 619);
        rms.alpha = 0;
        this.add(rms);

        // sold_item_1
        const sold_item_1 = scene.add.image(43, 31, "pick_bonus_winning", "rms.png");
        sold_item_1.scaleX = 0.25;
        sold_item_1.scaleY = 0.25;
        rms.add(sold_item_1);

        // goldAmount_1
        const goldAmount_1 = new Text(scene, 44, 91);
        goldAmount_1.text = "100 RMS";
        goldAmount_1.setStyle({
            color: "#FFFFFF",
            fontFamily: "Oxanium",
            fontSize: "28px",
            fontStyle: "bold",
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#6710C6",
            "shadow.stroke": false,
            "shadow.fill": true,
        });
        rms.add(goldAmount_1);

        // coinSplash
        const coinSplash = scene.add.sprite(195, 422, "coin_splash", "Property 1=remiscoin_splash_f_01.png");
        coinSplash.visible = false;
        this.add(coinSplash);

        this.rectangle = rectangle;
        this.bgTransparent = bgTransparent;
        this.bg = bg;
        this.bulletTop = bulletTop;
        this.bulletBottom = bulletBottom;
        this.title = title;
        this.winAmountText = winAmountText;
        this.gold = gold;
        this.goldAmount_1 = goldAmount_1;
        this.rms = rms;
        this.coinSplash = coinSplash;

        /* START-USER-CTR-CODE */
        this.bg.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private rectangle: Phaser.GameObjects.Rectangle;
    private bgTransparent: Phaser.GameObjects.Image;
    private bg: Phaser.GameObjects.Image;
    private bulletTop: Phaser.GameObjects.Image;
    private bulletBottom: Phaser.GameObjects.Image;
    private title: Phaser.GameObjects.Image;
    private winAmountText: Text;
    private gold: Phaser.GameObjects.Container;
    private goldAmount_1: Text;
    private rms: Phaser.GameObjects.Container;
    private coinSplash: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */

    public winAmount: number;
    public winType: "" | "mini" | "minor" | "major" | "grand" | "double_grand" | "upgrade_chance";
    public isAuto: boolean;

    init() {
        this.title.setTexture("pick_bonus_winning", `${this.winType}.png`);
        this.bgTransparent.setTexture("pick_bonus_winning", `${this.winType}_bg_transparent.png`);
        this.bg.setTexture("pick_bonus_winning", `${this.winType}_bg.png`);
    }

    getBackgroundColor() {
        if (this.winType === "mini") return 1727764;
        if (this.winType === "minor") return 5130291;
        if (this.winType === "major") return 10430761;
        if (this.winType === "grand") return 3739521;
        if (this.winType === "double_grand") return 16536867;
        return 10430761;
    }

    async playWinAnimation() {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.bgTransparent,
                alpha: { from: 0, to: 0.8 },
                duration: 500,
                ease: "Power2",
                onComplete: () => resolve(),
            });
        });

        if (this.winType !== "mini") {
            await new Promise<void>((resolve) => {
                this.scene.tweens.add({
                    targets: this.bulletTop,
                    y: 422 - 50,
                    x: 195 - 25,
                    alpha: 1,
                    duration: 500,
                    delay: 250,
                    ease: "Power2",
                });

                this.scene.tweens.add({
                    targets: this.bulletBottom,
                    y: 422 + 50,
                    x: 195 + 25,
                    alpha: 1,
                    duration: 500,
                    delay: 250,
                    ease: "Power2",
                    onComplete: () => resolve(),
                });
            });

            this.bulletBottom.alpha = 0;
            this.bulletTop.alpha = 0;
        }

        this.bgTransparent.alpha = 1;
        this.rectangle.fillColor = this.getBackgroundColor();
        this.rectangle.alpha = 1;

        await new Promise<void>((resolve) => {
            this.scene.add.tween({
                targets: this.bg,
                props: {
                    alpha: {
                        duration: 200,
                        value: 1,
                    },
                    scale: {
                        duration: 400,
                        value: { from: 2.5, to: 1 },
                        ease: "Bounce.easeOut",
                        keyframes: [
                            { scale: 2.5, duration: 0 },
                            { scale: 2, duration: 80 },
                            { scale: 1.5, duration: 80 },
                            { scale: 0.5, duration: 80 },
                            { scale: 0, duration: 80 },
                            { scale: 1, duration: 80 },
                        ],
                    },
                },
                onComplete: () => resolve(),
            });

            this.scene.add.tween({
                targets: this.title,
                props: {
                    alpha: {
                        duration: 100,
                        value: 1,
                    },
                    scale: {
                        duration: 400,
                        value: { from: 0, to: 1 },
                        ease: "Bounce.easeOut",
                    },
                },
                onComplete: () => resolve(),
            });
        });

        EventBus.emit(CUSTOM_EVENTS.JACKPOT_WIN, this.winType);
        this.coinSplash.visible = true;
        this.coinSplash.play("coin_splash");

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.gold,
                y: "-=25",
                alpha: 1,
                duration: 500,
                ease: "Power2",
                onComplete: () => {
                    this.scene.tweens.addCounter({
                        from: 0,
                        to: this.winAmount,
                        duration: 400,
                        ease: Phaser.Math.Easing.Linear,
                        onUpdate: (tween) => {
                            const value = Math.round(tween.getValue());
                            this.winAmountText.setText(`${numberWithCommas(value)} GOLD`);
                        },
                        onComplete: () => {
                            resolve();
                        },
                    });
                },
            });
        });

        this.scene.time.delayedCall(2000, () => {
            this.destroy();
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
