// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class Step11 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // reward_bg_png
        const reward_bg_png = scene.add.image(0, 0, "tutorial", "reward_bg.png");
        this.add(reward_bg_png);

        // text
        const text = new Text(scene, 10, -286);
        text.text = "You Got";
        text.setStyle({
            align: "center",
            color: "#D8E63C",
            fontFamily: "Saiba",
            fontSize: "64px",
        });
        this.add(text);

        // text_1
        const text_1 = new Text(scene, 12, -218);
        text_1.text = "Reward";
        text_1.setStyle({
            align: "center",
            color: "#D8E63C",
            fontFamily: "Saiba",
            fontSize: "64px",
        });
        this.add(text_1);

        // gold
        const gold = scene.add.container(-34, -120);
        gold.alpha = 0;
        this.add(gold);

        // buy_coin_4_1_png
        const buy_coin_4_1_png = scene.add.image(49, 42, "tutorial", "buy-coin-4 1.png");
        gold.add(buy_coin_4_1_png);

        // goldAmount
        const goldAmount = new Text(scene, 48, 94);
        goldAmount.text = "0 GOLD";
        goldAmount.setStyle({
            color: "#E2DEC6",
            fontSize: "36px",
            fontStyle: "bold",
        });
        gold.add(goldAmount);

        // miningRate
        const miningRate = scene.add.container(-30, 56);
        miningRate.alpha = 0;
        this.add(miningRate);

        // buy_coin_4_1_png_1
        const buy_coin_4_1_png_1 = scene.add.image(49, 39, "tutorial", "artifact_3_02 1.png");
        miningRate.add(buy_coin_4_1_png_1);

        // rmxRate
        const rmxRate = new Text(scene, 48, 153);
        rmxRate.text = "0 RMX/MIN";
        rmxRate.setStyle({
            color: "#E2DEC6",
            fontSize: "36px",
            fontStyle: "bold",
        });
        miningRate.add(rmxRate);

        // goldRate
        const goldRate = new Text(scene, 51, 204);
        goldRate.text = "0 GOLD/MIN";
        goldRate.setStyle({
            color: "#E2DEC6",
            fontSize: "36px",
            fontStyle: "bold",
        });
        miningRate.add(goldRate);

        // mining
        const mining = new Text(scene, 49, 105);
        mining.text = "Mining Rate";
        mining.setStyle({
            color: "#E2DEC6",
            fontSize: "24px",
            fontStyle: "bold",
        });
        miningRate.add(mining);

        // closeButton
        const closeButton = new Text(scene, 15, 363);
        closeButton.alpha = 0;
        closeButton.text = "Close in (10s)";
        closeButton.setStyle({
            color: "#E2DEC6",
            fontSize: "20px",
            fontStyle: "bold",
        });
        this.add(closeButton);

        this.goldAmount = goldAmount;
        this.gold = gold;
        this.rmxRate = rmxRate;
        this.goldRate = goldRate;
        this.miningRate = miningRate;
        this.closeButton = closeButton;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private goldAmount: Text;
    private gold: Phaser.GameObjects.Container;
    private rmxRate: Text;
    private goldRate: Text;
    private miningRate: Phaser.GameObjects.Container;
    private closeButton: Text;

    /* START-USER-CODE */

    showCountDownText() {
        const countdownSeconds = 10;
        let currentSeconds = countdownSeconds;

        const updateText = () => {
            if (currentSeconds > 0) {
                this.closeButton.text = `Close in (${currentSeconds}s)`;
                currentSeconds--;
            } else {
                this.handleClose();
                return;
            }
        };

        // Set initial text
        updateText();

        // Create timer event that fires every second
        this.scene.time.addEvent({
            delay: 1000,
            repeat: countdownSeconds,
            callback: updateText,
        });
    }

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.gold,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 500,
                onComplete: () => resolve(),
            });
        });

        this.scene.tweens.addCounter({
            from: 0,
            to: 150000,
            duration: 500,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.goldAmount.setText(`${numberWithCommas(value)} GOLD`);
            },
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.miningRate,
                alpha: { from: 0, to: 1 },
                duration: 500,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        const user = this.scene.userManager.getData();

        this.rmxRate.setText(`${user?.miningRate?.rmx || 0} RMX/MIN`);
        this.goldRate.setText(`${user?.miningRate?.otaGold || 0} GOLD/MIN`);

        try {
            await Promise.all([
                this.scene.userManager.syncWithBackend(),
                this.scene.chapterManager.syncWithBackend({
                    chapter: 1,
                    bossLevel: 1,
                }),
            ]);
        } catch (error) {}

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.closeButton,
                alpha: { from: 0, to: 1 },
                duration: 500,
                onComplete: () => resolve(),
            });
        });

        this.showCountDownText();
        new PushComponent(this.closeButton, this.handleClose, this).activate();
    }

    handleClose() {
        // this.scene.tutorial.destroy();
        this.scene.scene.start("MiningGame");
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
