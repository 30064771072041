import { Scene } from "phaser";
import FirstTimePurchase from "@/shared/prefabs/telegram-star/FirstTimePurchase";
import FreeBonus from "@/shared/prefabs/telegram-star/FreeBonus";
import { getActivePopupsForUser, PopupResponse } from "@/lib/api/telegram-backend/methods/popup";
import { getActiveFreeBonusForUser } from "@/lib/api/telegram-backend/methods/free-bonus";

export class PopupManager {
    private scene: Scene;
    private activePopup: boolean = false;

    constructor(scene: Scene) {
        this.scene = scene;
    }

    async checkActivePopups(
        onBalanceSync?: () => void,
        { skipFreeBonus }: { skipFreeBonus: boolean } = { skipFreeBonus: false },
    ) {
        const popups = await getActivePopupsForUser();
        if (popups && popups.length > 0) {
            const now = Date.now();

            const validPopups = popups.filter((popup) => now >= popup.startDate && now <= popup.endDate);

            for (const popup of validPopups) {
                if (this.activePopup) break;

                if (popup.type === "iap") {
                    await this.handleIAPPopup(popup, onBalanceSync);
                }
            }
        }

        if (!this.activePopup && !skipFreeBonus) {
            await this.handleFreeBonus(onBalanceSync);
        }
    }

    private async handleFreeBonus(onBalanceSync?: () => void): Promise<void> {
        const freeBonus = await getActiveFreeBonusForUser();

        if (!freeBonus || !freeBonus.isFreeBonus || this.activePopup) return;

        this.activePopup = true;

        const freeBonusPopup = new FreeBonus(this.scene, 0, 0);
        this.scene.add.existing(freeBonusPopup);

        const success = await freeBonusPopup.show(freeBonus);
        if (success) {
            onBalanceSync?.();
        }
        this.activePopup = false;
    }

    private async handleIAPPopup(popup: PopupResponse, onBalanceSync?: () => void): Promise<void> {
        if (popup.paymentConfig == null) return;

        return new Promise((resolve) => {
            this.activePopup = true;

            const firstTimePurchasePopup = new FirstTimePurchase(this.scene, 0, 0);
            firstTimePurchasePopup.setDisplayData(popup);
            firstTimePurchasePopup.fadeIn();
            this.scene.add.existing(firstTimePurchasePopup);

            const cleanup = () => {
                firstTimePurchasePopup.fadeOut(500, () => {
                    firstTimePurchasePopup.destroy();
                    this.activePopup = false;
                    resolve();
                });
            };

            firstTimePurchasePopup.once("close", cleanup);

            firstTimePurchasePopup.once("purchase", (rewardAmount: number) => {
                onBalanceSync?.();
                cleanup();
            });
        });
    }

    private extractNumbers(input: string): [number, number] | undefined {
        const matches = input.match(/\d+/g);
        if (matches && matches.length >= 2) {
            const num1 = parseInt(matches[0], 10);
            const num2 = parseInt(matches[1], 10);
            return [num1, num2];
        }
        return;
    }
}
