import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { createTelegramStarInvoice, getTelegramStarPaymentResult } from "@/lib/api/telegram-backend/methods";
import { openInvoice } from "@/utils/open-invoice";
import { PopupResponse } from "@/lib/api/telegram-backend/methods/popup";
import { appLogger } from "@/utils/logger";
import { showAlert } from "@/lib/telegram/web-app";

const logger = appLogger.withNamespace("[shared/prefabs/telegram-star/FirstTimePurchase]");

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class FirstTimePurchase extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // background
        const background = scene.add.image(195, 425, "telegram_star-0", "1st-time-deal-background.png");
        background.scaleY = 1.1437980563420587;
        this.add(background);

        // hot_deal
        const hot_deal = scene.add.image(199, 210, "telegram_star-0", "hot-deal.png");
        this.add(hot_deal);

        // _1st_time_deal
        const _1st_time_deal = scene.add.image(194, 307, "telegram_star-0", "1st-time-deal.png");
        this.add(_1st_time_deal);

        // textContainer
        const textContainer = scene.add.container(122, 380);
        this.add(textContainer);

        // newValue
        const newValue = scene.add.container(108, 41);
        textContainer.add(newValue);

        // newValueText
        const newValueText = scene.add.text(-5, 13, "", {});
        newValueText.setOrigin(0, 0.5);
        newValueText.text = "0";
        newValueText.setStyle({
            "align": "center",
            "color": "#FFD404",
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "stroke": "#000",
            "strokeThickness": 2,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        newValueText.setPadding({ "right": 2 });
        newValue.add(newValueText);

        // newValueStar
        const newValueStar = scene.add.image(28, 11, "telegram_star-0", "star.png");
        newValueStar.scaleX = 0.5903830210024983;
        newValueStar.scaleY = 0.5903830210024983;
        newValue.add(newValueStar);

        // text_2
        const text_2 = scene.add.text(0, 39, "", {});
        text_2.text = "now only";
        text_2.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        textContainer.add(text_2);

        // text_1
        const text_1 = scene.add.text(70, 0, "", {});
        text_1.text = "value";
        text_1.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        textContainer.add(text_1);

        // originalValue
        const originalValue = scene.add.container(22, 1);
        textContainer.add(originalValue);

        // originalValueText
        const originalValueText = scene.add.text(12, 16, "", {});
        originalValueText.setOrigin(1, 0.5);
        originalValueText.text = "0";
        originalValueText.setStyle({
            "align": "center",
            "color": "#FFD404",
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        originalValue.add(originalValueText);

        // star
        const star = scene.add.image(28, 13.12933349609375, "telegram_star-0", "star.png");
        star.scaleX = 0.5903830210024983;
        star.scaleY = 0.5903830210024983;
        originalValue.add(star);

        // strikethrough
        const strikethrough = scene.add.rectangle(38, 15.13, 50, 12);
        strikethrough.scaleX = 0.9061744908145637;
        strikethrough.scaleY = 0.19094227277155187;
        strikethrough.setOrigin(1, 0.5);
        strikethrough.isFilled = true;
        originalValue.add(strikethrough);

        // button
        const button = scene.add.container(186, 585);
        this.add(button);

        // purchaseButton
        const purchaseButton = scene.add.image(9, 14, "telegram_star-0", "green-button.png");
        purchaseButton.scaleX = 0.35483333156640307;
        purchaseButton.scaleY = 0.35483333156640307;
        button.add(purchaseButton);

        // star_4
        const star_4 = scene.add.image(0, 11, "telegram_star-0", "star.png");
        star_4.scaleX = 0.5903830210024983;
        star_4.scaleY = 0.5903830210024983;
        button.add(star_4);

        // purchaseAmountText
        const purchaseAmountText = scene.add.text(13, 0, "", {});
        purchaseAmountText.text = "0";
        purchaseAmountText.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        button.add(purchaseAmountText);

        // descriptor
        const descriptor = scene.add.container(109, 647);
        this.add(descriptor);

        // star_2
        const star_2 = scene.add.image(19, 13, "telegram_star-0", "star.png");
        star_2.scaleX = 0.3791651151355213;
        star_2.scaleY = 0.3791651151355213;
        descriptor.add(star_2);

        // newValueText_1
        const newValueText_1 = scene.add.text(32, 6, "", {});
        newValueText_1.text = ": is a Telegram Star";
        newValueText_1.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "14px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        newValueText_1.setLineSpacing(1);
        descriptor.add(newValueText_1);

        // newValueText_2
        const newValueText_2 = scene.add.text(0, 0, "", {});
        newValueText_2.text = "*";
        newValueText_2.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "14px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 2,
            "shadow.blur": 1,
        });
        newValueText_2.setLineSpacing(1);
        descriptor.add(newValueText_2);

        // coinBar
        const coinBar = scene.add.container(195, 496);
        this.add(coinBar);

        // coins_bar
        const coins_bar = scene.add.image(0, 0, "telegram_star-0", "coins-bar.png");
        coinBar.add(coins_bar);

        // coin
        const coin = scene.add.image(-134, 18.416000366210938, "telegram_star-0", "coin.png");
        coinBar.add(coin);

        // rewardGoldAmount
        const rewardGoldAmount = scene.add.text(-1, 17, "", {});
        rewardGoldAmount.setOrigin(0.5, 0.5);
        rewardGoldAmount.text = "0";
        rewardGoldAmount.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "36px",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 4,
            "shadow.blur": 1,
        });
        rewardGoldAmount.setLineSpacing(1);
        coinBar.add(rewardGoldAmount);

        // closeButton
        const closeButton = scene.add.text(347, 19, "", {});
        closeButton.text = "[x]";
        closeButton.setStyle({ "fontFamily": "Oxanium", "fontSize": "24px" });
        this.add(closeButton);

        this.newValueText = newValueText;
        this.newValueStar = newValueStar;
        this.originalValueText = originalValueText;
        this.star = star;
        this.strikethrough = strikethrough;
        this.purchaseButton = purchaseButton;
        this.purchaseAmountText = purchaseAmountText;
        this.rewardGoldAmount = rewardGoldAmount;
        this.closeButton = closeButton;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);

        // Make the background image interactive to block clicks
        background.setInteractive();

        this.purchaseButton.setInteractive({ useHandCursor: true }).on("pointerdown", this.handlePurchase);
        this.closeButton.setInteractive({ useHandCursor: true }).on("pointerdown", this.handleClose);
        /* END-USER-CTR-CODE */
    }

    private newValueText: Phaser.GameObjects.Text;
    private newValueStar: Phaser.GameObjects.Image;
    private originalValueText: Phaser.GameObjects.Text;
    private star: Phaser.GameObjects.Image;
    private strikethrough: Phaser.GameObjects.Rectangle;
    private purchaseButton: Phaser.GameObjects.Image;
    private purchaseAmountText: Phaser.GameObjects.Text;
    private rewardGoldAmount: Phaser.GameObjects.Text;
    private closeButton: Phaser.GameObjects.Text;

    /* START-USER-CODE */
    private paymentConfigId: string;
    private telegramStarInvoicePollingTimer: ReturnType<typeof setInterval> | undefined;

    setDisplayData(popup: PopupResponse) {
        const { paymentConfig } = popup;
        if (!paymentConfig || !paymentConfig.metadata) return;

        const originalStarValue = paymentConfig.metadata.originalPrice;
        if (!originalStarValue) return;
        const newStarValue = paymentConfig.price;
        const rewardGoldAmount = paymentConfig.rewardValue;

        this.originalValueText.setText(originalStarValue.toString());
        this.originalValueText.setOrigin(1, 0.5);
        const textWidth = this.originalValueText.width;
        const totalWidth = textWidth + this.star.width * 0.62;
        this.strikethrough.width = totalWidth;
        this.strikethrough.setOrigin(1, 0.5);
        this.strikethrough.setX(this.star.x + this.star.width * 0.2);

        this.newValueText.setText(newStarValue.toString());
        this.newValueStar.setX(this.newValueText.width + 4);

        this.rewardGoldAmount.setText(`${numberWithCommas(rewardGoldAmount)}`);

        this.purchaseAmountText.setText(newStarValue.toString());
        this.paymentConfigId = paymentConfig.id;
    }

    fadeIn(duration = 500) {
        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            duration: duration,
            ease: "Power2",
        });
    }

    fadeOut(duration = 500, onComplete?: () => void) {
        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            duration: duration,
            ease: "Power2",
            onComplete,
        });
    }

    private handlePollTelegramStarInvoice(transactionId: string) {
        const pollingInterval = 3000;

        if (this.telegramStarInvoicePollingTimer) {
            clearInterval(this.telegramStarInvoicePollingTimer);
        }

        this.telegramStarInvoicePollingTimer = setInterval(async () => {
            try {
                const paymentResult = await getTelegramStarPaymentResult(transactionId);

                if (paymentResult && paymentResult.status === "success") {
                    this.handleTelegramStarPaymentSuccess(paymentResult.otaGold);
                    this.clearTelegramStarInvoicePollingTimer();
                } else if (paymentResult && paymentResult.status === "failed") {
                    this.handleTelegramStarPaymentFailure();
                    this.clearTelegramStarInvoicePollingTimer();
                }
            } catch (error) {
                logger.error("[handlePollTelegramStarInvoice] Polling error:", error);
            }
        }, pollingInterval);
    }

    private handleTelegramStarPaymentSuccess(rewardAmount: number) {
        this.emit("purchase", rewardAmount);
        showAlert(`Payment successful! You now have ${numberWithCommas(rewardAmount)} OTA GOLD.`);
    }

    private handleTelegramStarPaymentFailure() {
        showAlert("Payment failed. Please try again.");
    }

    private clearTelegramStarInvoicePollingTimer() {
        if (this.telegramStarInvoicePollingTimer) {
            clearInterval(this.telegramStarInvoicePollingTimer);
            this.telegramStarInvoicePollingTimer = undefined;
        }
    }

    handlePurchase = async () => {
        const confirmed = confirm("Do you want to proceed with this purchase?");
        if (!confirmed) {
            return;
        }

        showAlert("Purchasing... press OK to continue");

        const result = await createTelegramStarInvoice({
            itemId: this.paymentConfigId,
        });

        if (!result) {
            return;
        }

        const { invoiceLink, transactionId } = result;
        openInvoice(invoiceLink);
        this.handlePollTelegramStarInvoice(transactionId);
    };

    handleClose = () => {
        this.emit("close");
    };

    destroy() {
        this.clearTelegramStarInvoicePollingTimer();
        super.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
