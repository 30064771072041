// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class BossLevelItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195.4857175160715, y ?? 136.4776159843486);

        // level
        const level = scene.add.image(0, 0, "boss_level", "level.png");
        this.add(level);

        this.level = level;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private level: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    public bossLevel: number;

    updateStatus() {
        const chapterData = this.scene.userManager.getChapterData();
        if (chapterData.bossLevel > this.bossLevel) {
            this.level.setTexture("boss_level", `level_done.png`);
        }
        if (chapterData.bossLevel === this.bossLevel) {
            this.level.setTexture("boss_level", `level_active.png`);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
