// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import PizzaOrange from "./PizzaOrange";
import PizzaGold from "./PizzaGold";
/* START-USER-IMPORTS */
import {
    doLuckyWheel,
    getLuckyWheel,
    LuckyWheelResponse,
    WheelPiece,
} from "@/lib/api/telegram-backend/methods/lucky-wheel";
import { WheelComponent } from "../components/WheelComponent";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class WheelGold extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 197, y ?? 392);

        // wheelContainer
        const wheelContainer = scene.add.container(0, 0);
        this.add(wheelContainer);

        // pizza_1
        const pizza_1 = new PizzaOrange(scene, 78.02869480927708, 53.23555322556615);
        pizza_1.angle = 90;
        wheelContainer.add(pizza_1);

        // pizza_2
        const pizza_2 = new PizzaGold(scene, 17.028704753895052, 92.24);
        pizza_2.angle = 135;
        wheelContainer.add(pizza_2);

        // pizza_3
        const pizza_3 = new PizzaOrange(scene, -52.971295246104944, 77.24);
        pizza_3.angle = -180;
        wheelContainer.add(pizza_3);

        // pizza_4
        const pizza_4 = new PizzaGold(scene, -90.97129524610494, 16.240000000000002);
        pizza_4.angle = -135;
        wheelContainer.add(pizza_4);

        // pizza_5
        const pizza_5 = new PizzaOrange(scene, -75.97130519072292, -53.76444677443385);
        pizza_5.angle = -90;
        wheelContainer.add(pizza_5);

        // pizza_6
        const pizza_6 = new PizzaGold(scene, -15.781295246104946, -92);
        pizza_6.angle = -45.00000000000006;
        wheelContainer.add(pizza_6);

        // pizza_7
        const pizza_7 = new PizzaOrange(scene, 54.028694809277084, -77.76);
        wheelContainer.add(pizza_7);

        // pizza_8
        const pizza_8 = new PizzaGold(scene, 92.02869480927708, -16.76444677443385);
        pizza_8.angle = 45;
        wheelContainer.add(pizza_8);

        // wheel
        const wheel = scene.add.container(-0.15565285938757256, -2.52939887178826);
        this.add(wheel);

        // aqua_wheel_png
        const aqua_wheel_png = scene.add.image(1.1556528593875726, 2.52939887178826, "lucky_wheel", "wheel_gold.png");
        wheel.add(aqua_wheel_png);

        // flash
        const flash = scene.add.sprite(
            1.1556528593875726,
            3.52939887178826,
            "wheel_animation",
            "Property 1=bulbs_flash_f_01.png",
        );
        wheel.add(flash);

        // pointer
        const pointer = scene.add.image(0, -176, "lucky_wheel", "pointer_gold.png");
        this.add(pointer);

        // pin
        const pin = scene.add.image(0, 18, "lucky_wheel", "royal_center_pin_gold.png");
        this.add(pin);

        // arrow_png
        const arrow_png = scene.add.image(102, -108, "lucky_wheel", "arrow_gold.png");
        this.add(arrow_png);

        this.pizza_1 = pizza_1;
        this.pizza_2 = pizza_2;
        this.pizza_3 = pizza_3;
        this.pizza_4 = pizza_4;
        this.pizza_5 = pizza_5;
        this.pizza_6 = pizza_6;
        this.pizza_7 = pizza_7;
        this.pizza_8 = pizza_8;
        this.wheelContainer = wheelContainer;
        this.flash = flash;
        this.wheel = wheel;
        this.pointer = pointer;
        this.pin = pin;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private pizza_1: PizzaOrange;
    private pizza_2: PizzaGold;
    private pizza_3: PizzaOrange;
    private pizza_4: PizzaGold;
    private pizza_5: PizzaOrange;
    private pizza_6: PizzaGold;
    private pizza_7: PizzaOrange;
    private pizza_8: PizzaGold;
    private wheelContainer: Phaser.GameObjects.Container;
    private flash: Phaser.GameObjects.Sprite;
    private wheel: Phaser.GameObjects.Container;
    private pointer: Phaser.GameObjects.Image;
    private pin: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    public static EVENTS = {
        SPIN_DONE: "spin_done",
        RESET_SPIN_DONE: "reset_spin_done",
    };

    private idleTween?: Phaser.Tweens.Tween;
    luckyWheel: LuckyWheelResponse;
    pizzas: (PizzaOrange | PizzaGold)[] = [];
    pieces: WheelPiece[] = [];

    init(luckyWheel: LuckyWheelResponse) {
        if (!luckyWheel) return;
        this.luckyWheel = luckyWheel;

        this.setupPieces();

        const wheelComponent = new WheelComponent(this.scene, this.wheelContainer, this.pointer, this.pin);

        this.wheelContainer.bringToTop(this.pin);
        this.pin.removeAllListeners();
        this.pin.setInteractive({ useHandCursor: true });
        this.pin.on("pointerdown", async () => {
            if (!this.luckyWheel || !this.luckyWheel.init || wheelComponent.wheelSpinning) return;

            if (this.idleTween) {
                this.idleTween.stop();
                this.idleTween.destroy();
                this.idleTween = undefined;
            }

            EventBus.emit(CUSTOM_EVENTS.SPIN_WHEEL);
            wheelComponent.wheelSpinning = true;
            const clearMockSpin = wheelComponent.mockSpinWheel();
            const claimLuckyWheel = await doLuckyWheel(this.luckyWheel.spinId);

            if (!claimLuckyWheel) {
                clearMockSpin();
                wheelComponent.wheelSpinning = false;
                return;
            }

            const currentLuckyWheel = this.luckyWheel;

            clearMockSpin();

            const sliceIndex = this.pieces.findIndex((p) => p.id === this.luckyWheel.rewardReel.id);
            await wheelComponent.spinWheel(sliceIndex);
            if (typeof sliceIndex === "number" && this.pizzas[sliceIndex]) {
                this.pizzas[sliceIndex].highlight();
            }

            this.emit(WheelGold.EVENTS.SPIN_DONE, "special", claimLuckyWheel, currentLuckyWheel);

            this.scene.time.delayedCall(5000, async () => {
                this.pizzas.forEach((pizza) => {
                    pizza.reset();
                });
                await wheelComponent.resetWheel();
                wheelComponent.wheelSpinning = false;
                this.emit(WheelGold.EVENTS.RESET_SPIN_DONE, true);
            });
        });
    }

    playFlash() {
        return new Promise((resolve) => {
            this.flash.play("wheel_flash");
            this.flash.once("animationcomplete", resolve);
        });
    }

    playIdle() {
        this.idleTween = this.scene.tweens.add({
            targets: this.wheel,
            angle: "+=10",
            duration: 1000,
            ease: "Sine.easeInOut",
            yoyo: true,
            repeat: -1,
        });
    }

    setupPieces() {
        this.pieces = this.luckyWheel.reel;

        this.pizzas = [
            this.pizza_1,
            this.pizza_2,
            this.pizza_3,
            this.pizza_4,
            this.pizza_5,
            this.pizza_6,
            this.pizza_7,
            this.pizza_8,
        ];

        this.pieces.forEach((piece, index) => {
            const pizza = this.pizzas[index];
            pizza.setPiece(piece);
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
