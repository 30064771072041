import { Scene } from "phaser";
import { gamePerformanceConfig } from "@/game.performance";

export default class Boot extends Scene {
    constructor() {
        super("WheelBoot");
    }

    preload() {
        //  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
        //  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.

        this.load.pack("wheel-boot-asset-pack", "fortune-wheel/wheel-boot-asset-pack.json");
    }

    init() {
        gamePerformanceConfig.initialize();
    }

    create() {
        this.scene.start("PreLoaderWheel");
    }
}
