/* START OF COMPILED CODE */

import Phaser from "phaser";
import BottomConsole from "../prefab/mining/BottomConsole";
import BossLevel from "../prefab/boss/BossLevel";
import TopConsole from "../prefab/mining/TopConsole";
import BoostMining from "../prefab/boss/BoostMining";
/* START-USER-IMPORTS */
import Player from "../prefab/Player";
import Creep from "../prefab/Creep";
import { CUSTOM_EVENTS, EventBusComponent } from "../components/events/EventBusComponent";
import { CreepSpawnerComponent } from "../components/spawners/CreepSpawnerComponent";
import { CreepDestroyedComponent } from "../components/spawners/CreepDestroyedComponent";
import * as CONFIG from "../config";
import CollectMaterials from "../prefab/mining/CollectMaterials";
import { getStoragePercent } from "@/ota-mining/utils/get-storage-percentage";
import ClaimPopup from "../prefab/ClaimPopup";
import PushComponent from "../components/animations/PushComponent";
import { generateReferralLink, shareReferralLink } from "@/ota-mining/utils/referral-link";
import { CreepConfig } from "../chapters";
import { EventBus } from "@/lib/event-bus";
import Missions from "@/shared/prefabs/missions/Missions";
import { PopupManager } from "@/shared/managers/PopupManager";
import { MAX_CHAPTER } from "@/shared/registry/chapter";
import { addToHomeScreen, checkHomeScreenStatus, onAddedToHomeScreen } from "@/lib/telegram/web-app";
import InviteAFriendDisplay from "@/shared/prefabs/referral/InviteAFriendDisplay";
import { trackButtonTap, BUTTON_NAMES, SCREEN_NAMES, setupScreenTracking } from "@/services/analytics";
/* END-USER-IMPORTS */

export default class Game extends Phaser.Scene {
    constructor() {
        super("MiningGame");

        /* START-USER-CTR-CODE */
        this.eventBusComponent = new EventBusComponent();
        this.popupManager = new PopupManager(this);
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "chapter_01_bg");
        bg.visible = false;

        // bottomConsole
        const bottomConsole = new BottomConsole(this, 195, 425);
        this.add.existing(bottomConsole);

        // bossLevel
        const bossLevel = new BossLevel(this, 195, 68);
        this.add.existing(bossLevel);

        // topConsole
        const topConsole = new TopConsole(this, 195, 81);
        this.add.existing(topConsole);

        // referralSquare
        const referralSquare = this.add.rectangle(159, 97, 0, 0);
        referralSquare.scaleX = 0.3898214945222077;
        referralSquare.scaleY = 0.33209009458699285;
        referralSquare.visible = false;

        // missionSquare
        const missionSquare = this.add.rectangle(277, 97, 0, 0);
        missionSquare.scaleX = 0.3898214945222077;
        missionSquare.scaleY = 0.33209009458699285;
        missionSquare.visible = false;

        // menu
        const menu = this.add.container(29, 28);
        menu.scaleX = 0.9258804405540396;
        menu.scaleY = 0.9258804405540396;

        // settings
        const settings = this.add.image(1, 0, "buttons_interface", "settings_button_01.png");
        settings.scaleX = 0.5;
        settings.scaleY = 0.5;
        menu.add(settings);

        // menuLabel
        const menuLabel = this.add.text(0, 32, "", {});
        menuLabel.setOrigin(0.5, 0.5);
        menuLabel.text = "Menu";
        menuLabel.setStyle({
            fontFamily: "Oxanium",
            fontSize: "10px",
            fontStyle: "bold",
            stroke: "#000",
            strokeThickness: 1,
        });
        menu.add(menuLabel);

        // boostMining
        const boostMining = new BoostMining(this, 352, 95);
        this.add.existing(boostMining);

        // wheelButton
        const wheelButton = this.add.image(56, 188, "earn_now");

        // missionIcon
        const missionIcon = this.add.image(334, 248, "mission");
        missionIcon.scaleX = 0.8;
        missionIcon.scaleY = 0.8;

        // inviteIcon
        const inviteIcon = this.add.image(336, 180, "invite");
        inviteIcon.scaleX = 0.8;
        inviteIcon.scaleY = 0.8;

        // homeShortcutIcon
        const homeShortcutIcon = this.add.image(339, 318, "home_shortcut");
        homeShortcutIcon.visible = false;

        this.bg = bg;
        this.bottomConsole = bottomConsole;
        this.topConsole = topConsole;
        this.referralSquare = referralSquare;
        this.missionSquare = missionSquare;
        this.settings = settings;
        this.boostMining = boostMining;
        this.wheelButton = wheelButton;
        this.missionIcon = missionIcon;
        this.inviteIcon = inviteIcon;
        this.homeShortcutIcon = homeShortcutIcon;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private bottomConsole!: BottomConsole;
    private topConsole!: TopConsole;
    private referralSquare!: Phaser.GameObjects.Rectangle;
    private missionSquare!: Phaser.GameObjects.Rectangle;
    private settings!: Phaser.GameObjects.Image;
    private boostMining!: BoostMining;
    private wheelButton!: Phaser.GameObjects.Image;
    private missionIcon!: Phaser.GameObjects.Image;
    private inviteIcon!: Phaser.GameObjects.Image;
    private homeShortcutIcon!: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private player!: Player;
    private creep!: Creep;
    private creepSpawner!: CreepSpawnerComponent;
    private disableAnimation: boolean = false;
    private step?: string;
    public eventBusComponent: EventBusComponent;
    private missionsPopup?: Missions;
    private invitePopup?: InviteAFriendDisplay;
    private popupManager: PopupManager;

    get creepConfig(): CreepConfig {
        return this.chapterManager.getData()!.creep;
    }

    init() {
        setupScreenTracking(this);
        const step = this.userManager.getIntroStep();
        if (!this.userManager.isTutorialCompleted()) {
            this.tutorial.init(step as string);
        }
        this.step = step;
    }

    create() {
        this.editorCreate();
        this.setupBackground();
        // this.checkActivePopups();
        this.setupSettings();
        this.setupInviteIcon();
        this.setupMissionsIcon();
        this.setupRemisWheelButton();
        this.setupHomeShortcutIcon();
        this.setupPlayer();
        this.updateMining();
        this.updateBoostMining();
        this.setupDisableAnimation();

        this.bottomConsole.on(CUSTOM_EVENTS.SYNC_USER, async () => {
            const updatedUser = await this.userManager.syncWithBackend();
            if (!updatedUser) return;
            this.topConsole.syncUserBalances();
        });

        // Stop any existing audio before playing mining background
        this.sys.game.audio.stopAllAudio();
        EventBus.emit(CUSTOM_EVENTS.PLAY_MINING_BG);

        // Listen for scene destroy event
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.shutdown();
        });

        return;
    }

    startMining() {
        this.setupPlayer();
        this.handleStartMiningAnimation();
    }

    // async checkActivePopups() {
    //     await this.popupManager.checkActivePopups(
    //         () => {
    //             this.topConsole.syncUserBalances();
    //         },
    //         { skipFreeBonus: true },
    //     );
    // }

    setupInviteIcon() {
        const user = this.userManager.getData();
        if (!user) return;

        new PushComponent(
            this.inviteIcon,
            () => {
                trackButtonTap(BUTTON_NAMES.REFERRAL_ICON, SCREEN_NAMES.MINING);
                // this.handleReferralClick(user.referralCode);

                if (this.invitePopup) {
                    this.invitePopup.setVisible(true);
                    return;
                }

                this.invitePopup = new InviteAFriendDisplay(this, 0, 0);
                this.invitePopup.setUserReferralCode(user.referralCode);
                this.invitePopup.show();
                this.add.existing(this.invitePopup);
            },
            this,
        ).activate();
    }

    setupMissionsIcon() {
        new PushComponent(this.missionIcon, () => {
            trackButtonTap(BUTTON_NAMES.MISSION_ICON, SCREEN_NAMES.MINING);
            if (this.missionsPopup) {
                this.missionsPopup.setVisible(true);
                return;
            }

            this.missionsPopup = new Missions(this, 195, 99);
            this.missionsPopup.on(CUSTOM_EVENTS.SYNC_USER, async () => {
                const updatedUser = await this.userManager.syncWithBackend();
                if (!updatedUser) return;
                this.topConsole.syncUserBalances();
            });
            this.add.existing(this.missionsPopup);
        }).activate();
    }

    async setupHomeShortcutIcon() {
        const status = await checkHomeScreenStatus();
        if (status === "unsupported" || status === "added") {
            this.homeShortcutIcon.setVisible(false);
            return;
        }

        this.homeShortcutIcon.setVisible(true);

        new PushComponent(
            this.homeShortcutIcon,
            () => {
                addToHomeScreen();
            },
            this,
        ).activate();

        onAddedToHomeScreen(() => {
            this.homeShortcutIcon.setVisible(false);
        });
    }

    handleReferralClick(userRefCode: string) {
        const referralLink = generateReferralLink(userRefCode);

        shareReferralLink(referralLink);
    }

    shutdown() {
        // Clean up any resources
        this.eventBusComponent.removeAllListeners();
        this.eventBusComponent.destroy();

        if (this.missionsPopup) {
            this.missionsPopup.destroy();
            this.missionsPopup = undefined;
        }

        if (this.invitePopup) {
            this.invitePopup.destroy();
            this.invitePopup = undefined;
        }
    }

    updateBoostMining() {
        const miningBoss = this.miningBossManager.getData();
        if (miningBoss) {
            this.boostMining.updateBossFightStatus();
        }
    }

    async updateMining() {
        const user = this.userManager.getData();
        if (!user) return;

        this.topConsole.init(this.eventBusComponent);

        const now = Date.now();

        const { storagePercent } = getStoragePercent(user.lastClaimedAt, now);

        if (storagePercent >= 100) {
            this.handleCollectMaterials();
            return;
        }

        this.bottomConsole.init(this.eventBusComponent);

        if (this.settingsManager.getData()?.effect) {
            this.handleStartMiningAnimation();
        } else {
            this.handleDestroyMiningAnimation();
        }
    }

    handleStartMiningAnimation() {
        this.disableAnimation = false;
        const player = this.player;
        player.startMining();

        const creepSpawner = new CreepSpawnerComponent(
            this,
            player,
            Creep,
            {
                interval: CONFIG.CREEP_GROUP_SPAWN_INTERVAL,
                spawnAt: CONFIG.CREEP_GROUP_SPAWN_START,
                spawnCount: CONFIG.CREEP_GROUP_SPAWN_MAX_COUNT,
                position: this.creepConfig.position,
            },
            this.eventBusComponent,
        );
        this.creepSpawner = creepSpawner;
        new CreepDestroyedComponent(this, this.eventBusComponent);

        // creep attack player
        this.physics.add.collider(player, creepSpawner.phaserGroup, ((
            playerGameObject: Player,
            creepGameObject: Creep,
        ) => {
            if (!creepGameObject.active || !playerGameObject.active) {
                return;
            }

            creepGameObject.weaponComponent.attackPlayer();
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);

        // player attack creep
        this.physics.add.overlap(player.weaponGameObjectGroup, creepSpawner.phaserGroup, ((
            creepGameObject: Creep,
            projectileGameObject: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody,
        ) => {
            if (!creepGameObject.active) {
                return;
            }
            player.weaponComponent.destroyBullet(projectileGameObject, {
                animationKey: "explosion",
                scale: 0.25,
            });
            creepGameObject.colliderComponent.collideWithEnemyWeapon(player.weaponComponent.damage);
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);
    }

    handleDestroyMiningAnimation() {
        this.disableAnimation = true;
        this.player && this.player.destroy?.();
        this.creepSpawner && this.creepSpawner.destroy?.();
        this.physics.world.colliders.destroy();
    }

    handleCollectMaterials() {
        const collectMaterials = new CollectMaterials(this, 0, 0);
        collectMaterials.setDepth(4);
        this.add.existing(collectMaterials);
        collectMaterials.once("destroy", this.handleClaimPopup, this);
    }

    handleClaimPopup() {
        const popupContainer = new ClaimPopup(this, 0, 0);
        popupContainer.setAlpha(0);
        popupContainer.setDepth(4);
        this.add.existing(popupContainer);
        this.tweens.add({
            targets: popupContainer,
            alpha: { from: 0, to: 1 },
            duration: 300,
            onComplete: () => {
                popupContainer.claimReward();
            },
        });

        popupContainer.once("destroy", () => {
            this.time.delayedCall(500, () => {
                this.updateMining();
            });
        });
    }

    setupBackground() {
        const chapterConfig = this.chapterManager.getData();
        if (chapterConfig) {
            this.bg.setTexture(chapterConfig.background.mining);
            // TODO: FIXME
            if (chapterConfig.id === 0) {
                this.bg.y = 380;
            }
        }

        this.bg.visible = true;
    }

    setupPlayer() {
        const player = new Player(this, 80, 525);
        player.init(this.eventBusComponent);
        player.setDepth(1);
        this.add.existing(player);
        this.player = player;
    }

    setupSettings() {
        new PushComponent(
            this.settings,
            () => {
                this.scene.sleep(this.scene.key);
                this.scene.run("Settings", {
                    fromScene: this.scene.key,
                    onReturn: () => {
                        this.scene.wake(this.scene.key);
                        this.scene.stop("Settings");
                    },
                });
            },
            this,
        ).activate();
    }

    setupRemisWheelButton() {
        new PushComponent(
            this.wheelButton,
            () => {
                trackButtonTap(BUTTON_NAMES.WHEEL_ICON, SCREEN_NAMES.MINING);
                this.scene.start("WheelBoot");
            },
            this,
        ).activate();
    }

    setupDisableAnimation() {
        if (!this.userManager.isTutorialCompleted() && (this.step === "0" || this.step === "1" || this.step === "2")) {
            this.handleDestroyMiningAnimation();
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
