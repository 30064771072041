export const chapter2 = {
    id: 2,
    boss: {
        name: "Ursolf",
        idleAnimationKey: "boss_02_walk",
        walkAnimationKey: "boss_02_walk",
        jumpAnimationKey: "boss_02_walk",
        attackAnimationKey: "boss_02_attack",
        gethitAnimationKey: "boss_02_gethit",
        destroyedAnimationKey: "boss_02_dead",
        size: {
            scale: 0.7,
        },
        position: {
            y: -25,
            attack: {
                y: -50,
            },
        },
        body: {
            offset: {
                x: -60,
                h: -70,
            },
        },
        texture: ["boss_02_walk", "Property 1=f_12.png"],
    },
    creep: {
        walkAnimationKey: "creep_02_walk",
        attackAnimationKey: "creep_02_attack",
        destroyedAnimationKey: "creep_02_die",
        size: {
            scale: 1,
        },
        position: {
            die: {
                x: 100,
            },
        },
        texture: ["creep_02_walk", "frame=F_01.png"],
    },
    background: {
        mining: "chapter_02_bg",
        boss: "chapter_02_bg",
        intro: "chapter_02_intro",
    },
};
