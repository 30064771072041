// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
export type BattleConfirmationPayloadType = {
    type: "battle-confirmation";
    data: {
        chance: number;
        level: number;
        step: string;
    };
};
/* END-USER-IMPORTS */

export default class BattleConfirmation extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 196, y ?? 124);

        // text
        const text = new Text(scene, 17, -35);
        text.scaleX = 1;
        text.scaleY = 1;
        text.text = "Your weapons are powerful enough. Face the Level {level} boss with your {chance}% win chance!";
        text.setStyle({ fixedWidth: 336, fixedHeight: 90 });
        text.setLineSpacing(18);
        text.setWordWrapWidth(336);
        this.add(text);

        // retry
        const retry = scene.add.image(-95, 70, "retry");
        this.add(retry);

        // action
        const action = scene.add.image(92, 70, "fight");
        this.add(action);

        this.text = text;
        this.retry = retry;
        this.action = action;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private text: Text;
    private retry: Phaser.GameObjects.Image;
    private action: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    setContent(content: BattleConfirmationPayloadType["data"]) {
        this.text.text = this.text.text
            .replace("{chance}", content.chance.toString())
            .replace("{level}", content.level.toString());

        this.retry.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            this.emit("retry", content.step);
        });

        this.action.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            this.emit("fight", content.step);
        });
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
