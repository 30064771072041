// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import PushComponent from "../components/animations/PushComponent";
import { getItemDisplayName, getRarityColor, getTotalAverageAttackPower } from "@/shared/utils/loot-box";
import { getItemTextureName } from "@/shared/utils/loot-box";
import { InventoryItem } from "@/shared/types/loot-box";
import Gem from "@/shared/prefabs/loot-box/Gem";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
/* END-USER-IMPORTS */

export default class WeaponPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 421);

        // bg
        const bg = scene.add.rectangle(0, 0, 390, 844);
        bg.isFilled = true;
        bg.fillColor = 0;
        bg.fillAlpha = 0.8;
        this.add(bg);

        // items_popup_png
        const items_popup_png = scene.add.image(0, -22, "boss_charger", "items_popup.png");
        this.add(items_popup_png);

        // closeButton
        const closeButton = scene.add.image(164, -219, "boss_charger", "close_button_hover.png");
        this.add(closeButton);

        // popupTitle
        const popupTitle = new Text(scene, 4, -201);
        popupTitle.text = "Total Attack: ";
        popupTitle.setStyle({ "fontSize": "32px" });
        this.add(popupTitle);

        // weapon
        const weapon = scene.add.image(-76, -124, "loot_box", "magnum.png");
        weapon.scaleX = 0.25;
        weapon.scaleY = 0.25;
        this.add(weapon);

        // weaponRank
        const weaponRank = new Text(scene, 52, -144);
        weaponRank.setOrigin(0, 0);
        weaponRank.text = "N";
        weaponRank.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });
        this.add(weaponRank);

        // weaponLevel
        const weaponLevel = new Text(scene, 52, -125);
        weaponLevel.setOrigin(0, 0);
        weaponLevel.text = "level 0";
        weaponLevel.setStyle({ "fontSize": "12px" });
        this.add(weaponLevel);

        // weaponAttack
        const weaponAttack = new Text(scene, 51, -105);
        weaponAttack.setOrigin(0, 0);
        weaponAttack.text = "Attack: 0";
        weaponAttack.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });
        this.add(weaponAttack);

        // weaponName
        const weaponName = new Text(scene, 52, -163);
        weaponName.setOrigin(0, 0);
        weaponName.text = "Magnum";
        weaponName.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });
        this.add(weaponName);

        // gemContainer
        const gemContainer = scene.add.container(-130, -55);
        this.add(gemContainer);

        this.bg = bg;
        this.popupTitle = popupTitle;
        this.weapon = weapon;
        this.weaponRank = weaponRank;
        this.weaponLevel = weaponLevel;
        this.weaponAttack = weaponAttack;
        this.weaponName = weaponName;
        this.gemContainer = gemContainer;

        /* START-USER-CTR-CODE */
        new PushComponent(
            closeButton,
            () => {
                this.destroy();
            },
            this,
        ).activate();

        const weaponBg = scene.add.graphics();
        weaponBg.fillStyle(0x000000, 0.5);
        weaponBg.lineStyle(1, 0x000000, 0.5);
        weaponBg.fillRoundedRect(40, -170, 140, 84, 8);
        this.addAt(weaponBg, 4);

        const items = scene.userManager.getInventoryItems();
        if (items) {
            const totalAttack = getTotalAverageAttackPower(items);
            this.popupTitle.setText(`Total Attack: ${formatCompactNumber(totalAttack)}`);
        }

        this.setupWeapon();
        this.setupGems();
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Rectangle;
    private popupTitle: Text;
    private weapon: Phaser.GameObjects.Image;
    private weaponRank: Text;
    private weaponLevel: Text;
    private weaponAttack: Text;
    private weaponName: Text;
    private gemContainer: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    setupWeapon() {
        const userWeapon = this.scene.userManager.getWeapon();
        if (userWeapon) {
            const itemTextureName = getItemTextureName(userWeapon);
            if (itemTextureName) this.weapon.setTexture("loot_box", itemTextureName + ".png");
            this.weaponName.text = getItemDisplayName(userWeapon) as string;
            this.weaponRank.text = userWeapon.rarity.toUpperCase();
            this.weaponRank.setStyle({ color: getRarityColor(userWeapon) });
            this.weaponLevel.text = `Level ${userWeapon.level}`;
            this.weaponAttack.text = `Attack: ${userWeapon.minAtk} - ${userWeapon.maxAtk}`;
        }
    }

    setupGems() {
        const fightingEquipments = this.scene.userManager.getInventoryItems();
        if (fightingEquipments) {
            fightingEquipments
                .filter((equipment: InventoryItem) => equipment.name === "gem")
                .sort((a: InventoryItem, b: InventoryItem) => a.slot - b.slot)
                .forEach((equipment: InventoryItem, index: number) => {
                    const itemContainer = new Phaser.GameObjects.Container(this.scene, 0, index * 55);
                    const gem = new Gem(this.scene, 0, 0);
                    gem.init(equipment, {
                        hideLevel: true,
                        hideRank: true,
                    });
                    gem.scale = 0.5;

                    const name = new Text(this.scene, 40, -10);
                    name.setOrigin(0, 0);
                    name.text = getItemDisplayName(equipment) as string;
                    name.setStyle({ fontSize: "12px", color: "#DEA614" });
                    name.setWordWrapWidth(70);
                    name.setLineSpacing(5);

                    const rarityLabel = new Text(this.scene, 120, -10);
                    rarityLabel.text = "Rarity";
                    rarityLabel.setOrigin(0, 0);
                    rarityLabel.setStyle({ fontSize: "8px" });

                    const rarity = new Text(this.scene, 120, 5);
                    rarity.setOrigin(0, 0);
                    rarity.text = equipment.rarity.toUpperCase();
                    rarity.setStyle({
                        fontSize: "12px",
                        color: getRarityColor(equipment),
                    });

                    const levelLabel = new Text(this.scene, 180, -10);
                    levelLabel.text = "Level";
                    levelLabel.setOrigin(0, 0);
                    levelLabel.setStyle({ fontSize: "8px" });

                    const level = new Text(this.scene, 180, 5);
                    level.setOrigin(0, 0);
                    level.text = equipment.level.toString();
                    level.setStyle({ fontSize: "12px", color: "#DEA614" });

                    const attackLabel = new Text(this.scene, 240, -10);
                    attackLabel.text = "Attack";
                    attackLabel.setOrigin(0, 0);
                    attackLabel.setStyle({ fontSize: "8px" });

                    const attack = new Text(this.scene, 240, 5);
                    attack.setOrigin(0, 0);
                    attack.text = `${equipment.minAtk} - ${equipment.maxAtk}`;
                    attack.setStyle({ fontSize: "12px", color: "#DEA614" });

                    itemContainer.add([gem, name, rarityLabel, rarity, levelLabel, level, attackLabel, attack]);
                    this.gemContainer.add(itemContainer);
                });
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
