// You can write more code here

/* START OF COMPILED CODE */

import AutoSpinSelection from "../auto-spin/AutoSpinSelection";
/* START-USER-IMPORTS */
import { trackButtonTap, BUTTON_NAMES, SCREEN_NAMES } from "@/services/analytics";
/* END-USER-IMPORTS */

export default class SpinButton extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // autoSpinSelection
        const autoSpinSelection = new AutoSpinSelection(scene, 195, -228);
        autoSpinSelection.visible = false;
        this.add(autoSpinSelection);

        // autoSpinOptionSelected
        const autoSpinOptionSelected = scene.add.container(159, -2);
        autoSpinOptionSelected.blendMode = Phaser.BlendModes.SKIP_CHECK;
        autoSpinOptionSelected.visible = false;
        this.add(autoSpinOptionSelected);

        // autoSpinOptionSelectedBackground
        const autoSpinOptionSelectedBackground = scene.add.image(35, 35, "auto_spin", "button.png");
        autoSpinOptionSelectedBackground.scaleX = 1.406956688000745;
        autoSpinOptionSelectedBackground.scaleY = 3.9769346571502067;
        autoSpinOptionSelected.add(autoSpinOptionSelectedBackground);

        // autoSpinOptionSelectedText
        const autoSpinOptionSelectedText = scene.add.text(37, -4, "", {});
        autoSpinOptionSelectedText.setOrigin(0.5, 0);
        autoSpinOptionSelectedText.setStyle({ "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        autoSpinOptionSelected.add(autoSpinOptionSelectedText);

        // spin_button_back_png
        const spin_button_back_png = scene.add.image(195, 67, "buttons_interface", "spin_button_back.png");
        spin_button_back_png.scaleX = 0.5;
        spin_button_back_png.scaleY = 0.5;
        this.add(spin_button_back_png);

        // spinButton
        const spinButton = scene.add.sprite(195, 75, "buttons_interface", "spin_button_04.png");
        spinButton.scaleX = 0.5;
        spinButton.scaleY = 0.5;
        this.add(spinButton);

        this.autoSpinSelection = autoSpinSelection;
        this.autoSpinOptionSelectedText = autoSpinOptionSelectedText;
        this.autoSpinOptionSelected = autoSpinOptionSelected;
        this.spinButton = spinButton;

        /* START-USER-CTR-CODE */
        this.setSpinButtonInteractive();
        this.spinButton.on("pointerdown", this.onButtonDown, this);
        this.spinButton.on("pointerup", this.onButtonUp, this);
        /* END-USER-CTR-CODE */
    }

    private autoSpinSelection: AutoSpinSelection;
    private autoSpinOptionSelectedText: Phaser.GameObjects.Text;
    private autoSpinOptionSelected: Phaser.GameObjects.Container;
    private spinButton: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    isHolding: boolean;
    holdTimer: Phaser.Time.TimerEvent | null;
    private autoSpinCount: number = 0;

    public getIsAutoFastEnabled(): boolean {
        return this.autoSpinSelection.getIsAutoFastEnabled();
    }

    clearHoldTimer() {
        if (this.holdTimer) {
            this.holdTimer.remove();
            this.holdTimer = null;
        }
    }

    onButtonDown() {
        if (
            // @ts-ignore
            !this.scene.isSlotsGameReady ||
            !this.scene.userManager.isTutorialCompleted()
        )
            return;
        trackButtonTap(BUTTON_NAMES.SPIN, SCREEN_NAMES.SLOTS);
        // Start a timer to check for hold duration
        this.holdTimer = this.scene.time.addEvent({
            delay: 1000, // Duration to consider it a hold (in milliseconds)
            callback: this.onHoldAction,
            callbackScope: this,
            loop: false,
        });
    }

    onButtonUp() {
        if (this.holdTimer) {
            this.clearHoldTimer();
            if (this.isHolding) {
                this.stopAutoSpin();
                return;
            }
            this.onButtonUpAction();
        }
    }

    onHoldAction() {
        if (this.isHolding) return;
        this.clearHoldTimer();

        this.showAutoSpinMenu();
    }

    private showAutoSpinMenu() {
        if (!this.autoSpinSelection) return;

        this.setSpinButtonUninteractive();

        this.autoSpinSelection.removeAllListeners("option-selected");
        this.autoSpinSelection.on("option-selected", (value: string | undefined) => {
            this.selectAutoSpinOption(value);
            this.hideAutoSpinMenu();
            this.emit("auto-spin-option-selected", this.autoSpinSelection.getIsOptionSelected());
        });

        this.autoSpinSelection.on("hide-auto-spin-menu", () => {
            this.hideAutoSpinMenu();
            this.emit("auto-spin-option-selected", this.autoSpinSelection.getIsOptionSelected());
        });

        this.autoSpinSelection.animateIn();
    }

    private hideAutoSpinMenu() {
        if (!this.autoSpinSelection) return;

        this.autoSpinSelection.animateOut();
        this.setSpinButtonInteractive();
    }

    private clearAutoSpinSelection() {
        this.autoSpinCount = 0;
        this.autoSpinOptionSelected.setVisible(false);
        this.autoSpinSelection.clearAutoSpinSelection();
    }

    private selectAutoSpinOption(value: string | undefined) {
        if (value == null) {
            this.clearAutoSpinSelection();
            return;
        }

        this.autoSpinCount = value === "∞" ? -1 : parseInt(value);

        if (this.autoSpinOptionSelected && this.autoSpinOptionSelectedText) {
            this.autoSpinOptionSelectedText.setText(`AUTO ${value}`);
            this.autoSpinOptionSelected.setVisible(true);
        }

        this.autoSpinSelection.setAutoSpinReady(true);
    }

    private startAutoSpin() {
        this.isHolding = true;
        this.setSpinButtonUninteractive();

        this.spinButton.setTexture("buttons_interface", "spin_button_stop_01.png");

        this.setSpinButtonInteractive();
        this.emit("auto-spin");
    }

    private updateAutoSpinDisplay() {
        if (this.autoSpinOptionSelected && this.autoSpinOptionSelectedText) {
            const shouldShow = this.autoSpinCount > 0 || this.autoSpinCount === -1;
            this.autoSpinOptionSelected.setVisible(shouldShow);

            if (shouldShow) {
                const displayValue = this.autoSpinCount === -1 ? "∞" : this.autoSpinCount.toString();
                this.autoSpinOptionSelectedText.setText(`AUTO ${displayValue}`);
            }
        }
    }

    private stopAutoSpin() {
        this.isHolding = false;
        this.clearAutoSpinSelection();

        this.emit("auto-spin-stop");
        this.spinButton.setTexture("buttons_interface", "spin_button_04.png");
        this.setSpinButtonUninteractive();
    }

    onButtonUpAction() {
        if (this.autoSpinSelection.getIsAutoSpinReady()) {
            this.startAutoSpin();
        } else {
            this.spinButton.play("spin_button_pressed");
            this.scene.time.delayedCall(0, async () => {
                this.setSpinButtonUninteractive();
                this.emit("normal-spin");
            });
        }
    }

    public resetSpinButton() {
        this.setSpinButtonInteractive();
        this.spinButton.stop();
        this.spinButton.setTexture("buttons_interface", "spin_button_01.png");
    }

    public getIsInteractive() {
        return this.spinButton.input?.enabled;
    }

    public setSpinButtonInteractive() {
        this.spinButton.setInteractive();
    }

    public setSpinButtonUninteractive() {
        this.spinButton.disableInteractive();
    }

    public onAutoSpin() {
        if (this.isHolding && this.autoSpinCount > 0) {
            this.autoSpinCount--;
            this.updateAutoSpinDisplay();

            if (this.autoSpinCount === 0) {
                this.stopAutoSpin();
            }
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
