import { gamePerformanceConfig } from "@/game.performance";
import { Scene, GameObjects, Tweens } from "phaser";
import { AnimationConfig } from "../../types/game.performance";

interface AnimationOptions {
    duration?: number;
    skipAnimation?: boolean;
    repeat?: number;
    yoyo?: boolean;
    onComplete?: () => void;
}

interface TweenOptions extends AnimationOptions {
    ease?: string;
    delay?: number;
}

export const getOptimizedAnimConfig = (
    options: AnimationOptions = {},
): (AnimationOptions & AnimationConfig) | undefined => {
    if (!gamePerformanceConfig.shouldPlayAnimation()) {
        return;
    }

    const config = gamePerformanceConfig.getAnimationConfig();
    const duration = options.duration ?? 1000;

    return {
        ...options,
        duration: config.animationMode === "reduced" ? duration / config.speed : duration,
        speed: config.speed,
        skipFrames: config.skipFrames,
        animationMode: config.animationMode,
        skipAnimation: options.skipAnimation ?? false,
    };
};

export const playAnimation = (sprite: GameObjects.Sprite, key: string, options: AnimationOptions = {}) => {
    const config = getOptimizedAnimConfig(options);

    if (!config || config.skipAnimation) {
        // If animations are disabled, show the last frame
        const anim = sprite.scene.anims.get(key);
        if (anim && anim.frames.length > 0) {
            sprite.setFrame(anim.frames[anim.frames.length - 1].frame.name);
        }
        options.onComplete?.();
        return;
    }

    sprite.play({ key, ...config });
};

export const createTween = (
    scene: Scene,
    targets: any,
    tweenConfig: TweenOptions & Record<string, any>,
): Tweens.Tween | undefined => {
    const config = getOptimizedAnimConfig(tweenConfig);

    if (!config || config.skipAnimation) {
        tweenConfig.onComplete?.();
        return;
    }

    // Preserve the original callbacks
    const { onComplete, onRepeat, ...restConfig } = tweenConfig;

    return scene.tweens.add({
        targets,
        ...restConfig,
        duration: config.duration,
        onComplete: function (this: Phaser.Tweens.Tween) {
            onComplete?.call(this);
        },
        onRepeat: function (this: Phaser.Tweens.Tween) {
            onRepeat?.call(this);
        },
    });
};

export const getAnimationDuration = (defaultDuration: number = 1000): number => {
    const config = getOptimizedAnimConfig({ duration: defaultDuration });
    return config ? (config.duration ?? 0) : 0;
};
