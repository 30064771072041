import { openTelegramLink } from "@/lib/telegram/web-app";
import { appLogger } from "@/utils/logger";
import { openLink } from "../open-link";

const logger = appLogger.withNamespace("[utils/missions]");

const TWITTER_USERNAME = "Remis_Idleslot";

const handleTwitterFollow = (): void => {
    try {
        const followWindow = window.open(
            `https://x.com/intent/follow?screen_name=${TWITTER_USERNAME}`,
            "followWindow",
            "width=600,height=600",
        );
        if (!followWindow) {
            throw new Error("Popup window was blocked");
        }
    } catch (error) {
        logger.error("[handleTwitterFollow]", error instanceof Error ? error.message : "Unknown error");
    }
};

const handleChannelSubscribe = (): void => {
    try {
        openTelegramLink(import.meta.env.VITE_TELEGRAM_CHANNEL_URL);
    } catch (error) {
        logger.error("[handleChannelSubscribe]", error instanceof Error ? error.message : "Unknown error");
    }
};

const handleGroupJoin = (): void => {
    try {
        openTelegramLink(import.meta.env.VITE_TELEGRAM_GROUP_URL);
    } catch (error) {
        logger.error("[handleGroupJoin]", error instanceof Error ? error.message : "Unknown error");
    }
};

const handleCoinSaviAirdrop = () => {
    try {
        openLink("https://docs.google.com/forms/d/e/1FAIpQLSeUqxI23vjxsINsns6DFd6ePQiKgMx9ykc9q_GpFojmFTT3Tg/viewform");
    } catch (error) {
        logger.error("[handleCoinSaviAirdrop]", error instanceof Error ? error.message : "Unknown error");
    }
};

const handleAmbassadorProgram = () => {
    try {
        openLink("https://docs.google.com/forms/d/e/1FAIpQLScPEnsmVxDKjWWHTZbgX0aF4YKsCPEf3bz3eWMhlUkBijNr5Q/viewform");
    } catch (error) {
        logger.error("[handleAmbassadorProgram]", error instanceof Error ? error.message : "Unknown error");
    }
};

export { handleTwitterFollow, handleChannelSubscribe, handleGroupJoin, handleCoinSaviAirdrop, handleAmbassadorProgram };
