// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas, parseFormattedNumber } from "@/ota-slots/utils/formatter";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { BetConfig } from "@/ota-slots/utils/total-bet/types";
import { getBetsForLevel, getNewBetIndex, hasNewBets } from "@/ota-slots/utils/total-bet";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[TotalBet]");
/* END-USER-IMPORTS */

export default class TotalBet extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 100, y ?? 88);

        // betMinusIcon
        const betMinusIcon = scene.add.image(31, -3, "buttons_interface", "minus_button_01.png");
        betMinusIcon.scaleX = 0.2;
        betMinusIcon.scaleY = 0.2;
        this.add(betMinusIcon);

        // betPlusIcon
        const betPlusIcon = scene.add.image(150, -3, "buttons_interface", "plus_button_01.png");
        betPlusIcon.scaleX = 0.2;
        betPlusIcon.scaleY = 0.2;
        this.add(betPlusIcon);

        // totalBetText
        const totalBetText = new Text(scene, 89, -2);
        totalBetText.text = "1000";
        totalBetText.setStyle({ "fontSize": "18px" });
        this.add(totalBetText);

        // totalBetLabel
        const totalBetLabel = new Text(scene, 91, -23);
        totalBetLabel.text = "Total Bet";
        totalBetLabel.setStyle({ "fontSize": "14px" });
        this.add(totalBetLabel);

        // unlockedNewBetText
        const unlockedNewBetText = new Text(scene, 94, -45);
        unlockedNewBetText.visible = false;
        unlockedNewBetText.text = "NEW BET UNLOCKED";
        unlockedNewBetText.setStyle({
            "fontSize": "18px",
            "fontStyle": "bold",
            "stroke": "#000",
            "strokeThickness": 2,
        });
        this.add(unlockedNewBetText);

        this.betMinusIcon = betMinusIcon;
        this.betPlusIcon = betPlusIcon;
        this.totalBetText = totalBetText;
        this.unlockedNewBetText = unlockedNewBetText;

        /* START-USER-CTR-CODE */
        this.originalY = this.y;
        this.initialize();
        /* END-USER-CTR-CODE */
    }

    private betMinusIcon: Phaser.GameObjects.Image;
    private betPlusIcon: Phaser.GameObjects.Image;
    private totalBetText: Text;
    private unlockedNewBetText: Text;

    /* START-USER-CODE */
    private allowedBets: number[] = [];
    private currentBetIndex: number = 1;
    private originalY: number;
    private betsByLevel: Record<number, number[]> = {};
    private currentUserLevel: number;
    public static readonly EVENTS = {
        BET_CHANGED: "bet-changed",
    };
    public static readonly LAST_BET_KEY = "lastBet";
    private plusPush: PushComponent;
    private minusPush: PushComponent;

    initialize() {
        this.plusPush = new PushComponent(this.betPlusIcon, this.increment, this).activate();
        this.minusPush = new PushComponent(this.betMinusIcon, this.decrement, this).activate();

        // Set initial level
        this.currentUserLevel = this.scene.userManager.getGoldSpentLevel();

        this.scene.userManager.on("changedata", this.onUserDataChange);

        this.updateUIState();
    }

    destroy(fromScene?: boolean) {
        // Clean up event listeners
        this.scene.userManager.off("changedata", this.onUserDataChange);

        // Clean up PushComponents
        this.plusPush?.deactivate();
        this.minusPush?.deactivate();

        // Call parent destroy
        super.destroy(fromScene);
    }

    private onUserDataChange = () => {
        const updatedLevel = this.scene.userManager.getGoldSpentLevel();
        logger.debug("onUserDataChange", { updatedLevel, currentUserLevel: this.currentUserLevel });
        if (updatedLevel !== this.currentUserLevel) {
            const previousLevel = this.currentUserLevel;
            this.currentUserLevel = updatedLevel;
            this.onLevelChange(previousLevel, updatedLevel);
        }
    };

    private updateAllowedBetsForLevel(newLevel: number, isInitial: boolean = false) {
        const currentBet = isInitial ? undefined : this.getCurrentBet();

        this.allowedBets = getBetsForLevel(newLevel, this.betsByLevel);

        const config: BetConfig = {
            betsByLevel: this.betsByLevel,
            currentLevel: newLevel,
            currentBetIndex: this.currentBetIndex,
            currentBet,
        };

        this.currentBetIndex = getNewBetIndex(config);

        if (isInitial) {
            // Restore last bet from localStorage if it exists
            const lastBet = Number(localStorage.getItem(TotalBet.LAST_BET_KEY));
            if (lastBet) {
                const lastBetIndex = this.allowedBets.indexOf(lastBet);
                if (lastBetIndex !== -1) {
                    this.currentBetIndex = lastBetIndex;
                }
            }
        }

        this.updateTotalBetText();
        this.updateUIState();
        this.emit(TotalBet.EVENTS.BET_CHANGED, this.getCurrentBet());
    }

    private showNewBetUnlocked() {
        this.unlockedNewBetText.setVisible(true);

        this.scene.time.delayedCall(6000, () => {
            this.unlockedNewBetText.setVisible(false);
        });
    }

    private onLevelChange(previousLevel: number, newLevel: number) {
        if (hasNewBets(newLevel, previousLevel, this.betsByLevel)) {
            this.showNewBetUnlocked();
        }
        this.updateAllowedBetsForLevel(newLevel);
    }

    public resetYPosition() {
        this.setY(this.originalY);
    }

    public setBetsByLevel(betsByLevel: Record<number, number[]>) {
        this.betsByLevel = betsByLevel;
        const currentLevel = this.scene.userManager.getGoldSpentLevel();
        this.updateAllowedBetsForLevel(currentLevel, true);
    }

    public getCurrentBet() {
        return parseFormattedNumber(this.totalBetText.text);
    }

    public setMaxBet() {
        // @ts-expect-error FIXME: isAuto type is not defined
        if (!this.allowedBets.length || this.scene.isAuto) return;

        // Set to the last index (highest bet)
        this.currentBetIndex = this.allowedBets.length - 1;
        this.updateTotalBetText();
        this.updateUIState();
        this.emit(TotalBet.EVENTS.BET_CHANGED, this.getCurrentBet());
    }

    public disableButtons() {
        this.plusPush?.deactivate();
        this.minusPush?.deactivate();
    }

    public enableButtons() {
        this.plusPush?.activate();
        this.minusPush?.activate();
    }

    private increment() {
        // @ts-expect-error FIXME: isAuto type is not defined
        if (!this.allowedBets.length || this.scene.isAuto) return;

        if (this.currentBetIndex < this.allowedBets.length - 1) {
            this.currentBetIndex += 1;
            this.updateTotalBetText();
            this.updateUIState();
            this.emit(TotalBet.EVENTS.BET_CHANGED, this.getCurrentBet());
        }
    }

    private decrement() {
        // @ts-expect-error FIXME: isAuto type is not defined
        if (!this.allowedBets.length || this.scene.isAuto) return;

        if (this.currentBetIndex > 0) {
            this.currentBetIndex -= 1;
            this.updateTotalBetText();
            this.updateUIState();
            this.emit(TotalBet.EVENTS.BET_CHANGED, this.getCurrentBet());
        }
    }

    private updateTotalBetText() {
        if (!this.allowedBets.length) return;

        const currentBet = this.allowedBets[this.currentBetIndex];
        this.totalBetText.setText(numberWithCommas(currentBet));
        localStorage.setItem(TotalBet.LAST_BET_KEY, currentBet.toString());
    }

    private updateUIState() {
        if (!this.allowedBets.length) return;

        if (this.currentBetIndex === 0) {
            this.betMinusIcon.setTint(0x888888); // Gray out
            this.minusPush.deactivate();
        } else {
            this.betMinusIcon.clearTint();
            this.minusPush.activate();
        }

        if (this.currentBetIndex === this.allowedBets.length - 1) {
            this.betPlusIcon.setTint(0x888888); // Gray out
            this.plusPush.deactivate();
        } else {
            this.betPlusIcon.clearTint();
            this.plusPush.activate();
        }
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
