// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { LuckyWheelResponse, WheelPiece } from "@/lib/api/telegram-backend/methods/lucky-wheel";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
/* END-USER-IMPORTS */

export default class Spin extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 176, y ?? 138);

        // spin
        const spin = scene.add.image(27, 0, "lucky_wheel", "spin_1.png");
        this.add(spin);

        // reward
        const reward = new Text(scene, 27, 44);
        reward.visible = false;
        reward.text = "0";
        reward.setStyle({ fontStyle: "bold" });
        this.add(reward);

        this.spin = spin;
        this.reward = reward;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private spin: Phaser.GameObjects.Image;
    private reward: Text;

    /* START-USER-CODE */
    init(index: number) {
        this.spin.setTexture("lucky_wheel", `spin_${index}.png`);
        this.reward.text = "0";
        this.reward.visible = false;
    }

    addReward(piece: LuckyWheelResponse["luckyWheelCycleHistoryReward"][0]) {
        this.reward.visible = true;
        const typeToTexture: Record<string, string> = {
            rmx: "rmx.png",
            ota_gold: "gold.png",
            usd: "dollar.png",
        };

        this.scene.tweens.addCounter({
            from: 0,
            to: piece.value,
            duration: 400,
            onUpdate: (tween) => {
                const value = tween.getValue();
                this.reward.text = value >= 10000 ? `${formatCompactNumber(value)}` : `${numberWithCommas(value)}`;
            },
        });
        this.spin.setTexture("lucky_wheel", typeToTexture[piece.type]);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
