// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import { numberWithCommas, parseFormattedNumber } from "@/ota-slots/utils/formatter";
type Point = {
    type: string;
    source: Phaser.GameObjects.Text;
};
/* END-USER-IMPORTS */

export default class Jackpot extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 22);

        // jackpotImage
        const jackpotImage = scene.add.image(0, 50, "jackpot", "jackpot.png");
        this.add(jackpotImage);

        // grandPoint
        const grandPoint = new Text(scene, 0, 21);
        grandPoint.text = "0";
        grandPoint.setStyle({ fontSize: "22px" });
        this.add(grandPoint);

        // majorPoint
        const majorPoint = new Text(scene, 0, 70);
        majorPoint.text = "0";
        majorPoint.setStyle({ fontSize: "14px" });
        this.add(majorPoint);

        // minorPoint
        const minorPoint = new Text(scene, -134, 70);
        minorPoint.text = "0";
        minorPoint.setStyle({ fontSize: "14px" });
        this.add(minorPoint);

        // miniPoint
        const miniPoint = new Text(scene, 134, 70);
        miniPoint.text = "0";
        miniPoint.setStyle({ fontSize: "14px" });
        this.add(miniPoint);

        this.jackpotImage = jackpotImage;
        this.grandPoint = grandPoint;
        this.majorPoint = majorPoint;
        this.minorPoint = minorPoint;
        this.miniPoint = miniPoint;

        /* START-USER-CTR-CODE */
        this.scene.events.once(Phaser.Scenes.Events.UPDATE, this.init, this);
        /* END-USER-CTR-CODE */
    }

    private jackpotImage: Phaser.GameObjects.Image;
    private grandPoint: Text;
    private majorPoint: Text;
    private minorPoint: Text;
    private miniPoint: Text;

    /* START-USER-CODE */

    timeEvent?: Phaser.Time.TimerEvent;
    totalBet?: number = 0;
    points: Point[] = [];
    // Write your code here

    getMultiplier(type: string) {
        switch (type) {
            case "double_grand":
                return 200;
            case "grand":
                return 100;
            case "major":
                return 20;
            case "minor":
                return 10;
            case "mini":
                return 5;
            default:
                return null;
        }
    }

    getRangePoolValue(type: string) {
        const multiplier = this.getMultiplier(type);
        const poolValue = !this.totalBet || !multiplier ? 60061504 : this.totalBet * multiplier;

        const minValue = poolValue * 0.95;

        return [minValue, poolValue];
    }

    runInit({ source, type }: Point) {
        const [minValue, poolValue] = this.getRangePoolValue(type);

        source.setText(numberWithCommas(Phaser.Math.Between(minValue, poolValue)));
    }

    runUpdate() {
        this.points.forEach(({ type, source }) => {
            const currentScore = parseFormattedNumber(source.text);
            const [minValue, poolValue] = this.getRangePoolValue(type);

            const newScore = Phaser.Math.Between(minValue, poolValue);

            this.scene.tweens.addCounter({
                from: currentScore,
                to: newScore,
                duration: 300,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    source.setText(`${numberWithCommas(value)}`);
                },
            });
        });
    }

    init() {
        this.points = [
            {
                type: "grand",
                source: this.grandPoint,
            },
            {
                type: "mini",
                source: this.miniPoint,
            },
            {
                type: "major",
                source: this.majorPoint,
            },
            {
                type: "minor",
                source: this.minorPoint,
            },
        ];

        this.points.forEach((point) => this.runInit(point));
    }

    start(points?: Point[]) {
        if (points) {
            this.points = points;
        }

        if (this.timeEvent) {
            this.timeEvent.remove();
            this.timeEvent.destroy();
            this.timeEvent = undefined;
        }

        this.timeEvent = this.scene.time.addEvent({
            delay: 600,
            callback: this.runUpdate,
            callbackScope: this,
            loop: true,
        });
    }

    doUpgrade() {
        this.jackpotImage.setTexture("jackpot", "upgraded jackpot.png");
        this.jackpotImage.setY(58);

        this.start([
            {
                type: "double_grand",
                source: this.grandPoint,
            },
            {
                type: "minor",
                source: this.miniPoint,
            },
            {
                type: "grand",
                source: this.majorPoint,
            },
            {
                type: "major",
                source: this.minorPoint,
            },
        ]);
    }

    reset() {
        this.jackpotImage.setTexture("jackpot", "jackpot.png");
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
