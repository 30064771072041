// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { EventBus, EVENTS } from "@/lib/event-bus";
import { TutorialPlugin } from "@/shared/plugins/tutorial";
import { userData } from "@/shared/plugins/tutorial/mock-data";
import PreloadProgressBar from "@/shared/prefabs/PreloadProgressBar";
import { appLogger } from "@/utils/logger";
import { getChapterKey, MAX_CHAPTER } from "@/shared/registry/chapter";
import { processReferral } from "@/lib/api/telegram-backend/methods/referral";
import { decodeReferralCode } from "@/ota-mining/utils/referral-link";
import { getStartAppParam } from "@/lib/telegram/web-app";

const logger = appLogger.withNamespace("[ota-mining/game/scenes/Preloader]");
/* END-USER-IMPORTS */

export default class Preloader extends Phaser.Scene {
    constructor() {
        const sceneConfig = {
            key: "MiningPreloader",
            pack: {
                files: [
                    {
                        type: "plugin",
                        key: "rexawaitloaderplugin",
                        url: "plugins/rexawaitloaderplugin.min.js",
                        start: true,
                    },
                ],
            },
        };
        super(sceneConfig);

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // preload_bg
        this.add.image(195, 422, "preload_bg");

        this.events.emit("scene-awake");
    }

    /* START-USER-CODE */

    // Write your code here
    init() {
        this.editorCreate();

        const progressBar = new PreloadProgressBar(this, 0, -40);

        this.load.on(Phaser.Loader.Events.PROGRESS, (value: number) => {
            progressBar.updateProgress(value);
        });

        this.events.emit("scene-awake");
    }

    private readonly sharedAssets = ["common", "loot-box", "telegram-star", "missions", "referral"];

    preload() {
        (this.plugins as any).get("rexawaitloaderplugin").addToScene(this);

        // Define shared asset packs
        const sharedPacks = [
            { key: "common-asset-pack", file: "shared/common-asset-pack.json" },
            {
                key: "loot-box-asset-pack",
                file: "shared/loot-box-asset-pack.json",
            },
            {
                key: "telegram-star-asset-pack",
                file: "shared/telegram-star-asset-pack.json",
            },
            {
                key: "missions-asset-pack",
                file: "shared/missions-asset-pack.json",
            },
            {
                key: "referral-asset-pack",
                file: "shared/referral-asset-pack.json",
            },
        ];

        // Batch load shared assets
        this.batchLoadAssetPacks(sharedPacks);

        // Load mining-specific assets if needed
        if (!this.cache.json.exists("mining-preload-asset-pack")) {
            this.load.pack("mining-preload-asset-pack", "ota-mining/mining-preload-asset-pack.json");
        }

        // Load payline sound if needed
        if (!this.cache.audio.exists("payline")) {
            this.load.audio("payline", "ota-slots/sounds/payline.mp3");
        }

        // Load chapter assets
        this.loadChapterAssets();

        this.load.on(Phaser.Loader.Events.COMPLETE, () => {
            logger.info("Load completed");
            EventBus.emit(EVENTS.CURRENT_SCENE_READY, this);
        });
    }

    private async handleAuthentication(): Promise<void> {
        return new Promise((resolve) => {
            EventBus.on(EVENTS.AUTH_INIT_DATA, this.userManager.authenticate);
            EventBus.emit(EVENTS.READY_FOR_AUTH_DATA);

            this.userManager.onAuthSuccess(async () => {
                // Handle referral
                const startapp = getStartAppParam();
                if (startapp) {
                    const referralCode = decodeReferralCode(startapp);
                    if (referralCode) await processReferral({ referralCode });
                }
                resolve();
            });
        });
    }

    private async loadChapterAssets(): Promise<void> {
        (this.load as any).rexAwait(async (successCallback: () => void) => {
            try {
                await this.handleAuthentication();
                // Sync with backend
                await Promise.all([this.miningBossManager.syncWithBackend(), this.settingsManager.syncWithBackend()]);

                const chapterData = this.userManager.getChapterData();

                await this.chapterManager.syncWithBackend(chapterData);

                // Load current chapter assets if not already loaded
                const currentChapterKey = getChapterKey(chapterData.chapter);
                const currentChapterAssetKey = `chapter_${currentChapterKey}_asset_pack`;

                // preload next chapter assets if boss level 10
                const nextChapter = chapterData.chapter + 1;
                if (chapterData.bossLevel === 10 && nextChapter <= MAX_CHAPTER) {
                    const nextChapterKey = getChapterKey(nextChapter);
                    this.loadChapterPack(`chapter_${nextChapterKey}_asset_pack`, () => {});
                }

                if (!this.cache.json.exists(currentChapterAssetKey)) {
                    await this.loadChapterPack(currentChapterKey, successCallback);
                } else {
                    successCallback();
                }

                this.load.start();
            } catch (error) {
                logger.error("Error loading chapter assets:", error);
                successCallback(); // Allow the game to continue even if there's an error
            }
        });
    }

    private loadChapterPack(chapterKey: string, callback: () => void): Promise<void> {
        return new Promise((resolve) => {
            this.load
                .pack(`chapter_${chapterKey}_asset_pack`, `ota-mining/asset-pack/chapter_${chapterKey}_asset_pack.json`)
                .once(Phaser.Loader.Events.FILE_COMPLETE, () => {
                    logger.info(`Chapter ${chapterKey} assets loaded`);
                    callback();
                    resolve();
                });
        });
    }

    create(): void {
        const introStep = this.userManager.getIntroStep();
        const isTutorialCompleted = this.userManager.isTutorialCompleted();

        if (!isTutorialCompleted) {
            const sceneKey = TutorialPlugin.steps?.[Number(introStep)]?.sceneKey ?? "MiningGame";
            this.userManager.syncWithMockData({
                ...userData.user,
                introStep: introStep as string,
            });
            this.scene.start(sceneKey);
        } else {
            this.scene.start("MiningGame");
        }
    }

    shutdown() {
        // Clean up any resources
        EventBus.off(EVENTS.AUTH_INIT_DATA);
    }

    private batchLoadAssetPacks(packs: Array<{ key: string; file: string }>, onComplete?: () => void): void {
        // Filter out already loaded packs
        const packsToLoad = packs.filter(({ key }) => !this.cache.json.exists(key));

        if (packsToLoad.length === 0) {
            onComplete?.();
            return;
        }

        // Load all packs
        packsToLoad.forEach(({ key, file }) => {
            this.load.pack(key, file);
        });

        // Set up single completion handler
        if (onComplete) {
            this.load.once(Phaser.Loader.Events.COMPLETE, onComplete);
        }

        this.load.start();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
