import {
    getLootBoxInfo,
    type LootBoxReward,
    openLootBox,
    performLootBoxAction,
    sellLootBox,
} from "@/lib/api/telegram-backend/methods/loot-box";
import LootBoxPopup from "./LootBoxPopup";
import type { InventoryItem } from "@/shared/types/loot-box";
import ActionDialog from "../action-dialog/ActionDialog";
import InventoryItemList from "./InventoryItemList";
/* START OF COMPILED CODE */

import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import WeekerItemList from "./WeekerItemList";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { SCREEN_NAMES } from "@/services/analytics";
import { BUTTON_NAMES } from "@/services/analytics";
import { trackButtonTap } from "@/services/analytics";
/* END-USER-IMPORTS */

export default class LootBoxIcon extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? -23.741949830176083);

        // icon
        const icon = scene.add.container(0, 6);
        this.add(icon);

        // image_8
        const image_8 = scene.add.image(0, 17.7419490814209, "loot_box", "loot-box-icon.png");
        icon.add(image_8);

        // ellipse_1
        const ellipse_1 = scene.add.image(27, -9, "loot_box", "loot-box-red-dot.png");
        icon.add(ellipse_1);

        // boxNumber
        const boxNumber = new Text(scene, 27, -9);
        boxNumber.text = "0";
        boxNumber.setStyle({
            align: "center",
            fixedWidth: 23,
            fixedHeight: 0,
            fontSize: "12px",
            fontStyle: "bold",
        });
        icon.add(boxNumber);

        this.boxNumber = boxNumber;
        this.icon = icon;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private boxNumber: Text;
    private icon: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    public async init({ skipSync, disabled }: { skipSync?: boolean; disabled?: boolean } = {}) {
        if (!skipSync) {
            this.syncTotalLootBox();
        }

        if (disabled) {
            this.disableIconInteractive();
        } else {
            this.enableIconInteractive();
        }

        new PushComponent(this.icon, async () => {
            const boxNumber = this.getBoxNumber();
            if (boxNumber === 0) return;

            const sceneName = {
                MiningGame: SCREEN_NAMES.MINING,
                SlotsGame: SCREEN_NAMES.SLOTS,
            }[this.scene.scene.key];

            if (sceneName) {
                trackButtonTap(BUTTON_NAMES.LOOTBOX_ICON, sceneName);
            }

            this.disableIconInteractive();

            const lootBoxInfo = await getLootBoxInfo();

            if (lootBoxInfo?.isNewLootBoxLevel) {
                this.openWeekerItemList();
            } else {
                const reward = await openLootBox();
                if (reward) {
                    this.openPopup(reward.lootBox);
                    this.setBoxNumber(boxNumber - 1);
                }
            }
        }).activate();
    }

    public async syncTotalLootBox() {
        const lootBoxInfo = await getLootBoxInfo();
        if (lootBoxInfo?.totalLootBox) {
            this.setBoxNumber(lootBoxInfo.totalLootBox);
        }
    }

    enableIconInteractive() {
        this.icon.setInteractive({
            hitArea: new Phaser.Geom.Circle(3, 12, 31.19328784273037),
            hitAreaCallback: Phaser.Geom.Circle.Contains,
            useHandCursor: true,
        });
    }

    disableIconInteractive() {
        this.icon.disableInteractive();
    }

    openWeekerItemList() {
        let isSelling = false;
        const popup = new WeekerItemList(this.scene, 0, 0);
        popup.on(LootBoxPopup.EVENTS.SELL_ALL, async () => {
            if (isSelling) return;
            isSelling = true;
            popup.updateCollectState(isSelling);

            const response = await sellLootBox();

            if (response) {
                this.emit(CUSTOM_EVENTS.SYNC_USER);
                getLootBoxInfo().then((lootBoxInfo) => {
                    if (typeof lootBoxInfo?.totalLootBox === "number") {
                        this.setBoxNumber(lootBoxInfo.totalLootBox);
                    }
                });
            }

            isSelling = false;
            popup.updateCollectState(isSelling);
            popup.destroy();
        });

        popup.on("destroy", () => {
            this.enableIconInteractive();
        });

        popup.init();
        this.scene.add.existing(popup);
    }

    async openPopup(lootBoxReward: LootBoxReward) {
        const rewardItem: InventoryItem = lootBoxReward.item;
        const popup = new LootBoxPopup(this.scene, 0, 0);
        popup.open(rewardItem);
        this.scene.add.existing(popup);

        popup.once(LootBoxPopup.EVENTS.COLLECT, async () => {
            const actionResponse = await performLootBoxAction({
                id: lootBoxReward.id,
                decision: "equip",
            });

            if (actionResponse) {
                this.emit(CUSTOM_EVENTS.SYNC_USER);
                EventBus.emit(CUSTOM_EVENTS.CLAIM_REWARD);
            }
            popup.destroy();
        });

        popup.once(LootBoxPopup.EVENTS.SELL, async () => {
            const actionResponse = await performLootBoxAction({
                id: lootBoxReward.id,
                decision: "sell",
            });

            if (actionResponse) {
                this.emit(CUSTOM_EVENTS.SYNC_USER);
                EventBus.emit(CUSTOM_EVENTS.CLAIM_REWARD);
            }

            popup.destroy();
        });

        popup.once(LootBoxPopup.EVENTS.EQUIP, async () => {
            const actionResponse = await performLootBoxAction({
                id: lootBoxReward.id,
                decision: "equip",
            });
            if (actionResponse) {
                EventBus.emit(CUSTOM_EVENTS.CLAIM_REWARD);
            }
            // NOTE: We need to await for the user sync before displaying the inventory list, so we are not emitting the sync user event here.
            await this.scene.userManager.syncWithBackend();

            popup.destroy();
            const inventoryItemList = new InventoryItemList(this.scene, 0, 0);
            inventoryItemList.init(rewardItem);
            this.scene.add.existing(inventoryItemList);

            const response = await this.scene.miningBossManager.syncWithBackend();
            if (response && response.winChance >= 50) {
                this.emit(ActionDialog.EVENTS.SHOW_BATTLE_CONFIRMATION, {
                    chance: response.winChance,
                    level: rewardItem.level,
                });
            }
        });

        popup.on("destroy", () => {
            this.enableIconInteractive();
        });
    }

    public getBoxNumber() {
        return Number(this.boxNumber.text);
    }

    public setBoxNumber(number: number) {
        this.boxNumber.setText(`${number}`);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
