// You can write more code here

/* START OF COMPILED CODE */

import Text from "../Text";
/* START-USER-IMPORTS */
import { InventoryItem } from "@/shared/types/loot-box";
import { getItemTextureName, getRarityColor } from "@/shared/utils/loot-box";
/* END-USER-IMPORTS */

export default class Gem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 100);

        // gem_bg
        const gem_bg = scene.add.image(0, 0, "loot_box", "gem_bg.png");
        this.add(gem_bg);

        // gem
        const gem = scene.add.image(0, 3, "blue_astrion");
        gem.scaleX = 0.15;
        gem.scaleY = 0.15;
        this.add(gem);

        // gemRank
        const gemRank = new Text(scene, -1, -36);
        gemRank.text = "SSR";
        gemRank.setStyle({ "color": "#DEA614", "fontSize": "16px", "stroke": "#DEA614" });
        this.add(gemRank);

        // gemLevel
        const gemLevel = new Text(scene, 3, 38);
        gemLevel.text = "LV0";
        gemLevel.setStyle({ "fontSize": "14px" });
        this.add(gemLevel);

        this.gem = gem;
        this.gemRank = gemRank;
        this.gemLevel = gemLevel;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private gem: Phaser.GameObjects.Image;
    private gemRank: Text;
    private gemLevel: Text;

    /* START-USER-CODE */
    public item: InventoryItem;

    get gemItem() {
        return this.gem;
    }

    init(
        item: InventoryItem,
        {
            hideRank = false,
            hideLevel = false,
        }: {
            hideRank?: boolean;
            hideLevel?: boolean;
        },
    ) {
        this.item = item;
        const itemTextureName = getItemTextureName(item);
        if (itemTextureName) this.gem.setTexture("loot_box", itemTextureName + ".png");
        if (!hideRank) {
            this.gemRank.setText(item.rarity.toUpperCase());
            this.gemRank.setStyle({ color: getRarityColor(item) });
        } else {
            this.gemRank.setVisible(false);
        }

        if (!hideLevel) {
            this.gemLevel.setText(`LV${item.level}`);
        } else {
            this.gemLevel.setVisible(false);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
