import "./game.loaders"; // Register custom loaders
import { gameInitializer } from "./game.initializer";
import { initAnalytics } from "./services/analytics";

initAnalytics();

// Initialize the game when the DOM is ready
window.addEventListener("load", () => {
    gameInitializer.start();
});

// Clean up when the window unloads
window.addEventListener("unload", () => {
    gameInitializer.destroy();
});
