// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Reels from "../prefabs/normal-spin/Reels";
import Text from "../../shared/prefabs/Text";
import FreeTotalWin from "../prefabs/free-spin/FreeTotalWin";
import AutoSpinWinDisplay from "../prefabs/auto-spin/AutoSpinWinDisplay";
/* START-USER-IMPORTS */
import {
    SlotMachineFreeSpin,
    SlotMachineFreeSpinResponse,
    spinSlotMachineFreeSpin,
} from "@/lib/api/telegram-backend/client";
import { sleep } from "@/lib/utils";
import { DEPTH_PRIORITY, SCENE_KEYS } from "@/ota-slots/constants";
import { setupBackground } from "../helpers/setup-background";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import JackpotPopup from "../prefabs/popup/JackpotPopup";
import Slot from "../prefabs/free-spin/Slot";
import WinningPopup from "../prefabs/popup/WinningPopup";
import { SpinData } from "../types";
import { setupScreenTracking } from "@/services/analytics";
/* END-USER-IMPORTS */

export default class FreeSpin extends Phaser.Scene {
    constructor() {
        super("SlotsFreeSpin");

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // free_spin_bg
        this.add.image(195, 422, "free_spin_atlas", "freeSpin.png");

        // free_spin_reel_png
        this.add.image(195, 400, "free_spin_atlas", "free_reel.png");

        // reels
        const reels = new Reels(this, 12, 255);
        this.add.existing(reels);

        // free_spin_bottom_console_png
        this.add.image(195, 812, "free_spin_atlas", "Frame 427318608.png");

        // free_spin_top_console_png
        this.add.image(195, 626, "free_spin_atlas", "Frame 427318603.png");

        // spinTotal
        const spinTotal = new Text(this, 195, 550);
        this.add.existing(spinTotal);
        spinTotal.text = "0/6 spins";
        spinTotal.setStyle({ color: "#AEF7EA", fontSize: "32px" });

        // slotsContainer
        const slotsContainer = this.add.container(47, 625);

        // totalWin
        const totalWin = new FreeTotalWin(this, 130, 747);
        this.add.existing(totalWin);
        totalWin.visible = false;

        // kitchenUp
        const kitchenUp = this.add.sprite(195, 435, "kitchen_up", "auraspike_ii_f_01.png");
        kitchenUp.visible = false;

        // autoSpinWinDisplay
        const autoSpinWinDisplay = new AutoSpinWinDisplay(this, 195, 379);
        this.add.existing(autoSpinWinDisplay);

        // coinSingleDrop
        const coinSingleDrop = this.add.sprite(195, 425, "coin_single_drop", "coin_single_drop_f_01.png");

        // coinBulk
        const coinBulk = this.add.sprite(195, 425, "coin_bulk", "coin_01_bulk_drop_f_01.png");

        // coinToss
        const coinToss = this.add.sprite(195, 425, "coin_toss", "coins_toss_f_01.png");

        this.reels = reels;
        this.spinTotal = spinTotal;
        this.slotsContainer = slotsContainer;
        this.totalWin = totalWin;
        this.kitchenUp = kitchenUp;
        this.autoSpinWinDisplay = autoSpinWinDisplay;
        this.coinSingleDrop = coinSingleDrop;
        this.coinBulk = coinBulk;
        this.coinToss = coinToss;

        this.events.emit("scene-awake");
    }

    private reels!: Reels;
    private spinTotal!: Text;
    private slotsContainer!: Phaser.GameObjects.Container;
    private totalWin!: FreeTotalWin;
    private kitchenUp!: Phaser.GameObjects.Sprite;
    private autoSpinWinDisplay!: AutoSpinWinDisplay;
    private coinSingleDrop!: Phaser.GameObjects.Sprite;
    private coinBulk!: Phaser.GameObjects.Sprite;
    private coinToss!: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    public slotMachineFreeSpinResponse!: SlotMachineFreeSpinResponse;
    public totalPiggyJackpotReward: number = 0;
    public spinData: SpinData;
    public spinIndex: number = -1;
    public resolve: (value: unknown) => void;

    init(spinData: SpinData) {
        setupScreenTracking(this);
        this.spinData = spinData;
    }

    async create() {
        this.editorCreate();
        this.reset();
        this.setupSpinSlots();
        EventBus.emit(CUSTOM_EVENTS.PLAY_FREE_BG);

        this.events.on(Phaser.Scenes.Events.WAKE, this.onWake, this);
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, this.shutdown, this);

        const slotMachineFreeSpinResponse = await spinSlotMachineFreeSpin(this.spinData!.spinId);
        if (slotMachineFreeSpinResponse) {
            this.slotMachineFreeSpinResponse = slotMachineFreeSpinResponse as SlotMachineFreeSpinResponse;
            this.setupSpinTotal();
            this.doFreeSpin();
        } else {
            this.scene.start(SCENE_KEYS.GAME);
        }
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/free_spin_bg.png");
    }

    async onWake(sys: Phaser.Scene, data: { finalReward: number }) {
        if (!data) return;
        this.totalPiggyJackpotReward = data.finalReward + this.totalPiggyJackpotReward;

        const totalWinAmount = data.finalReward + this.totalWin.currentTotalWin;
        this.totalWin.setAmount(totalWinAmount);

        const spinData = this.slotMachineFreeSpinResponse[this.spinIndex];
        if (spinData) {
            const currentWinAmount = spinData.totalFreeSpinReward + data.finalReward;
            const slot = this.slotsContainer.list[this.spinIndex] as Slot;
            slot.showAmount?.(currentWinAmount);
            await this.showSpinWinDisplay(currentWinAmount);

            await sleep(500);
        }

        this.resolve(true);
        this.resolve = () => {};
    }

    setupSpinTotal() {
        this.spinTotal.text = `${this.spinIndex + 1}/${this.slotMachineFreeSpinResponse.length} spins`;
    }

    setupSpinSlots() {
        for (let i = 0; i < 6; i++) {
            const item = new Slot(this, i * 60, 0);
            item.init(i + 1);
            this.slotsContainer.add(item);
        }
    }

    async doFreeSpin() {
        this.totalWin.hide();
        for (let i = 0; i < this.slotMachineFreeSpinResponse.length; i++) {
            EventBus.emit(CUSTOM_EVENTS.SLOT_ACTIVE);
            const slot = this.slotsContainer.list[i] as Slot;
            slot.activeSlot?.();

            this.spinIndex = i;
            this.setupSpinTotal();

            const spinData = this.slotMachineFreeSpinResponse[i];

            this.reels.cancelHighlightPaylines();
            EventBus.emit(CUSTOM_EVENTS.REEL_SPINNING);
            await this.reels.spinReels({
                ...spinData,
                totalBet: 0,
                isAuto: this.spinData!.isAuto,
                isAutoFast: true,
            });
            const totalWinAmount = spinData.totalFreeSpinReward + this.totalWin.currentTotalWin;

            slot.showAmount?.(spinData.totalFreeSpinReward);
            this.totalWin.setAmount(totalWinAmount);
            if (spinData.isPiggyJackpotTrigger) {
                await this.handlePickBonus(spinData);
                await sleep(500);
            } else {
                await this.showSpinWinDisplay(spinData.totalFreeSpinReward);
                await sleep(1000);
            }
        }
        this.handleWinPopup();
    }

    handlePickBonus(spinData: SlotMachineFreeSpin) {
        return new Promise((resolve) => {
            const jackpotContainer = new JackpotPopup(this, 195, 422);
            jackpotContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
            this.add.existing(jackpotContainer);

            jackpotContainer.init(SCENE_KEYS.FREE_SPIN, {
                ...spinData,
                isAuto: this.spinData!.isAuto,
                isAutoFast: this.spinData!.isAutoFast,
                totalBet: this.spinData!.totalBet,
            });

            this.resolve = resolve;
        });
    }

    private async showSpinWinDisplay(amount: number): Promise<void> {
        if (!this.autoSpinWinDisplay || amount === 0) {
            return;
        }

        await this.autoSpinWinDisplay.fadeIn();

        this.autoSpinWinDisplay.setAmount(amount);

        await new Promise<void>((resolve) => {
            this.time.delayedCall(1500, async () => {
                await this.autoSpinWinDisplay.fadeOut();
                resolve();
            });
        });
    }

    handleWinPopup() {
        const totalFreeSpinReward = this.slotMachineFreeSpinResponse.reduce(
            (sum, spinData) => sum + spinData.totalFreeSpinReward,
            0,
        );
        const totalWinAmount = totalFreeSpinReward + this.totalPiggyJackpotReward;

        this.kitchenUp.visible = true;
        this.kitchenUp.play("kitchen_up");
        this.kitchenUp.once(Phaser.Animations.Events.ANIMATION_COMPLETE, async () => {
            this.coinSingleDrop.play("coin_single_drop");
            this.coinSingleDrop.once(Phaser.Animations.Events.ANIMATION_COMPLETE, async () => {
                this.kitchenUp.destroy();
                this.coinBulk.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
                this.coinBulk.play("coin_bulk");
                this.coinBulk.on(Phaser.Animations.Events.ANIMATION_REPEAT, () => {
                    this.coinToss.stop();
                    this.coinToss.setFrame(0);
                    this.coinToss.play("coin_toss");
                });
                const popupContainer = new WinningPopup(this, 0, 0);

                this.add.existing(popupContainer);

                popupContainer.show("you_win", {
                    winAmount: totalWinAmount,
                    duration: 3000,
                });

                popupContainer.once("destroy", () => {
                    this.redirectToGame(totalWinAmount);
                });
            });
        });

        return;
    }

    redirectToGame(totalWinAmount: number) {
        this.scene.stop(SCENE_KEYS.FREE_SPIN).wake(SCENE_KEYS.GAME, {
            finalReward: totalWinAmount,
            fromScene: SCENE_KEYS.FREE_SPIN,
            isAuto: this.spinData!.isAuto,
        });
    }

    shutdown() {
        EventBus.emit(CUSTOM_EVENTS.PLAY_SLOTS_BG);
        this.events.off(Phaser.Scenes.Events.WAKE, this.onWake, this);
    }

    reset() {
        this.spinIndex = -1;
        this.totalPiggyJackpotReward = 0;
        this.resolve = () => {};
        this.slotMachineFreeSpinResponse = undefined as unknown as SlotMachineFreeSpinResponse;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
