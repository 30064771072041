export const chapter1 = {
    id: 1,
    boss: {
        name: "Triocula",
        idleAnimationKey: "boss_01_walk",
        walkAnimationKey: "boss_01_walk",
        jumpAnimationKey: "boss_01_walk",
        attackAnimationKey: "boss_01_attack",
        gethitAnimationKey: "boss_01_gethit",
        destroyedAnimationKey: "boss_01_dead",
        size: {
            scale: 0.7,
        },
        position: {
            y: -35,
        },
        body: {
            offset: {
                x: -50,
                h: -70,
            },
        },
        texture: ["boss_01_walk", "Property 1=f_01.png"],
    },
    creep: {
        walkAnimationKey: "creep_01_walk",
        attackAnimationKey: "creep_01_attack",
        destroyedAnimationKey: "creep_01_die",
        size: {
            scale: 0.25,
        },
        position: {
            y: 30,
        },
        texture: ["creep_01_walk", "Property 1=f_01.png"],
    },
    background: {
        mining: "chapter_01_bg",
        boss: "chapter_01_bg",
        intro: "chapter_01_intro",
    },
};
