import { User } from "@/lib/api/telegram-backend/client";

const lastClaimedAt = Date.now() - 60 * 1000;

const fightingEquipments = [
    {
        sellingPrice: 800,
        b: 10,
        s: 120,
        d: 1,
        level: 3,
        name: "gem",
        maxAtk: 14,
        slot: 1,
        minAtk: 10,
        rarity: "n",
    },
    {
        sellingPrice: 1400,
        b: 10,
        s: 200,
        d: 1,
        level: 1,
        name: "gem",
        maxAtk: 22,
        slot: 3,
        minAtk: 18,
        rarity: "n",
    },
    {
        sellingPrice: 400,
        b: 10,
        s: 60,
        d: 2,
        level: 2,
        name: "gem",
        maxAtk: 14,
        slot: 5,
        minAtk: 10,
        rarity: "ssr",
    },
    {
        sellingPrice: 1400,
        b: 10,
        s: 200,
        d: 1,
        level: 1,
        name: "gem",
        maxAtk: 22,
        slot: 2,
        minAtk: 18,
        rarity: "n",
    },
];

export const userData = {
    accessToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmYjAxZjkwMC1mMmIyLTRlODktODAzZC03ODM0ZjFkODY4ZDEiLCJuYW1lIjoiQW5kcmV3IFJvZ3VlIiwiaWF0IjoxNzM0NTc3NDQ4LCJleHAiOjE3MzQ2NjM4NDh9.8x0FgOOR7gItkwYseSS4FSa2xUDRSn72RXEBky8Z7Sk",
    refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmYjAxZjkwMC1mMmIyLTRlODktODAzZC03ODM0ZjFkODY4ZDEiLCJuYW1lIjoiQW5kcmV3IFJvZ3VlIiwiaWF0IjoxNzM0NTc3NDQ4LCJleHAiOjIwNDk5Mzc0NDh9.9gvRLZ3yCZNPqGnmjfPB4smUyYTOtiYoP0yzQz4hAPo",
    user: {
        id: "fb01f900-f2b2-4e89-803d-7834f1d868d1",
        username: "rogue",
        name: "Andrew Rogue",
        languageCode: "en",
        providerType: "telegram",
        providerId: "99281932",
        countryCode: "VN",
        timezone: "Asia/Bangkok",
        referralCode: "kHjmDvxQ",
        otaGold: 1389,
        rmx: 0.139,
        lastClaimedAt,
        miningLevel: 1,
        lastFightBossAt: null,
        fightingEquipments: [
            {
                sellingPrice: 500,
                b: 50,
                s: 200,
                d: 1,
                level: 2,
                name: "weapon",
                maxAtk: 110,
                slot: 6,
                minAtk: 90,
                rarity: "n",
            },
            ...fightingEquipments,
        ],
        introStep: "0",
    } as unknown as User,
    configure: {
        allowedBets: [500, 1000, 2000, 5000, 7500, 10000],
    },
};

export const claimData = {
    user: {
        ...userData.user,
        otaGold: 1389,
        rmx: 1.39,
    },
    reward: {
        otaGold: 1389.024,
        rmx: 1.39,
    },
};

export const bossFightDataStep4 = {
    won: true,
    attacks: ["310", "300", "320", "280", "230"],
    bossHealth: 1200,
};

export const bossFightDataStep6 = {
    won: false,
    attacks: ["200", "300", "200", "310", "230"],
    bossHealth: 3000,
};

export const bossFightDataStep9 = {
    won: true,
    attacks: ["800", "910", "820", "890", "849"],
    bossHealth: 3000,
};

export const spinData = {
    spinId: "51cff3ef-703c-4183-8e59-2219f9b67291",
    spinResult: [
        ["symbol_2_red_planet", "symbol_1_satellite", "symbol_11_black_hole"],
        ["symbol_9_space_captain", "symbol_9_space_captain", "symbol_4_green_alien"],
        ["symbol_7_space_girl", "symbol_3_planet_Earth", "symbol_4_green_alien"],
        ["symbol_8_girl_alien", "symbol_7_space_girl", "symbol_9_space_captain"],
        ["symbol_2_red_planet", "symbol_3_planet_Earth", "symbol_1_satellite"],
    ],
    winningPaylines: [
        {
            payline: 3,
            paylineDetail: [10, 11, 12, 13, 14],
            symbol: "symbol_4_green_alien",
            matchCount: 3,
            rewardPoint: 150,
        },
        {
            payline: 5,
            paylineDetail: [10, 6, 2, 8, 14],
            symbol: "symbol_7_space_girl",
            matchCount: 3,
            rewardPoint: 150,
        },
    ],
    totalPaylineReward: 1000,
    isPiggyJackpotTrigger: false,
    isFreeSpinTrigger: false,
};

export const rifleData = {
    sellingPrice: 2500,
    b: 250,
    s: 1000,
    d: 5,
    level: 2,
    name: "weapon",
    maxAtk: 555,
    slot: 6,
    minAtk: 450,
    rarity: "r",
};

export const openBoxData = {
    lootBox: {
        item: rifleData,
        id: "05ceddb2-19c9-4f5f-afd9-703cfd3158f4",
        level: 2,
        status: "available",
    },
    action: ["equip"],
};

export const miningBossData = {
    bossHealth: 3000,
    winChance: 79,
    lastFightBossAt: "2024-12-16T06:58:36.777Z",
};

export const userDataWithRifle = {
    ...userData,
    user: {
        ...userData.user,
        fightingEquipments: [rifleData, ...fightingEquipments],
    } as unknown as User,
};
