// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../../shared/prefabs/Text";
import LootBoxIcon from "../../../../shared/prefabs/loot-box/LootBoxIcon";
/* START-USER-IMPORTS */
import { getStoragePercent } from "@/ota-mining/utils/get-storage-percentage";
import { CUSTOM_EVENTS, EventBusComponent } from "../../components/events/EventBusComponent";
import ClaimPopup from "../ClaimPopup";
import PushComponent from "../../components/animations/PushComponent";
import { EventBus } from "@/lib/event-bus";
import { formatCompactNumber } from "@/shared/utils/number-formatter";
import { BUTTON_NAMES, SCREEN_NAMES, trackButtonTap } from "@/services/analytics";
/* END-USER-IMPORTS */

export default class BottomConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // bottom_console
        const bottom_console = scene.add.image(0, 325, "bottom_console", "bottom-console.png");
        this.add(bottom_console);

        // stackingGold
        const stackingGold = scene.add.container(0, 257);
        stackingGold.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(stackingGold);

        // image_5
        const image_5 = scene.add.image(-11, 1, "coin_icon");
        image_5.scaleX = 0.2215064764113549;
        image_5.scaleY = 0.2215064764113549;
        stackingGold.add(image_5);

        // otaGoldAmount
        const otaGoldAmount = new Text(scene, 6, 9);
        otaGoldAmount.setOrigin(0, 1);
        otaGoldAmount.text = "0/0";
        otaGoldAmount.setStyle({ "fontFamily": "Oxanium", "fontSize": "14px", "fontStyle": "bold" });
        stackingGold.add(otaGoldAmount);

        // dailyGoldMiningRate
        const dailyGoldMiningRate = scene.add.container(11, 18);
        dailyGoldMiningRate.blendMode = Phaser.BlendModes.SKIP_CHECK;
        stackingGold.add(dailyGoldMiningRate);

        // goldRate
        const goldRate = new Text(scene, 10, 6);
        goldRate.scaleX = 1;
        goldRate.scaleY = 1;
        goldRate.setOrigin(0, 1);
        goldRate.text = "0/day";
        goldRate.setStyle({
            "align": "left",
            "color": "#999FAE",
            "fixedWidth": 0,
            "fixedHeight": 12,
            "fontSize": "10px",
            "fontStyle": "",
        });
        dailyGoldMiningRate.add(goldRate);

        // pickaxe_png_1
        const pickaxe_png_1 = scene.add.image(0, 0, "bottom_console", "pickaxe.png");
        dailyGoldMiningRate.add(pickaxe_png_1);

        // stackingRmx
        const stackingRmx = scene.add.container(-153, 258);
        stackingRmx.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(stackingRmx);

        // dailyRmxMiningRate
        const dailyRmxMiningRate = scene.add.container(27, 18);
        dailyRmxMiningRate.blendMode = Phaser.BlendModes.SKIP_CHECK;
        stackingRmx.add(dailyRmxMiningRate);

        // rmxRate
        const rmxRate = new Text(scene, 9, 6);
        rmxRate.setOrigin(0, 1);
        rmxRate.text = "0/day";
        rmxRate.setStyle({
            "color": "#999FAE",
            "fixedWidth": 0,
            "fixedHeight": 12,
            "fontSize": "10px",
            "fontStyle": "",
        });
        dailyRmxMiningRate.add(rmxRate);

        // pickaxe_png
        const pickaxe_png = scene.add.image(0, 0, "bottom_console", "pickaxe.png");
        dailyRmxMiningRate.add(pickaxe_png);

        // rmxGoldAmount
        const rmxGoldAmount = new Text(scene, 22, 10);
        rmxGoldAmount.setOrigin(0, 1);
        rmxGoldAmount.text = "0/0";
        rmxGoldAmount.setStyle({
            "color": "#D8E63C",
            "fontFamily": "Oxanium",
            "fontSize": "18px",
            "fontStyle": "bold",
        });
        stackingRmx.add(rmxGoldAmount);

        // remis_coin
        const remis_coin = scene.add.image(-6, 5, "remis_coin");
        remis_coin.scaleX = 0.0999784165975349;
        remis_coin.scaleY = 0.0999784165975349;
        stackingRmx.add(remis_coin);

        // claimButton
        const claimButton = scene.add.image(135, 264, "bottom_console", "claim-button.png");
        claimButton.visible = false;
        this.add(claimButton);

        // countdownTimerBlock
        const countdownTimerBlock = scene.add.container(135, 265);
        countdownTimerBlock.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(countdownTimerBlock);

        // countdownBlock
        const countdownBlock = scene.add.image(0, 0, "bottom_console", "countdown-block.png");
        countdownTimerBlock.add(countdownBlock);

        // countDown
        const countDown = new Text(scene, 0, 0);
        countDown.visible = true;
        countDown.text = "00:00";
        countDown.setStyle({ "color": "#1F1A18", "fontSize": "16px", "fontStyle": "bold" });
        countdownTimerBlock.add(countDown);

        // lootBoxIcon
        const lootBoxIcon = new LootBoxIcon(scene, -145, 346);
        this.add(lootBoxIcon);

        // getMoreBoxes
        const getMoreBoxes = scene.add.container(51, 361);
        getMoreBoxes.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(getMoreBoxes);

        // boxes_button_background_png
        const boxes_button_background_png = scene.add.image(-8, 6, "bottom_console", "boxes-button-background.png");
        getMoreBoxes.add(boxes_button_background_png);

        // getMoreBoxesButton
        const getMoreBoxesButton = scene.add.image(-8, 8, "bottom_console", "get-more-boxes-button.png");
        getMoreBoxes.add(getMoreBoxesButton);

        this.otaGoldAmount = otaGoldAmount;
        this.goldRate = goldRate;
        this.rmxRate = rmxRate;
        this.rmxGoldAmount = rmxGoldAmount;
        this.claimButton = claimButton;
        this.countDown = countDown;
        this.countdownTimerBlock = countdownTimerBlock;
        this.lootBoxIcon = lootBoxIcon;
        this.getMoreBoxesButton = getMoreBoxesButton;

        /* START-USER-CTR-CODE */
        // Write your code here
        /* END-USER-CTR-CODE */
    }

    private otaGoldAmount: Text;
    private goldRate: Text;
    private rmxRate: Text;
    private rmxGoldAmount: Text;
    private claimButton: Phaser.GameObjects.Image;
    private countDown: Text;
    private countdownTimerBlock: Phaser.GameObjects.Container;
    private lootBoxIcon: LootBoxIcon;
    private getMoreBoxesButton: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private claimCountdownTimer?: Phaser.Time.TimerEvent;
    private accumulationTimer?: Phaser.Time.TimerEvent;
    private claimButtonComponent?: PushComponent;
    private currentOtaGold: number = 0;
    private currentRmx: number = 0;
    public eventBusComponent: EventBusComponent;

    public init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;

        this.claimButtonComponent = new PushComponent(this.claimButton, () => {
            trackButtonTap(BUTTON_NAMES.CLAIM, SCREEN_NAMES.MINING);
            this.handleClaim();
        });

        const user = this.scene.userManager.getData();
        if (user?.miningRate) {
            // Convert minute rates to daily rates
            const dailyRmx = user.miningRate.rmx * 60 * 24;
            const dailyOtaGold = user.miningRate.otaGold * 60 * 24;

            this.rmxRate.setText(`${formatCompactNumber(dailyRmx, { smallNumberDecimals: 2 })}/day`);
            this.goldRate.setText(`${formatCompactNumber(dailyOtaGold, { smallNumberDecimals: 2 })}/day`);
        }

        new PushComponent(this.getMoreBoxesButton, () => {
            if (!this.scene.userManager.isTutorialCompleted()) return;
            this.scene.scene.start("SlotsBoot");
        }).activate();

        this.updateBottomConsole();

        this.lootBoxIcon.init();
        this.lootBoxIcon.on(CUSTOM_EVENTS.SYNC_USER, async () => {
            this.emit(CUSTOM_EVENTS.SYNC_USER);
        });

        // Make sure the scene calls our update method
        this.scene.events.on("update", this.update, this);
    }

    private startAccumulationTimer() {
        if (this.accumulationTimer) {
            this.accumulationTimer.destroy();
        }

        const user = this.scene.userManager.getData();
        if (!user?.miningRate) return;

        const updateAccumulation = () => {
            const now = Date.now();
            const user = this.scene.userManager.getData();
            if (!user) return;

            const { storagePercent, safeDiffMinutes, safeDiffSeconds } = getStoragePercent(user.lastClaimedAt, now);

            // Stop updating if storage is full
            if (storagePercent >= 100) {
                this.accumulationTimer?.destroy();
                return;
            }

            // Calculate total minutes including fractional minutes from seconds
            const MAX_MINUTE_PER_MINING = 60 * 24; // 1440 minutes
            const totalMinutes = safeDiffMinutes + safeDiffSeconds / 60;
            const minutesLimited = Math.min(totalMinutes, MAX_MINUTE_PER_MINING);

            // Calculate accumulated amounts based on precise minutes
            const dailyOtaGold = (user.miningRate?.otaGold || 0) * 60 * 24;
            const dailyRmx = (user.miningRate?.rmx || 0) * 60 * 24;

            // Calculate current amounts using the same rate-per-minute logic as backend
            this.currentOtaGold = (user.miningRate?.otaGold || 0) * minutesLimited;
            this.currentRmx = (user.miningRate?.rmx || 0) * minutesLimited;

            this.updateAmountTexts(dailyOtaGold, dailyRmx);
        };

        updateAccumulation();

        this.accumulationTimer = this.scene.time.addEvent({
            delay: 1000,
            callback: updateAccumulation,
            loop: true,
        });
    }

    private updateBottomConsole() {
        const user = this.scene.userManager.getData();
        if (!user) return;

        const now = Date.now();
        const lastClaimedAt = user.lastClaimedAt;
        const diffInSeconds = Math.floor((now - lastClaimedAt) / 1000);
        const remainingSeconds = Math.max(0, 60 - diffInSeconds);

        // Initialize current values using the same calculation as accumulation timer
        const { safeDiffMinutes } = getStoragePercent(lastClaimedAt, now);
        const MAX_MINUTE_PER_MINING = 60 * 24; // 1440 minutes
        const minutesLimited = Math.min(safeDiffMinutes, MAX_MINUTE_PER_MINING);

        // Calculate daily and current amounts
        const dailyOtaGold = (user.miningRate?.otaGold || 0) * 60 * 24;
        const dailyRmx = (user.miningRate?.rmx || 0) * 60 * 24;

        this.currentOtaGold = (user.miningRate?.otaGold || 0) * minutesLimited;
        this.currentRmx = (user.miningRate?.rmx || 0) * minutesLimited;

        // Update display
        this.updateAmountTexts(dailyOtaGold, dailyRmx);

        // Start accumulation timer
        this.startAccumulationTimer();

        if (remainingSeconds === 0) {
            this.showClaimButton();
        }
    }

    private showClaimButton() {
        this.countdownTimerBlock.setVisible(false);
        this.claimButton.setVisible(true);
        this.claimButtonComponent?.activate();
    }

    private updateAmountTexts(dailyOtaGold: number, dailyRmx: number) {
        this.otaGoldAmount.setText(
            `${formatCompactNumber(this.currentOtaGold, { smallNumberDecimals: 2 })}/${formatCompactNumber(dailyOtaGold, { smallNumberDecimals: 2 })}`,
        );
        this.rmxGoldAmount.setText(
            `${formatCompactNumber(this.currentRmx, { smallNumberDecimals: 2 })}/${formatCompactNumber(dailyRmx, { smallNumberDecimals: 2 })}`,
        );
    }

    async handleClaim() {
        if (!this.scene.userManager.isTutorialCompleted()) return;

        EventBus.emit(CUSTOM_EVENTS.CLAIM_REWARD);
        const popupContainer = new ClaimPopup(this.scene, 0, 0);
        popupContainer.setAlpha(0);
        popupContainer.setDepth(4);
        this.scene.add.existing(popupContainer);

        this.scene.tweens.add({
            targets: popupContainer,
            alpha: { from: 0, to: 1 },
            duration: 150,
            onComplete: () => {
                popupContainer.claimReward();
            },
        });

        popupContainer.once("destroy", () => {
            if (this.claimCountdownTimer) {
                this.claimCountdownTimer.destroy();
                this.claimCountdownTimer = undefined;
            }
            this.updateBottomConsole();
            this.eventBusComponent.emit(CUSTOM_EVENTS.CLAIM_REWARD_SUCCESS);
        });
    }

    public update() {
        const user = this.scene.userManager.getData();
        if (!user) return;

        const now = Date.now(); // Get fresh timestamp every frame
        const lastClaimedAt = user.lastClaimedAt;
        const diffInSeconds = Math.floor((now - lastClaimedAt) / 1000);
        const remainingSeconds = Math.max(0, 60 - diffInSeconds);

        // Format time as M:SS
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;
        const timeString = `${minutes}:${seconds.toString().padStart(2, "0")}`;

        // Update the countdown text
        this.countDown.setText(timeString);

        // Show claim button if time is up
        if (remainingSeconds === 0) {
            this.showClaimButton();
        } else {
            this.countdownTimerBlock.visible = true;
            this.claimButton.visible = false;
            this.claimButtonComponent?.deactivate();
        }
    }

    public destroy() {
        // Clean up when the scene is destroyed
        this.scene.events.off("update", this.update, this);
        if (this.accumulationTimer) {
            this.accumulationTimer.destroy();
        }
        if (this.claimCountdownTimer) {
            this.claimCountdownTimer.destroy();
        }
        super.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
