import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/mining-boss]");

export type BossFightInfoResponse = components["schemas"]["GetBossFightInfoResponseDto"];
export type FightBossResponse = components["schemas"]["FightBossResponseDto"];

export const getMiningBossFightInfo = async (): Promise<BossFightInfoResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/mining-boss");

    if (error) {
        logger.error("[getMiningBossFightInfo]", error);
        return;
    }

    return data as BossFightInfoResponse;
};

export const fightMiningBoss = async (): Promise<FightBossResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/mining-boss/fight-boss");

    if (error) {
        logger.error("[fightMiningBoss]", error);
        return;
    }

    return data as FightBossResponse;
};
