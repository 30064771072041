// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class NextChapter extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // chapterIntro
        const chapterIntro = scene.add.image(0, 0, "_MISSING");
        this.add(chapterIntro);

        this.chapterIntro = chapterIntro;

        /* START-USER-CTR-CODE */
        chapterIntro.setInteractive();
        chapterIntro.once("pointerdown", () => {
            this.destroy();
        });

        if (this.chapterConfig) {
            chapterIntro.setTexture(this.chapterConfig.background.intro);
        }

        /* END-USER-CTR-CODE */
    }

    private chapterIntro: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    get chapterConfig() {
        return this.scene.chapterManager.getData();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
