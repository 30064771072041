interface FormatCompactNumberOptions {
    smallNumberDecimals?: number; // undefined = default behavior, 1 = one decimal, 2 = two decimals, etc.
}

export function formatCompactNumber(value: number, options: FormatCompactNumberOptions = {}): string {
    const { smallNumberDecimals } = options;

    if (value >= 1e9) {
        return `${(value / 1e9).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}B`;
    } else if (value >= 1e6) {
        return `${(value / 1e6).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}M`;
    } else if (value >= 1e3) {
        return `${(value / 1e3).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}K`;
    }

    return typeof smallNumberDecimals === "number"
        ? value.toLocaleString(undefined, {
              minimumFractionDigits: smallNumberDecimals,
              maximumFractionDigits: smallNumberDecimals,
          })
        : value.toLocaleString();
}
