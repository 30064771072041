export const chapter6 = {
    id: 6,
    boss: {
        name: "Razorlord",
        idleAnimationKey: "boss_06_walk",
        walkAnimationKey: "boss_06_walk",
        jumpAnimationKey: "boss_06_walk",
        attackAnimationKey: "boss_06_attack",
        gethitAnimationKey: "boss_06_gethit",
        destroyedAnimationKey: "boss_06_dead",
        size: {
            scale: 0.4,
        },
        position: {
            y: -15,
            attack: {
                y: -70,
            },
            dead: {
                y: -25,
            },
        },
        texture: ["boss_06_walk", "alien1-walk22.png"],
    },
    creep: {
        walkAnimationKey: "creep_06_walk",
        attackAnimationKey: "creep_06_attack",
        destroyedAnimationKey: "creep_06_die",
        size: {
            scale: 0.2,
        },
        position: {
            y: 0,
        },
        body: {
            offset: {
                x: -70,
                h: -70,
            },
        },
        texture: ["creep_06_walk", "alien_7-walk0.png"],
    },
    background: {
        mining: "chapter_06_bg",
        boss: "chapter_06_bg",
        intro: "chapter_06_intro",
    },
};
