import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[shared/utils/local-storage]");

export class LocalStorage<T> {
    private key: string;
    private initValue: T;

    constructor(key: string, initValue: T) {
        this.key = key;
        this.initValue = initValue;
    }

    getData() {
        try {
            const data = localStorage.getItem(this.key);
            if (data !== null) {
                return JSON.parse(data);
            }
        } catch (error) {
            logger.error(`Failed to get data for key ${this.key}:`, error);
        }

        return this.initValue;
    }

    setData(data: T) {
        try {
            localStorage.setItem(this.key, JSON.stringify(data));
        } catch (error) {
            logger.error(`Failed to set data for key ${this.key}:`, error);
        }
    }
}
