import { gamePerformanceConfig } from "@/game.performance";
import { SoundConfig } from "../../types/game.performance";

export interface SoundOptions extends Phaser.Types.Sound.SoundConfig {
    baseVolume?: number;
}

export const getOptimizedSoundConfig = (options: SoundOptions = {}): (SoundOptions & SoundConfig) | undefined => {
    if (!gamePerformanceConfig.shouldPlaySound()) {
        return;
    }

    const config = gamePerformanceConfig.getSoundConfig();
    const baseVolume = options.baseVolume ?? options.volume ?? 1;

    return {
        ...options,
        // For example, if a baseVolume of 0.4 for music, and the device optimization sets the volume scale to 0.5 for low-end devices, the final volume would be 0.2 (0.4 * 0.5).
        volume: baseVolume * config.volume,
        rate: config.rate,
    };
};
