import { AUTO, Scale, Types } from "phaser";

export const gameConfig: Types.Core.GameConfig = {
    type: AUTO,
    backgroundColor: "#000000",
    scale: {
        mode: Scale.FIT,
        autoCenter: Scale.CENTER_BOTH,
        width: 390,
        height: 844,
        parent: "game-container",
    },
    physics: {
        default: "arcade",
        arcade: {
            debug: false,
            gravity: { y: 0, x: 0 },
        },
    },
    render: {
        pixelArt: false,
        antialias: true,
        antialiasGL: true,
        roundPixels: true,
        mipmapFilter: "LINEAR_MIPMAP_LINEAR",
    },
};
