// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Boss from "../Boss";
import Text from "../../../../shared/prefabs/Text";
import ChargingCountdown from "./ChargingCountdown";
/* START-USER-IMPORTS */
import PushComponent from "../../components/animations/PushComponent";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "../../components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class BoostMining extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 204, y ?? 121);

        // image_1
        const image_1 = scene.add.image(4, 0, "boss_level", "boss_bg.png");
        this.add(image_1);

        // boss
        const boss = new Boss(scene, 4, -2);
        boss.scaleX = 0.25;
        boss.scaleY = 0.25;
        this.add(boss);

        // fightButton
        const fightButton = new Text(scene, 7, 12);
        fightButton.visible = false;
        fightButton.text = "Fight Boss";
        fightButton.setStyle({ color: "#D8E63C", fontSize: "14px" });
        fightButton.setWordWrapWidth(30);
        this.add(fightButton);

        // chargingCountdown
        const chargingCountdown = new ChargingCountdown(scene, 2, 12);
        this.add(chargingCountdown);

        this.boss = boss;
        this.fightButton = fightButton;
        this.chargingCountdown = chargingCountdown;

        /* START-USER-CTR-CODE */
        this.setInteractive(new Phaser.Geom.Rectangle(-50, -50, 100, 150), Phaser.Geom.Rectangle.Contains);
        this.on("pointerdown", () => {
            if (this.chapterData.isExceedChapter) return;
            if (this.scene.userManager.getData()) {
                EventBus.emit(CUSTOM_EVENTS.TAP);
                this.scene.scene.start("MiningBossCharger");
            }
        });
        this.chargingCountdown.text.setStyle({ fontSize: "10px" });
        /* END-USER-CTR-CODE */
    }

    private boss: Boss;
    private fightButton: Text;
    private chargingCountdown: ChargingCountdown;

    /* START-USER-CODE */

    get chapterData() {
        return this.scene.userManager.getChapterData();
    }

    updateBossFightStatus() {
        const fightButton = this.fightButton;
        const chargingCountdown = this.chargingCountdown;
        this.chargingCountdown.updateBossFightStatus(() => {
            if (this.chapterData.isExceedChapter) {
                this.fightButton.text = "Comming Soon";
            }
            fightButton.visible = true;
            chargingCountdown.visible = false;
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
