import { BetConfig } from "./types";

export function getAppropriateLevel(level: number, betsByLevel: Record<number, number[]>): number {
    const levelKeys = Object.keys(betsByLevel)
        .map(Number)
        .sort((a, b) => a - b);
    return (
        levelKeys.find(
            (key, index) => level >= key && (index === levelKeys.length - 1 || level < levelKeys[index + 1]),
        ) || levelKeys[0]
    );
}

export function getBetsForLevel(level: number, betsByLevel: Record<number, number[]>): number[] {
    const appropriateLevel = getAppropriateLevel(level, betsByLevel);
    return betsByLevel[appropriateLevel];
}

export function getNewBetIndex(config: BetConfig): number {
    const newAllowedBets = getBetsForLevel(config.currentLevel, config.betsByLevel);

    if (!config.currentBet) {
        return Math.min(config.currentBetIndex, newAllowedBets.length - 1);
    }

    const newBetIndex = newAllowedBets.indexOf(config.currentBet);

    if (newBetIndex === -1) {
        // If previous bet doesn't exist, find next highest bet
        const nextHighestBet = newAllowedBets.find((bet) => bet > config.currentBet!);
        if (nextHighestBet) {
            return newAllowedBets.indexOf(nextHighestBet);
        }
        // If no higher bet exists, keep same index if possible
        return Math.min(config.currentBetIndex, newAllowedBets.length - 1);
    }

    return newBetIndex;
}

export function hasNewBets(newLevel: number, previousLevel: number, betsByLevel: Record<number, number[]>): boolean {
    const previousBets = getBetsForLevel(previousLevel, betsByLevel);
    const newBets = getBetsForLevel(newLevel, betsByLevel);

    return newBets.some((bet) => !previousBets.includes(bet));
}
