// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { OPTIONS, symbolMappingId } from "../../constants";
import { HighlightPaylinesAnimationsManager } from "../../highlight-paylines-animations-manager";
import { SlotMachineResponse } from "@/lib/api/telegram-backend/client";
import { sleep } from "@/lib/utils";
import { createTween } from "@/utils/animation-utils";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import { SpinData } from "@/ota-slots/types";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[ota-slots/prefabs/normal-spin/Reels]");
/* END-USER-IMPORTS */

export default class Reels extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // frameBlackBackgroundOffset
        const frameBlackBackgroundOffset = scene.add.rectangle(0, 258, 124, 150);
        frameBlackBackgroundOffset.scaleX = 2.97292899783872;
        frameBlackBackgroundOffset.scaleY = 0.23252424087130902;
        frameBlackBackgroundOffset.setOrigin(0, 0);
        frameBlackBackgroundOffset.alpha = 0.8;
        frameBlackBackgroundOffset.isFilled = true;
        frameBlackBackgroundOffset.fillColor = 0;
        frameBlackBackgroundOffset.fillAlpha = 0;
        frameBlackBackgroundOffset.strokeColor = 0;
        frameBlackBackgroundOffset.strokeAlpha = 0;
        frameBlackBackgroundOffset.lineWidth = 0;
        this.add(frameBlackBackgroundOffset);

        // frameBlackBackground
        const frameBlackBackground = scene.add.rectangle(0, -2, 124, 131);
        frameBlackBackground.scaleX = 2.97292899783872;
        frameBlackBackground.scaleY = 1.9498947228143622;
        frameBlackBackground.setOrigin(0, 0);
        frameBlackBackground.isFilled = true;
        frameBlackBackground.fillColor = 0;
        frameBlackBackground.fillAlpha = 0;
        frameBlackBackground.strokeColor = 0;
        frameBlackBackground.strokeAlpha = 0;
        frameBlackBackground.lineWidth = 0;
        this.add(frameBlackBackground);

        this.frameBlackBackgroundOffset = frameBlackBackgroundOffset;
        this.frameBlackBackground = frameBlackBackground;

        /* START-USER-CTR-CODE */
        // this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        this.setupReels();
        this.createMask();
        this.highlightPaylinesManager = new HighlightPaylinesAnimationsManager(this);
        /* END-USER-CTR-CODE */
    }

    private frameBlackBackgroundOffset: Phaser.GameObjects.Rectangle;
    private frameBlackBackground: Phaser.GameObjects.Rectangle;
    public isAuto: boolean = false;

    /* START-USER-CODE */

    public reelContainers: Phaser.GameObjects.Container[] = [];
    private highlightPaylinesManager: HighlightPaylinesAnimationsManager;

    createMask() {
        const maskShape = this.scene.add.graphics();
        maskShape.fillStyle(0xffffff);

        maskShape.fillRect(
            this.x,
            this.y,
            this.frameBlackBackground.displayWidth,
            this.frameBlackBackground.displayHeight,
        );

        maskShape.visible = false;

        const mask = maskShape.createGeometryMask();

        this.reelContainers.forEach((reelContainer) => {
            reelContainer.setMask(mask);
            reelContainer.setDepth(1);
        });
    }

    setupReelContainers() {
        const cellWidth = this.frameBlackBackground.displayWidth / OPTIONS.cols;
        const cellHeight = this.frameBlackBackground.displayHeight / OPTIONS.rows;

        const rectX = this.frameBlackBackground.x;
        const rectY = this.frameBlackBackground.y;

        for (let i = 0; i < OPTIONS.cols; i++) {
            const col = i % OPTIONS.cols;
            const row = Math.floor(i / OPTIONS.cols);
            const x = rectX + col * cellWidth + cellWidth / 2;
            const y = rectY + row * cellHeight + cellHeight / 2;
            const container = this.scene.add.container(x, 370);

            this.reelContainers.push(container);
        }

        this.add(this.reelContainers);
    }

    setupReels() {
        this.setupReelContainers();
        for (const reelContainer of this.reelContainers) {
            const symbols = Array.from({ length: OPTIONS.totalSymbols }, (_, i) => {
                const randomSymbol = this.getWeightedRandomSymbol();
                const sprite = this.scene.add.sprite(0, i * -OPTIONS.symbolHeight, "symbols", randomSymbol);
                sprite.scale = 0.24;
                (sprite as any).idx = i;
                return sprite;
            });
            reelContainer.add(symbols);
        }
    }

    setupRealWait() {
        const rows = 3;
        const columns = 5;
        const cellWidth = this.frameBlackBackground.displayWidth / columns;
        const rectX = this.frameBlackBackground.x;

        const x = rectX + 4 * cellWidth + cellWidth / 2;

        const sprite = this.scene.add.sprite(x, 140, "reel_wait", "reel_wait_00001.png");
        sprite.scale = 0.3;
        sprite.play({ key: "real_wait", repeat: 0 });
        sprite.once("animationcomplete", () => {
            sprite.destroy();
            this.reelContainers[this.reelContainers.length - 1].list.map(
                (item) => ((item as Phaser.GameObjects.Sprite).alpha = 1),
            );
        });
        this.reelContainers[this.reelContainers.length - 1].list.map(
            (item) => ((item as Phaser.GameObjects.Sprite).alpha = 0),
        );
        this.add(sprite);
    }

    cancelHighlightPaylines() {
        this.highlightPaylinesManager.cancelHighlightPaylines();
    }

    mockSpinReels({ isAutoFast = false }: { isAutoFast?: boolean } = {}) {
        EventBus.emit(CUSTOM_EVENTS.REEL_SPINNING);

        const spinningReelsTween = this.reelContainers.map((reelContainer, reelIndex) => {
            return createTween(this.scene, reelContainer, {
                props: {
                    y: {
                        value: "+=" + OPTIONS.symbolHeight,
                        duration: isAutoFast ? OPTIONS.duration / 2 : OPTIONS.duration,
                    },
                },
                repeat: -1,
                onRepeat: function () {
                    this.updateTo("y", this.targets[0].y + OPTIONS.symbolHeight, true);
                    this.targets[0].first.y = this.targets[0].last.y - OPTIONS.symbolHeight;
                    const symbol = this.targets[0].first;

                    const randomSymbolId = Phaser.Math.Between(1, 11).toString() as keyof typeof symbolMappingId;
                    const randomSymbolKey = symbolMappingId[randomSymbolId];
                    const randomSymbol = `symbol_${randomSymbolId}_${randomSymbolKey}_2.png`;

                    symbol.setVisible(true).setTexture("symbols_blurry", randomSymbol);
                    this.targets[0].moveTo(symbol, OPTIONS.totalSymbols - 1);
                },
                onStop: function () {
                    reelContainer.y = 370;
                    reelContainer.list.forEach((sprite: any, idx) => {
                        sprite.y = idx * -OPTIONS.symbolHeight;
                        sprite.idx = idx;
                    });
                },
            });
        });

        return () => {
            spinningReelsTween.forEach((tween) => tween?.stop());
        };
    }

    async spinReels(spinData: SpinData) {
        const highlightPaylines = this.highlightPaylines.bind(this);
        const highlightBonusAndFreeSymbols = this.highlightBonusAndFreeSymbols.bind(this);
        const getWeightedRandomSymbol = this.getWeightedRandomSymbol.bind(this);

        return new Promise<void>(async (resolve) => {
            if (!spinData) {
                logger.error("[spinReels] No slot result");
                return;
            }

            const { spinResult, isAutoFast } = spinData;

            this.reelContainers.forEach((reelContainer, reelIndex) => {
                const isLastReel = reelIndex === this.reelContainers.length - 1;

                createTween(this.scene, reelContainer, {
                    props: {
                        y: {
                            value: "+=" + OPTIONS.symbolHeight,
                            duration: isAutoFast ? OPTIONS.duration / 2 : OPTIONS.duration,
                        },
                    },
                    repeat: OPTIONS.repeat[reelIndex],
                    onRepeat: function () {
                        this.updateTo("y", this.targets[0].y + OPTIONS.symbolHeight, true);
                        this.targets[0].first.y = this.targets[0].last.y - OPTIONS.symbolHeight;
                        const symbol = this.targets[0].first;

                        const randomSymbol = getWeightedRandomSymbol(true);

                        if (spinResult[reelIndex] == null) return;

                        const symbolIdx = symbol.idx <= OPTIONS.rows ? Math.abs(symbol.idx - OPTIONS.rows) : symbol.idx;

                        let finalSymbol = spinResult[reelIndex][symbolIdx];

                        finalSymbol = finalSymbol ? `${finalSymbol}_2.png` : randomSymbol;

                        symbol.setVisible(true).setTexture("symbols_blurry", finalSymbol);

                        this.targets[0].moveTo(symbol, OPTIONS.totalSymbols - 1);
                    },
                    onComplete: function () {
                        this.targets[0].scene.tweens.add({
                            targets: this.targets[0],
                            props: {
                                y: {
                                    value: "-=" + OPTIONS.symbolHeight,
                                    duration: isAutoFast ? OPTIONS.duration / 2 : OPTIONS.duration * 2,
                                },
                            },
                            repeat: 1,
                            onRepeat: function () {
                                this.updateTo("y", this.targets[0].y - OPTIONS.symbolHeight, true);
                            },
                            onComplete: function () {
                                this.targets[0].last.y = this.targets[0].first.y + OPTIONS.symbolHeight;
                                const symbol = this.targets[0].last;
                                this.targets[0].moveTo(symbol, 0);
                                //set texture symbols
                                for (let symbolIndex = 0; symbolIndex < OPTIONS.totalSymbols; symbolIndex++) {
                                    const symbolsName = this.targets[0].list[symbolIndex].frame.name;

                                    this.targets[0].list[symbolIndex].setTexture(
                                        "symbols",
                                        symbolsName.replace("_2.png", ".png"),
                                    );
                                }
                                reelContainer.list.forEach((sprite: any, idx) => {
                                    sprite.idx = idx;
                                });
                                EventBus.emit(CUSTOM_EVENTS.CASH_REGISTER);
                                if (isLastReel) {
                                    highlightBonusAndFreeSymbols(spinData, () => {
                                        resolve();
                                        highlightPaylines(spinData);
                                    });
                                }
                            },
                        });
                    },
                });
            });
        });
    }

    getWeightedRandomSymbol(blurry: boolean = false): string {
        const randomSymbolId = Phaser.Math.Between(1, 12).toString() as keyof typeof symbolMappingId;
        const randomSymbolKey = symbolMappingId[randomSymbolId];
        const randomSymbol = `symbol_${randomSymbolId}_${randomSymbolKey}${blurry ? "_2" : ""}.png`;
        return randomSymbol;
    }

    async highlightBonusAndFreeSymbols(spinData: SlotMachineResponse, resolve?: () => void) {
        this.highlightPaylinesManager.isHighlightingPaylines = true;

        const bonusFreeSymbols = this.highlightPaylinesManager.getBonusFreeSymbols(spinData);

        const animtatioBonusFreeSymbols = bonusFreeSymbols.bonus.map((symbol) =>
            this.highlightPaylinesManager.animateSymbol(symbol, false),
        );
        const animtatioFreeSymbols = bonusFreeSymbols.free.map((symbol) =>
            this.highlightPaylinesManager.animateSymbol(symbol, false),
        );

        const isValidBonusSymbol = bonusFreeSymbols.bonus.some((symbol) => symbol.match);
        if (isValidBonusSymbol) {
            EventBus.emit(CUSTOM_EVENTS.BONUS_SYMBOL);
        }

        await Promise.all([...animtatioBonusFreeSymbols, ...animtatioFreeSymbols]);

        resolve?.();
    }

    async highlightPaylines(spinData: SlotMachineResponse, resolve?: () => void) {
        this.highlightPaylinesManager.isHighlightingPaylines = true;
        const signal = this.highlightPaylinesManager.createAnimationController();

        const showNextPayline = async (index: number) => {
            if (!this.highlightPaylinesManager || index >= spinData.winningPaylines.length) {
                throw new Error("Error");
            }

            const win = spinData.winningPaylines[index];
            if (!win) return;

            const paylineSymbols = this.highlightPaylinesManager.getPaylineSymbols(spinData, win);

            const animtationSymbols = paylineSymbols.map((symbol) =>
                this.highlightPaylinesManager.animateSymbol(symbol, true, signal),
            );

            const destroyPayline = this.highlightPaylinesManager.animatePayline(win, signal);

            if (!signal.aborted && !((spinData.isFreeSpinTrigger || spinData.isPiggyJackpotTrigger) && index === 0)) {
                EventBus.emit(CUSTOM_EVENTS.PAYLINE_HIGHLIGHT);
            }

            const destroyAnimations = await Promise.all(animtationSymbols);
            destroyAnimations.map((destroy) => destroy());

            await sleep(400);

            destroyPayline();

            await sleep(500);
        };

        try {
            if (spinData.winningPaylines.length === 0) {
                resolve?.();
                return;
            }
            for (let index = 0; index < spinData.winningPaylines.length; index++) {
                await showNextPayline(index);
            }
        } catch (e) {
            resolve?.();
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
