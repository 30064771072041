import Phaser from "phaser";
import { InputComponent } from "../input/InputComponent";
import { CUSTOM_EVENTS, EventBusComponent } from "../events/EventBusComponent";
import { WeaponId } from "@/shared/types/loot-box";
import { EventBus } from "@/lib/event-bus";

interface BulletConfig {
    damage: number;
    speed: number;
    interval: number;
    lifespan: number;
    maxCount: number;
    xOffset: number;
    flipX: boolean;
}

/**
 * The `WeaponComponent` is used for enabling a game object to fire bullets
 * in our game. When input is detected from the provided `InputComponent`, this
 * component will check if we are able to fire a new bullet and do so based on the
 * fire interval and the max number of bullets that can be on screen at a time for
 * the attached game object.
 *
 * The bullet game objects are managed via a Phaser 3 Group, that way we can create
 * a simple object pool.
 */
export class PlayerWeaponComponent {
    private gameObject: Phaser.GameObjects.Container;
    private inputComponent: InputComponent;
    public bulletGroup: Phaser.GameObjects.Group;
    private fireBulletInterval: number;
    private bulletConfig: BulletConfig;
    private eventBusComponent: EventBusComponent;
    public weaponId: WeaponId;

    constructor(
        gameObject: Phaser.GameObjects.Container,
        inputComponent: InputComponent,
        bulletConfig: BulletConfig,
        eventBusComponent: EventBusComponent,
        weaponId: WeaponId,
    ) {
        this.gameObject = gameObject;
        this.inputComponent = inputComponent;
        this.bulletConfig = bulletConfig;
        this.eventBusComponent = eventBusComponent;
        this.fireBulletInterval = 0;
        this.weaponId = weaponId;

        this.bulletGroup = this.gameObject.scene.physics.add.group({
            name: `bullets-${Phaser.Math.RND.uuid()}`,
            enable: false,
        });
        this.bulletGroup.createMultiple({
            key: "bullet",
            quantity: this.bulletConfig.maxCount,
            active: false,
            visible: false,
        });

        this.gameObject.scene.physics.world.on(Phaser.Physics.Arcade.Events.WORLD_STEP, this.worldStep, this);
        this.gameObject.once(
            Phaser.GameObjects.Events.DESTROY,
            () => {
                this.gameObject.scene.physics.world.off(Phaser.Physics.Arcade.Events.WORLD_STEP, this.worldStep, this);
            },
            this,
        );
    }

    get damage(): number {
        return this.bulletConfig.damage;
    }

    set xOffset(value: number) {
        this.bulletConfig.xOffset = value;
    }

    update(dt: number): void {
        this.fireBulletInterval -= dt;
        if (this.fireBulletInterval > 0) {
            return;
        }

        if (this.inputComponent.shootIsDown) {
            const bullet = this.bulletGroup.getFirstDead();
            if (bullet === undefined || bullet === null) {
                return;
            }

            const x = this.gameObject.x + this.bulletConfig.xOffset;
            const y = this.gameObject.y + 1;
            bullet.enableBody(true, x, y, true, true);
            bullet.body.velocity.x += this.bulletConfig.speed; // Changed to x velocity
            bullet.setState(this.bulletConfig.lifespan);
            // bullet.play("bullet");
            bullet.setScale(1);
            bullet.body.setSize(18, 14); // Swapped width/height for horizontal orientation
            // bullet.setAngle(90); // Rotate bullet 90 degrees for horizontal orientation
            bullet.setFlipX(this.bulletConfig.flipX); // Changed to flipX

            this.fireBulletInterval = this.bulletConfig.interval;
            EventBus.emit(CUSTOM_EVENTS.PLAYER_SHOOT, this.weaponId);
        }
    }

    worldStep(delta: number): void {
        (this.bulletGroup.getChildren() as Phaser.Types.Physics.Arcade.SpriteWithDynamicBody[]).forEach((bullet) => {
            if (!bullet.active) {
                return;
            }

            const state = bullet.state as number;
            bullet.setState(state - delta);
            if (Number(bullet.state) <= 0) {
                bullet.disableBody(true, true);
            }
        });
    }

    destroyBullet(
        bullet: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody,
        {
            animationKey = "explosion",
            scale = 0.25,
        }: {
            animationKey?: string;
            scale?: number;
        } = {},
    ): void {
        const explosion = this.gameObject.scene.add.sprite(
            bullet.x + 25,
            bullet.y + Phaser.Math.RND.between(-20, 0),
            "explosion",
        );
        explosion.setScale(scale);
        explosion.play(animationKey);
        bullet.setState(0);
    }
}
