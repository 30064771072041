// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import SkipButton from "./SkipButton";
import Text from "../Text";
/* START-USER-IMPORTS */
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import Circle from "./Circle";
/* END-USER-IMPORTS */

export default class Step4 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // tutorial_bg_png
        const tutorial_bg_png = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        this.add(tutorial_bg_png);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -379);
        this.add(skipButton_1);

        // head_png
        const head_png = scene.add.image(-150, -23, "tutorial", "Head.png");
        this.add(head_png);

        // message1
        const message1 = scene.add.container(-118, -45);
        this.add(message1);

        // boxMessage1
        const boxMessage1 = scene.add.image(146, 52, "tutorial", "box_message_2.png");
        boxMessage1.scaleY = 0.8;
        message1.add(boxMessage1);

        // content1
        const content1 = new Text(scene, 149, 49);
        content1.text = "Great!\n\nYou've learned how to claim REMIS and GOLD from Storage.";
        content1.setWordWrapWidth(275);
        message1.add(content1);

        // message2
        const message2 = scene.add.container(-116, 80);
        message2.alpha = 0;
        this.add(message2);

        // boxMessage2
        const boxMessage2 = scene.add.image(115, 39, "tutorial", "box_message_2.png");
        boxMessage2.scaleX = 0.8;
        boxMessage2.scaleY = 0.6;
        message2.add(boxMessage2);

        // content2
        const content2 = new Text(scene, 107, 37);
        content2.text = "Next up: Fight bosses to boost your mining rate!";
        content2.setLineSpacing(4);
        content2.setWordWrapWidth(220);
        message2.add(content2);

        // arrow
        const arrow = scene.add.container(-179, -185);
        arrow.alpha = 0;
        this.add(arrow);

        // tap_1
        const tap_1 = new Text(scene, 79, -2);
        tap_1.text = "Tap to Fight";
        tap_1.setStyle({ "color": "#D8E63C", "fontSize": "26px", "fontStyle": "bold" });
        arrow.add(tap_1);

        // tap
        const tap = scene.add.image(176, -1, "tutorial", "arrow.png");
        tap.angle = -90;
        arrow.add(tap);

        this.message1 = message1;
        this.message2 = message2;
        this.tap = tap;
        this.arrow = arrow;

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    private message1: Phaser.GameObjects.Container;
    private message2: Phaser.GameObjects.Container;
    private tap: Phaser.GameObjects.Image;
    private arrow: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    async init(eventBusComponent: EventBusComponent) {
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.message2,
                y: { from: 90, to: 80 },
                alpha: { from: 0, to: 1 },
                duration: 400,
                onComplete: () => resolve(),
            });
        });

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.arrow,
                alpha: { from: 0, to: 1 },
                duration: 400,
                delay: 200,
                onComplete: () => resolve(),
            });
        });

        const circle = new Circle(this, this.scene, 325, 240, 164);
        this.add(circle);
        circle.show();
        circle.onClick(this.fightBoss);

        this.scene.tweens.add({
            targets: this.tap,
            x: {
                value: "+=10",
            },
            duration: 500,
            yoyo: true,
            repeat: -1,
        });
    }

    fightBoss() {
        this.scene.userManager.syncIntroStep("4");
        this.scene.scene.start("MiningBoss");
        this.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
