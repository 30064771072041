import { LocalStorage } from "../utils/local-storage";
import { DataManager } from "./data-manager";

const defaultSettings = {
    sound: true,
    music: true,
    effect: true,
};

interface SettingsData {
    sound: boolean;
    music: boolean;
    effect: boolean;
}

const settingsStorage = new LocalStorage<SettingsData>("settings", defaultSettings);

export class SettingsManager extends DataManager<SettingsData> {
    public key = "settings";

    async syncWithBackend() {
        const settings = settingsStorage.getData();
        this.setSettings(settings);
        return this.getData();
    }

    setSettings(settings: {
        sound?: boolean;
        music?: boolean;
        effect?: boolean;
    }) {
        const currentSettings = this.getData() as SettingsData;
        const newSettings = {
            ...currentSettings,
            ...settings,
        };
        this.setData(newSettings);
        settingsStorage.setData(newSettings);
        return newSettings;
    }

    toggleSound() {
        const currentSettings = this.getData();
        return this.setSettings({ sound: !currentSettings?.sound });
    }

    toggleMusic() {
        const currentSettings = this.getData();
        return this.setSettings({ music: !currentSettings?.music });
    }

    toggleEffect() {
        const currentSettings = this.getData();
        return this.setSettings({ effect: !currentSettings?.effect });
    }
}
