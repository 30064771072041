export const chapter3 = {
    id: 3,
    boss: {
        name: "Slitherfangrm",
        idleAnimationKey: "boss_03_attack",
        walkAnimationKey: "boss_03_walk",
        jumpAnimationKey: "boss_03_walk",
        attackAnimationKey: "boss_03_attack",
        gethitAnimationKey: "boss_03_gethit",
        destroyedAnimationKey: "boss_03_dead",
        size: {
            scale: 0.7,
        },
        position: {
            y: -5,
        },
        texture: ["boss_03_walk", "Property 1=boss_01_walk_F_01.png"],
    },
    creep: {
        walkAnimationKey: "creep_03_walk",
        attackAnimationKey: "creep_03_attack",
        destroyedAnimationKey: "creep_03_die",
        size: {
            scale: 1,
        },
        position: {
            y: 0,
        },
        texture: ["creep_03_walk", "Property 1=f_09.png"],
    },
    background: {
        mining: "chapter_03_bg",
        boss: "chapter_03_bg",
        intro: "chapter_03_intro",
    },
};
