import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/mission]");

type MissionResponse = components["schemas"]["MissionResponseDto"][];
export type Mission = components["schemas"]["MissionResponseDto"];
// TODO: Remove this once BE updates API schema
export type MissionRewardType = "ota_gold";

export const getMissions = async (): Promise<MissionResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/mission");

    if (error) {
        logger.error("[getMissions]", error);
        return;
    }

    return data as MissionResponse;
};

type ClaimMissionRequest = components["schemas"]["ClaimMissionDto"];
type ClaimedMissionResponse = components["schemas"]["MissionResponseDto"];

export const claimMission = async (missionData: ClaimMissionRequest): Promise<ClaimedMissionResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/mission", {
        body: missionData,
    });

    if (error) {
        logger.error("[claimMission]", error);
        return;
    }

    return data as ClaimedMissionResponse;
};
