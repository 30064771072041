import { gamePerformanceConfig } from "@/game.performance";
import { Scene } from "phaser";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[ota-mining/game/scenes/Boot]");

export default class Boot extends Scene {
    constructor() {
        super("MiningBoot");
    }

    init() {
        gamePerformanceConfig.initialize();
    }

    preload() {
        //  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
        //  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.

        this.load.pack("mining-boot-asset-pack", "ota-mining/mining-boot-asset-pack.json");
        this.load.pack("boot-asset-pack", "ota-slots/boot-asset-pack.json");

        this.load.webfont("Oxanium", {
            custom: {
                families: ["Oxanium"],
            },
            active: () => {
                logger.info("Oxanium font loaded");
            },
        });

        this.load.webfont("Saiba", {
            custom: {
                families: ["Saiba"],
            },
            active: () => {
                logger.info("Saiba font loaded");
            },
        });
    }

    create() {
        this.scene.start("MiningPreloader");
    }
}
