// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import PizzaBlack from "./PizzaBlack";
import PizzaGold from "./PizzaGold";
/* START-USER-IMPORTS */
import { WheelComponent } from "../components/WheelComponent";
import {
    doLuckyWheel,
    getLuckyWheel,
    LuckyWheelResponse,
    sortWheelPieces,
    WheelPiece,
} from "@/lib/api/telegram-backend/methods/lucky-wheel";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class Wheel extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // wheelContainer
        const wheelContainer = scene.add.container(0, 0);
        this.add(wheelContainer);

        // pizza_1
        const pizza_1 = new PizzaBlack(scene, 78.02869480927708, 53.23555322556615);
        pizza_1.angle = 90;
        wheelContainer.add(pizza_1);

        // pizza_2
        const pizza_2 = new PizzaGold(scene, 17.028704753895052, 92.24);
        pizza_2.angle = 135;
        wheelContainer.add(pizza_2);

        // pizza_3
        const pizza_3 = new PizzaBlack(scene, -52.971295246104944, 77.24);
        pizza_3.angle = -180;
        wheelContainer.add(pizza_3);

        // pizza_4
        const pizza_4 = new PizzaGold(scene, -90.97129524610494, 16.84);
        pizza_4.angle = -135;
        wheelContainer.add(pizza_4);

        // pizza_5
        const pizza_5 = new PizzaBlack(scene, -75.97130519072292, -53.76444677443385);
        pizza_5.angle = -90;
        wheelContainer.add(pizza_5);

        // pizza_6
        const pizza_6 = new PizzaGold(scene, -15.781295246104946, -92);
        pizza_6.angle = -45.00000000000006;
        wheelContainer.add(pizza_6);

        // pizza_7
        const pizza_7 = new PizzaBlack(scene, 54.03, -77.76);
        wheelContainer.add(pizza_7);

        // pizza_8
        const pizza_8 = new PizzaGold(scene, 93, -16.76);
        pizza_8.angle = 45;
        wheelContainer.add(pizza_8);

        // aqua_wheel_png
        const aqua_wheel_png = scene.add.image(0, -1, "lucky_wheel", "aqua_wheel.png");
        this.add(aqua_wheel_png);

        // pointer
        const pointer = scene.add.image(0, -125, "lucky_wheel", "pointer.png");
        this.add(pointer);

        // pin
        const pin = scene.add.image(0, 22, "lucky_wheel", "royal_center_pin.png");
        this.add(pin);

        // arrow_png
        const arrow_png = scene.add.image(103, -91, "lucky_wheel", "arrow.png");
        this.add(arrow_png);

        this.pizza_1 = pizza_1;
        this.pizza_2 = pizza_2;
        this.pizza_3 = pizza_3;
        this.pizza_4 = pizza_4;
        this.pizza_5 = pizza_5;
        this.pizza_6 = pizza_6;
        this.pizza_7 = pizza_7;
        this.pizza_8 = pizza_8;
        this.wheelContainer = wheelContainer;
        this.pointer = pointer;
        this.pin = pin;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private pizza_1: PizzaBlack;
    private pizza_2: PizzaGold;
    private pizza_3: PizzaBlack;
    private pizza_4: PizzaGold;
    private pizza_5: PizzaBlack;
    private pizza_6: PizzaGold;
    private pizza_7: PizzaBlack;
    private pizza_8: PizzaGold;
    private wheelContainer: Phaser.GameObjects.Container;
    private pointer: Phaser.GameObjects.Image;
    private pin: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    public static EVENTS = {
        SPIN_DONE: "spin_done",
        RESET_SPIN_DONE: "reset_spin_done",
    };
    luckyWheel?: LuckyWheelResponse;
    pizzas: (PizzaBlack | PizzaGold)[] = [];
    pieces: WheelPiece[] = [];

    async init(luckyWheel: LuckyWheelResponse) {
        if (!luckyWheel) return;
        this.luckyWheel = luckyWheel;

        this.setupPieces();

        const wheelComponent = new WheelComponent(this.scene, this.wheelContainer, this.pointer, this.pin);

        this.wheelContainer.bringToTop(this.pin);
        this.pin.removeAllListeners();
        this.pin.setInteractive({ useHandCursor: true });
        this.pin.on("pointerdown", async () => {
            if (!this.luckyWheel || wheelComponent.wheelSpinning) return;

            EventBus.emit(CUSTOM_EVENTS.SPIN_WHEEL);
            wheelComponent.wheelSpinning = true;
            const clearMockSpin = wheelComponent.mockSpinWheel();
            const claimLuckyWheel = await doLuckyWheel(this.luckyWheel.spinId);

            if (!claimLuckyWheel) {
                clearMockSpin();
                wheelComponent.wheelSpinning = false;
                return;
            }
            const currentLuckyWheel = this.luckyWheel;
            const isLastNormalSpin = this.luckyWheel.cycleNormalSpin === 2;

            let nextLuckyWheel: LuckyWheelResponse | undefined;
            if (!isLastNormalSpin) {
                nextLuckyWheel = await getLuckyWheel();
            }
            clearMockSpin();

            const sliceIndex = this.pieces.findIndex((p) => p.id === this.luckyWheel!.rewardReel.id);
            await wheelComponent.spinWheel(sliceIndex);
            if (typeof sliceIndex === "number" && this.pizzas[sliceIndex]) {
                this.pizzas[sliceIndex].highlight();
            }

            this.emit(Wheel.EVENTS.SPIN_DONE, "normal", claimLuckyWheel, currentLuckyWheel);
            if (nextLuckyWheel) {
                this.luckyWheel = nextLuckyWheel;
            }

            this.scene.time.delayedCall(5000, async () => {
                if (isLastNormalSpin) {
                    this.luckyWheel = undefined;
                } else {
                    this.setupPieces();
                }

                this.pizzas.forEach((pizza) => {
                    pizza.reset();
                });

                await wheelComponent.resetWheel();
                wheelComponent.wheelSpinning = false;
                this.emit(Wheel.EVENTS.RESET_SPIN_DONE, isLastNormalSpin);
            });
        });
    }

    setupPieces() {
        this.pieces = sortWheelPieces(this.luckyWheel!.reel);

        this.pizzas = [
            this.pizza_1,
            this.pizza_2,
            this.pizza_3,
            this.pizza_4,
            this.pizza_5,
            this.pizza_6,
            this.pizza_7,
            this.pizza_8,
        ];

        this.pieces.forEach((piece, index) => {
            const pizza = this.pizzas[index];
            pizza.setPiece(piece);
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
