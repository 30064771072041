import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/api/telegram-backend/methods/lucky-wheel]");

export type WheelPiece = components["schemas"]["ReelItemDto"];

export type LuckyWheelResponse = components["schemas"]["LuckyWheelResponseDto"];
export type ClaimLuckyWheelResponse = components["schemas"]["LuckyWheelClaimResponseDto"];

const PIECE_TYPE_ORDER = ["rmx", "usd", "ota_gold", "usd", "rmx", "usd", "ota_gold", "usd"];

export const sortWheelPieces = (pieces: WheelPiece[]) => {
    // Group pieces by their type
    const piecesByType: Record<string, WheelPiece[]> = {
        rmx: pieces.filter((p) => p.pieceType === "rmx"),
        usd: pieces.filter((p) => p.pieceType === "usd"),
        ota_gold: pieces.filter((p) => p.pieceType === "ota_gold"),
    };

    // Create the result array following the order
    const result = PIECE_TYPE_ORDER.map((type) => {
        const piecesOfType = piecesByType[type];
        if (!piecesOfType || piecesOfType.length === 0) {
            return null;
        }

        // Take the first piece and remove it from the array
        const piece = piecesOfType.shift();
        return piece;
    });

    return result as WheelPiece[];
};

export const getLuckyWheel = async (): Promise<LuckyWheelResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/luck-wheel");

    if (error) {
        logger.error("[getLuckyWheel]", error);
        return;
    }

    return data as LuckyWheelResponse;
};

export const doLuckyWheel = async (id: string): Promise<ClaimLuckyWheelResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/luck-wheel", {
        body: {
            id,
        },
    });

    if (error) {
        logger.error("[doLuckyWheel]", error);
        logger.alert(`The /luck-wheel API returned an error: ${(error as Error).message}. Please try again.`);
        return;
    }

    return data as ClaimLuckyWheelResponse;
};
