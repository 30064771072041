// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import { InventoryItem } from "@/shared/types/loot-box";
import { getItemTextureName, getRarityColor } from "@/shared/utils/loot-box";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class WeekerGem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 206.71859488375222, y ?? 129.47095924329028);

        // gem_bg
        const gem_bg = scene.add.image(0, 0, "loot_box", "gem_bg.png");
        this.add(gem_bg);

        // gem
        const gem = scene.add.image(0, 3, "blue_astrion");
        gem.scaleX = 0.1;
        gem.scaleY = 0.1;
        this.add(gem);

        // gemRank
        const gemRank = new Text(scene, -1, 30);
        gemRank.text = "SSR";
        gemRank.setStyle({ "color": "#DEA614", "fontSize": "16px", "stroke": "#DEA614" });
        this.add(gemRank);

        // gemLevel
        const gemLevel = new Text(scene, 0, 58);
        gemLevel.text = "LEVEL 0";
        gemLevel.setStyle({ "fontSize": "16px" });
        this.add(gemLevel);

        // coin_icon
        const coin_icon = scene.add.image(-27, -25, "coin_icon");
        coin_icon.scaleX = 0.15;
        coin_icon.scaleY = 0.15;
        this.add(coin_icon);

        // sellingPrice
        const sellingPrice = new Text(scene, -18, -31);
        sellingPrice.setOrigin(0, 0);
        sellingPrice.text = "0";
        sellingPrice.setStyle({ "fontSize": "10px" });
        this.add(sellingPrice);

        this.gem = gem;
        this.gemRank = gemRank;
        this.gemLevel = gemLevel;
        this.sellingPrice = sellingPrice;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private gem: Phaser.GameObjects.Image;
    private gemRank: Text;
    private gemLevel: Text;
    private sellingPrice: Text;

    /* START-USER-CODE */

    public item: InventoryItem;

    get gemItem() {
        return this.gem;
    }

    init(item: InventoryItem) {
        this.item = item;
        const itemTextureName = getItemTextureName(item);
        if (itemTextureName) {
            this.gem.setTexture("loot_box", itemTextureName + ".png");
            if (item.name === "weapon") {
                this.gem.scale = 0.1;
            }
        }
        this.gemRank.setText(item.rarity.toUpperCase());
        this.gemRank.setStyle({ color: getRarityColor(item) });
        this.gemLevel.setText(`LEVEL ${item.level}`);
        this.sellingPrice.setText(numberWithCommas(item.sellingPrice));
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
