// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { KeyboardInputComponent } from "../components/input/KeyboardInputComponent";
import { PlayerWeaponComponent } from "../components/weapon/PlayerWeaponComponent";
import { CUSTOM_EVENTS, EventBusComponent } from "../components/events/EventBusComponent";
import * as CONFIG from "../config";
import { ColliderComponent } from "../components/collider/ColliderComponent";
import { HealthComponent } from "../components/health/HealthComponent";
import Creep from "./Creep";
import { getWeaponId } from "@/shared/utils/loot-box";
import { WeaponId } from "@/shared/types/loot-box";
import { createTween, playAnimation } from "@/utils/animation-utils";
/* END-USER-IMPORTS */

export default class Player extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // player
        const player = scene.add.sprite(0, 0, "_MISSING");
        player.scaleX = 0.6;
        player.scaleY = 0.6;
        this.add(player);

        this.player = player;

        /* START-USER-CTR-CODE */
        // handle automatic call to update
        this.isInitialized = false;
        this.weapon = getWeaponId(scene.userManager.getWeapon());

        // this.setDepth(1);
        this.scene.events.on(Phaser.Scenes.Events.UPDATE, this.update, this);
        this.once(
            Phaser.GameObjects.Events.DESTROY,
            () => {
                this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
            },
            this,
        );
        scene.userManager.on("changedata", () => {
            const newWaeponId = getWeaponId(scene.userManager.getWeapon());
            if (newWaeponId !== this.weapon) {
                this.changeWeapon(newWaeponId);
            }
        });

        player.setTexture(this.idleAnimationKey);
        /* END-USER-CTR-CODE */
    }

    private player: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    public keyboardInputComponent: KeyboardInputComponent;
    public weaponComponent: PlayerWeaponComponent;
    public healthComponent: HealthComponent;
    public colliderComponent: ColliderComponent;
    public eventBusComponent: EventBusComponent;
    private activeCreeps: Set<Creep> = new Set();
    private playerTween?: Phaser.Tweens.Tween;
    private isMining: boolean = false;
    public isInitialized: boolean = false;
    public weapon: WeaponId = "rifle";

    get gameObject() {
        return this.player;
    }

    get weaponGameObjectGroup() {
        return this.weaponComponent.bulletGroup;
    }

    get attackAnimationKey() {
        return `player_${this.weapon}_shoot`;
    }

    get idleAnimationKey() {
        return `player_${this.weapon}_idle`;
    }

    get hurtAnimationKey() {
        return `player_${this.weapon}_hurt`;
    }

    get deadAnimationKey() {
        return `player_${this.weapon}_dead`;
    }

    get shootTexture() {
        if (this.weapon === "mangun") {
            return ["player_mangun_shoot", "shoot-anim-01.png"];
        }
        if (this.weapon === "bazooka") {
            return ["player_bazooka_shoot", "shoot-anim-01.png"];
        }
        if (this.weapon === "sniper") {
            return ["player_sniper_shoot", "shoot-anim-01.png"];
        }
        return ["player_rifle_shoot", "shoot-anim-01.png"];
    }

    init(eventBusComponent: EventBusComponent, health: number = CONFIG.PLAYER_HEALTH) {
        // this.playPlayerIdleAnimation();
        this.scene.physics.add.existing(this);
        if (this.body) {
            (this.body as Phaser.Physics.Arcade.Body).setSize(100, 150);
            (this.body as Phaser.Physics.Arcade.Body).setOffset(-50, -75);
            (this.body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
            (this.body as Phaser.Physics.Arcade.Body).pushable = false;
        }

        this.eventBusComponent = eventBusComponent;
        this.keyboardInputComponent = new KeyboardInputComponent(this.scene);
        this.keyboardInputComponent.lockInput = true;
        this.weaponComponent = new PlayerWeaponComponent(
            this,
            this.keyboardInputComponent,
            {
                speed: CONFIG.PLAYER_BULLET_SPEED,
                damage: CONFIG.PLAYER_BULLET_DAMAGE,
                interval: CONFIG.PLAYER_BULLET_INTERVAL,
                lifespan: CONFIG.PLAYER_BULLET_LIFESPAN,
                maxCount: CONFIG.PLAYER_BULLET_MAX_COUNT,
                xOffset: this.weapon === "sniper" ? 100 : 25,
                flipX: false,
            },
            this.eventBusComponent,
            this.weapon,
        );
        this.healthComponent = new HealthComponent(health);
        this.colliderComponent = new ColliderComponent(this.healthComponent, this.eventBusComponent);

        // register custom events
        this.eventBusComponent.on(CUSTOM_EVENTS.ENEMY_SPAWNED, this.onEnemySpawned, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.CREEP_DEAD, this.onEnemyDead, this);

        this.isInitialized = true;
    }

    changeWeapon(weapon: "rifle" | "mangun" | "bazooka" | "sniper") {
        this.weapon = weapon;
        if (this.weaponComponent) {
            this.weaponComponent.xOffset = weapon === "sniper" ? 100 : 25;
            this.weaponComponent.weaponId = weapon;
        }

        // if the player is idle, restart the animation
        if (this.playerTween?.isPlaying()) {
            this.playerTween.stop();
            this.playPlayerIdleAnimation();
        } else {
            if (this.player.anims?.currentAnim?.key) {
                const currentAnim = this.player.anims?.currentAnim?.key;
                const spiledAnimKey = currentAnim?.split("_") || [];
                const actionAnimationKey = spiledAnimKey[spiledAnimKey?.length - 1];

                if (!actionAnimationKey) return;

                const animationKey = `player_${weapon}_${actionAnimationKey}`;
                this.player.stop();
                this.player.setFrame(0);
                playAnimation(this.player, animationKey);
            }
        }
    }

    startMining() {
        this.isMining = true;
    }

    pauseMining() {
        this.isMining = false;
        this.keyboardInputComponent.shootIsDown = false;

        this.player.stop();
        this.player.setTexture(this.shootTexture[0], this.shootTexture[1]);
    }

    playPlayerIdleAnimation() {
        if (this.playerTween?.isPlaying()) return;
        this.player.setTexture(this.idleAnimationKey);
        this.playerTween = createTween(this.scene, this.player, {
            y: 24,
            duration: 800,
            yoyo: true,
            repeat: -1,
            ease: "Sine.easeInOut",
        });
    }

    onEnemySpawned(creep: Creep) {
        this.activeCreeps.add(creep);
    }

    onEnemyDead(creep: Creep) {
        this.activeCreeps.delete(creep);
    }

    reset() {
        this.setActive(true);
        this.setVisible(true);
        this.isMining = false;
        this.keyboardInputComponent.shootIsDown = false;
        this.player.stop();
        this.player.setTexture(this.idleAnimationKey);
        this.playPlayerIdleAnimation();
        // this.setDepth(1);
    }

    update(ts: DOMHighResTimeStamp, dt: number) {
        if (this.scene === undefined || !this.active || !this.isInitialized) {
            return;
        }

        if (this.healthComponent.isDead) {
            this.hide();
            if (this.player.anims.currentAnim?.key !== this.deadAnimationKey) {
                this.player.stop();
                playAnimation(this.player, this.deadAnimationKey);
                this.eventBusComponent.emit(CUSTOM_EVENTS.PLAYER_DEAD);
            }
            return;
        }

        // Auto shoot when there are active creeps
        if (this.isMining) {
            if (this.playerTween) {
                this.scene.tweens.remove(this.playerTween);
                this.playerTween = undefined;
                this.player.setPosition(0, 0);
            }
            if (this.activeCreeps.size > 0) {
                if (!this.player.anims.isPlaying) {
                    playAnimation(this.player, this.attackAnimationKey);
                    this.keyboardInputComponent.shootIsDown = true;
                }
            } else {
                this.keyboardInputComponent.shootIsDown = false;
                this.player.stop();
                this.player.setTexture(this.shootTexture[0], this.shootTexture[1]);
            }

            this.keyboardInputComponent.update();
            this.weaponComponent.update(dt);
        }
    }

    hide() {
        this.setActive(false);
        this.setDepth(0);
        this.keyboardInputComponent.lockInput = true;
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
