// Mining Game
import MiningBoot from "./ota-mining/game/scenes/Boot";
import MiningGame from "./ota-mining/game/scenes/Game";
import MiningBoss from "./ota-mining/game/scenes/BossFighter";
import MiningBossCharger from "./ota-mining/game/scenes/BossCharger";
import MiningPreloader from "./ota-mining/game/scenes/Preloader";

// Slots Game
import SlotsBoot from "./ota-slots/scenes/Boot";
import SlotsPreloader from "./ota-slots/scenes/Preloader";
import SlotsGame from "./ota-slots/scenes/Game";
import SlotsFreeSpin from "./ota-slots/scenes/FreeSpin";
import SlotsPickBonus from "./ota-slots/scenes/PickBonus";

import Settings from "./shared/scenes/Settings";
import WheelPreLoader from "./fortune-wheel/scenes/PreLoader";
import WheelBoot from "./fortune-wheel/scenes/Boot";

// Fortune Wheel Game
import LuckyWheel from "./fortune-wheel/scenes/LuckyWheel";

export const gameScenes = [
    // Mining Game
    MiningBoot,
    MiningPreloader,
    MiningGame,
    MiningBoss,
    MiningBossCharger,
    // Slots Game (Initial Entry)
    SlotsBoot,
    SlotsPreloader,
    SlotsGame,
    SlotsFreeSpin,
    SlotsPickBonus,

    // Common
    Settings,

    // Fortune Wheel Game
    WheelBoot,
    WheelPreLoader,
    LuckyWheel,
];
