import { showAlert } from "@/lib/telegram/web-app";
import { appLogger } from "./logger";

const logger = appLogger.withNamespace("[utils/share-to-story]");
const tg = window.Telegram.WebApp;

export const shareToStory = async (
    mediaUrl: string,
    caption: string = "",
    widgetUrl: string = "",
    widgetName: string = "",
) => {
    if (!tg) {
        logger.error("Telegram Web App SDK is not available.");
        return;
    }
    if (!mediaUrl || !mediaUrl.startsWith("https://")) {
        showAlert("Invalid media URL. Please provide a valid HTTPS URL.");
        return;
    }

    const storyParams: StoryShareParams = {};

    if (caption) {
        const isPremiumUser = tg.initDataUnsafe?.user?.is_premium;
        // 0-200 characters for regular users and 0-2048 characters for premium subscribers.
        const maxCaptionLength = isPremiumUser ? 2048 : 200;
        storyParams.text = caption.slice(0, maxCaptionLength);
    }

    if (widgetUrl && widgetUrl.startsWith("https://")) {
        // An object that describes a widget link to be included in the story. Note that only premium subscribers can post stories with links.
        storyParams.widget_link = {
            url: widgetUrl,
            // The name to be displayed for the widget link, 0-48 characters.
            name: widgetName.slice(0, 48),
        };
    }

    try {
        tg.shareToStory(mediaUrl, storyParams);
        logger.info("Media shared successfully to Telegram Stories.");
    } catch (error) {
        logger.error("Failed to share media to Telegram Stories:", error);
    }
};
