// You can write more code here

/* START OF COMPILED CODE */

import Text from "../prefabs/Text";
/* START-USER-IMPORTS */
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { getVersionInfo } from "@/utils/version";
import {
    checkHomeScreenStatus,
    onAddedToHomeScreen,
    offAddedToHomeScreen,
    offAddToHomeScreenFailed,
    onAddToHomeScreenFailed,
    addToHomeScreen,
} from "@/lib/telegram/web-app";
import { setupScreenTracking } from "@/services/analytics";
import { appLogger } from "@/utils/logger";
/* END-USER-IMPORTS */

const logger = appLogger.withNamespace("[shared/scenes/Settings]");

export default class Settings extends Phaser.Scene {
    constructor() {
        super("Settings");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // settings_bg_png
        this.add.image(195, 422, "settings", "settings_bg.png");

        // closeButton
        const closeButton = this.add.image(338, 52, "settings", "close.png");

        // text
        const text = new Text(this, 117, 53);
        this.add.existing(text);
        text.text = "SETTINGS MENU";
        text.setStyle({
            color: "#B1B5D4",
            fontSize: "24px",
            fontStyle: "bold",
        });

        // soundIcon
        const soundIcon = this.add.image(48, 133, "settings", "sound_off.png");

        // soundButton
        const soundButton = this.add.image(304, 135, "settings", "off.png");

        // musicIcon
        const musicIcon = this.add.image(47, 212, "settings", "music_off.png");

        // musicButton
        const musicButton = this.add.image(304, 213, "settings", "off.png");

        // effectIcon
        const effectIcon = this.add.image(48, 288, "settings", "effect_off.png");

        // effectButton
        const effectButton = this.add.image(303, 290, "settings", "off.png");

        // soundLabel
        const soundLabel = new Text(this, 132, 134);
        this.add.existing(soundLabel);
        soundLabel.text = "SOUND";
        soundLabel.setStyle({ color: "#5C5C5C", fontSize: "28px" });

        // musicLabel
        const musicLabel = new Text(this, 127, 209);
        this.add.existing(musicLabel);
        musicLabel.text = "MUSIC";
        musicLabel.setStyle({ color: "#5C5C5C", fontSize: "28px" });

        // effectLabel
        const effectLabel = new Text(this, 154, 288);
        this.add.existing(effectLabel);
        effectLabel.text = "ANIMATION";
        effectLabel.setStyle({ color: "#5C5C5C", fontSize: "28px" });

        // version
        const version = new Text(this, 194, 809);
        this.add.existing(version);
        version.text = "v";
        version.setStyle({ color: "#5C5C5C", fontSize: "16px" });

        // addToHomeScreen
        const addToHomeScreen = this.add.container(60, 399);
        addToHomeScreen.blendMode = Phaser.BlendModes.SKIP_CHECK;
        addToHomeScreen.visible = false;

        // phone_frame
        const phone_frame = this.add.image(-12, -28, "phone_frame");
        addToHomeScreen.add(phone_frame);

        // addToHomeScreenText
        const addToHomeScreenText = new Text(this, 82, -30);
        addToHomeScreenText.text = "ADD TO HOME SCREEN";
        addToHomeScreenText.setStyle({ "color": "#DFE0E2", "fontSize": "18px", "fontStyle": "bold" });
        addToHomeScreenText.setWordWrapWidth(142);
        addToHomeScreen.add(addToHomeScreenText);

        // addShortcutButton
        const addShortcutButton = this.add.image(228, -31, "add_shortcut_button");
        addShortcutButton.scaleX = 0.9;
        addShortcutButton.scaleY = 0.9;
        addToHomeScreen.add(addShortcutButton);

        // redDot
        const redDot = this.add.ellipse(301, -51, 15, 15);
        redDot.isFilled = true;
        redDot.fillColor = 15869735;
        addToHomeScreen.add(redDot);

        this.closeButton = closeButton;
        this.soundIcon = soundIcon;
        this.soundButton = soundButton;
        this.musicIcon = musicIcon;
        this.musicButton = musicButton;
        this.effectIcon = effectIcon;
        this.effectButton = effectButton;
        this.soundLabel = soundLabel;
        this.musicLabel = musicLabel;
        this.effectLabel = effectLabel;
        this.version = version;
        this.addShortcutButton = addShortcutButton;
        this.redDot = redDot;
        this.addToHomeScreen = addToHomeScreen;

        this.events.emit("scene-awake");
    }

    private closeButton!: Phaser.GameObjects.Image;
    private soundIcon!: Phaser.GameObjects.Image;
    private soundButton!: Phaser.GameObjects.Image;
    private musicIcon!: Phaser.GameObjects.Image;
    private musicButton!: Phaser.GameObjects.Image;
    private effectIcon!: Phaser.GameObjects.Image;
    private effectButton!: Phaser.GameObjects.Image;
    private soundLabel!: Text;
    private musicLabel!: Text;
    private effectLabel!: Text;
    private version!: Text;
    private addShortcutButton!: Phaser.GameObjects.Image;
    private redDot!: Phaser.GameObjects.Ellipse;
    private addToHomeScreen!: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    public fromScene: string;

    init() {
        setupScreenTracking(this);
    }

    create({
        fromScene,
        forceReload,
    }: {
        fromScene: string;
        forceReload: boolean;
    }) {
        this.fromScene = fromScene;

        this.editorCreate();

        const { version, commitHash } = getVersionInfo();
        this.version.setText(`v${version} (${commitHash})`);

        new PushComponent(
            this.closeButton,
            () => {
                if (forceReload) {
                    this.scene.start(this.fromScene);
                } else {
                    this.scene.wake(this.fromScene);
                    this.scene.stop();
                }
            },
            this,
        ).activate();

        new PushComponent(
            this.soundButton,
            () => {
                this.settingsManager.toggleSound();
                this.updateTextures();
            },
            this,
        ).activate();

        new PushComponent(
            this.musicButton,
            () => {
                const settings = this.settingsManager.getData();
                this.settingsManager.toggleMusic();

                if (settings?.music) {
                    this.sys.game.audio.disableMusic();
                } else {
                    if (this.fromScene === "SlotsGame") {
                        this.sys.game.audio.playSlotsBgSound();
                    } else {
                        this.sys.game.audio.playMiningBgSound();
                    }
                }

                this.updateTextures();
            },
            this,
        ).activate();

        new PushComponent(
            this.effectButton,
            () => {
                this.settingsManager.toggleEffect();
                this.updateTextures();
            },
            this,
        ).activate();

        this.updateTextures();

        this.setupAddToHomeScreenButton();
    }

    updateTextures() {
        const settings = this.settingsManager.getData();
        if (settings) {
            this.soundButton.setTexture("settings", settings.sound ? "on.png" : "off.png");
            this.musicButton.setTexture("settings", settings.music ? "on.png" : "off.png");
            this.effectButton.setTexture("settings", settings.effect ? "on.png" : "off.png");

            this.soundIcon.setTexture("settings", settings.sound ? "sound_on.png" : "sound_off.png");
            this.musicIcon.setTexture("settings", settings.music ? "music_on.png" : "music_off.png");
            this.effectIcon.setTexture("settings", settings.effect ? "effect_on.png" : "effect_off.png");

            this.soundLabel.setStyle({
                color: settings.sound ? "#219B76" : "#5C5C5C",
            });
            this.musicLabel.setStyle({
                color: settings.music ? "#219B76" : "#5C5C5C",
            });
            this.effectLabel.setStyle({
                color: settings.effect ? "#219B76" : "#5C5C5C",
            });
        }
    }

    async setupAddToHomeScreenButton() {
        const status = await checkHomeScreenStatus();

        if (status === "unsupported") {
            this.addToHomeScreen.setVisible(false);
            return;
        }

        const button = new PushComponent(
            this.addShortcutButton,
            async () => {
                const onSuccess = async () => {
                    logger.info("Successfully added to home screen");
                    this.redDot.setVisible(false);
                    this.addShortcutButton.setTexture("added.png");
                    try {
                        // TODO: Replace with actual API call
                    } catch (error) {
                        logger.error("Failed to update server:", error);
                    } finally {
                        offAddedToHomeScreen(onSuccess);
                        offAddToHomeScreenFailed(onFailed);
                    }
                };

                const onFailed = () => {
                    logger.info("Failed to add to home screen or user declined");
                    offAddedToHomeScreen(onSuccess);
                    offAddToHomeScreenFailed(onFailed);
                };

                onAddedToHomeScreen(onSuccess);
                onAddToHomeScreenFailed(onFailed);
                addToHomeScreen();
            },
            this,
        );

        if (status === "added") {
            this.redDot.setVisible(false);
            this.addShortcutButton.setTexture("added");
            button.deactivate();
        } else {
            button.activate();
        }

        this.addToHomeScreen.setVisible(true);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
