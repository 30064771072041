// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { HealthComponent } from "../components/health/HealthComponent";
import { CUSTOM_EVENTS, EventBusComponent } from "../components/events/EventBusComponent";
import * as CONFIG from "../config";
import { CreepInputComponent } from "../components/input/CreepInputComponent";
import { HorizontalMovementComponent } from "../components/movement/HorizontalMovementComponent";
import { ColliderComponent } from "../components/collider/ColliderComponent";
import { CreepWeaponComponent } from "../components/weapon/CreepWeaponComponent";
import EasyProgressbar from "./EasyProgressBar";
import { CreepConfig } from "../chapters";
/* END-USER-IMPORTS */

export default class Creep extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // creep
        const creep = scene.add.sprite(0, 0, "_MISSING");
        this.add(creep);

        this.creep = creep;

        /* START-USER-CTR-CODE */
        // health bar
        const healthBarWidth = 100;
        const healthBarHeight = 10;
        const healthBarY = -70 - healthBarHeight / 2;
        this.healthBar = new EasyProgressbar(scene, -healthBarWidth / 2, healthBarY, healthBarWidth, healthBarHeight, {
            backgroundColor: 0x000000,
            color: 0xff0000,
            progress: 1,
        });
        this.healthBar.setVisible(false);
        this.add(this.healthBar);

        this.isInitialized = false;
        this.creep.setTexture(this.config.texture[0], this.config.texture[1]);
        this.creep.play(this.walkAnimationKey);
        this.creep.setScale(this.config.size.scale);

        this.scene.add.existing(this);
        this.scene.physics.add.existing(this);
        if (this.body) {
            (this.body as Phaser.Physics.Arcade.Body).setSize(
                this.config.body?.size?.w || 120,
                this.config.body?.size?.h || 140,
            );
            (this.body as Phaser.Physics.Arcade.Body).setOffset(
                this.config.body?.offset?.x || -20,
                this.config.body?.offset?.y || -70,
            );
            (this.body as Phaser.Physics.Arcade.Body).immovable = false;
        }
        this.scene.events.on(Phaser.Scenes.Events.UPDATE, this.update, this);
        this.once(
            Phaser.GameObjects.Events.DESTROY,
            () => {
                this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
            },
            this,
        );
        /* END-USER-CTR-CODE */
    }

    private creep: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    public isInitialized: boolean;
    public inputComponent: CreepInputComponent;
    public horizontalMovementComponent: HorizontalMovementComponent;
    public weaponComponent: CreepWeaponComponent;
    public healthComponent: HealthComponent;
    public colliderComponent: ColliderComponent;
    public eventBusComponent: EventBusComponent;
    public healthBar: EasyProgressbar;

    get gameObject() {
        return this;
    }

    get creepObject() {
        return this.creep;
    }

    get config(): CreepConfig {
        return this.scene.chapterManager.getData()!.creep;
    }

    get creepAssetKey() {
        return "creep";
    }

    get walkAnimationKey() {
        return this.config.walkAnimationKey;
    }

    get destroyedAnimationKey() {
        return this.config.destroyedAnimationKey;
    }

    get attackAnimationKey() {
        return this.config.attackAnimationKey;
    }

    init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;
        this.inputComponent = new CreepInputComponent();
        this.horizontalMovementComponent = new HorizontalMovementComponent(this, this.inputComponent, {
            velocity: CONFIG.CREEP_MOVEMENT_HORIZONTAL_VELOCITY,
            maxVelocity: 200,
        });
        this.weaponComponent = new CreepWeaponComponent(
            this,
            {
                damage: CONFIG.CREEP_WEAPON_DAMAGE,
            },
            this.eventBusComponent,
        );
        this.healthComponent = new HealthComponent(CONFIG.CREEP_HEALTH);
        this.colliderComponent = new ColliderComponent(this.healthComponent, this.eventBusComponent);
        this.eventBusComponent.emit(CUSTOM_EVENTS.CREEP_INIT, this);
        this.isInitialized = true;
    }

    reset() {
        this.setActive(true);
        this.setVisible(true);
        this.creep.play(this.walkAnimationKey);
        this.healthComponent.reset();
        this.horizontalMovementComponent.reset();
        this.healthBar.setVisible(false);
    }

    update(ts: DOMHighResTimeStamp, dt: number) {
        if (!this.isInitialized) {
            return;
        }

        if (!this.active) {
            return;
        }

        if (this.healthComponent.isDead) {
            this.setActive(false);
            this.setVisible(false);
            this.weaponComponent.reset();
            this.eventBusComponent.emit(CUSTOM_EVENTS.CREEP_DEAD, this);
            return;
        }

        // Show and update health bar when health is not full
        if (this.healthComponent.currentLife < this.healthComponent.startingLife) {
            this.healthBar.setVisible(true);
            const healthPercent = this.healthComponent.currentLife / this.healthComponent.startingLife;
            this.healthBar.setProgress(healthPercent);
        } else {
        }

        this.inputComponent.update();
        this.horizontalMovementComponent.update();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
