import {
    authenticateImota,
    authenticateTelegram,
    Config,
    getAuthenticatedUser,
    syncIntroStepWithBackend,
    type User,
} from "@/lib/api/telegram-backend/client";
import { DataManager } from "./data-manager";
import { InventoryItem, InventoryItems } from "../types/loot-box";
import { AuthInitData } from "../../../types";
import { getChapterAndBossLevel } from "./chapter";
import { LuckyWheelResponse } from "@/lib/api/telegram-backend/methods/lucky-wheel";

type AuthResult = {
    user: User | undefined;
    config: Config | undefined;
};

export type LoginData = {
    provider: AuthInitData["provider"];
    platform: AuthInitData["platform"];
};

export class UserManager extends DataManager<User> {
    public key = "user";
    private configKey = "config";
    private loginKey = "login";

    private authHooks: ((data: {
        user: User;
        config: Config | undefined;
    }) => void)[] = [];

    public async syncWithBackend() {
        const user = await getAuthenticatedUser();
        if (user) this.setData(user);
        return user;
    }

    public async syncWithMockData(user: User) {
        const newUser = {
            ...this.getData(),
            ...user,
        };
        if (user) {
            this.setData(newUser);
        }
        return newUser;
    }

    public async syncIntroStep(step: string) {
        syncIntroStepWithBackend(step === "10" ? "finished" : step);
        const user = this.getData();
        const newUser = {
            ...user,
            introStep: step,
        };
        if (user) {
            this.setData(newUser as User);
        }
        return newUser;
    }

    public isTutorialCompleted(): boolean {
        const introStep = this.getIntroStep();
        // TODO: Temporarily disable tutorial
        return true;
        return introStep === "10" || introStep === "finished";
    }

    public getIntroStep(): string | undefined {
        return this.getData()?.introStep;
    }

    public getInventoryItems(): InventoryItems | undefined {
        return this.getData()?.fightingEquipments;
    }

    public getInventoryItemBySlot(slot: number): InventoryItem | undefined {
        const items = this.getInventoryItems();
        if (!items) return;
        return items.find((item) => item.slot === slot);
    }

    getWeapon(): InventoryItem | undefined {
        const items = this.getInventoryItems();
        const weapon = items?.find((equipment: InventoryItem) => equipment.name === "weapon");
        return weapon;
    }

    getGoldSpentLevel(): number {
        return this.getData()?.goldSpentLevel || 1;
    }

    public authenticate = async (data: AuthInitData): Promise<AuthResult | undefined> => {
        const { provider, payload, platform } = data;
        const authenticate = provider === "imota" ? authenticateImota : authenticateTelegram;
        const { user, config } = await authenticate(payload);
        if (user) {
            this.setData(user);
            this.authHooks.forEach((hook) => hook({ user, config }));
            // Clear to prevent memory leaks
            this.authHooks = [];
        }

        if (config) {
            this.setConfigData(config);
        }

        this.setLoginData({ provider, platform });

        return { user, config };
    };

    public onAuthSuccess(callback: (data: { user: User; config: Config | undefined }) => void) {
        this.authHooks.push(callback);
    }

    setConfigData(value: Config) {
        this.game.registry.set(this.configKey, value);
    }

    getConfigData(): Config | undefined {
        return this.game.registry.get(this.configKey);
    }

    setLoginData(value: LoginData) {
        this.game.registry.set(this.loginKey, value);
    }

    getLoginData(): LoginData | undefined {
        return this.game.registry.get(this.loginKey);
    }

    getChapterData() {
        return getChapterAndBossLevel(this.getData()?.miningLevel || 0);
    }

    getLuckyWheelData() {
        return this.getData()?.luckyWheel as unknown as {
            cycleNormalSpin: number;
            normal: number;
            special: number;
        };
    }

    getLuckyWheelCycleHistoryReward() {
        return this.getData()
            ?.luckyWheelCycleHistoryReward as unknown as LuckyWheelResponse["luckyWheelCycleHistoryReward"];
    }
}
