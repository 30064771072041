import { capitalize } from "@/lib/utils";
import {
    rarityToGemColor,
    rarityToGemHexColor,
    rarityToWeaponType,
    slotNumberToGemShape,
    weaponTypeToName,
    weaponTypeToWeaponId,
} from "../constants/loot-box";
import { GemHexColor, InventoryItem, ItemAssetName, ItemDisplayName } from "../types/loot-box";
import { shareToStory } from "@/utils/share-to-story";

export const getAverageAttackPower = (item: InventoryItem) => (item.minAtk + item.maxAtk) / 2;

export const getTotalAverageAttackPower = (items: InventoryItem[]): number => {
    return items.reduce((total, item) => total + getAverageAttackPower(item), 0);
};

export const getWeaponId = (weapon?: InventoryItem) => {
    if (!weapon) return weaponTypeToWeaponId.magnum;

    const type = rarityToWeaponType[weapon.rarity];
    return weaponTypeToWeaponId[type];
};

export const getRarityColor = (item: InventoryItem): GemHexColor => {
    return rarityToGemHexColor[item.rarity];
};

export const getSecondaryRarityGradientColor = (primaryColor: string) => {
    // Ensure the input color is in the correct format
    if (!/^#([0-9A-F]{3}){1,2}([0-9A-F]{2})?$/i.test(primaryColor)) {
        throw new Error("Invalid color format. Please use a hex color like #C851E5 or #C851E5E3.");
    }

    // Convert the hex color to RGB
    const hex = primaryColor.replace("#", "");
    const rgb =
        hex.length === 3
            ? [parseInt(hex[0] + hex[0], 16), parseInt(hex[1] + hex[1], 16), parseInt(hex[2] + hex[2], 16)]
            : [parseInt(hex.substring(0, 2), 16), parseInt(hex.substring(2, 4), 16), parseInt(hex.substring(4, 6), 16)];

    // Transform the RGB values to generate a secondary color
    const secondaryRgb = rgb.map((value) => Math.max(0, Math.min(255, Math.round(value * 0.55)))); // Darken the color by 45%

    // Convert the RGB back to hex
    const secondaryHex = `#${secondaryRgb.map((v) => v.toString(16).padStart(2, "0")).join("")}`;

    return secondaryHex;
};

export const getItemTextureName = (item: InventoryItem): ItemAssetName | undefined => {
    if (item.name === "weapon") {
        return rarityToWeaponType[item.rarity];
    } else if (item.name === "gem") {
        const color = rarityToGemColor[item.rarity];
        const shape = slotNumberToGemShape[item.slot];
        return `${color}_${shape}`;
    }
};

export const getItemDisplayName = (item: InventoryItem): ItemDisplayName | undefined => {
    if (item.name === "weapon") {
        const weaponType = rarityToWeaponType[item.rarity];
        return weaponTypeToName[weaponType];
    } else if (item.name === "gem") {
        const color = rarityToGemColor[item.rarity];
        const shape = slotNumberToGemShape[item.slot];
        return `${capitalize(color)} ${capitalize(shape)}`;
    }
};

export type ItemComparisonResult = "upgrade" | "downgrade" | "neutral";
export const compareItemAttackPower = (currentItem: InventoryItem, nextItem: InventoryItem): ItemComparisonResult => {
    const currentAverage = getAverageAttackPower(currentItem);
    const nextAverage = getAverageAttackPower(nextItem);

    if (nextAverage > currentAverage) {
        return "upgrade";
    } else if (nextAverage < currentAverage) {
        return "downgrade";
    } else {
        return "neutral";
    }
};

export const shareRewardToStory = (rewardItem: InventoryItem) => {
    const mediaUrl = `https://imota-slot.amoti.info/shared/assets/atlas/loot-box/${rewardItem.name}s/${getItemTextureName(rewardItem)}.png`;
    const caption = `🎉 I just opened a Loot Box and won an ${rewardItem.rarity.toUpperCase()} ${rewardItem.name.charAt(0).toUpperCase() + rewardItem.name.slice(1)}! 🔥

✨ **Reward Details** ✨
- **Name**: ${getItemDisplayName(rewardItem)}
- **Rarity**: ${rewardItem.rarity.toUpperCase()}
- **Level**: ${rewardItem.level}
- **Attack Power**: ${rewardItem.minAtk}-${rewardItem.maxAtk}
- **Selling Price**: ${rewardItem.sellingPrice} Gold

Try your luck and win amazing rewards now! 💎`;

    // const widgetUrl = "https://example.com/play-now";
    // const widgetName = "Try Your Luck!";

    shareToStory(mediaUrl, caption);
};
