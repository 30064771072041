import createClient, { type Middleware } from "openapi-fetch";
import type { components, paths } from "@/lib/api/telegram-backend/generated/schema.d";
import { clearTokens, getAccessToken, getRefreshToken, setTokens } from "./token-storage";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[telegram-backend/client]");

export type User = components["schemas"]["ClientUserDto"];
export type Config = components["schemas"]["ConfigureDto"];

const BASE_URL = import.meta.env.VITE_TELEGRAM_BACKEND_URL;

const UNPROTECTED_ROUTES: (keyof paths)[] = ["/", "/auth/telegram", "/auth/imota"];

const authMiddleware: Middleware = {
    async onRequest({ request }) {
        if (typeof window === "undefined") {
            // If we are on the server, skip adding Authorization header
            return request;
        }

        const url = new URL(request.url);

        if (UNPROTECTED_ROUTES.includes(url.pathname as keyof paths)) {
            return request;
        }

        const accessToken = getAccessToken();

        if (accessToken) {
            request.headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
            logger.error("No access token found");
        }

        return request;
    },

    // Handle responses
    async onResponse({ request, response }) {
        if (response.status === 401) {
            const refreshToken = getRefreshToken();

            if (refreshToken) {
                // If the response is unauthorized, attempt to refresh the token
                const refreshResponse = await fetch(`${BASE_URL}auth/refresh`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-refresh-token": refreshToken,
                    },
                });

                if (refreshResponse.ok) {
                    const refreshData = await refreshResponse.json();
                    setTokens(refreshData.accessToken);

                    // Retry the original request with the new access token
                    const newRequest = new Request(request, {
                        headers: {
                            ...Object.fromEntries(request.headers),
                            Authorization: `Bearer ${refreshData.accessToken}`,
                        },
                    });

                    const retryResponse = await fetch(newRequest);

                    return retryResponse;
                } else {
                    clearTokens();
                }
            }
        }
        return response;
    },
};

export const telegramBackendApi = createClient<paths>({ baseUrl: BASE_URL });

telegramBackendApi.use(authMiddleware);

export const authenticateTelegram = async (
    initDataRaw: string,
): Promise<{
    user: components["schemas"]["LoginResponseDto"]["user"] | undefined;
    config: components["schemas"]["LoginResponseDto"]["configure"] | undefined;
}> => {
    const { data, error } = await telegramBackendApi.POST("/auth/telegram", {
        params: {
            header: {
                Authorization: `tma ${initDataRaw}`,
            },
        },
    });

    if (error) {
        logger.error("[authenticateTelegram]", error);
        return { user: undefined, config: undefined };
    }

    if (data.accessToken) {
        setTokens(data.accessToken, data.refreshToken);
    }

    return { user: data.user, config: data.configure };
};

export const authenticateImota = async (
    imotaAccessToken: string,
): Promise<{
    user: components["schemas"]["LoginResponseDto"]["user"] | undefined;
    config: components["schemas"]["LoginResponseDto"]["configure"] | undefined;
}> => {
    const { data, error } = await telegramBackendApi.POST("/auth/imota", {
        params: {
            header: {
                Authorization: `imota ${imotaAccessToken}`,
            },
        },
    });

    if (error) {
        logger.error("[authenticateTelegram]", error);
        logger.alert(`The /auth/imota API returned error: ${(error as Error).message}. Reload the page.`);
        return { user: undefined, config: undefined };
    }

    if (data.accessToken) {
        setTokens(data.accessToken, data.refreshToken);
    }

    return { user: data.user, config: data.configure };
};

type ClientUserResponse = components["schemas"]["ClientUserDto"];

export const getAuthenticatedUser = async (): Promise<ClientUserResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/auth/me");

    if (error) {
        logger.error("[getAuthenticatedUser]", error);
        return;
    }

    return data as ClientUserResponse;
};

type SlotMachineRequest = components["schemas"]["SlotMachineBetRequestDto"];
export type SlotMachineResponse = components["schemas"]["SlotMachineResponseDto"];
export const spinSlotMachine = async (
    betAmount: SlotMachineRequest["betAmount"] = 1000,
): Promise<SlotMachineResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/slot-machine/spin", {
        body: { betAmount },
    });

    if (error) {
        logger.alert(`The /slot-machine/spin API returned error: ${(error as Error).message}. Reload the page.`);
        logger.error("/slot-machine/spin", error);
        return;
    }

    logger.debug("[spinSlotMachine]", data);
    return data;
};

type SpinPiggyJackpotRequest = components["schemas"]["SpinPiggyJackpotRequestDto"];
export type SpinPiggyJackpotResponse = components["schemas"]["SpinPiggyJackpotResponseDto"];
export const spinPiggyJackpot = async (
    historyId: SpinPiggyJackpotRequest["historyId"],
): Promise<SpinPiggyJackpotResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/slot-machine-piggy-jackpot/spin", {
        body: { historyId },
    });

    if (error) {
        logger.error("/slot-machine-piggy-jackpot/spin", error);
        return;
    }

    logger.debug("[spinPiggyJackpot]", data);
    return data;
};

type SlotMachineFreeSpinRequest = components["schemas"]["SlotMachineFreeSpinRequestDto"];
export type SlotMachineFreeSpin = components["schemas"]["SlotMachineResponseDto"] & {
    totalFreeSpinReward: number;
};

export type SlotMachineFreeSpinResponse = SlotMachineFreeSpin[];
export const spinSlotMachineFreeSpin = async (
    historyId: SlotMachineFreeSpinRequest["historyId"],
): Promise<SlotMachineFreeSpinResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/slot-machine-free-spin/spin", {
        body: { historyId },
    });

    if (error) {
        logger.error("/slot-machine-free-spin/spin", error);
        return;
    }

    logger.debug("[spinSlotMachineFreeSpin]", data);

    return data as SlotMachineFreeSpinResponse;
};

export const syncIntroStepWithBackend = async (step: string): Promise<boolean> => {
    const { error } = await telegramBackendApi.POST("/intro", {
        body: { step },
    });

    if (error) {
        logger.error("[syncIntroStep]", error);
        return false;
    }

    return true;
};
