// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
import SkipButton from "./SkipButton";
/* START-USER-IMPORTS */
import Player from "@/ota-mining/game/prefab/Player";
import { sleep } from "@/lib/utils";
import { EventBusComponent } from "@/ota-mining/game/components/events/EventBusComponent";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class Step1 extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // tutorial_bg_png
        const tutorial_bg_png = scene.add.image(0, 0, "tutorial", "tutorial_bg.png");
        this.add(tutorial_bg_png);

        // playerName
        const playerName = new Text(scene, 0, 196);
        playerName.visible = false;
        playerName.text = "Hi {{player_name}},";
        playerName.setStyle({ fontSize: "28px", fontStyle: "bold" });
        this.add(playerName);

        // skipButton_1
        const skipButton_1 = new SkipButton(scene, 139, -379);
        this.add(skipButton_1);

        this.playerName = playerName;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private playerName: Text;

    /* START-USER-CODE */
    private eventBusComponent: EventBusComponent;

    async init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;
        const user = this.scene.userManager.getData();
        const player = new Player(this.scene, -200, this.y - 200);
        this.add(player);
        player.rotation = 145;
        player.setScale(1.5);

        // Add tween to fly player in from left with optimized animation
        this.scene.tweens.add({
            targets: player,
            x: this.x / 2, // Final x position,
            y: this.y / 2 - 50, // Final y position,
            rotation: 0,
            duration: 500, // Reduced duration for smoother animation
            onComplete: () => {
                player.playPlayerIdleAnimation();
                this.playerName.text = `Hi ${user?.name},`;
                this.playerName.visible = true;
            },
        });

        await sleep(2000);

        player.destroy();

        const playerAvatar = this.scene.add.image(0, 0, "tutorial", "player_avatar.png");
        this.add(playerAvatar);

        // First animate the avatar appearing
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: playerAvatar,
                scale: { from: 0, to: 1 },
                duration: 250,
                onComplete: () => resolve(),
            });
        });

        // Then move avatar up
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: playerAvatar,
                scale: { from: 1, to: 0.4 },
                y: { from: 0, to: -235 },
                duration: 250,
                delay: 1000,
                onComplete: () => resolve(),
            });
        });

        // Fade out name
        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: this.name,
                alpha: { from: 1, to: 0 },
                duration: 400,
                delay: 1000,
                onComplete: () => resolve(),
            });
        });

        this.playerName.destroy();

        // Show message box
        const boxMessage = this.scene.add.image(2, -55, "tutorial", "box_message.png");
        boxMessage.setScale(1, 0);
        this.addAt(boxMessage, 1);

        await new Promise<void>((resolve) => {
            this.scene.tweens.add({
                targets: boxMessage,
                scaleY: { from: 0, to: 1 },
                duration: 400,
                ease: "Back.easeOut",
                onComplete: () => resolve(),
            });
        });

        const startNow = this.scene.add.image(0, 350, "tutorial", "start_now.png");
        startNow.alpha = 0;
        this.add(startNow);

        this.scene.tweens.add({
            targets: startNow,
            alpha: { from: 0, to: 1 },
            duration: 300,
            delay: 500,
        });

        new PushComponent(
            startNow,
            () => {
                this.eventBusComponent.emit("next", {
                    step: "1",
                });
            },
            this,
        ).activate();

        const content = new Text(this.scene, 0, -50);
        content.text =
            "Welcome to REMIS, where we explore the stars, fight for hope, and uncover treasures!\n\nBefore we start the adventure, let's finish this 1-minute guide and get exclusive welcome rewards. Ready to launch?";
        content.setStyle({ fontSize: "18px" });
        content.setLineSpacing(4);
        content.setWordWrapWidth(296);
        content.alpha = 0;
        this.add(content);

        this.scene.tweens.add({
            targets: content,
            alpha: { from: 0, to: 1 },
            duration: 300,
            delay: 500,
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
