// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class SpinWinningPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // rectangle_1
        const rectangle_1 = scene.add.rectangle(195, 422, 390, 844);
        rectangle_1.isFilled = true;
        rectangle_1.fillColor = 2040110;
        rectangle_1.strokeColor = 5995679;
        this.add(rectangle_1);

        // bg
        const bg = scene.add.sprite(195, 422, "_MISSING");
        bg.alpha = 0;
        bg.alphaTopLeft = 0;
        bg.alphaTopRight = 0;
        bg.alphaBottomLeft = 0;
        bg.alphaBottomRight = 0;
        this.add(bg);

        // title
        const title = scene.add.image(195, 284, "_MISSING");
        title.alpha = 0;
        title.alphaTopLeft = 0;
        title.alphaTopRight = 0;
        title.alphaBottomLeft = 0;
        title.alphaBottomRight = 0;
        this.add(title);

        // gold
        const gold = scene.add.image(202, 758, "auto_spin", "coin-bag.png");
        gold.scaleX = 0.8;
        gold.scaleY = 0.8;
        gold.alpha = 0;
        gold.alphaTopLeft = 0;
        gold.alphaTopRight = 0;
        gold.alphaBottomLeft = 0;
        gold.alphaBottomRight = 0;
        this.add(gold);

        // goldAmount
        const goldAmount = new Text(scene, 195, 551);
        goldAmount.alpha = 0;
        goldAmount.text = "0 GOLD";
        goldAmount.setStyle({
            "color": "#080A1B",
            "fontSize": "40px",
            "fontStyle": "bold",
            "stroke": "#D8E63C",
            "strokeThickness": 4,
            "shadow.offsetX": 2,
            "shadow.offsetY": 2,
            "shadow.color": "#939C29",
            "shadow.stroke": true,
            "shadow.fill": true,
        });
        this.add(goldAmount);

        // coinSplash
        const coinSplash = scene.add.sprite(195, 422, "coin_splash", "Property 1=remiscoin_splash_f_01.png");
        coinSplash.visible = false;
        this.add(coinSplash);

        this.bg = bg;
        this.title = title;
        this.gold = gold;
        this.goldAmount = goldAmount;
        this.coinSplash = coinSplash;

        /* START-USER-CTR-CODE */
        bg.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Sprite;
    private title: Phaser.GameObjects.Image;
    private gold: Phaser.GameObjects.Image;
    private goldAmount: Text;
    private coinSplash: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    public winAmount: number;
    public winType: "" | "big_win" | "huge_win" | "super_win" | "massive_win";
    public isAuto: boolean;

    init() {
        this.title.setTexture(`${this.winType}_atlas`, `${this.winType}.png`);
    }

    async playAnimation(): Promise<void> {
        this.bg.alpha = 1;
        this.bg.play(this.winType);

        const scaleAnimation = {
            alpha: {
                duration: 200,
                value: 1,
            },
            scale: {
                duration: 400,
                value: { from: 1.5, to: 1 },
                ease: "Bounce.easeOut",
                keyframes: [
                    { scale: 1.5, duration: 0 },
                    { scale: 1.3, duration: 80 },
                    { scale: 1.2, duration: 80 },
                    { scale: 0.5, duration: 80 },
                    { scale: 0, duration: 80 },
                    { scale: 1, duration: 80 },
                ],
            },
        };

        const yAnimation = (y: number) => ({
            alpha: {
                duration: 200,
                value: 1,
            },
            y: {
                duration: 400,
                value: { from: y + 100, to: y },
                ease: "Bounce.easeOut",
                keyframes: [
                    { y: y + 100, duration: 0 },
                    { y: y + 80, duration: 100 },
                    { y: y + 50, duration: 100 },
                    { y: y - 100, duration: 100 },
                    { y: y, duration: 100 },
                ],
            },
        });

        this.bg.once("animationcomplete", () => {
            EventBus.emit(CUSTOM_EVENTS.SPIN_WIN, this.winType);

            this.scene.add.tween({
                targets: this.bg,
                props: {
                    ...scaleAnimation,
                },
            });

            this.scene.add.tween({
                targets: this.title,
                props: {
                    ...scaleAnimation,
                },
                ease: "Power2",
            });

            this.scene.add.tween({
                targets: this.goldAmount,
                props: {
                    ...yAnimation(this.goldAmount.y),
                },
                ease: "Power2",
            });

            this.scene.add.tween({
                targets: this.gold,
                props: {
                    ...yAnimation(this.gold.y),
                },
                ease: "Power2",

                onComplete: () => {
                    this.scene.tweens.addCounter({
                        from: 0,
                        to: this.winAmount,
                        duration: 400,
                        ease: Phaser.Math.Easing.Linear,
                        onUpdate: (tween) => {
                            const value = Math.round(tween.getValue());
                            this.goldAmount.setText(`${numberWithCommas(value)} GOLD`);
                        },
                    });

                    EventBus.emit(CUSTOM_EVENTS.WIN_POPUP);

                    this.scene.time.delayedCall(5000, () => {
                        this.destroy();
                    });
                },
            });

            this.coinSplash.visible = true;
            this.coinSplash.play("coin_splash");
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
