// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { SlotMachineResponse, spinPiggyJackpot, SpinPiggyJackpotResponse } from "@/lib/api/telegram-backend/client";
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import { SCENE_KEYS } from "@/ota-slots/constants";
import { sleep } from "@/lib/utils";
import { SpinData } from "@/ota-slots/types";
/* END-USER-IMPORTS */

export default class JackpotPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // rectangle_1
        const rectangle_1 = scene.add.rectangle(0, 0, 390, 844);
        rectangle_1.isFilled = true;
        rectangle_1.fillColor = 0;
        this.add(rectangle_1);

        // image_1
        const image_1 = scene.add.image(0, 0, "pick_bonus_popup");
        image_1.scaleX = 0.5;
        image_1.scaleY = 0.5;
        this.add(image_1);

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */

    async init(sceneKey: string, spinData: SpinData) {
        EventBus.emit(CUSTOM_EVENTS.BONUS_GAME);
        const [piggyJackpotResponse] = await Promise.all([spinPiggyJackpot(spinData!.spinId), sleep(1500)]);

        this.scene.scene.sleep(sceneKey).launch(SCENE_KEYS.PICK_BONUS, {
            sceneKey,
            spinData: {
                ...spinData,
                piggyJackpotResponse,
            },
        });

        this.destroy();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
