// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { WheelPiece } from "@/lib/api/telegram-backend/methods/lucky-wheel";
/* END-USER-IMPORTS */

export default class PizzaGold extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 213.94301316784694, y ?? 190.67164125856158);

        // pizza
        const pizza = scene.add.image(0, 0, "lucky_wheel", "pizza_gold.png");
        this.add(pizza);

        // dollar_png
        const dollar_png = scene.add.image(-36, 28, "lucky_wheel", "dollar.png");
        dollar_png.scaleX = 0.45;
        dollar_png.scaleY = 0.45;
        this.add(dollar_png);

        // value
        const value = new Text(scene, -41, 3);
        value.angle = -66;
        value.setOrigin(0, 0);
        value.text = "0";
        value.setStyle({ color: "#000", fontSize: "28px", fontStyle: "bold" });
        this.add(value);

        // dots
        const dots = scene.add.sprite(0, -4, "lucky_wheel", "dots_white.png");
        dots.scaleX = 0.38;
        dots.scaleY = 0.38;
        dots.visible = false;
        this.add(dots);

        this.pizza = pizza;
        this.value = value;
        this.dots = dots;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private pizza: Phaser.GameObjects.Image;
    private value: Text;
    private dots: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    public getCurrentValue(): number {
        return Number(this.value.text);
    }

    setPiece(piece: WheelPiece) {
        this.scene.tweens.addCounter({
            from: this.getCurrentValue(),
            to: piece.value,
            duration: 300,
            onUpdate: (tween) => {
                this.value.text = `${tween.getValue().toFixed(2)}`;
            },
        });
    }

    highlight() {
        this.dots.visible = true;
        this.dots.play("wheel_blink");
    }

    reset() {
        this.dots.stop();
        this.dots.setTexture("lucky_wheel", "dots_white.png");
        this.dots.visible = false;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
