import { isTMA } from "@telegram-apps/bridge";
import { appLogger } from "@/utils/logger";

const logger = appLogger.withNamespace("[lib/telegram/web-app]");
const tg = window.Telegram?.WebApp;

type HomeScreenStatus = "unsupported" | "unknown" | "added" | "missed";
type EventName = "biometricTokenUpdated" | "homeScreenAdded" | "homeScreenAddFailed";

export const isTelegramWebApp = () => isTMA("simple");

export const isFeatureAvailable = (feature: keyof typeof tg) => {
    if (!isTelegramWebApp()) {
        logger.warn(`${feature} is not available in mock environment`);
        return false;
    }
    try {
        return typeof tg?.[feature] === "function";
    } catch {
        return false;
    }
};

// Home Screen Methods
export const addToHomeScreen = () => {
    if (!isFeatureAvailable("addToHomeScreen")) {
        return;
    }
    tg.addToHomeScreen();
};

export const checkHomeScreenStatus = async (): Promise<HomeScreenStatus> => {
    if (!isFeatureAvailable("checkHomeScreenStatus")) {
        return "unsupported";
    }
    return new Promise((resolve) => {
        try {
            tg.checkHomeScreenStatus((status: HomeScreenStatus) => {
                resolve(status);
            });
        } catch (error) {
            logger.error("Failed to check home screen status:", error);
            resolve("unsupported");
        }
    });
};

const onEvent = (eventName: EventName, callback: () => void) => {
    if (!isFeatureAvailable("onEvent")) {
        return;
    }
    tg.onEvent(eventName, callback);
};

const offEvent = (eventName: EventName, callback: () => void) => {
    if (!isFeatureAvailable("offEvent")) {
        return;
    }
    tg.offEvent(eventName, callback);
};

// Home Screen Event Handlers
export const onAddedToHomeScreen = (callback: () => void) => {
    onEvent("homeScreenAdded", callback);
};

export const offAddedToHomeScreen = (callback: () => void) => {
    offEvent("homeScreenAdded", callback);
};

export const onAddToHomeScreenFailed = (callback: () => void) => {
    onEvent("homeScreenAddFailed", callback);
};

export const offAddToHomeScreenFailed = (callback: () => void) => {
    offEvent("homeScreenAddFailed", callback);
};

// Telegram Link Methods
export const openTelegramLink = (url: string) => {
    if (!isFeatureAvailable("openTelegramLink")) {
        logger.debug("Falling back to window.open for Telegram link:", url);
        window.open(url, "_blank");
        return;
    }
    tg.openTelegramLink(url);
};

export const showAlert = (message: string) => {
    if (!isFeatureAvailable("showAlert")) {
        logger.debug("Falling back to native alert");
        logger.alert(message);
        return;
    }
    tg.showAlert(message);
};

export const getStartAppParam = () => window.Telegram.WebApp.initDataUnsafe.start_param;
