/* START OF COMPILED CODE */

import Text from "../Text";
/* START-USER-IMPORTS */
import { DEPTH_PRIORITY } from "@/ota-slots/constants";
import { getItemDisplayName, getItemTextureName, getRarityColor } from "@/shared/utils/loot-box";
import { InventoryItem } from "@/shared/types/loot-box";
import Gem from "@/shared/prefabs/loot-box/Gem";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class InventoryItemList extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // bg
        const bg = scene.add.rectangle(194, 421, 390, 844);
        bg.isFilled = true;
        bg.fillColor = 0;
        bg.fillAlpha = 0.8;
        this.add(bg);

        // items_popup_png
        const items_popup_png = scene.add.image(194, 399, "loot_box", "panel-lg.png");
        this.add(items_popup_png);

        // closeButton
        const closeButton = scene.add.image(358, 188, "boss_charger", "close_button_hover.png");
        this.add(closeButton);

        // gemContainer
        const gemContainer = scene.add.container(64, 330);
        this.add(gemContainer);

        // container_1
        const container_1 = scene.add.container(118, 216);
        container_1.blendMode = Phaser.BlendModes.SKIP_CHECK;
        this.add(container_1);

        // newWeapon
        const newWeapon = scene.add.container(-27, -13);
        newWeapon.blendMode = Phaser.BlendModes.SKIP_CHECK;
        newWeapon.visible = false;
        container_1.add(newWeapon);

        // ellipses_glow_png
        const ellipses_glow_png = scene.add.image(25, 46, "loot_box", "ellipses-glow.png");
        newWeapon.add(ellipses_glow_png);

        // newText
        const newText = scene.add.text(0, 0, "", {});
        newText.text = "NEW";
        newText.setStyle({ "color": "#D8E63C", "fontFamily": "Oxanium", "fontStyle": "bold" });
        newWeapon.add(newText);

        // shadowFx
        newText.preFX!.addShadow(0, 0, 0.1, 1, 734504, 6, 1);

        // weapon
        const weapon = scene.add.image(0, 39, "magnum");
        weapon.scaleX = 0.25;
        weapon.scaleY = 0.25;
        container_1.add(weapon);

        // container_3
        const container_3 = scene.add.container(106, 8);
        container_3.blendMode = Phaser.BlendModes.SKIP_CHECK;
        container_1.add(container_3);

        // frame_png
        const frame_png = scene.add.image(52, 38, "loot_box", "frame.png");
        container_3.add(frame_png);

        // weaponRank
        const weaponRank = new Text(scene, 1, 19);
        weaponRank.setOrigin(0, 0);
        weaponRank.text = "N";
        weaponRank.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });
        container_3.add(weaponRank);

        // weaponLevel
        const weaponLevel = new Text(scene, 1, 38);
        weaponLevel.setOrigin(0, 0);
        weaponLevel.text = "level 0";
        weaponLevel.setStyle({ "fontSize": "12px" });
        container_3.add(weaponLevel);

        // weaponAttack
        const weaponAttack = new Text(scene, 0, 58);
        weaponAttack.setOrigin(0, 0);
        weaponAttack.text = "Attack: 0";
        weaponAttack.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });
        container_3.add(weaponAttack);

        // weaponName
        const weaponName = new Text(scene, 1, 0);
        weaponName.setOrigin(0, 0);
        weaponName.text = "Magnum";
        weaponName.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });
        container_3.add(weaponName);

        // your_inventory_png
        const your_inventory_png = scene.add.image(196, 123, "loot_box", "your-inventory.png");
        this.add(your_inventory_png);

        // autoCloseText
        const autoCloseText = scene.add.text(127, 692, "", {});
        autoCloseText.text = "auto close in 3s";
        autoCloseText.setStyle({ "fontFamily": "Oxanium", "fontSize": "20px" });
        this.add(autoCloseText);

        this.bg = bg;
        this.gemContainer = gemContainer;
        this.newWeapon = newWeapon;
        this.weapon = weapon;
        this.weaponRank = weaponRank;
        this.weaponLevel = weaponLevel;
        this.weaponAttack = weaponAttack;
        this.weaponName = weaponName;
        this.autoCloseText = autoCloseText;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        bg.setInteractive();
        new PushComponent(closeButton, () => this.destroy(), this).activate();

        const weaponBg = scene.add.graphics();
        weaponBg.fillStyle(0x000000, 0.5);
        weaponBg.lineStyle(1, 0x000000, 0.5);
        weaponBg.fillRoundedRect(40, -170, 140, 84, 8);
        this.addAt(weaponBg, 4);
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Rectangle;
    private gemContainer: Phaser.GameObjects.Container;
    private newWeapon: Phaser.GameObjects.Container;
    private weapon: Phaser.GameObjects.Image;
    private weaponRank: Text;
    private weaponLevel: Text;
    private weaponAttack: Text;
    private weaponName: Text;
    private autoCloseText: Phaser.GameObjects.Text;
    private autoCloseTimer: Phaser.Time.TimerEvent;

    /* START-USER-CODE */
    private newItem: InventoryItem;

    public init(item: InventoryItem) {
        this.setNewItem(item);
        this.setupWeapon();
        this.setupGems();
        this.startAutoCloseTimer();
    }

    private setNewItem(item: InventoryItem) {
        this.newItem = item;
    }

    private setupWeapon() {
        const userWeapon = this.scene.userManager.getWeapon();
        if (userWeapon) {
            const itemTextureName = getItemTextureName(userWeapon);
            if (itemTextureName) this.weapon.setTexture("loot_box", itemTextureName + ".png");
            this.weaponName.text = getItemDisplayName(userWeapon) as string;
            this.weaponRank.text = userWeapon.rarity.toUpperCase();
            this.weaponRank.setStyle({ color: getRarityColor(userWeapon) });
            this.weaponLevel.text = `Level ${userWeapon.level}`;
            this.weaponAttack.text = `Attack: ${userWeapon.minAtk} - ${userWeapon.maxAtk}`;
            this.newWeapon.setVisible(userWeapon.slot === this.newItem.slot);
        }
    }

    private setupGems() {
        const fightingEquipments = this.scene.userManager.getInventoryItems();
        if (fightingEquipments) {
            fightingEquipments
                .filter((equipment: InventoryItem) => equipment.name === "gem")
                .sort((a: InventoryItem, b: InventoryItem) => a.slot - b.slot)
                .forEach((equipment: InventoryItem, index: number) => {
                    const itemContainer = new Phaser.GameObjects.Container(this.scene, 0, index * 55);
                    const gem = new Gem(this.scene, 0, 0);
                    gem.init(equipment, {
                        hideLevel: true,
                        hideRank: true,
                    });
                    gem.scale = 0.5;

                    const name = new Text(this.scene, 40, -10);
                    name.setOrigin(0, 0);
                    name.text = getItemDisplayName(equipment) as string;
                    name.setStyle({ "fontSize": "12px", color: "#FEFEFE" });
                    name.setWordWrapWidth(70);
                    name.setLineSpacing(5);

                    const rarityLabel = new Text(this.scene, 120, -10);
                    rarityLabel.text = "Rarity";
                    rarityLabel.setOrigin(0, 0);
                    rarityLabel.setStyle({ "fontSize": "8px", color: "#FEFEFE" });

                    const rarity = new Text(this.scene, 120, 5);
                    rarity.setOrigin(0, 0);
                    rarity.text = equipment.rarity.toUpperCase();
                    rarity.setStyle({ "fontSize": "12px", color: "#FEFEFE" });

                    const levelLabel = new Text(this.scene, 180, -10);
                    levelLabel.text = "Level";
                    levelLabel.setOrigin(0, 0);
                    levelLabel.setStyle({ "fontSize": "8px", color: "#FEFEFE" });

                    const level = new Text(this.scene, 180, 5);
                    level.setOrigin(0, 0);
                    level.text = equipment.level.toString();
                    level.setStyle({ "fontSize": "12px", color: "#FEFEFE" });

                    const attackLabel = new Text(this.scene, 240, -10);
                    attackLabel.text = "Attack";
                    attackLabel.setOrigin(0, 0);
                    attackLabel.setStyle({ "fontSize": "8px", color: "#FEFEFE" });

                    const attack = new Text(this.scene, 240, 5);
                    attack.setOrigin(0, 0);
                    attack.text = `${equipment.minAtk} - ${equipment.maxAtk}`;
                    attack.setStyle({ "fontSize": "12px", color: "#FEFEFE" });

                    const newItemGlow = this.scene.add.image(135, 0, "loot_box", "new-item-glow.png").setVisible(false);
                    if (equipment.slot === this.newItem.slot) {
                        newItemGlow.setVisible(true);
                        name.setStyle({ color: "#DEA614" });
                        rarity.setStyle({ color: "#DEA614" });
                        attack.setStyle({ color: "#DEA614" });
                        level.setStyle({ color: "#DEA614" });
                    }

                    itemContainer.add([
                        newItemGlow,
                        gem,
                        name,
                        rarityLabel,
                        rarity,
                        levelLabel,
                        level,
                        attackLabel,
                        attack,
                    ]);
                    this.gemContainer.add(itemContainer);
                });
        }
    }

    private startAutoCloseTimer() {
        let secondsLeft = 5;
        this.autoCloseText.setText(`auto close in ${secondsLeft}s`);
        this.autoCloseTimer = this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
                secondsLeft--;
                if (secondsLeft > 0) {
                    this.autoCloseText.setText(`auto close in ${secondsLeft}s`);
                } else {
                    this.destroy();
                }
            },
            repeat: secondsLeft - 1,
        });
    }

    override destroy(fromScene?: boolean) {
        if (this.autoCloseTimer) {
            this.autoCloseTimer.destroy();
        }
        super.destroy(fromScene);
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
