export const getWinType = (
    totalWinAmount: number,
    totalBet: number,
): "none" | "big_win" | "huge_win" | "massive_win" | "super_win" => {
    if (!totalWinAmount) {
        return "none";
    }
    const multiplier = totalWinAmount / totalBet;

    if (multiplier >= 20) {
        return "super_win";
    } else if (multiplier >= 15) {
        return "massive_win";
    } else if (multiplier >= 10) {
        return "huge_win";
    } else if (multiplier >= 5) {
        return "big_win";
    }

    return "none";
};
