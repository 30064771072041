// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { EventBus } from "@/lib/event-bus";
import { CUSTOM_EVENTS } from "@/ota-mining/game/components/events/EventBusComponent";
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class WinningPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        this.blendMode = Phaser.BlendModes.SKIP_CHECK;

        // background
        const background = scene.add.rectangle(195, 422, 390, 844);
        background.visible = false;
        background.isFilled = true;
        background.fillColor = 0;
        background.fillAlpha = 0.8;
        this.add(background);

        // win
        const win = scene.add.sprite(195, 468, "you_win", "you_win_coins_anim_00000.png");
        win.scaleX = 0.4;
        win.scaleY = 0.4;
        this.add(win);

        // amount
        const amount = scene.add.container(32, 454);
        amount.blendMode = Phaser.BlendModes.SKIP_CHECK;
        amount.visible = false;
        this.add(amount);

        // winAmountText
        const winAmountText = new Text(scene, 171, 31);
        winAmountText.setOrigin(0.5, 0.5);
        winAmountText.text = "0";
        winAmountText.setStyle({ "color": "#f3e192", "fontSize": "24px", "stroke": "#f3e192" });
        amount.add(winAmountText);

        // coinIcon
        const coinIcon = scene.add.image(104, 0, "coin_icon");
        coinIcon.scaleX = 0.5;
        coinIcon.scaleY = 0.5;
        coinIcon.setOrigin(0, 0);
        amount.add(coinIcon);

        this.background = background;
        this.win = win;
        this.winAmountText = winAmountText;
        this.coinIcon = coinIcon;
        this.amount = amount;

        /* START-USER-CTR-CODE */
        background.setInteractive();
        /* END-USER-CTR-CODE */
    }

    private background: Phaser.GameObjects.Rectangle;
    private win: Phaser.GameObjects.Sprite;
    private winAmountText: Text;
    private coinIcon: Phaser.GameObjects.Image;
    private amount: Phaser.GameObjects.Container;

    /* START-USER-CODE */
    public show(winType: "you_win" | "big_win", options: { winAmount: number; duration?: number }) {
        if (winType === "big_win") {
            this.win.setTexture("big_win_1", "big_win_animation_00000.png");
        } else if (winType === "you_win") {
            this.win.setTexture("you_win", "you_win_coins_anim_00000.png");
        }
        this.win.play(winType);

        this.scene.time.delayedCall(500, () => {
            EventBus.emit(CUSTOM_EVENTS.COIN_ANIMATION);

            this.coinIcon.setX(this.winAmountText.x - this.winAmountText.width / 2 - this.coinIcon.displayWidth - 4);
            this.amount.setVisible(true);
            this.scene.tweens.addCounter({
                from: 0,
                to: options.winAmount,
                duration: options.duration ?? 200,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.winAmountText.setText(`${numberWithCommas(value)}`);

                    // Update coin icon position based on text width
                    const textWidth = this.winAmountText.width;
                    this.coinIcon.setX(this.winAmountText.x - textWidth / 2 - this.coinIcon.displayWidth - 4);
                },
                onComplete: () => {
                    const timer = this.scene.time.delayedCall(3000, () => {
                        this.background.setInteractive(false);
                        this.destroy();
                    });

                    this.background.on("pointerdown", () => {
                        timer.remove();
                        this.destroy();
                    });
                },
            });
        });
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
